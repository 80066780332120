var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-container",
    { staticClass: "pa-0", attrs: { fluid: "" } },
    [
      _c(
        "v-layout",
        { attrs: { flex: "", "align-center": "", "justify-center": "" } },
        [
          _c(
            "v-flex",
            { attrs: { md12: "" } },
            [
              _c(
                "v-card",
                { attrs: { tile: "" } },
                [
                  _c(
                    "v-toolbar",
                    { attrs: { dense: "", dark: "", color: "primary" } },
                    [
                      _c("Breadcrumbs", {
                        attrs: { customLastCrumb: "User Profile" }
                      })
                    ],
                    1
                  ),
                  _c("v-divider")
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _c(
        "v-card",
        [
          _c("UserDetails", {
            attrs: { "user-data": _vm.userData, visible: "true" }
          })
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
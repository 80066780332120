var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-row",
    [
      _c(
        "v-expansion-panels",
        { staticClass: "ma-5" },
        [
          _c(
            "v-expansion-panel",
            [
              _c("v-expansion-panel-header", { staticClass: "primary" }, [
                _c("div", { staticClass: "headline" }, [_vm._v("Tips")])
              ]),
              _c(
                "v-expansion-panel-content",
                [
                  _c(
                    "v-row",
                    [
                      _c("v-spacer"),
                      !_vm.isExported
                        ? _c(
                            "v-btn",
                            {
                              directives: [
                                {
                                  name: "show",
                                  rawName: "v-show",
                                  value:
                                    !_vm.editing &&
                                    _vm.assigned &&
                                    _vm.eventEnd(),
                                  expression:
                                    "!editing && assigned && eventEnd()"
                                }
                              ],
                              staticClass: "ma-1",
                              attrs: { color: "accent" },
                              on: { click: _vm.editingTips }
                            },
                            [_vm._v("Edit")]
                          )
                        : _vm._e(),
                      _c(
                        "v-btn",
                        {
                          directives: [
                            {
                              name: "show",
                              rawName: "v-show",
                              value: _vm.editing,
                              expression: "editing"
                            }
                          ],
                          staticClass: "ma-1",
                          attrs: { color: "accent" },
                          on: { click: _vm.cancelEditing }
                        },
                        [_vm._v("Cancel")]
                      )
                    ],
                    1
                  ),
                  _c(
                    "v-card-actions",
                    [
                      _c(
                        "v-row",
                        {
                          directives: [
                            {
                              name: "show",
                              rawName: "v-show",
                              value: _vm.assigned,
                              expression: "assigned"
                            }
                          ]
                        },
                        [
                          _c("v-spacer"),
                          _c(
                            "v-col",
                            { attrs: { cols: "4", sm: "4", md: "4" } },
                            [
                              _c(
                                "v-card",
                                { attrs: { tile: "" } },
                                [
                                  _c(
                                    "v-card-title",
                                    {
                                      staticClass:
                                        "body-2 headline customPadding"
                                    },
                                    [_c("span", [_vm._v("Total Tips")])]
                                  ),
                                  _c(
                                    "v-card-text",
                                    { staticClass: "headline customPadding" },
                                    [
                                      _vm._v(
                                        " " +
                                          _vm._s(
                                            _vm.getNumeral(this.totalTipAmount)
                                          ) +
                                          " "
                                      )
                                    ]
                                  )
                                ],
                                1
                              )
                            ],
                            1
                          ),
                          _c("v-spacer"),
                          _c(
                            "v-col",
                            { attrs: { cols: "4", sm: "4", md: "4" } },
                            [
                              _c(
                                "v-card",
                                { attrs: { tile: "" } },
                                [
                                  _c(
                                    "v-card-title",
                                    {
                                      staticClass:
                                        "body-2 headline customPadding"
                                    },
                                    [_c("span", [_vm._v("Disbursed")])]
                                  ),
                                  _c(
                                    "v-card-text",
                                    { staticClass: "headline customPadding" },
                                    [
                                      _vm._v(
                                        " " +
                                          _vm._s(
                                            _vm.getNumeral(
                                              _vm.getTotalDisbursed()
                                            )
                                          ) +
                                          " "
                                      )
                                    ]
                                  )
                                ],
                                1
                              )
                            ],
                            1
                          ),
                          _c("v-spacer"),
                          _c(
                            "v-col",
                            { attrs: { cols: "4", sm: "4", md: "4" } },
                            [
                              _c(
                                "v-card",
                                {
                                  attrs: {
                                    tile: "",
                                    color: _vm.setRemainingCardColor()
                                  }
                                },
                                [
                                  _c(
                                    "v-card-title",
                                    {
                                      staticClass:
                                        "body-2 headline customPadding"
                                    },
                                    [_c("span", [_vm._v("Remaining")])]
                                  ),
                                  _c(
                                    "v-card-text",
                                    { staticClass: "headline customPadding" },
                                    [
                                      _vm._v(
                                        " " + _vm._s(_vm.getRemainder()) + " "
                                      )
                                    ]
                                  )
                                ],
                                1
                              )
                            ],
                            1
                          ),
                          _c("v-spacer")
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c("v-data-table", {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: _vm.editing,
                        expression: "editing"
                      }
                    ],
                    attrs: { headers: _vm.headers, items: _vm.tipsList },
                    scopedSlots: _vm._u([
                      {
                        key: "top",
                        fn: function() {
                          return [
                            _c(
                              "v-row",
                              { staticClass: "justify-center" },
                              _vm._l(_vm.splitTipsSelect, function(select) {
                                return _c(
                                  "v-card",
                                  {
                                    key: select.id,
                                    staticClass: "ma-2",
                                    attrs: {
                                      flat: "",
                                      color: _vm.splitTipsColor(select)
                                    }
                                  },
                                  [
                                    _c(
                                      "v-card-title",
                                      {
                                        staticClass: "headline justify-center"
                                      },
                                      [_vm._v(" " + _vm._s(select.text) + " ")]
                                    ),
                                    _c(
                                      "v-card-text",
                                      { staticClass: "headline text-center" },
                                      [
                                        _vm._v(
                                          " " +
                                            _vm._s("$" + select.amount) +
                                            " "
                                        )
                                      ]
                                    ),
                                    _c("v-select", {
                                      attrs: {
                                        label: "Split Tips",
                                        items: _vm.splitTipsOptions,
                                        "hide-details": "",
                                        solo: "",
                                        type: "text"
                                      },
                                      model: {
                                        value: _vm.$data[select.model],
                                        callback: function($$v) {
                                          _vm.$set(_vm.$data, select.model, $$v)
                                        },
                                        expression: "$data[select.model]"
                                      }
                                    })
                                  ],
                                  1
                                )
                              }),
                              1
                            )
                          ]
                        },
                        proxy: true
                      },
                      {
                        key: "item.name",
                        fn: function(ref) {
                          var item = ref.item
                          return [
                            _vm._v(" " + _vm._s(item.name) + " "),
                            _c(
                              "v-tooltip",
                              {
                                attrs: { bottom: "" },
                                scopedSlots: _vm._u(
                                  [
                                    {
                                      key: "activator",
                                      fn: function(ref) {
                                        var on = ref.on
                                        return [
                                          _c(
                                            "v-icon",
                                            _vm._g(
                                              { attrs: { color: "green" } },
                                              on
                                            ),
                                            [
                                              _vm._v(
                                                _vm._s(
                                                  _vm.hasDriverBonus(
                                                    item.userId
                                                  )
                                                )
                                              )
                                            ]
                                          )
                                        ]
                                      }
                                    }
                                  ],
                                  null,
                                  true
                                )
                              },
                              [_c("span", [_vm._v("Driver Pay Added")])]
                            )
                          ]
                        }
                      },
                      {
                        key: "item.gratuity",
                        fn: function(ref) {
                          var item = ref.item
                          return [
                            _c("v-currency-field", {
                              attrs: {
                                type: "number",
                                dense: "",
                                "single-line": "",
                                "hide-details": "",
                                clearable: "",
                                prefix: "$",
                                min: 0,
                                max: _vm.getMax(item.gratuity)
                              },
                              model: {
                                value: item.gratuity,
                                callback: function($$v) {
                                  _vm.$set(item, "gratuity", _vm._n($$v))
                                },
                                expression: "item.gratuity"
                              }
                            }),
                            _c("v-slider", {
                              attrs: {
                                step: ".01",
                                dense: "",
                                "hide-details": "",
                                success:
                                  _vm.getCurrentGratuitySum() >=
                                  _vm.totalGratuityAmount,
                                min: 0,
                                max: _vm.getMax(item.gratuity)
                              },
                              scopedSlots: _vm._u(
                                [
                                  {
                                    key: "prepend",
                                    fn: function() {
                                      return [
                                        _c(
                                          "v-icon",
                                          {
                                            attrs: {
                                              disabled: item.gratuity <= 0
                                            },
                                            on: {
                                              click: function($event) {
                                                return _vm.decrementGratuity(
                                                  item
                                                )
                                              }
                                            }
                                          },
                                          [_vm._v(" mdi-minus ")]
                                        )
                                      ]
                                    },
                                    proxy: true
                                  },
                                  {
                                    key: "append",
                                    fn: function() {
                                      return [
                                        _c(
                                          "v-icon",
                                          {
                                            attrs: {
                                              disabled:
                                                _vm.getCurrentGratuitySum() >=
                                                _vm.totalGratuityAmount
                                            },
                                            on: {
                                              click: function($event) {
                                                return _vm.incrementGratuity(
                                                  item
                                                )
                                              }
                                            }
                                          },
                                          [_vm._v(" mdi-plus ")]
                                        )
                                      ]
                                    },
                                    proxy: true
                                  }
                                ],
                                null,
                                true
                              ),
                              model: {
                                value: item.gratuity,
                                callback: function($$v) {
                                  _vm.$set(item, "gratuity", _vm._n($$v))
                                },
                                expression: "item.gratuity"
                              }
                            })
                          ]
                        }
                      },
                      {
                        key: "item.autoGratuity",
                        fn: function(ref) {
                          var item = ref.item
                          return [
                            _c("v-currency-field", {
                              attrs: {
                                type: "number",
                                dense: "",
                                "single-line": "",
                                "hide-details": "",
                                clearable: "",
                                prefix: "$",
                                min: 0,
                                max: _vm.getMax(item.autoGratuity, true)
                              },
                              model: {
                                value: item.autoGratuity,
                                callback: function($$v) {
                                  _vm.$set(item, "autoGratuity", _vm._n($$v))
                                },
                                expression: "item.autoGratuity"
                              }
                            }),
                            _c("v-slider", {
                              attrs: {
                                step: ".01",
                                dense: "",
                                "hide-details": "",
                                success:
                                  _vm.getCurrentAutoGratuitySum() >=
                                  _vm.totalAutoGratuityAmount,
                                min: 0,
                                max: _vm.getMax(item.autoGratuity, true)
                              },
                              scopedSlots: _vm._u(
                                [
                                  {
                                    key: "prepend",
                                    fn: function() {
                                      return [
                                        _c(
                                          "v-icon",
                                          {
                                            attrs: {
                                              disabled: item.autoGratuity <= 0
                                            },
                                            on: {
                                              click: function($event) {
                                                return _vm.decrementAutoGratuity(
                                                  item
                                                )
                                              }
                                            }
                                          },
                                          [_vm._v(" mdi-minus ")]
                                        )
                                      ]
                                    },
                                    proxy: true
                                  },
                                  {
                                    key: "append",
                                    fn: function() {
                                      return [
                                        _c(
                                          "v-icon",
                                          {
                                            attrs: {
                                              disabled:
                                                _vm.getCurrentAutoGratuitySum() >=
                                                _vm.totalAutoGratuityAmount
                                            },
                                            on: {
                                              click: function($event) {
                                                return _vm.incrementAutoGratuity(
                                                  item
                                                )
                                              }
                                            }
                                          },
                                          [_vm._v(" mdi-plus ")]
                                        )
                                      ]
                                    },
                                    proxy: true
                                  }
                                ],
                                null,
                                true
                              ),
                              model: {
                                value: item.autoGratuity,
                                callback: function($$v) {
                                  _vm.$set(item, "autoGratuity", _vm._n($$v))
                                },
                                expression: "item.autoGratuity"
                              }
                            })
                          ]
                        }
                      },
                      {
                        key: "item.comments",
                        fn: function(ref) {
                          var item = ref.item
                          return [
                            _c(
                              "v-textarea",
                              {
                                attrs: {
                                  "auto-grow": "",
                                  clearable: "",
                                  outlined: "",
                                  dense: "",
                                  rows: "1",
                                  "hide-details": "",
                                  "full-width": ""
                                },
                                model: {
                                  value: item.comments,
                                  callback: function($$v) {
                                    _vm.$set(item, "comments", $$v)
                                  },
                                  expression: "item.comments"
                                }
                              },
                              [_vm._v(_vm._s(item.comments))]
                            )
                          ]
                        }
                      }
                    ]),
                    model: {
                      value: _vm.tipsList,
                      callback: function($$v) {
                        _vm.tipsList = $$v
                      },
                      expression: "tipsList"
                    }
                  }),
                  _c(
                    "v-card-subtitle",
                    {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: !_vm.assigned,
                          expression: "!assigned"
                        }
                      ]
                    },
                    [_vm._v("No employees assigned to the event. ")]
                  ),
                  _c(
                    "v-row",
                    [
                      _c(
                        "v-container",
                        [
                          _c("v-data-table", {
                            directives: [
                              {
                                name: "show",
                                rawName: "v-show",
                                value:
                                  !_vm.editing &&
                                  _vm.assigned &&
                                  _vm.eventEnd(),
                                expression: "!editing && assigned && eventEnd()"
                              }
                            ],
                            attrs: {
                              headers: _vm.headers,
                              items: _vm.tipsList,
                              "item-key": "user.name",
                              "sort-by": "user"
                            },
                            scopedSlots: _vm._u([
                              {
                                key: "item.name",
                                fn: function(ref) {
                                  var item = ref.item
                                  return [
                                    _vm._v(" " + _vm._s(item.name) + " "),
                                    _c(
                                      "v-tooltip",
                                      {
                                        attrs: { bottom: "" },
                                        scopedSlots: _vm._u(
                                          [
                                            {
                                              key: "activator",
                                              fn: function(ref) {
                                                var on = ref.on
                                                return [
                                                  _c(
                                                    "v-icon",
                                                    _vm._g(
                                                      {
                                                        attrs: {
                                                          color: "green"
                                                        }
                                                      },
                                                      on
                                                    ),
                                                    [
                                                      _vm._v(
                                                        _vm._s(
                                                          _vm.hasDriverBonus(
                                                            item.userId
                                                          )
                                                        )
                                                      )
                                                    ]
                                                  )
                                                ]
                                              }
                                            }
                                          ],
                                          null,
                                          true
                                        )
                                      },
                                      [_c("span", [_vm._v("Driver Pay Added")])]
                                    )
                                  ]
                                }
                              },
                              {
                                key: "item.gratuity",
                                fn: function(ref) {
                                  var item = ref.item
                                  return [
                                    _vm._v(
                                      " " +
                                        _vm._s(_vm.getNumeral(item.gratuity)) +
                                        " "
                                    )
                                  ]
                                }
                              },
                              {
                                key: "item.autoGratuity",
                                fn: function(ref) {
                                  var item = ref.item
                                  return [
                                    _vm._v(
                                      " " +
                                        _vm._s(
                                          _vm.getNumeral(item.autoGratuity)
                                        ) +
                                        " "
                                    )
                                  ]
                                }
                              }
                            ])
                          }),
                          _c(
                            "v-card-subtitle",
                            {
                              directives: [
                                {
                                  name: "show",
                                  rawName: "v-show",
                                  value:
                                    !_vm.editing &&
                                    _vm.assigned &&
                                    !_vm.eventEnd(),
                                  expression:
                                    "!editing && assigned && !eventEnd()"
                                }
                              ]
                            },
                            [_vm._v(" Cannot Assign tips before Event ")]
                          )
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "v-card-actions",
                    [
                      _c("v-spacer"),
                      _c(
                        "v-btn",
                        {
                          directives: [
                            {
                              name: "show",
                              rawName: "v-show",
                              value: _vm.editing,
                              expression: "editing"
                            }
                          ],
                          attrs: { color: "accent" },
                          on: {
                            click: function($event) {
                              return _vm.putTips()
                            }
                          }
                        },
                        [_vm._v("Save")]
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
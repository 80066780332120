import Vue from "vue";
import VueRouter from "vue-router";
import store from "@/store/store";
import {Role} from "@/helpers/role";
import Home from "@/views/Home.vue";
import Orders from "@/views/Orders.vue";
import Schedule from "@/views/Schedule.vue";
import MyAssignments from "@/views/MyAssignments.vue";
import Tips from "@/views/Tips.vue";
import Commissions from "@/views/Commissions.vue";
import Timeclock from "@/views/Timeclock.vue";
import PayrollTips from "@/views/PayrollTips.vue";
import PayrollCommissions from "@/views/PayrollCommissions.vue";
import PayrollInquiry from "@/views/PayrollInquiry.vue";
import RefreshOrder from "@/views/RefreshOrder";
import Login from "@/views/Login.vue";
import Admin from "@/views/Admin.vue";
import ForgotPassword from "@/views/ForgotPassword.vue";
import ResetPassword from "@/views/ResetPassword.vue";
import OrderDetails from "@/views/OrderDetails.vue";
import ScheduleDetails from "@/views/ScheduleDetails.vue";
import AdminAssignments from "@/views/AdminAssignments";
import TimeClockAudit from "@/views/TimeClockAudit";
import ViewProfile from "@/views/ViewProfile.vue";
import UserSearch from "@/views/UserSearch.vue";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "Home",
    component: Home,
    meta: {requiresAuth: true},
  },
  {
    path: "/scheduleemployees",
    name: "Schedule Employees",
    component: Orders,
    meta: {
      requiresAuth: true,
      authorize: [
        Role.Administrator,
        Role.CateringManager,
        Role.SalesRep,
        Role.Payroll,
      ],
    },
  },
  {
    path: "/timeclockaudit",
    name: "TimeClockAudit",
    component: TimeClockAudit,
    meta: {
      requiresAuth: true,
      authorize: [
        Role.Administrator,
        Role.CateringManager,
        Role.SalesRep,
        Role.Payroll,
      ],
    },
  },
  {
    path: "/events",
    name: "Events",
    component: Schedule,
    meta: {
      requiresAuth: true,
      authorize: [
        Role.Administrator,
        Role.CateringManager,
        Role.SalesRep,
        Role.Employee,
        Role.Payroll,
      ],
    },
  },
  {
    path: "/myschedule",
    name: "My Schedule",
    component: MyAssignments,
    meta: {
      requiresAuth: true,
      authorize: [
        Role.Administrator,
        Role.CateringManager,
        Role.SalesRep,
        Role.Employee,
        Role.Payroll,
      ],
    },
  },
  {
    path: "/scheduleemployees/orderdetails",
    name: "OrderDetails",
    component: OrderDetails,
    meta: {
      requiresAuth: true,
      authorize: [Role.Administrator, Role.CateringManager, Role.SalesRep],
    },
    beforeEnter(to, from, next) {
      if (to.params && to.params["orderId"]) {
        store.dispatch("setWorkingOrderId", to.params["orderId"]);
      }
      next(); // make sure to always call next()!
    },
  },
  {
    path: "/events/scheduledetails",
    name: "ScheduleDetails",
    component: ScheduleDetails,
    props: true,
    meta: {
      requiresAuth: true,
      authorize: [
        Role.Administrator,
        Role.CateringManager,
        Role.SalesRep,
        Role.CallCenter,
        Role.Employee,
        Role.Payroll,
      ],
    },
    beforeEnter(to, from, next) {
      if (to.params && to.params["orderId"]) {
        store.dispatch("setWorkingOrderId", to.params["orderId"]);
      }
      next(); // make sure to always call next()!
    },
  },
  {
    path: "/tips",
    name: "Tips",
    component: Tips,
    meta: {
      requiresAuth: true,
      authorize: [Role.Administrator, Role.Employee],
    },
  },
  {
    path: "/commissions",
    name: "Commissions",
    component: Commissions,
    meta: {
      requiresAuth: true,
      authorize: [Role.Administrator, Role.SalesRep, Role.CallCenter],
    },
  },
  {
    path: "/timeclock",
    name: "Timeclock",
    component: Timeclock,
    meta: {
      requiresAuth: true,
      authorize: [Role.Administrator, Role.Employee],
    },
  },
  {
    path: "/payrolltips",
    name: "PayrollTips",
    component: PayrollTips,
    meta: {
      requiresAuth: true,
      authorize: [Role.Administrator, Role.CateringManager, Role.Payroll],
    },
  },
  {
    path: "/payrollcommissions",
    name: "PayrollCommissions",
    component: PayrollCommissions,
    meta: {
      requiresAuth: true,
      authorize: [Role.Administrator, Role.Payroll, Role.CateringManager]},
  },
  {
    path: "/payrollinquiry",
    name: "PayrollInquiry",
    component: PayrollInquiry,
    meta: {
      requiresAuth: true,
      authorize: [Role.Administrator, Role.CateringManager, Role.Payroll],
    },
  },
  {
    path: "/refreshorder",
    name: "RefreshOrder",
    component: RefreshOrder,
    meta: {
      requiresAuth: true,
      // eslint-disable-next-line max-len
      authorize: [Role.Administrator, Role.CateringManager, Role.Payroll, Role.SalesRep],
    },
  },
  {
    path: "/adminassignments",
    name: "AdminAssignments",
    component: AdminAssignments,
    meta: {
      requiresAuth: true,
      authorize: [Role.Administrator, Role.CateringManager],
    },
  },
  {
    path: "/admin",
    name: "Admin",
    component: Admin,
    meta: {requiresAuth: true, authorize: [Role.Administrator]},
  },
  {
    path: "/login",
    name: "Login",
    component: Login,
    meta: {requiresAuth: false},
  },
  {
    path: "/viewProfile",
    name: "ViewProfile",
    component: ViewProfile,
    meta: {requiresAuth: true},
  },
  {
    path: "/userSearch",
    name: "UserSearch",
    component: UserSearch,
    meta: {requiresAuth: true},
  },
  {
    path: "/forgotpassword",
    name: "ForgotPassword",
    component: ForgotPassword,
    meta: {requiresAuth: false},
  },
  {
    path: "/resetpassword/:email/:code(.*)",
    name: "ResetPassword",
    component: ResetPassword,
    meta: {requiresAuth: false},
    props: true,
  },
];

const router = new VueRouter({
  routes,
  mode: "history",
});

router.beforeEach((to, from, next) => {
  if (to.matched.some((record) => record.meta.requiresAuth)) {
    // this route requires auth, check if logged in
    // if not, redirect to login page.
    if (!store.getters["isAuthenticated"]) {
      next({
        path: "/login",
        query: {redirect: to.fullPath},
      });
    } else {
      if (to.matched.some((record) => record.meta.authorize)) {
        const currentUser = store.getters["getCurrentUser"];
        const authorize = to.meta.authorize;

        if (!currentUser) {
          // not logged in so redirect to login page with the return url
          next({
            path: "/login",
            query: {redirect: to.fullPath},
          });
        }
        // check if route is restricted by role
        if (authorize.length && currentUser.roles && currentUser.roles.length) {
          let authorized = false;
          currentUser.roles.forEach((r) => {
            if (authorize.includes(r)) {
              authorized = true;
            }
          });
          if (!authorized) {
            // role not authorized so redirect to home page
            next({path: "/"});
          }
        }
      }
      next();
    }
  } else {
    next(); // make sure to always call next()!
  }
});

export default router;

var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-container",
    { staticClass: "pa-0", attrs: { fluid: "" } },
    [
      _c(
        "v-layout",
        { attrs: { flex: "", "align-center": "", "justify-center": "" } },
        [
          _c(
            "v-flex",
            { attrs: { md12: "" } },
            [
              _c(
                "v-card",
                { attrs: { tile: "" } },
                [
                  _c(
                    "v-toolbar",
                    { attrs: { dense: "", color: "primary" } },
                    [_c("Breadcrumbs")],
                    1
                  ),
                  _c(
                    "v-toolbar",
                    { attrs: { flat: "" } },
                    [
                      _c(
                        "v-toolbar-items",
                        {
                          staticClass: "hidden-sm-and-down",
                          staticStyle: { width: "100%" }
                        },
                        [
                          _c(
                            "v-row",
                            [
                              _c(
                                "v-col",
                                [
                                  _c("v-text-field", {
                                    staticClass: "mt-2",
                                    attrs: {
                                      "append-icon": "mdi-magnify",
                                      label: "Search",
                                      "single-line": "",
                                      "hide-details": "",
                                      clearable: ""
                                    },
                                    model: {
                                      value: _vm.search,
                                      callback: function($$v) {
                                        _vm.search = $$v
                                      },
                                      expression: "search"
                                    }
                                  })
                                ],
                                1
                              ),
                              _c(
                                "v-col",
                                [
                                  _c(
                                    "v-menu",
                                    {
                                      attrs: {
                                        "close-on-content-click": false,
                                        transition: "scale-transition",
                                        "offset-y": ""
                                      },
                                      scopedSlots: _vm._u([
                                        {
                                          key: "activator",
                                          fn: function(ref) {
                                            var on = ref.on
                                            return [
                                              _c(
                                                "v-text-field",
                                                _vm._g(
                                                  {
                                                    attrs: {
                                                      label: "Order Start",
                                                      "prepend-icon": "event",
                                                      readonly: "",
                                                      clearable: ""
                                                    },
                                                    model: {
                                                      value:
                                                        _vm.filterStartDate,
                                                      callback: function($$v) {
                                                        _vm.filterStartDate = $$v
                                                      },
                                                      expression:
                                                        "filterStartDate"
                                                    }
                                                  },
                                                  on
                                                )
                                              )
                                            ]
                                          }
                                        }
                                      ]),
                                      model: {
                                        value: _vm.startDateMenu,
                                        callback: function($$v) {
                                          _vm.startDateMenu = $$v
                                        },
                                        expression: "startDateMenu"
                                      }
                                    },
                                    [
                                      _c("v-date-picker", {
                                        on: {
                                          input: function($event) {
                                            _vm.startDateMenu = false
                                          }
                                        },
                                        model: {
                                          value: _vm.filterStartDate,
                                          callback: function($$v) {
                                            _vm.filterStartDate = $$v
                                          },
                                          expression: "filterStartDate"
                                        }
                                      })
                                    ],
                                    1
                                  )
                                ],
                                1
                              ),
                              _c(
                                "v-col",
                                [
                                  _c(
                                    "v-menu",
                                    {
                                      attrs: {
                                        "close-on-content-click": false,
                                        transition: "scale-transition",
                                        "offset-y": ""
                                      },
                                      scopedSlots: _vm._u([
                                        {
                                          key: "activator",
                                          fn: function(ref) {
                                            var on = ref.on
                                            return [
                                              _c(
                                                "v-text-field",
                                                _vm._g(
                                                  {
                                                    attrs: {
                                                      label: "Order End",
                                                      "prepend-icon": "event",
                                                      readonly: "",
                                                      clearable: ""
                                                    },
                                                    model: {
                                                      value: _vm.filterEndDate,
                                                      callback: function($$v) {
                                                        _vm.filterEndDate = $$v
                                                      },
                                                      expression:
                                                        "filterEndDate"
                                                    }
                                                  },
                                                  on
                                                )
                                              )
                                            ]
                                          }
                                        }
                                      ]),
                                      model: {
                                        value: _vm.endDateMenu,
                                        callback: function($$v) {
                                          _vm.endDateMenu = $$v
                                        },
                                        expression: "endDateMenu"
                                      }
                                    },
                                    [
                                      _c("v-date-picker", {
                                        on: {
                                          input: function($event) {
                                            _vm.endDateMenu = false
                                          }
                                        },
                                        model: {
                                          value: _vm.filterEndDate,
                                          callback: function($$v) {
                                            _vm.filterEndDate = $$v
                                          },
                                          expression: "filterEndDate"
                                        }
                                      })
                                    ],
                                    1
                                  )
                                ],
                                1
                              ),
                              _c(
                                "v-col",
                                [
                                  _c(
                                    "v-menu",
                                    {
                                      attrs: {
                                        "close-on-content-click": false,
                                        transition: "scale-transition",
                                        "offset-y": ""
                                      },
                                      scopedSlots: _vm._u([
                                        {
                                          key: "activator",
                                          fn: function(ref) {
                                            var on = ref.on
                                            return [
                                              _c(
                                                "v-text-field",
                                                _vm._g(
                                                  {
                                                    attrs: {
                                                      label: "Paycheck Start",
                                                      "prepend-icon": "event",
                                                      readonly: "",
                                                      clearable: ""
                                                    },
                                                    model: {
                                                      value:
                                                        _vm.filterPayStartDate,
                                                      callback: function($$v) {
                                                        _vm.filterPayStartDate = $$v
                                                      },
                                                      expression:
                                                        "filterPayStartDate"
                                                    }
                                                  },
                                                  on
                                                )
                                              )
                                            ]
                                          }
                                        }
                                      ]),
                                      model: {
                                        value: _vm.payStartDateMenu,
                                        callback: function($$v) {
                                          _vm.payStartDateMenu = $$v
                                        },
                                        expression: "payStartDateMenu"
                                      }
                                    },
                                    [
                                      _c("v-date-picker", {
                                        on: {
                                          input: function($event) {
                                            _vm.payStartDateMenu = false
                                          }
                                        },
                                        model: {
                                          value: _vm.filterPayStartDate,
                                          callback: function($$v) {
                                            _vm.filterPayStartDate = $$v
                                          },
                                          expression: "filterPayStartDate"
                                        }
                                      })
                                    ],
                                    1
                                  )
                                ],
                                1
                              ),
                              _c(
                                "v-col",
                                [
                                  _c(
                                    "v-menu",
                                    {
                                      attrs: {
                                        "close-on-content-click": false,
                                        transition: "scale-transition",
                                        "offset-y": ""
                                      },
                                      scopedSlots: _vm._u([
                                        {
                                          key: "activator",
                                          fn: function(ref) {
                                            var on = ref.on
                                            return [
                                              _c(
                                                "v-text-field",
                                                _vm._g(
                                                  {
                                                    attrs: {
                                                      label: "Paycheck End",
                                                      "prepend-icon": "event",
                                                      readonly: "",
                                                      clearable: ""
                                                    },
                                                    model: {
                                                      value:
                                                        _vm.filterPayEndDate,
                                                      callback: function($$v) {
                                                        _vm.filterPayEndDate = $$v
                                                      },
                                                      expression:
                                                        "filterPayEndDate"
                                                    }
                                                  },
                                                  on
                                                )
                                              )
                                            ]
                                          }
                                        }
                                      ]),
                                      model: {
                                        value: _vm.payEndDateMenu,
                                        callback: function($$v) {
                                          _vm.payEndDateMenu = $$v
                                        },
                                        expression: "payEndDateMenu"
                                      }
                                    },
                                    [
                                      _c("v-date-picker", {
                                        on: {
                                          input: function($event) {
                                            _vm.payEndDateMenu = false
                                          }
                                        },
                                        model: {
                                          value: _vm.filterPayEndDate,
                                          callback: function($$v) {
                                            _vm.filterPayEndDate = $$v
                                          },
                                          expression: "filterPayEndDate"
                                        }
                                      })
                                    ],
                                    1
                                  )
                                ],
                                1
                              )
                            ],
                            1
                          )
                        ],
                        1
                      ),
                      _c(
                        "v-menu",
                        {
                          attrs: { "close-on-content-click": false },
                          scopedSlots: _vm._u([
                            {
                              key: "activator",
                              fn: function(ref) {
                                var on = ref.on
                                return [
                                  _c(
                                    "span",
                                    { staticClass: "hidden-md-and-up" },
                                    [_vm._v("Filters")]
                                  ),
                                  _c(
                                    "v-app-bar-nav-icon",
                                    _vm._g(
                                      { staticClass: "hidden-md-and-up" },
                                      on
                                    )
                                  )
                                ]
                              }
                            }
                          ])
                        },
                        [
                          _c(
                            "v-list",
                            { attrs: { flat: "" } },
                            [
                              _c("v-text-field", {
                                staticClass: "pa-3",
                                attrs: {
                                  "append-icon": "mdi-magnify",
                                  label: "Search",
                                  "single-line": "",
                                  "hide-details": ""
                                },
                                model: {
                                  value: _vm.search,
                                  callback: function($$v) {
                                    _vm.search = $$v
                                  },
                                  expression: "search"
                                }
                              }),
                              _c(
                                "v-menu",
                                {
                                  attrs: {
                                    "close-on-content-click": false,
                                    "nudge-right": 40,
                                    transition: "scale-transition",
                                    "offset-y": "",
                                    "min-width": "290px"
                                  },
                                  scopedSlots: _vm._u([
                                    {
                                      key: "activator",
                                      fn: function(ref) {
                                        var on = ref.on
                                        return [
                                          _c(
                                            "v-text-field",
                                            _vm._g(
                                              {
                                                staticClass: "pa-3",
                                                attrs: {
                                                  label: "Order Start",
                                                  "prepend-icon": "event",
                                                  readonly: "",
                                                  clearable: ""
                                                },
                                                model: {
                                                  value: _vm.filterStartDate,
                                                  callback: function($$v) {
                                                    _vm.filterStartDate = $$v
                                                  },
                                                  expression: "filterStartDate"
                                                }
                                              },
                                              on
                                            )
                                          )
                                        ]
                                      }
                                    }
                                  ]),
                                  model: {
                                    value: _vm.startDateNavMenu,
                                    callback: function($$v) {
                                      _vm.startDateNavMenu = $$v
                                    },
                                    expression: "startDateNavMenu"
                                  }
                                },
                                [
                                  _c("v-date-picker", {
                                    on: {
                                      input: function($event) {
                                        _vm.startDateNavMenu = false
                                      }
                                    },
                                    model: {
                                      value: _vm.filterStartDate,
                                      callback: function($$v) {
                                        _vm.filterStartDate = $$v
                                      },
                                      expression: "filterStartDate"
                                    }
                                  })
                                ],
                                1
                              ),
                              _c(
                                "v-menu",
                                {
                                  attrs: {
                                    "close-on-content-click": false,
                                    "nudge-right": 40,
                                    transition: "scale-transition",
                                    "offset-y": "",
                                    "min-width": "290px"
                                  },
                                  scopedSlots: _vm._u([
                                    {
                                      key: "activator",
                                      fn: function(ref) {
                                        var on = ref.on
                                        return [
                                          _c(
                                            "v-text-field",
                                            _vm._g(
                                              {
                                                staticClass: "pa-3",
                                                attrs: {
                                                  label: "Order End",
                                                  "prepend-icon": "event",
                                                  readonly: "",
                                                  clearable: ""
                                                },
                                                model: {
                                                  value: _vm.filterEndDate,
                                                  callback: function($$v) {
                                                    _vm.filterEndDate = $$v
                                                  },
                                                  expression: "filterEndDate"
                                                }
                                              },
                                              on
                                            )
                                          )
                                        ]
                                      }
                                    }
                                  ]),
                                  model: {
                                    value: _vm.endDateNavMenu,
                                    callback: function($$v) {
                                      _vm.endDateNavMenu = $$v
                                    },
                                    expression: "endDateNavMenu"
                                  }
                                },
                                [
                                  _c("v-date-picker", {
                                    on: {
                                      input: function($event) {
                                        _vm.endDateNavMenu = false
                                      }
                                    },
                                    model: {
                                      value: _vm.filterEndDate,
                                      callback: function($$v) {
                                        _vm.filterEndDate = $$v
                                      },
                                      expression: "filterEndDate"
                                    }
                                  })
                                ],
                                1
                              ),
                              _c(
                                "v-menu",
                                {
                                  attrs: {
                                    "close-on-content-click": false,
                                    "nudge-right": 40,
                                    transition: "scale-transition",
                                    "offset-y": "",
                                    "min-width": "290px"
                                  },
                                  scopedSlots: _vm._u([
                                    {
                                      key: "activator",
                                      fn: function(ref) {
                                        var on = ref.on
                                        return [
                                          _c(
                                            "v-text-field",
                                            _vm._g(
                                              {
                                                staticClass: "pa-3",
                                                attrs: {
                                                  label: "Check Start",
                                                  "prepend-icon": "event",
                                                  readonly: "",
                                                  clearable: ""
                                                },
                                                model: {
                                                  value: _vm.filterStartDate,
                                                  callback: function($$v) {
                                                    _vm.filterStartDate = $$v
                                                  },
                                                  expression: "filterStartDate"
                                                }
                                              },
                                              on
                                            )
                                          )
                                        ]
                                      }
                                    }
                                  ]),
                                  model: {
                                    value: _vm.payStartDateNavMenu,
                                    callback: function($$v) {
                                      _vm.payStartDateNavMenu = $$v
                                    },
                                    expression: "payStartDateNavMenu"
                                  }
                                },
                                [
                                  _c("v-date-picker", {
                                    on: {
                                      input: function($event) {
                                        _vm.payStartDateNavMenu = false
                                      }
                                    },
                                    model: {
                                      value: _vm.filterStartDate,
                                      callback: function($$v) {
                                        _vm.filterStartDate = $$v
                                      },
                                      expression: "filterStartDate"
                                    }
                                  })
                                ],
                                1
                              ),
                              _c(
                                "v-menu",
                                {
                                  attrs: {
                                    "close-on-content-click": false,
                                    "nudge-right": 40,
                                    transition: "scale-transition",
                                    "offset-y": "",
                                    "min-width": "290px"
                                  },
                                  scopedSlots: _vm._u([
                                    {
                                      key: "activator",
                                      fn: function(ref) {
                                        var on = ref.on
                                        return [
                                          _c(
                                            "v-text-field",
                                            _vm._g(
                                              {
                                                staticClass: "pa-3",
                                                attrs: {
                                                  label: "Check End",
                                                  "prepend-icon": "event",
                                                  readonly: "",
                                                  clearable: ""
                                                },
                                                model: {
                                                  value: _vm.filterPayEndDate,
                                                  callback: function($$v) {
                                                    _vm.filterPayEndDate = $$v
                                                  },
                                                  expression: "filterPayEndDate"
                                                }
                                              },
                                              on
                                            )
                                          )
                                        ]
                                      }
                                    }
                                  ]),
                                  model: {
                                    value: _vm.payEndDateNavMenu,
                                    callback: function($$v) {
                                      _vm.payEndDateNavMenu = $$v
                                    },
                                    expression: "payEndDateNavMenu"
                                  }
                                },
                                [
                                  _c("v-date-picker", {
                                    on: {
                                      input: function($event) {
                                        _vm.payEndDateNavMenu = false
                                      }
                                    },
                                    model: {
                                      value: _vm.filterPayEndDate,
                                      callback: function($$v) {
                                        _vm.filterPayEndDate = $$v
                                      },
                                      expression: "filterPayEndDate"
                                    }
                                  })
                                ],
                                1
                              )
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c("v-divider"),
                  _c(
                    "v-layout",
                    { attrs: { column: "" } },
                    [
                      _c("v-data-table", {
                        staticClass: "minimum-height",
                        attrs: {
                          headers: _vm.headers,
                          items: _vm.filteredTips
                        },
                        scopedSlots: _vm._u([
                          {
                            key: "no-data",
                            fn: function() {
                              return [_vm._v(" No tips to view. ")]
                            },
                            proxy: true
                          },
                          {
                            key: "item.orderStart",
                            fn: function(ref) {
                              var item = ref.item
                              return [
                                _vm._v(
                                  " " + _vm._s(_vm.getStartDateTime(item)) + " "
                                )
                              ]
                            }
                          },
                          {
                            key: "item.payrollSubmissionTimestamp",
                            fn: function(ref) {
                              var item = ref.item
                              return [
                                _vm._v(
                                  " " +
                                    _vm._s(
                                      _vm.getShortDate(
                                        item.payrollSubmissionTimestamp
                                      )
                                    ) +
                                    " "
                                )
                              ]
                            }
                          },
                          {
                            key: "item.gratuity",
                            fn: function(ref) {
                              var item = ref.item
                              return [
                                !item.payrollSubmissionTimestamp &&
                                item.gratuity
                                  ? _c(
                                      "v-tooltip",
                                      {
                                        attrs: { bottom: "" },
                                        scopedSlots: _vm._u(
                                          [
                                            {
                                              key: "activator",
                                              fn: function(ref) {
                                                var on = ref.on
                                                return [
                                                  _c("v-icon", _vm._g({}, on), [
                                                    _vm._v(
                                                      "mdi-approximately-equal-box"
                                                    )
                                                  ])
                                                ]
                                              }
                                            }
                                          ],
                                          null,
                                          true
                                        )
                                      },
                                      [
                                        _c("span", [
                                          _vm._v(
                                            "Only an Estimate. Amount may change. Pending Approval"
                                          )
                                        ])
                                      ]
                                    )
                                  : _vm._e(),
                                _vm._v(
                                  " " +
                                    _vm._s(_vm.getNumeral(item.gratuity)) +
                                    " "
                                )
                              ]
                            }
                          },
                          {
                            key: "item.autoGratuity",
                            fn: function(ref) {
                              var item = ref.item
                              return [
                                !item.payrollSubmissionTimestamp &&
                                item.autoGratuity
                                  ? _c(
                                      "v-tooltip",
                                      {
                                        attrs: { bottom: "" },
                                        scopedSlots: _vm._u(
                                          [
                                            {
                                              key: "activator",
                                              fn: function(ref) {
                                                var on = ref.on
                                                return [
                                                  _c("v-icon", _vm._g({}, on), [
                                                    _vm._v(
                                                      "mdi-approximately-equal-box"
                                                    )
                                                  ])
                                                ]
                                              }
                                            }
                                          ],
                                          null,
                                          true
                                        )
                                      },
                                      [
                                        _c("span", [
                                          _vm._v(
                                            "Only an Estimate. Amount may change. Pending Approval"
                                          )
                                        ])
                                      ]
                                    )
                                  : _vm._e(),
                                _vm._v(
                                  " " +
                                    _vm._s(_vm.getNumeral(item.autoGratuity)) +
                                    " "
                                )
                              ]
                            }
                          },
                          {
                            key: "item.driverBonus",
                            fn: function(ref) {
                              var item = ref.item
                              return [
                                !item.payrollSubmissionTimestamp &&
                                item.driverBonus
                                  ? _c(
                                      "v-tooltip",
                                      {
                                        attrs: { bottom: "" },
                                        scopedSlots: _vm._u(
                                          [
                                            {
                                              key: "activator",
                                              fn: function(ref) {
                                                var on = ref.on
                                                return [
                                                  _c("v-icon", _vm._g({}, on), [
                                                    _vm._v(
                                                      "mdi-approximately-equal-box"
                                                    )
                                                  ])
                                                ]
                                              }
                                            }
                                          ],
                                          null,
                                          true
                                        )
                                      },
                                      [_c("span", [_vm._v("Pending Approval")])]
                                    )
                                  : _vm._e(),
                                _vm._v(
                                  " " +
                                    _vm._s(_vm.getNumeral(item.driverBonus)) +
                                    " "
                                )
                              ]
                            }
                          }
                        ])
                      })
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
<template>
  <v-container fluid class="pa-0">
    <v-layout flex align-center justify-center>
      <v-flex md12>
        <v-card tile>
          <v-toolbar dense color="primary">
            <Breadcrumbs />
          </v-toolbar>
          <v-divider></v-divider>
          <v-tabs v-model="tab" fixed-tabs
            :background-color="'primary darken-1'"
            hide-slider="true"
            :active-class="'primary black--text'">
            <v-tab href="#tab-1">
              List
              <v-icon left>mdi-format-list-bulleted</v-icon>
            </v-tab>
            <v-tab href="#tab-2">
              Calendar
              <v-icon left>mdi-calendar-month</v-icon>
            </v-tab>
          </v-tabs>
          <OrdersFilterToolbar
            class="grey lighten-5"
            :orderStatusList="orderStatusList"
            :orderMarketList="marketList"
            :allEmployeesList="employeeList"
            :allOrders="allOrders"
            :orderFormatList="orderFormatList"
            @setFilteredOrders="setFilteredOrders"
            @getOrders="getOrders"
          />
          <v-divider></v-divider>
          <v-tabs-items v-model="tab">
            <v-tab-item :value="'tab-1'">
              <v-card flat tile>
                <OrdersGrid :order-list="filteredOrderList"
                 :isSchedule="false"
                 :visible="false"
                 @getOrders="getOrders" />
              </v-card>
            </v-tab-item>
            <v-tab-item :value="'tab-2'">
              <v-card flat tile>
                <OrdersCalendar
                  :order-list="filteredOrderList"
                  :isSchedule="false"
                />
              </v-card>
            </v-tab-item>
          </v-tabs-items>
        </v-card>
      </v-flex>
    </v-layout>
  </v-container>
</template>

<script>
import Breadcrumbs from "@/components/Breadcrumbs";
import orderApi from "@/api/orderApi";
import OrdersGrid from "@/components/OrdersGrid.vue";
import OrdersCalendar from "@/components/OrdersCalendar.vue";
import OrdersFilterToolbar from "@/components/OrdersFilterToolbar.vue";
import {mapGetters} from "vuex";
import Vue from "vue";
import moment from "moment";

export default {
  name: "Orders",
  components: {
    OrdersGrid,
    OrdersCalendar,
    OrdersFilterToolbar,
    Breadcrumbs,
  },
  data() {
    return {
      tab: null,
      allOrders: [],
      filteredOrders: [],
      orderStatusList: [],
      marketList: [],
      employeeList: [],
      orderFormatList: [],
      prevRoute: null,
    };
  },
  beforeRouteEnter(to, from, next) {
    next((vm) => {
      vm.prevRoute = from;
    });
  },
  mounted() {
    this.getMarketList();
    this.$nextTick(() => {
      if (this.prevRoute.path === "/scheduleemployees/orderdetails") {
        this.getOrders(this.orderFilters);
      }
    });
  },
  methods: {
    setFilteredOrders(orders) {
      this.filteredOrders = orders;
    },
    async getMarketList() {
      await orderApi.getMarketList().then((response) => {
        this.marketList = response.data.marketList;
        this.orderStatusList = response.data.orderStatusList;
        this.orderFormatList = response.data.orderFormatList;
      });
    },
    async getOrders(orderFilters) {
      if (!orderFilters.startDate) {
        orderFilters.startDate = null;
      }
      if (!orderFilters.endDate) {
        orderFilters.endDate = null;
      }
      const startDate = moment(orderFilters.startDate);
      const endDate = moment(orderFilters.endDate);
      if (endDate.diff(startDate, "days") > 14) {
        Vue.swal({
          // eslint-disable-next-line max-len
          title: "Search date ranges must be less than 14 days apart, please change dates and try again",
          text: "",
          type: "error",
          showCancelButton: false,
          confirmButtonColor: "accent",
          confirmButtonText: "Ok",
          closeOnConfirm: false,
        });
      } else {
        await orderApi.getFilteredOrders(orderFilters).then((response) => {
          if (response.data) {
            let allOrders = response.data.orders;
            if (orderFilters.document === "With Attachment") {
              allOrders = allOrders.filter(function(el) {
                return el.isDocument === true;
              });
            } else if (orderFilters.document === "Without Attachment") {
              allOrders = allOrders.filter(function(el) {
                return el.isDocument === false;
              });
            } else {
              allOrders = allOrders;
            }
            this.visible = false;
            this.allOrders = allOrders;
            // this.orderStatusList = response.data.orderStatusList;
            // this.orderFormatList = response.data.orderFormatList;
          }
          this.initialLoad = false;
        });
      }
    },
  },
  computed: {
    ...mapGetters({
      orderFilters: "getOrderFilters",
      currentUser: "getCurrentUser",
    }),
    filteredOrderList() {
      return this.filteredOrders;
    },
  },
  watch: {
    "orderFilters.startDate": function(newVal, oldVal) {
      if (newVal != oldVal) {
        // this.getOrders(this.orderFilters);
      }
    },
    "orderFilters.endDate": function(newVal, oldVal) {
      if (newVal != oldVal) {
        // this.getOrders(this.orderFilters);
      }
    },
    "orderFilters.market": function(newVal, oldVal) {
      if (newVal != oldVal) {
        // this.getOrders(this.orderFilters);
      }
    },
  },
};
</script>

var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-container",
    { attrs: { fluid: "", "fill-height": "" } },
    [
      _c(
        "v-layout",
        { attrs: { flex: "", "align-center": "", "justify-center": "" } },
        [
          _c(
            "v-flex",
            { attrs: { xs12: "", sm10: "", md6: "", lg4: "", xl3: "" } },
            [
              _c(
                "v-card",
                { attrs: { tile: "" } },
                [
                  _c(
                    "v-toolbar",
                    { attrs: { dense: "", color: "primary" } },
                    [
                      _c("v-toolbar-title", [
                        _vm._v("Reset Forgotten Password")
                      ])
                    ],
                    1
                  ),
                  _c("v-divider"),
                  _c("v-card-text", { staticClass: "pt-4" }, [
                    _c(
                      "div",
                      [
                        _c(
                          "v-row",
                          [
                            _c(
                              "v-col",
                              { attrs: { cols: "12", sm: "12", md: "12" } },
                              [
                                _vm._v(
                                  " Please enter enter your new password. "
                                )
                              ]
                            )
                          ],
                          1
                        ),
                        _c(
                          "v-row",
                          [
                            _c(
                              "v-col",
                              { attrs: { cols: "12", sm: "12", md: "12" } },
                              [
                                _c(
                                  "v-form",
                                  {
                                    ref: "form",
                                    model: {
                                      value: _vm.valid,
                                      callback: function($$v) {
                                        _vm.valid = $$v
                                      },
                                      expression: "valid"
                                    }
                                  },
                                  [
                                    _c("v-text-field", {
                                      attrs: {
                                        label: "Password",
                                        min: "8",
                                        "append-icon": _vm.e1
                                          ? "visibility"
                                          : "visibility_off",
                                        type: _vm.e1 ? "password" : "text",
                                        rules: _vm.passwordRules,
                                        required: ""
                                      },
                                      on: {
                                        "click:append": function() {
                                          return (_vm.e1 = !_vm.e1)
                                        }
                                      },
                                      model: {
                                        value: _vm.password,
                                        callback: function($$v) {
                                          _vm.password = $$v
                                        },
                                        expression: "password"
                                      }
                                    }),
                                    _c("v-text-field", {
                                      attrs: {
                                        label: "Repeat Password",
                                        min: "8",
                                        "append-icon": _vm.e2
                                          ? "visibility"
                                          : "visibility_off",
                                        type: _vm.e2 ? "password" : "text",
                                        rules: _vm.repeatPasswordRules,
                                        required: ""
                                      },
                                      on: {
                                        "click:append": function() {
                                          return (_vm.e2 = !_vm.e2)
                                        }
                                      },
                                      model: {
                                        value: _vm.passwordRepeat,
                                        callback: function($$v) {
                                          _vm.passwordRepeat = $$v
                                        },
                                        expression: "passwordRepeat"
                                      }
                                    })
                                  ],
                                  1
                                )
                              ],
                              1
                            )
                          ],
                          1
                        )
                      ],
                      1
                    )
                  ]),
                  _c(
                    "v-card-actions",
                    [
                      _c(
                        "v-btn",
                        {
                          class: {
                            accent: _vm.valid
                          },
                          attrs: { disabled: !_vm.valid },
                          on: { click: _vm.submit }
                        },
                        [_vm._v("Submit")]
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
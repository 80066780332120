<template>
  <div>
    <v-toolbar class="primary" dense>
      <BreadCrumbs />
    </v-toolbar>
    <v-card class="ma-1 mt-4" flat>
      <v-card-title class="primary"
      >Search</v-card-title>
      <v-row class="ma-1 justify-center">
        <v-col>
          <v-select cols="12" md="6" lg="3" xl="3"
            :items="filterOptions"
            v-model="filterSelection"
            outlined
            label="Filters"
          ></v-select>
        </v-col>
        <v-col v-if="isSelectionOrder"
          cols="12" md="6" lg="3" xl="3">
          <v-text-field
            outlined
            label="Order Number"
            v-model.number="filters.orderId"
            :rules="[rules.required]"
          >
          </v-text-field>
        </v-col>
        <v-col v-if="isSelectionEmpId"
          cols="12" md="6" lg="3" xl="3"
          ><v-text-field
            outlined
            label="Employee Id"
            v-model.number="filters.cityEmployeeId"
            :rules="[rules.required]"
          ></v-text-field>
        </v-col>
        <v-col v-if="isSelectionEmail"
          cols="12" md="6" lg="3" xl="3"
          ><v-text-field
            outlined
            label="Email Address"
            v-model="filters.emailAddress"
            :rules="[rules.required]"
          ></v-text-field
        ></v-col>
        <v-col v-if="isSelectionEmpId || isSelectionEmail"
          cols="12" md="6" lg="3" xl="3"
          ><v-menu
            v-model="startDateMenu"
            :close-on-content-click="false"
            transition="scale-transition"
            min-width="290px"
            offset-y
          >
            <template v-slot:activator="{ on }">
              <v-text-field
                v-model="filters.startDate"
                label="Start Date"
                readonly
                clearable
                outlined
                v-on="on"
              ></v-text-field>
            </template>
            <v-date-picker
              v-model="filters.startDate"
              @input="startDateMenu = false"
            ></v-date-picker>
          </v-menu>
        </v-col>
        <v-col v-if="isSelectionEmpId || isSelectionEmail"
          cols="12" md="6" lg="3" xl="3"
          ><v-menu
            v-model="endDateMenu"
            :close-on-content-click="false"
            transition="scale-transition"
            min-width="290px"
            offset-y
          >
            <template v-slot:activator="{ on }">
              <v-text-field
                v-model="filters.endDate"
                label="End Date"
                readonly
                clearable
                outlined
                v-on="on"
              ></v-text-field>
            </template>
            <v-date-picker
              v-model="filters.endDate"
              @input="endDateMenu = false"
            ></v-date-picker>
          </v-menu>
        </v-col>
      </v-row>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn
          v-if="showSearchBtn"
          class="mr-3"
          color="accent"
          @click="getPayrollInquiryData"
          >Search</v-btn
        >
      </v-card-actions>
    </v-card>
    <v-data-table
      :headers="tipsHeaders"
      :items="tips"
      class="ma-1 mt-4"
      v-if="tips.length > 0"
    >
      <template v-slot:top>
        <v-toolbar flat class="primary">
          <v-toolbar-title>Tips</v-toolbar-title>
        </v-toolbar>
      </template>
      <template #item.name="{item}">
        {{ `${item.userFirstName} ${item.userLastName}` }}
      </template>
      <template #item.gratuity="{item}">
        {{ getNumeral(item.gratuity) }}
      </template>
      <template #item.autoGratuity="{item}">
        {{ getNumeral(item.autoGratuity) }}
      </template>
      <template #item.total="{item}">
        {{ getNumeral(item.gratuity + item.autoGratuity) }}
      </template>
      <template #item.payrollSubmissionTimestamp="{item}">
        {{ getDateFromDateTime(item.payrollSubmissionTimestamp) }}
      </template>
      <template #item.orderDate="{item}">
        {{ getDateFromDateTime(item.orderStart) }}
      </template>
    </v-data-table>
    <v-data-table
      :headers="commissionsHeaders"
      :items="commissions"
      class="ma-1 mt-4"
      v-if="commissions.length > 0"
    >
      <template v-slot:top>
        <v-toolbar flat class="primary">
          <v-toolbar-title>Commissions</v-toolbar-title>
        </v-toolbar>
        <v-divider class="primary"></v-divider>
      </template>
      <template #item.name="{item}">
        {{ `${item.userFirstName} ${item.userLastName}` }}
      </template>
      <template #item.cityEmployeeId="{item}">
        {{ `${item.user.cityEmployeeId}` }}
      </template>
      <template #item.amount="{item}">
        {{ getNumeral(item.amount) }}
      </template>
      <template #item.orderDate="{item}">
        {{ getDateFromDateTime(item.orderStart) }}
      </template>
      <template #item.payrollSubmissionTimestamp="{item}">
        {{ getDateFromDateTime(item.payrollSubmissionTimestamp) }}
      </template>
    </v-data-table>
  </div>
</template>

<script>
import BreadCrumbs from "@/components/Breadcrumbs";
import payrollApi from "@/api/payrollApi";
import numeral from "numeral";
import UserTime from "@/helpers/userTime";
export default {
  name: "PayrollInquiry",
  components: {
    BreadCrumbs,
  },
  data() {
    return {
      filters: {
        startDate: null,
        endDate: null,
        cityEmployeeId: undefined,
        orderId: undefined,
        emailAddress: "",
      },
      endDateMenu: false,
      startDateMenu: false,
      order: [],
      tips: [],
      commissions: [],
      filterSelection: "",
      filterOptions: [
        {text: "Order", value: "order"},
        {text: "Employee Id", value: "employeeId"},
        {text: "Email Address", value: "emailAddress"},
      ],
      rules: {
        required: (value) => !!value || "Required.",
      },
      isSelectionOrder: false,
      isSelectionEmail: false,
      isSelectionEmpId: false,
      showSearchBtn: false,
    };
  },
  methods: {
    getPayrollInquiryData() {
      this.clearData();
      payrollApi.getAllPayrollInquiryData(this.filters).then((response) => {
        if (!response.data) {
          this.$root.$emit("errorToast", "No Data Found");
        } else {
          this.order = response.data.order;
          this.tips = response.data.tips;
          this.commissions = response.data.commissions;
          if (this.order) {
            this.tips = this.order.tips;
            this.commissions = this.order.commissions;
          }
        }
      });
    },
    getNumeral(amount) {
      return numeral(amount).format("$0,0.00");
    },
    getDateFromDateTime(date) {
      return UserTime.getShortDate(date);
    },
    clearData() {
      this.order = [];
      this.commissions = [];
      this.tips = [];
    },
  },
  computed: {
    commissionsHeaders() {
      return [
        {text: "Order", value: "orderName"},
        {text: "Event Date", value: "orderDate"},
        {text: "Name", value: "name"},
        {text: "Id", value: "cityEmployeeId"},
        {text: "Amount", value: "amount"},
        {text: "PayCheck Date", value: "payrollSubmissionTimestamp"},
      ];
    },
    tipsHeaders() {
      return [
        {text: "Order", value: "orderName"},
        {text: "Event Date", value: "orderDate"},
        {text: "Name", value: "name"},
        {text: "Id", value: "etlCityEmployeeId"},
        {text: "Gratuity", value: "gratuity"},
        {text: "Auto Gratuity", value: "autoGratuity"},
        {text: "Total", value: "total"},
        {text: "PayCheck Date", value: "payrollSubmissionTimestamp"},
      ];
    },
  },
  watch: {
    filterSelection() {
      if (this.filterSelection === this.filterOptions[0].value) {
        this.isSelectionOrder = true;
        this.isSelectionEmail = false;
        this.isSelectionEmpId = false;
      }
      if (this.filterSelection === this.filterOptions[1].value) {
        this.isSelectionEmpId = true;
        this.isSelectionOrder = false;
        this.isSelectionEmail = false;
      }
      if (this.filterSelection === this.filterOptions[2].value) {
        this.isSelectionEmail = true;
        this.isSelectionOrder = false;
        this.isSelectionEmpId = false;
      }
      this.filters = {
        startDate: null,
        endDate: null,
        cityEmployeeId: undefined,
        orderId: undefined,
        emailAddress: "",
      };
    },
    "filters.orderId": function() {
      this.showSearchBtn = true;
    },
    "filters.cityEmployeeId": function() {
      this.showSearchBtn = true;
    },
    "filters.emailAddress": function() {
      this.showSearchBtn = true;
    },
  },
};
</script>

<style></style>

var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-layout",
    { attrs: { column: "" } },
    [
      _c("v-data-table", {
        ref: "expandableTable",
        staticClass: "elevation-2 minimum-height",
        attrs: {
          headers: _vm.headers,
          "hide-default-header": "",
          items: _vm.clockEventsList,
          "group-by": "groupHeaderKey",
          "sort-by": _vm.clockIn,
          dense: ""
        },
        scopedSlots: _vm._u([
          {
            key: "no-data",
            fn: function() {
              return [_vm._v(" No punches to view. ")]
            },
            proxy: true
          },
          {
            key: "group.header",
            fn: function(ref) {
              var group = ref.group
              var toggle = ref.toggle
              var isOpen = ref.isOpen
              return [
                _c(
                  "v-btn",
                  {
                    attrs: { icon: "" },
                    on: {
                      click: function($event) {
                        return toggle()
                      }
                    }
                  },
                  [
                    _c("v-icon", [
                      _vm._v(
                        "mdi-" +
                          _vm._s(
                            isOpen
                              ? "arrow-up-drop-circle-outline"
                              : "arrow-down-drop-circle-outline"
                          )
                      )
                    ])
                  ],
                  1
                ),
                _c("span", [
                  _c("strong", [_vm._v(_vm._s(_vm.getGroupHeader(group)))])
                ])
              ]
            }
          },
          {
            key: "group.summary",
            fn: function(ref) {
              var items = ref.items
              return [
                _c("span", { staticClass: "pl-5" }, [
                  _vm._v(_vm._s(_vm.getGroupSummary(items)))
                ])
              ]
            }
          },
          {
            key: "item.clockEvent",
            fn: function(ref) {
              var item = ref.item
              return [
                _c("div", [
                  _c("span", { staticClass: "block-50" }, [_vm._v("IN:")]),
                  _c("span", [_vm._v(_vm._s(_vm.getimeString(item.clockIn)))])
                ]),
                _c("div", [
                  _c("span", { staticClass: "block-50" }, [_vm._v("OUT:")]),
                  _c("span", [_vm._v(_vm._s(_vm.getimeString(item.clockOut)))])
                ])
              ]
            }
          }
        ])
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
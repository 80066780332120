var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-container",
    { staticClass: "pa-0", attrs: { fluid: "" } },
    [
      _c(
        "v-layout",
        { attrs: { flex: "", "align-center": "", "justify-center": "" } },
        [
          _c(
            "v-flex",
            { attrs: { md12: "" } },
            [
              _c(
                "v-card",
                { attrs: { tile: "" } },
                [
                  _c(
                    "v-toolbar",
                    { attrs: { dense: "", color: "primary" } },
                    [_c("Breadcrumbs")],
                    1
                  ),
                  _c("v-divider"),
                  _c(
                    "v-tabs",
                    {
                      attrs: {
                        "fixed-tabs": "",
                        "background-color": "primary darken-1",
                        "hide-slider": "true",
                        "active-class": "primary black--text"
                      },
                      model: {
                        value: _vm.tab,
                        callback: function($$v) {
                          _vm.tab = $$v
                        },
                        expression: "tab"
                      }
                    },
                    [
                      _c(
                        "v-tab",
                        { attrs: { href: "#tab-1" } },
                        [
                          _vm._v(" List "),
                          _c("v-icon", { attrs: { left: "" } }, [
                            _vm._v("mdi-format-list-bulleted")
                          ])
                        ],
                        1
                      ),
                      _c(
                        "v-tab",
                        { attrs: { href: "#tab-2" } },
                        [
                          _vm._v(" Calendar "),
                          _c("v-icon", { attrs: { left: "" } }, [
                            _vm._v("mdi-calendar-month")
                          ])
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c("OrdersFilterToolbar", {
                    staticClass: "grey lighten-5",
                    attrs: {
                      allEmployeesList: _vm.employeeList,
                      allOrders: _vm.allOrders,
                      isSchedule: true,
                      orderFormatList: _vm.orderFormatList
                    },
                    on: {
                      setFilteredOrders: _vm.setFilteredOrders,
                      getOrders: _vm.getOrders
                    }
                  }),
                  _c("v-divider"),
                  _c(
                    "v-tabs-items",
                    {
                      model: {
                        value: _vm.tab,
                        callback: function($$v) {
                          _vm.tab = $$v
                        },
                        expression: "tab"
                      }
                    },
                    [
                      _c(
                        "v-tab-item",
                        { attrs: { value: "tab-1" } },
                        [
                          _c(
                            "v-card",
                            { attrs: { tile: "", flat: "" } },
                            [
                              _c("OrdersGrid", {
                                attrs: {
                                  "order-list": _vm.filteredOrders,
                                  isSchedule: true
                                }
                              })
                            ],
                            1
                          )
                        ],
                        1
                      ),
                      _c(
                        "v-tab-item",
                        { attrs: { value: "tab-2" } },
                        [
                          _c(
                            "v-card",
                            { attrs: { tile: "", flat: "" } },
                            [
                              _c("OrdersCalendar", {
                                attrs: {
                                  "order-list": _vm.filteredOrders,
                                  isSchedule: true
                                }
                              })
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
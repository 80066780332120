<template>
  <v-app>
    <v-navigation-drawer v-model="drawer" enable-resize-watcher app>
      <v-list dense nav class="py-0">
        <v-list-item two-line class="px-0 hidden-sm-and-up">
          <v-list-item-content>
            <v-list-item-title
              v-show="isAuthenticated"
              v-text="
                currentUser.firstName && currentUser.lastName
                  ? `${currentUser.firstName} ${currentUser.lastName}`
                  : ''
              "
            ></v-list-item-title>
            <v-list-item-subtitle
              v-show="isAuthenticated"
              v-text="currentUser.email"
            ></v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>
        <v-divider></v-divider>
        <v-list-group
          v-for="(item, index) in navItems"
          :key="index"
          v-show="showNavItem(item)"
          :appendIcon="item.subList ? 'arrow_drop_down' : ''"
        >
          <template v-slot:activator>
            <v-list-item
              :to="item.path == '#' ? '' : item.path"
              link
              id="navList"
            >
              <v-list-item-icon>
                <v-icon>{{ item.icon }}</v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title>{{ item.title }}</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </template>
          <v-list-item
            v-for="subItem in item.subList"
            :key="subItem.title"
            :to="subItem.path == '#' ? '' : subItem.path"
            v-show="showNavItem(subItem)"
            link
            class="pl-5"
          >
            <v-list-item-icon>
              <v-icon>{{ subItem.icon }}</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title>{{ subItem.title }}</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list-group>
        <v-divider></v-divider>
        <v-list-item link to="/login" v-show="!isAuthenticated">
          <v-list-item-icon>
            <v-icon>mdi-login</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>Login</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-list-item link @click="logout()" v-show="isAuthenticated">
          <v-list-item-icon>
            <v-icon>mdi-logout</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>Logout</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list>
    </v-navigation-drawer>
    <v-app-bar app class="secondary">
      <v-app-bar-nav-icon @click.stop="drawer = !drawer" style="color: #fff">
      </v-app-bar-nav-icon>
      <v-img
        class="mx-2"
        src="@/assets/logo-citybbq-white.png"
        max-height="40"
        max-width="180"
        contain
      ></v-img>
      <v-spacer></v-spacer>
      <v-list-item>
        <v-list-item-content
          style="text-align: end;"
          two-line
          class="hidden-xs-only"
        >
          <v-list-item-title
            style="color:#fff"
            v-show="isAuthenticated"
            v-text="
              currentUser.firstName && currentUser.lastName
                ? `${currentUser.firstName} ${currentUser.lastName}`
                : ''
            "
          ></v-list-item-title>
          <v-list-item-subtitle
            style="color:#fff"
            v-show="isAuthenticated"
            v-text="currentUser.email ? currentUser.email : ''"
          ></v-list-item-subtitle>
        </v-list-item-content>
        <v-list-item-icon class="hidden-xs-only">
          <v-menu left bottom>
            <template v-slot:activator="{ on }">
              <v-hover v-slot:default="{ hover }">
                <v-btn
                  icon
                  v-on="on"
                  :elevation="hover ? 8 : 4"
                  v-show="isAuthenticated"
                >
                  <v-icon link size="60" style="color: #fff"
                    >mdi-account-circle-outline</v-icon
                  >
                </v-btn>
              </v-hover>
            </template>
            <v-list>
              <v-list-item>
                <v-list-item-content>
                  <span>{{ currentUser.email }}</span>
                </v-list-item-content>
              </v-list-item>
              <v-divider></v-divider>
              <v-list-item link to="/viewProfile" v-show="isAuthenticated">
                <v-list-item-icon>
                  <v-icon>mdi-account</v-icon>
                </v-list-item-icon>
                <v-list-item-content>
                  <v-list-item-title>View User Profile</v-list-item-title>
                </v-list-item-content>
              </v-list-item>
              <v-divider></v-divider>
              <v-list-item link @click="logout()">
                <v-list-item-icon>
                  <v-icon>mdi-logout</v-icon>
                </v-list-item-icon>
                <v-list-item-content>
                  <v-list-item-title>Logout</v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </v-list>
          </v-menu>
        </v-list-item-icon>
      </v-list-item>
    </v-app-bar>
    <v-content>
      <Spinner />
      <router-view></router-view>
    </v-content>
    <Snackbar ref="snackbar" />
    <v-footer color="secondary" padless>
      <v-row justify="center" no-gutters>
        <v-col class="py-4 text-center white--text" cols="12">
          &copy;{{ new Date().getFullYear() }} — <strong>City Barbeque</strong>
        </v-col>
      </v-row>
    </v-footer>
  </v-app>
</template>

<script>
import {mapGetters} from "vuex";
import {Role} from "@/helpers/role";
import Snackbar from "@/components/Snackbar.vue";
import Spinner from "@/components/Spinner.vue";

export default {
  name: "App",
  data() {
    return {
      drawer: false,
      navItems: [
        {
          title: "Home",
          path: "/",
          icon: "mdi-home-outline",
          roles: [
            Role.Administrator,
            Role.CateringManager,
            Role.Payroll,
            Role.SalesRep,
            Role.CallCenter,
            Role.Employee,
          ],
        },
        {
          title: "Schedule",
          icon: "mdi-calendar-text-outline",
          roles: [Role.Administrator, Role.CateringManager],
          subList: [
            {
              title: "Schedule Employees",
              path: "/scheduleemployees",
              icon: "mdi-account-multiple-plus",
              roles: [Role.Administrator, Role.CateringManager],
            },
            {
              title: "Schedule Admin Employee",
              path: "/adminassignments",
              icon: "mdi-account-key",
              roles: [Role.Administrator, Role.CateringManager],
            },
            {
              title: "Time Clock Audit",
              path: "/timeclockaudit",
              icon: "mdi-account-key",
              roles: [Role.Administrator, Role.CateringManager],
            },
          ],
        },
        {
          title: "Events",
          path: "/events",
          icon: "mdi-calendar-account",
          roles: [
            Role.Administrator,
            Role.CateringManager,
            Role.Employee,
            Role.Payroll,
          ],
        },
        {
          title: "My Schedule",
          path: "/myschedule",
          icon: "mdi-calendar-account",
          roles: [Role.Administrator, Role.CateringManager, Role.Employee],
        },
        {
          title: "View Tips",
          path: "/tips",
          icon: "mdi-account-cash-outline",
          roles: [Role.Administrator, Role.Employee],
        },
        {
          title: "View Commissions",
          path: "/commissions",
          icon: "mdi-cash",
          roles: [Role.Administrator, Role.SalesRep, Role.CallCenter],
        },
        {
          title: "Timeclock",
          path: "/timeclock",
          icon: "mdi-clock-outline",
          roles: [Role.Administrator, Role.Employee],
        },
        {
          title: "Payroll",
          icon: "mdi-clipboard-account-outline",
          roles: [Role.Administrator, Role.CateringManager, Role.Payroll],
          subList: [
            {
              title: "Payroll Commissions",
              path: "/payrollcommissions",
              icon: "mdi-cash-usd",
              roles: [Role.Administrator, Role.CateringManager, Role.Payroll],
            },
            {
              title: "Payroll Tips",
              path: "/payrolltips",
              icon: "mdi-account-multiple-plus",
              roles: [Role.Administrator, Role.CateringManager, Role.Payroll],
            },
            {
              title: "Payroll Inquiry",
              path: "/payrollinquiry",
              icon: "mdi-text-box-search-outline",
              roles: [Role.Administrator, Role.CateringManager, Role.Payroll],
            },
          ],
        },
        {
          title: "Maintenance",
          icon: "mdi-clipboard-account-outline",
          // eslint-disable-next-line max-len
          roles: [Role.Administrator, Role.CateringManager, Role.Payroll, Role.SalesRep],
          subList: [
            {
              title: "Refresh Orders",
              path: "/refreshorder",
              icon: "mdi-account-multiple-plus",
              // eslint-disable-next-line max-len
              roles: [Role.Administrator, Role.CateringManager, Role.Payroll, Role.SalesRep],
            },
          ],
        },
        {
          title: "User Management",
          icon: "mdi-clipboard-account-outline",
          // eslint-disable-next-line max-len
          roles: [Role.Administrator, Role.CateringManager, Role.Payroll],
          subList: [
            {
              title: "User Search",
              path: "/userSearch",
              icon: "mdi-account-multiple-plus",
              // eslint-disable-next-line max-len
              roles: [Role.Administrator, Role.CateringManager, Role.Payroll],
            },
          ],
        },
        {
          title: "Admin",
          path: "/admin",
          icon: "mdi-head-minus-outline",
          roles: [Role.Administrator],
        },
      ],
    };
  },
  components: {
    Snackbar,
    Spinner,
  },
  created() {
    this.$root.$on("successToast", (message) => {
      this.$refs.snackbar.showSuccess(message);
    });
    this.$root.$on("errorToast", (message) => {
      this.$refs.snackbar.showError(message);
    });
  },
  watch: {
    currentUser: function(val, oldVal) {
      if (this.isAuthenticated && !this.currentUser.cityEmployeeId) {
        // eslint-disable-next-line max-len
        this.$refs.snackbar.showError("Your user data is missing.  Please contact your manager.", 0);
      } else {
        this.$refs.snackbar.hide();
      }
    },
  },
  methods: {
    showNavItem(item) {
      let ret = false;
      item.roles.forEach((r) => {
        if (this.currentUser.roles && this.currentUser.roles.includes(r)) {
          ret = true;
        }
      });
      return ret;
    },
    logout() {
      this.$store.dispatch("authLogout").then(() => {
        if (this.$route.path !== "/login") {
          this.$router.push("/login");
        }
      });
      this.$AuthService.logout();
    },
  },
  computed: mapGetters({
    isAuthenticated: "isAuthenticated",
    currentUser: "getCurrentUser",
  }),
};
</script>
<style scoped>
#app {
  background: #221e1f;
  overflow: hidden;
}
tbody tr:nth-of-type(even) {
  background-color: rgba(236, 237, 237);
}

tbody tr:nth-of-type(odd) {
  background-color: rgb(250, 250, 250);
}

#navList {
  padding: 0;
  margin: 0;
  border-radius: 0;
}
</style>

<template>
  <v-container fluid class="pa-0">
    <v-layout flex align-center justify-center>
      <v-flex md12>
        <v-card tile>
          <v-toolbar dense color="primary">
            <Breadcrumbs />
          </v-toolbar>
          <v-divider></v-divider>
          <v-tabs
            v-model="tab"
                  fixed-tabs
                  :background-color="'primary darken-1'"
                  hide-slider="true"
                  :active-class="'primary black--text'"
            >
            <v-tab href="#tab-1">
              Users
              <v-icon left>mdi-account-box-outline</v-icon>
            </v-tab>
          </v-tabs>
          <v-tabs-items v-model="tab">
            <v-tab-item :value="'tab-1'">
              <v-card flat tile>
                <UsersGrid
                  :currentUsersList="userList"
                  :allUsersList="userList"
                  :userRoleList="userRoleList"
                ></UsersGrid>
              </v-card>
            </v-tab-item>
          </v-tabs-items>
        </v-card>
      </v-flex>
    </v-layout>
  </v-container>
</template>
<script>
import userApi from "@/api/userApi";
import UsersGrid from "@/components/UsersGrid";
import Breadcrumbs from "@/components/Breadcrumbs";

export default {
  name: "Admin",
  data() {
    return {
      tab: null,
      userList: [],
    };
  },
  components: {
    UsersGrid,
    Breadcrumbs,
  },
  mounted() {
    userApi.getAllUsers().then((response) => {
      this.userList = response.data;
    });
  },
};
</script>

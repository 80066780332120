var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-layout",
    {
      directives: [
        {
          name: "resize",
          rawName: "v-resize",
          value: _vm.onResize,
          expression: "onResize"
        }
      ],
      attrs: { column: "" }
    },
    [
      _c("v-data-table", {
        staticClass: "elevation-2 minimum-height",
        class: { mobile: _vm.isMobile },
        attrs: {
          headers: _vm.isMobile ? _vm.mobileHeaders : _vm.headers,
          items: _vm.localAssignments,
          search: _vm.search,
          "sort-by": _vm.sortBy,
          "sort-desc": _vm.sortOrder,
          "hide-default-header": _vm.isMobile,
          "items-per-page": parseInt(_vm.itemsPerPage)
        },
        on: {
          "update:items-per-page": function($event) {
            return _vm.handlePageChange($event)
          }
        },
        scopedSlots: _vm._u([
          {
            key: "header.name",
            fn: function() {
              return [
                _c(
                  "button",
                  {
                    on: {
                      click: function($event) {
                        return _vm.filterClick("name")
                      }
                    }
                  },
                  [_vm._v("Name")]
                )
              ]
            },
            proxy: true
          },
          {
            key: "header.date",
            fn: function() {
              return [
                _c(
                  "button",
                  {
                    on: {
                      click: function($event) {
                        return _vm.filterClick("date")
                      }
                    }
                  },
                  [_vm._v("Date")]
                )
              ]
            },
            proxy: true
          },
          {
            key: "header.start",
            fn: function() {
              return [
                _c(
                  "button",
                  {
                    on: {
                      click: function($event) {
                        return _vm.filterClick("start")
                      }
                    }
                  },
                  [_vm._v("Start")]
                )
              ]
            },
            proxy: true
          },
          {
            key: "header.end",
            fn: function() {
              return [
                _c(
                  "button",
                  {
                    on: {
                      click: function($event) {
                        return _vm.filterClick("end")
                      }
                    }
                  },
                  [_vm._v("End")]
                )
              ]
            },
            proxy: true
          },
          {
            key: "header.orderClass",
            fn: function() {
              return [
                _c(
                  "button",
                  {
                    on: {
                      click: function($event) {
                        return _vm.filterClick("orderClass")
                      }
                    }
                  },
                  [_vm._v("Type")]
                )
              ]
            },
            proxy: true
          },
          {
            key: "body",
            fn: function(props) {
              return [
                !_vm.localAssignments || _vm.localAssignments.length < 1
                  ? _c("tbody", [
                      _c("tr", { staticClass: "v-data-table__empty-wrapper" }, [
                        _c("td", { attrs: { colspan: "6" } }, [
                          _vm._v(
                            " " +
                              _vm._s(
                                _vm.isSchedule
                                  ? "No shifts scheduled."
                                  : "No orders found."
                              ) +
                              " "
                          )
                        ])
                      ])
                    ])
                  : !_vm.isMobile
                  ? _c(
                      "tbody",
                      _vm._l(props.items, function(item, index) {
                        return _c("tr", { key: index }, [
                          _c("td", { staticClass: "text-xs-right" }, [
                            _vm._v(" " + _vm._s(_vm.getEventName(item)) + " ")
                          ]),
                          _c("td", { staticClass: "text-xs-right" }, [
                            _vm._v(
                              " " + _vm._s(_vm.getShortDate(item.start)) + " "
                            )
                          ]),
                          _c("td", { staticClass: "text-xs-right" }, [
                            _c("span", { staticClass: "no-wrap" }, [
                              _vm._v(
                                _vm._s(
                                  _vm.getShortTime(item.start) +
                                    " " +
                                    (item.order.startDateTimeZone ||
                                      item.cityLocation.timezone)
                                )
                              )
                            ])
                          ]),
                          _c("td", { staticClass: "text-xs-right" }, [
                            _c("span", { staticClass: "no-wrap" }, [
                              _vm._v(
                                _vm._s(
                                  _vm.getShortTime(item.end) +
                                    " " +
                                    (item.order.startDateTimeZone ||
                                      item.cityLocation.timezone)
                                )
                              )
                            ])
                          ]),
                          _c("td", { staticClass: "text-xs-right" }, [
                            _vm._v(
                              " " +
                                _vm._s(item.order.orderClass || "Admin") +
                                " "
                            )
                          ]),
                          _c("td", { staticClass: "text-xs-right" }, [
                            _vm._v(_vm._s(item.order.status || "admin"))
                          ]),
                          _c("td", { staticClass: "text-xs-right" }, [
                            _vm._v(
                              " " +
                                _vm._s(
                                  item.order.market ||
                                    item.cityLocation.description
                                ) +
                                " "
                            )
                          ]),
                          _c(
                            "td",
                            [
                              _c(
                                "v-icon",
                                {
                                  directives: [
                                    {
                                      name: "show",
                                      rawName: "v-show",
                                      value: item.cityOrderId > 0,
                                      expression: "item.cityOrderId > 0"
                                    }
                                  ],
                                  staticClass: "accent--text",
                                  on: {
                                    click: function($event) {
                                      return _vm.selectRow(item)
                                    }
                                  }
                                },
                                [_vm._v(" mdi-chevron-right mdi-36px ")]
                              )
                            ],
                            1
                          )
                        ])
                      }),
                      0
                    )
                  : _vm.isMobile
                  ? _c(
                      "tbody",
                      _vm._l(props.items, function(item, index) {
                        return _c("tr", { key: index }, [
                          _c("td", { staticClass: "pa-1" }, [
                            _c("ul", { staticClass: "flex-content" }, [
                              _c(
                                "li",
                                {
                                  staticClass: "flex-item pa-0",
                                  attrs: { "data-label": "Name" }
                                },
                                [
                                  _vm._v(
                                    " " + _vm._s(_vm.getEventName(item)) + " "
                                  )
                                ]
                              ),
                              _c(
                                "li",
                                {
                                  staticClass: "flex-item pa-0",
                                  attrs: { "data-label": "Job" }
                                },
                                [
                                  _vm._v(
                                    " " +
                                      _vm._s(item.cityJobCode.description) +
                                      " "
                                  )
                                ]
                              ),
                              _c(
                                "li",
                                {
                                  staticClass: "flex-item pa-0",
                                  attrs: { "data-label": "Start" }
                                },
                                [
                                  _vm._v(
                                    " " +
                                      _vm._s(
                                        _vm.getShortTime(item.start) +
                                          " " +
                                          (item.order.startDateTimeZone ||
                                            item.cityLocation.timezone)
                                      ) +
                                      " "
                                  )
                                ]
                              ),
                              _c(
                                "li",
                                {
                                  staticClass: "flex-item pa-0",
                                  attrs: { "data-label": "End" }
                                },
                                [
                                  _vm._v(
                                    " " +
                                      _vm._s(
                                        _vm.getShortTime(item.end) +
                                          " " +
                                          (item.order.startDateTimeZone ||
                                            item.cityLocation.timezone)
                                      ) +
                                      " "
                                  )
                                ]
                              ),
                              _c("li", { staticClass: "flex-item pa-0" }),
                              _c(
                                "li",
                                { staticClass: "flex-item pa-0 text-end" },
                                [
                                  _c(
                                    "v-icon",
                                    {
                                      directives: [
                                        {
                                          name: "show",
                                          rawName: "v-show",
                                          value: item.cityOrderId > 0,
                                          expression: "item.cityOrderId > 0"
                                        }
                                      ],
                                      staticClass: "accent--text",
                                      on: {
                                        click: function($event) {
                                          return _vm.selectRow(item.order)
                                        }
                                      }
                                    },
                                    [_vm._v(" mdi-chevron-right mdi-36px ")]
                                  )
                                ],
                                1
                              )
                            ])
                          ])
                        ])
                      }),
                      0
                    )
                  : _vm._e()
              ]
            }
          }
        ])
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-dialog",
    { attrs: { value: _vm.show, persistent: "" } },
    [
      _c(
        "v-card",
        [
          _c(
            "v-card-text",
            [
              _c(
                "v-container",
                [
                  _c(
                    "v-row",
                    [
                      _c("v-col", { attrs: { cols: "12" } }, [
                        _c("input", {
                          attrs: {
                            multiple: "",
                            label: "Upload File",
                            counter: "",
                            accept: "image/*,.pdf,.heic,.heif",
                            rules: _vm.rules,
                            placeholder: "Input File",
                            type: "file"
                          },
                          on: { change: _vm.assignValue }
                        })
                      ]),
                      _c(
                        "v-col",
                        { attrs: { cols: "12" } },
                        [
                          _c("v-select", {
                            attrs: {
                              label: "Title",
                              items: _vm.items,
                              required: ""
                            },
                            on: {
                              change: function($event) {
                                ;[_vm.changeToText(), _vm.checkValue()]
                              }
                            },
                            model: {
                              value: _vm.selectTitle,
                              callback: function($$v) {
                                _vm.selectTitle = $$v
                              },
                              expression: "selectTitle"
                            }
                          }),
                          _vm.showText
                            ? _c("v-text-field", {
                                attrs: { label: "Title", outlined: "" },
                                model: {
                                  value: _vm.title,
                                  callback: function($$v) {
                                    _vm.title = $$v
                                  },
                                  expression: "title"
                                }
                              })
                            : _vm._e()
                        ],
                        1
                      ),
                      _c(
                        "v-col",
                        { attrs: { cols: "12" } },
                        [
                          _c("v-text-field", {
                            attrs: {
                              label: "Description",
                              outlined: "",
                              required: ""
                            },
                            on: { change: _vm.checkValue },
                            model: {
                              value: _vm.description,
                              callback: function($$v) {
                                _vm.description = $$v
                              },
                              expression: "description"
                            }
                          })
                        ],
                        1
                      ),
                      _c(
                        "v-col",
                        { attrs: { cols: "12" } },
                        [
                          _c("v-text-field", {
                            attrs: {
                              value: _vm.orderId,
                              label: "Order",
                              outlined: "",
                              disabled: ""
                            }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          ),
          _c(
            "v-card-actions",
            [
              _c("v-spacer"),
              _c(
                "v-btn",
                {
                  attrs: { color: "blue darken-1", text: "" },
                  on: {
                    click: function($event) {
                      ;[_vm.$emit("close"), _vm.clearAll()]
                    }
                  }
                },
                [_vm._v(" Cancel ")]
              ),
              _c(
                "v-btn",
                {
                  attrs: {
                    color: "blue darken-1",
                    text: "",
                    disabled: _vm.checkEnable
                  },
                  on: { click: _vm.saveUploadedFiles }
                },
                [_vm._v(" Save ")]
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
<template>
    <v-container fluid class="pa-0">
      <div v-show="show" class="profile-parent-div">
        <v-card-text>
          <v-row>
            <v-col cols="4">
              <v-list-item-title style="font-weight: bold">
                First Name:
              </v-list-item-title>
              <v-list-item-content>
                {{ userData.firstName }}
              </v-list-item-content>
            </v-col>
            <v-col cols="4">
              <v-list-item-title style="font-weight: bold">
                Last Name:
              </v-list-item-title>
              <v-list-item-content>
                {{ userData.lastName }}
              </v-list-item-content>
            </v-col>
            <v-col cols="4">
              <v-list-item-title style="font-weight: bold">
                CCQ Email:
              </v-list-item-title>
              <v-list-item-content>
                {{ userData.cityEmailAddress }}
              </v-list-item-content>
            </v-col>
          </v-row>
            <v-row>
            <v-col cols="8">
              <v-list-item-title style="font-weight: bold">
                Eligible For Commission
                <span id="condition" @mouseover="ShowHoverMessage()"
                  @mouseout="showText = false"
                  v-show="eligibleForCommission == 'No'">?
                </span> :  {{ eligibleForCommission }}
              </v-list-item-title>
              <v-list-item-content>
                {{ EligibleCommision() }}
                <div class="hover-text" v-if="showText">
                  <pre>{{ hoverText }}</pre>
                </div>
              </v-list-item-content>
            </v-col>
            <v-col cols="4">
              <v-list-item-title style="font-weight: bold">
                Monkey Media Email:  {{ existInMonkey }}
              </v-list-item-title>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="4">
              <v-list-item-title style="font-weight: bold">
                Security Groups:
              </v-list-item-title>
              <v-list-item-content>
                <ul>
                  <li v-for="(role, index) in roles"
                    :key="index"
                  >{{ role }}
                  </li>
                </ul>
              </v-list-item-content>
            </v-col>
            <v-col cols="4">
              <v-list-item-title style="font-weight: bold">
                Locations Assigned:
              </v-list-item-title>
              <v-list-item-content>
                <ul>
                  <li v-for="(location, index) in locations"
                      :key="index"
                  >{{ location.description }}
                  </li>
                </ul>
              </v-list-item-content>
            </v-col>
            <v-col cols="4">
              <v-list-item-title style="font-weight: bold">
                Jobs Assigned:
              </v-list-item-title>
              <v-list-item-content>
                <ul>
                  <li v-for="(job, index) in jobcodes"
                      :key="index"
                  >{{ job.description }}
                  </li>
                </ul>
              </v-list-item-content>
            </v-col>
          </v-row>
        </v-card-text>
      </div>
    </v-container>
  </template>
<script>
// import userApi from "@/api/userApi";
export default {
  name: "ViewProfile",
  props: {
    userData: {
      type: Object,
      required: true,
    },
    visible: {
      type: Boolean,
      required: false,
    },
  },
  components: {},
  data() {
    return {
      userRoles: [],
      eligibleForCommission: "No",
      commissionRate: 0,
      hoverText: "",
      showText: false,
    };
  },
  mounted() {},
  methods: {
    EligibleCommision() {
      const reqRoles = ["SalesRep", "CallCenter"];
      if (this.userData.roles) {
        const userRoles = this.userData.roles;
        const inMokey = this.existInMonkey;
        // eslint-disable-next-line max-len
        if (inMokey == "Yes" && userRoles.some((r) => reqRoles.includes(r))) {
          this.eligibleForCommission = "Yes";
        } else {
          this.eligibleForCommission = "No";
        }
        if (this.eligibleForCommission == "Yes") {
          if (userRoles.includes("SalesRep")) {
            return `Commission Rate: ${3.5}%`;
          } else {
            return `Commission Rate: ${1.5}%`;
          }
        }
      }
    },
    ShowHoverMessage() {
      this.showText = true;
      let msg = "";
      if (this.userData.monkeyDetails.length == 0) {
        msg += "Monkey Media and CCQ email must match. \n";
      }
      if (this.eligibleForCommission == "No") {
        // eslint-disable-next-line max-len
        msg += "Must have one of the security groups, either “SalesRep”, or “CallCenter.";
      }
      this.hoverText = msg;
    },
  },
  computed: {
    show: {
      get() {
        return this.visible;
      },
    },
    locations() {
      return this.userData.cityLocations;
    },
    jobcodes() {
      return this.userData.cityJobCodes;
    },
    roles() {
      return this.userData.roles;
    },
    existInMonkey() {
      // eslint-disable-next-line max-len
      if (Object.keys(this.userData).length > 0 && this.userData.monkeyDetails.length > 0) {
        return "Yes";
      } else {
        return "No";
      }
    },
  },
};
</script>
<style scoped>
  ul {
    list-style-type: square;
  }
  .profile-parent-div li {
    margin: 2px 0;
  }
  .profile-parent-div {
    background-color: #ffffff;
    border: 1px solid#cec3ba !important;
    height: auto;
  }
  .profile-parent-div div {
    font-size: 16px;
    color: #000000DE
  }
  .v-tooltip__content {
    background: black !important;
  }
  #condition {
    color: blue;
    animation: blink 1s linear infinite;
    font-size: larger;
  }
  @keyframes blinker {
    50% {
      opacity: 0;
    }
  }
  .hover-text {
    position: absolute;
    background: black;
    color: white !important;
    padding: 5px;
    border-radius: 5px;
    z-index: 100;
  }
</style>

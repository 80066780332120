<template>
  <v-snackbar v-model="snackbar" :color="color"
    :timeout="timeout" :top="true">
    {{ text }}
    <v-btn @click="snackbar = false" v-if="showCloseButton">
      Close
    </v-btn>
  </v-snackbar>
</template>
<script>
export default {
  data: () => ({
    snackbar: false,
    text: "",
    timeout: 0,
    showCloseButton: false,
    color: "",
  }),
  methods: {
    showSuccess(message) {
      this.text = message;
      this.color = "success";
      this.showCloseButton = false;
      this.timeout = 3000;
      this.snackbar = true;
    },
    showError(message, timeout) {
      if (timeout === null || typeof timeout === "undefined") {
        // Need to allow timeout === -1 - that is for indefinite time.
        timeout = 3000;
      }

      this.text = message;
      this.color = "error";
      this.showCloseButton = false;
      this.timeout = timeout;
      this.snackbar = true;
    },
    hide() {
      this.snackbar = false;
    },
  },
};
</script>

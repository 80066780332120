import Vue from "vue";

export default {
  getById(userId) {
    return Vue.axios.get(`${process.env.VUE_APP_API_BASE}/Users/${userId}`);
  },
  getByEmail(email) {
    return Vue.axios.get(
        `${process.env.VUE_APP_API_BASE}/Users/GetUserByEmail/${email}`,
    );
  },
  getAllUsers() {
    return Vue.axios.get(`${process.env.VUE_APP_API_BASE}/Users/`);
  },
  getProfileDetails(param) {
    // eslint-disable-next-line max-len
    return Vue.axios.get(`${process.env.VUE_APP_API_BASE}/Users/UserProfileDetails?userParam=${param}`);
  },
};

<template>
  <v-row class="fill-height elevation-2">
    <v-col class="pt-0">
      <v-sheet height="64">
        <v-toolbar flat color="white">
          <v-btn outlined class="mr-4" color="grey darken-4" @click="setToday"
            >Today
          </v-btn>
          <v-btn fab text small color="grey darken-2" @click="prev">
            <v-icon small>mdi-chevron-left</v-icon>
          </v-btn>
          <v-btn fab text small color="grey darken-2" @click="next">
            <v-icon small>mdi-chevron-right</v-icon>
          </v-btn>
          <v-toolbar-title>{{ title }}</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-menu bottom right>
            <template v-slot:activator="{ on }">
              <v-btn outlined color="grey darken-2" v-on="on">
                <span>{{ typeToLabel[type] }}</span>
                <v-icon right>mdi-menu-down</v-icon>
              </v-btn>
            </template>
            <v-list>
              <v-list-item @click="type = 'day'">
                <v-list-item-title>Day</v-list-item-title>
              </v-list-item>
              <v-list-item @click="type = 'week'">
                <v-list-item-title>Week</v-list-item-title>
              </v-list-item>
              <v-list-item @click="type = 'month'">
                <v-list-item-title>Month</v-list-item-title>
              </v-list-item>
              <v-list-item @click="type = '4day'">
                <v-list-item-title>4 days</v-list-item-title>
              </v-list-item>
            </v-list>
          </v-menu>
        </v-toolbar>
      </v-sheet>
      <v-sheet height="600">
        <v-calendar
          ref="calendar"
          v-model="focus"
          color="accent"
          :events="assignmentsList"
          :event-name="getEventHeader"
          :event-color="getEventColor"
          event-start="calStartDateTime"
          event-end="calEndDateTime"
          :type="type"
          @click:event="showEvent"
          @click:more="viewDay"
          @click:date="viewDay"
          @change="changeView"
        ></v-calendar>
        <v-menu
          v-model="selectedOpen"
          :close-on-content-click="false"
          :activator="selectedElement"
          offset-x
        >
          <v-card color="grey lighten-4" min-width="250px" flat tile>
            <v-card-text>
              <div>
                <strong>{{
                  selectedEvent.order ? getEventName(selectedEvent) : ""
                }}</strong>
              </div>
              <v-divider></v-divider>
              <div
                v-show="selectedEvent.order && selectedEvent.order.cityOrderId"
              >
                ({{ selectedEvent.order ? selectedEvent.order.status : "" }})
              </div>
              <div
                v-show="selectedEvent.order && selectedEvent.order.cityOrderId"
              >
                {{
                  selectedEvent.order
                    ? this.getXLongDate(selectedEvent.order.startDateTime)
                    : ""
                }}
              </div>
              <div>
                <strong>EAT TIME:</strong>
                {{
                  selectedEvent.order
                    ? this.getShortTime(selectedEvent.start)
                    : ""
                }}
              </div>
              <div>
                <strong>END:</strong>
                {{
                  selectedEvent.order
                    ? this.getShortTime(selectedEvent.end)
                    : ""
                }}
              </div>
              <div>
                <strong>JOB:</strong>
                {{
                  selectedEvent.order
                    ? selectedEvent.cityJobCode.description
                    : ""
                }}
              </div>
              <div>
                {{ selectedEvent.order ? selectedEvent.order.notes : "" }}
              </div>
            </v-card-text>
            <v-card-actions>
              <v-btn text color="accent" @click="goToOrder(selectedEvent.order)"
                v-show="selectedEvent.order && selectedEvent.order.cityOrderId"
                >Go</v-btn
              >
            </v-card-actions>
          </v-card>
        </v-menu>
      </v-sheet>
    </v-col>
  </v-row>
</template>
<script>
import UserTime from "@/helpers/userTime";
import {OrderStatus} from "@/helpers/orderStatus";
import router from "@/router/index.js";

export default {
  name: "AssignmentsCalendar",
  props: {
    assignmentsList: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      today: this.now,
      focus: "",
      type: this.defaultType ? this.defaultType : "month",
      typeToLabel: {
        "month": "Month",
        "week": "Week",
        "day": "Day",
        "4day": "4 Days",
      },
      start: null,
      end: null,
      selectedEvent: {},
      selectedElement: null,
      selectedOpen: false,
    };
  },
  mounted() {
    this.$refs.calendar.scrollToTime(`${new Date().getHours()}:00`);
    this.assignmentsList.forEach((ou) => {
      ou.calStartDateTime = UserTime.getCalendarTimestamp(ou.start);
      ou.calEndDateTime = UserTime.getCalendarTimestamp(ou.end);
    });
  },
  watch: {
    assignmentsList: {
      // Handle inital load.
      handler: function(val, oldVal) {
        this.assignmentsList.forEach((ou) => {
          ou.calStartDateTime = UserTime.getCalendarTimestamp(ou.start);
          ou.calEndDateTime = UserTime.getCalendarTimestamp(ou.end);
        });
      },
    },
  },
  computed: {
    title() {
      const {start, end} = this;
      if (!start || !end) {
        return "";
      }

      const startMonth = this.monthFormatter(start);
      const endMonth = this.monthFormatter(end);
      const suffixMonth = startMonth === endMonth ? "" : endMonth;

      const startYear = start.year;
      const endYear = end.year;
      const suffixYear = startYear === endYear ? "" : endYear;

      const startDay = start.day + this.nth(start.day);
      const endDay = end.day + this.nth(end.day);

      switch (this.type) {
        case "month":
          return `${startMonth} ${startYear}`;
        case "week":
        case "4day":
          return `${startMonth} ${startDay} ${startYear}
          - ${suffixMonth} ${endDay} ${suffixYear}`;
        case "day":
          return `${startMonth} ${startDay} ${startYear}`;
      }
      return "";
    },
    monthFormatter() {
      return this.$refs.calendar.getFormatter({
        timeZone: "UTC",
        month: "long",
      });
    },
  },
  methods: {
    getEventHeader(event, isTimedEvent) {
      if (event.input.cityOrderId === 0) {
        // Admin assignment
        return `ADMIN - ${event.input.cityLocation.description}`;
      } else {
        return event.input.order.name;
      }
    },
    getEventName(assn) {
      if (assn.order.cityOrderId === 0) {
        // Admin assignment
        return `ADMIN - ${assn.cityLocation.description}`;
      } else {
        return assn.order.name;
      }
    },
    goToOrder(order) {
      this.selectedOpen = false;
      router.push({
        name: "ScheduleDetails",
        params: {orderId: order.cityOrderId},
      });
    },
    getEventColor(event) {
      switch (event.order.status) {
        case OrderStatus.Incomplete:
          return "error";
        case OrderStatus.Confirmed:
          return "success";
        case OrderStatus.Unconfirmed:
          return "warning";
        default:
          return "info";
      }
    },
    viewDay({date}) {
      this.focus = date;
      this.type = "day";
    },
    setToday() {
      this.focus = this.today;
    },
    prev() {
      this.$refs.calendar.prev();
    },
    next() {
      this.$refs.calendar.next();
    },
    showEvent({nativeEvent, event}) {
      const open = () => {
        this.selectedEvent = event;
        this.selectedElement = nativeEvent.target;
        setTimeout(() => (this.selectedOpen = true), 10);
      };

      if (this.selectedOpen) {
        this.selectedOpen = false;
        setTimeout(open, 10);
      } else {
        open();
      }

      nativeEvent.stopPropagation();
    },
    changeView({start, end}) {
      this.start = start;
      this.end = end;
    },
    nth(d) {
      return d > 3 && d < 21 ?
        "th" :
        ["th", "st", "nd", "rd", "th", "th", "th", "th", "th", "th"][d % 10];
    },
    rnd(a, b) {
      return Math.floor((b - a + 1) * Math.random()) + a;
    },
    formatDate(a, withTime) {
      return withTime ?
        "${a.getFullYear()}-${a.getMonth() + 1}-${a.getDate()} " +
            "${a.getHours()}:${a.getMinutes()}" :
        "${a.getFullYear()}-${a.getMonth() + 1}-${a.getDate()}";
    },
    getLongDateTime(timestamp) {
      return UserTime.getLongDateTime(timestamp);
    },
    getXLongDate(timestamp) {
      return UserTime.getXLongDate(timestamp);
    },
    getShortTime(timestamp) {
      return UserTime.getShortTime(timestamp);
    },
    getDateTimeWithTimezone(datetime, timezone) {
      return datetime ? `${datetime} ${timezone}` : "";
    },
  },
};
</script>

import { render, staticRenderFns } from "./AssignmentsGrid.vue?vue&type=template&id=1b83d3ac&scoped=true&"
import script from "./AssignmentsGrid.vue?vue&type=script&lang=js&"
export * from "./AssignmentsGrid.vue?vue&type=script&lang=js&"
import style0 from "./AssignmentsGrid.vue?vue&type=style&index=0&id=1b83d3ac&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "1b83d3ac",
  null
  
)

/* vuetify-loader */
import installComponents from "!../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VDataTable } from 'vuetify/lib/components/VDataTable';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VLayout } from 'vuetify/lib/components/VGrid';
installComponents(component, {VDataTable,VIcon,VLayout})


/* vuetify-loader */
import installDirectives from "!../../node_modules/vuetify-loader/lib/runtime/installDirectives.js"
import Resize from 'vuetify/lib/directives/resize'
installDirectives(component, {Resize})


/* hot reload */
if (module.hot) {
  var api = require("/home/vsts/work/1/s/frontend/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('1b83d3ac')) {
      api.createRecord('1b83d3ac', component.options)
    } else {
      api.reload('1b83d3ac', component.options)
    }
    module.hot.accept("./AssignmentsGrid.vue?vue&type=template&id=1b83d3ac&scoped=true&", function () {
      api.rerender('1b83d3ac', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/components/AssignmentsGrid.vue"
export default component.exports
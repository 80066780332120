<template>
  <v-container fluid class="pa-0">
        <v-toolbar class="primary" dense>
            <BreadCrumbs />
          </v-toolbar>
        <v-card tile>
          <v-row class="ma-1">
            <v-col class="col-align" cols="8" md="6" lg="3" xl="3">
              <v-text-field
                outlined
                label="Order Number"
                v-model.number="orderId"
                :rules="[rules.required]"
                type="number"
                maxlength="10"
              >
              </v-text-field>
            </v-col>
            <v-col>
              <button
              class="refresh_button"
              @click="refreshOrder"
                >Refresh</button
              >
            </v-col>
          </v-row>
        </v-card>
  </v-container>
</template>

<script>
import BreadCrumbs from "@/components/Breadcrumbs";
import orderApi from "@/api/orderApi";
import Vue from "vue";
import {mapGetters} from "vuex";

export default {
  name: "RefreshOrder",
  components: {
    BreadCrumbs,
  },
  data() {
    return {
      orderId: "",
      rules: {
        required: (value) => !!value || "Required.",
      },
    };
  },
  computed: mapGetters({
    isAuthenticated: "isAuthenticated",
    currentUser: "getCurrentUser",
  }),
  methods: {
    async refreshOrder() {
      const data = {
        user: this.currentUser.id,
        order: this.orderId,
        email: this.currentUser.email,
      };
      await orderApi.getRefreshOrder(data).then((response) => {
        if (response.data.statusCode === 200) {
          Vue.swal({
            title: "Your Request has been successfully sent!",
            text:
            "The order may take up to 30 mins to Refresh",
            type: "success",
            showCancelButton: false,
            confirmButtonColor: "accent",
            confirmButtonText: "Ok",
            closeOnConfirm: false,
          });
        } else {
          Vue.swal({
            title: "Could not Process Request",
            text:
            "Could not process order id, please verify the order number",
            type: "error",
            showCancelButton: false,
            confirmButtonColor: "accent",
            confirmButtonText: "Ok",
            closeOnConfirm: false,
          });
        }
      });
    },
  },
};
</script>

<style scoped>
.refresh_button{
  padding: 16px !important;
  background-color: #af1e2d;
  border-color: #af1e2d;
  color: #fff;
  border-radius: 7px;
}
.v-text-field{
  width: 300px;
}
.col-align{
  text-align: -webkit-right
}
input::-webkit-inner-spin-button,
input::-webkit-outer-spin-button {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  margin: 0;
}
</style>

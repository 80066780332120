<template>
  <v-container fluid class="pa-0">
    <v-layout flex align-center justify-center>
      <v-flex md12>
        <v-card tile>
          <v-toolbar dense color="primary">
            <Breadcrumbs />
          </v-toolbar>
          <v-divider></v-divider>
          <v-tabs
            v-model="tab"
            fixed-tabs
            :background-color="'primary darken-1'"
            hide-slider="true"
            :active-class="'primary black--text'"
          >
            <v-tab href="#tab-1">
              List
              <v-icon left>mdi-format-list-bulleted</v-icon>
            </v-tab>
            <v-tab href="#tab-2">
              Calendar
              <v-icon left>mdi-calendar-month</v-icon>
            </v-tab>
          </v-tabs>
          <OrdersFilterToolbar
            class="grey lighten-5"
            :allEmployeesList="employeeList"
            :allOrders="allOrders"
            :isSchedule="true"
            :orderFormatList="orderFormatList"
            @setFilteredOrders="setFilteredOrders"
            @getOrders="getOrders"
          />
          <v-divider></v-divider>
          <v-tabs-items v-model="tab">
            <v-tab-item :value="'tab-1'">
              <v-card tile flat>
                <OrdersGrid :order-list="filteredOrders" :isSchedule="true" />
              </v-card>
            </v-tab-item>
            <v-tab-item :value="'tab-2'">
              <v-card tile flat>
                <OrdersCalendar
                  :order-list="filteredOrders"
                  :isSchedule="true"
                />
              </v-card>
            </v-tab-item>
          </v-tabs-items>
        </v-card>
      </v-flex>
    </v-layout>
  </v-container>
</template>

<script>
import Breadcrumbs from "@/components/Breadcrumbs";
import orderApi from "@/api/orderApi";
import OrdersGrid from "@/components/OrdersGrid.vue";
import OrdersCalendar from "@/components/OrdersCalendar.vue";
import OrdersFilterToolbar from "@/components/OrdersFilterToolbar.vue";

export default {
  name: "Orders",
  components: {
    OrdersGrid,
    OrdersCalendar,
    Breadcrumbs,
    OrdersFilterToolbar,
  },
  data() {
    return {
      tab: null,
      allOrders: [],
      filteredOrders: [],
      allEmployeesList: [],
      orderFormatList: [],
    };
  },
  mounted() {
    orderApi.getOrderFormatList().then((response) => {
      this.orderFormatList = response.data.orderFormatList;
    });
    orderApi.getEmployeesList().then((response) => {
      this.employeeList = response.data.employeeList;
    });
  },
  methods: {
    async getOrders(orderFilters) {
      orderApi.getScheduleOrders(orderFilters).then((response) => {
        let allOrders = response.data.orders;

        allOrders = allOrders.reduce((unique, o) => {
          if (!unique.some((obj) => {
            return obj.id === o.id;
          })) {
            unique.push(o);
          }
          return unique;
        }, []);
        if (orderFilters.document === "With Attachment") {
          allOrders = allOrders.filter(function(el) {
            return el.isDocument === true;
          });
        } else if (orderFilters.document === "Without Attachment") {
          allOrders = allOrders.filter(function(el) {
            return el.isDocument === false;
          });
        } else {
          allOrders = allOrders;
        }
        this.allOrders = allOrders;
      });
    },
    setFilteredOrders(orders) {
      this.filteredOrders = orders;
    },
  },
};
</script>

/* eslint-disable max-len */
/* eslint-disable require-jsdoc */
import * as Msal from "msal";
import store from "@/store/store";
import router from "@/router";

// With a lot of help from ; https://stackoverflow.com/questions/52944052/creating-a-single-instance-of-a-class-within-a-vue-application
// https://github.com/AzureAD/microsoft-authentication-library-for-js/blob/dev/lib/msal-core/src/UserAgentApplication.ts

export default class AuthService {
  constructor() {
    this.accessTokenRequest = {
      scopes: [process.env.VUE_APP_AZURE_SCOPE],
      prompt: "select_account",
    };
    this.loginRequest = {
      scopes: ["openid"],
    };
    this.msalConfig = {
      auth: {
        clientId: process.env.VUE_APP_AZURE_CLIENT_ID,
        authority: process.env.VUE_APP_AZURE_AUTHORITY,
        redirectUri: process.env.VUE_APP_AZURE_REDIRECT_URI,
        validateAuthority: true,
        navigateToLoginRequestUrl: false,
      },
      cache: {
        cacheLocation: "localStorage",
        storeAuthStateInCookie: false,
      },
    };

    this.app = new Msal.UserAgentApplication(this.msalConfig);

    this.app.handleRedirectCallback((error, response) => {
      if (error) {
        console.error(error);
      } else {
        if (response.tokenType === "id_token") {
          console.log("id_token acquired at: " + new Date().toString());
          this.app.getAccount();
        } else if (response.tokenType === "access_token") {
          console.log("access_token acquired at: " + new Date().toString());
          setTimeout(() => {
            store.commit("START_LOADING", true);
          }, 100);
          store.dispatch("authAzureLoggedIn", response.accessToken)
              .then(() => {
                store.dispatch("userRequestEmail",
                    response.account.userName).then(() => {
                  setTimeout(() => {
                    store.commit("FINISH_LOADING", false);
                  }, 100);
                  router.push("/");
                });
              });
        }
      }
    });
  }

  logout() {
    this.app.logout();
  }

  login() {
    this.app.loginRedirect(this.loginRequest);
  }

  isLoggedIn() {
    const account = this.app.getAccount();
    return !!account;
  }

  getToken() {
    setTimeout(() => {
      store.commit("START_LOADING", true);
    }, 100);
    this.app.acquireTokenSilent(this.accessTokenRequest).then(
        (response) => {
          store.dispatch("authAzureLoggedIn", response.accessToken)
              .then(() => {
                store.dispatch("userRequestEmail",
                    response.account.userName).then(() => {
                  setTimeout(() => {
                    store.commit("FINISH_LOADING", false);
                  }, 100);
                  router.push("/");
                });
              });
        },
    ).catch((error) => {
      console.error(error);
      this.app.acquireTokenRedirect(this.accessTokenRequest);
    });
  }
}

var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-row",
    [
      _c(
        "v-col",
        { staticClass: "hidden-sm-and-up", attrs: { xs: "12" } },
        [
          _c("TriColList", { attrs: { "detail-items": _vm.mobileDetailItems } })
        ],
        1
      ),
      _c(
        "v-col",
        { staticClass: "hidden-xs-only hidden-lg-and-up", attrs: { sm: "6" } },
        [
          _c("TriColList", {
            attrs: { "detail-items": _vm.firstHalfDetailItems }
          })
        ],
        1
      ),
      _c(
        "v-col",
        { staticClass: "hidden-xs-only hidden-lg-and-up", attrs: { sm: "6" } },
        [
          _c("TriColList", {
            attrs: { "detail-items": _vm.secondHalfDetailItems }
          })
        ],
        1
      ),
      _c(
        "v-col",
        { staticClass: "hidden-md-and-down", attrs: { lg: "4" } },
        [
          _c("TriColList", {
            attrs: { "detail-items": _vm.firstThirdDetailItems }
          })
        ],
        1
      ),
      _c(
        "v-col",
        { staticClass: "hidden-md-and-down", attrs: { lg: "4" } },
        [
          _c("TriColList", {
            attrs: { "detail-items": _vm.secondThirdDetailItems }
          })
        ],
        1
      ),
      _c(
        "v-col",
        { staticClass: "hidden-md-and-down", attrs: { lg: "4" } },
        [
          _c("TriColList", {
            attrs: { "detail-items": _vm.finalThirdDetailItems }
          })
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
<template>
  <v-container fluid fill-height>
    <v-layout flex align-center justify-center>
      <v-flex xs12 sm10 md6 lg4 xl3>
        <v-card tile>
          <v-toolbar dense color="primary">
            <v-toolbar-title>Reset Forgotten Password</v-toolbar-title>
          </v-toolbar>
          <v-divider></v-divider>
          <v-card-text class="pt-4">
            <div>
              <v-row>
                <v-col cols="12" sm="12" md="12">
                  Please enter enter your new password.
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12" sm="12" md="12">
                  <v-form v-model="valid" ref="form">
                    <v-text-field
                      label="Password"
                      v-model="password"
                      min="8"
                      :append-icon="e1 ? 'visibility' : 'visibility_off'"
                      @click:append="() => (e1 = !e1)"
                      :type="e1 ? 'password' : 'text'"
                      :rules="passwordRules"
                      required
                    />
                    <v-text-field
                      label="Repeat Password"
                      v-model="passwordRepeat"
                      min="8"
                      :append-icon="e2 ? 'visibility' : 'visibility_off'"
                      @click:append="() => (e2 = !e2)"
                      :type="e2 ? 'password' : 'text'"
                      :rules="repeatPasswordRules"
                      required
                    />
                  </v-form>
                </v-col>
              </v-row>
            </div>
          </v-card-text>
          <v-card-actions>
            <v-btn
              :class="{
                accent: valid,
              }"
              :disabled="!valid"
              @click="submit"
              >Submit</v-btn
            >
          </v-card-actions>
        </v-card>
      </v-flex>
    </v-layout>
  </v-container>
</template>
<script>
import authApi from "@/api/authApi";

export default {
  name: "ResetPassword",
  props: {
    email: String,
    code: String,
  },
  data() {
    return {
      valid: false,
      e1: true,
      e2: true,
      password: "",
      repeatPassword: "",
      passwordRules: [
        (v) => !!v || "Password is required",
        // eslint-disable-next-line max-len
        (v) => /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[!@#$%^&*()])[A-Za-z\d!@#$%^&*()]{8,}$/.test(v) ||
          // eslint-disable-next-line max-len
          "Minimum eight characters, at least one uppercase letter, one lowercase letter, one number and one special character [! @ # $ % ^ & * ( )]",
      ],
      repeatPasswordRules: [
        (v) => (!!v && v) === this.password || "Password doesn't match.",
      ],
    };
  },
  methods: {
    submit() {
      if (this.$refs.form.validate()) {
        authApi.resetPassword(this.credentials).then((response) => {
          this.$root.$emit("successToast", "Password reset successful.");
          this.$router.push("/login");
        }),
        (error) => {
          this.$root.$emit(
              "errorToast",
              "Error encountered.  Please contact support.",
          );
        };
      }
    },
  },
  computed: {
    credentials() {
      return {
        email: this.email,
        password: this.password,
        resetToken: this.code,
      };
    },
  },
};
</script>

<template>
  <v-container fluid class="pa-0">
    <v-layout flex align-center justify-center>
      <v-flex md12>
        <v-card tile>
          <v-toolbar dense color="primary">
            <Breadcrumbs />
          </v-toolbar>
          <v-divider></v-divider>
          <v-tabs
            v-model="tab"
            fixed-tabs
            :background-color="'primary darken-1'"
            hide-slider="true"
            :active-class="'primary black--text'"
          >
            <v-tab href="#tab-1">
              List
              <v-icon left>mdi-format-list-bulleted</v-icon>
            </v-tab>
            <v-tab href="#tab-2">
              Calendar
              <v-icon left>mdi-calendar-month</v-icon>
            </v-tab>
          </v-tabs>
          <v-toolbar flat>
            <v-toolbar-items style="width: 100%;" class="hidden-sm-and-down">
              <v-row>
                <v-spacer></v-spacer>
                <v-col>
                  <v-menu
                    v-model="startDateMenu"
                    :close-on-content-click="false"
                    transition="scale-transition"
                    offset-y
                  >
                    <template v-slot:activator="{ on }">
                      <v-text-field
                        v-model="filterStartDate"
                        label="Start"
                        prepend-icon="event"
                        readonly
                        clearable
                        v-on="on"
                      ></v-text-field>
                    </template>
                    <v-date-picker
                      v-model="filterStartDate"
                      @input="startDateMenu = false"
                    ></v-date-picker>
                  </v-menu>
                </v-col>
                <v-col>
                  <v-menu
                    v-model="endDateMenu"
                    :close-on-content-click="false"
                    transition="scale-transition"
                    offset-y
                  >
                    <template v-slot:activator="{ on }">
                      <v-text-field
                        v-model="filterEndDate"
                        label="End"
                        prepend-icon="event"
                        readonly
                        clearable
                        v-on="on"
                      ></v-text-field>
                    </template>
                    <v-date-picker
                      v-model="filterEndDate"
                      @input="endDateMenu = false"
                    ></v-date-picker>
                  </v-menu>
                </v-col>
              </v-row>
            </v-toolbar-items>
            <v-menu :close-on-content-click="false">
              <template v-slot:activator="{ on }">
                <span class="hidden-md-and-up">Filters</span>
                <v-app-bar-nav-icon
                  v-on="on"
                  class="hidden-md-and-up"
                ></v-app-bar-nav-icon>
              </template>
              <v-list flat>
                <v-text-field
                  v-model="search"
                  append-icon="mdi-magnify"
                  label="Search"
                  single-line
                  hide-details
                  class="pa-3"
                ></v-text-field>
                <v-menu
                  v-model="startDateNavMenu"
                  :close-on-content-click="false"
                  :nudge-right="40"
                  transition="scale-transition"
                  offset-y
                  min-width="290px"
                >
                  <template v-slot:activator="{ on }">
                    <v-text-field
                      v-model="filterStartDate"
                      label="Start"
                      prepend-icon="event"
                      readonly
                      clearable
                      v-on="on"
                      class="pa-3"
                    ></v-text-field>
                  </template>
                  <v-date-picker
                    v-model="filterStartDate"
                    @input="startDateNavMenu = false"
                  ></v-date-picker>
                </v-menu>
                <v-menu
                  v-model="endDateNavMenu"
                  :close-on-content-click="false"
                  :nudge-right="40"
                  transition="scale-transition"
                  offset-y
                  min-width="290px"
                >
                  <template v-slot:activator="{ on }">
                    <v-text-field
                      v-model="filterEndDate"
                      label="End"
                      prepend-icon="event"
                      readonly
                      clearable
                      v-on="on"
                      class="pa-3"
                    ></v-text-field>
                  </template>
                  <v-date-picker
                    v-model="filterEndDate"
                    @input="endDateNavMenu = false"
                  ></v-date-picker>
                </v-menu>
              </v-list>
            </v-menu>
          </v-toolbar>
          <v-divider></v-divider>
          <v-tabs-items v-model="tab">
            <v-tab-item :value="'tab-1'">
              <v-card flat tile>
                <AssignmentsGrid :assignments-list="filteredAssignments" />
              </v-card>
            </v-tab-item>
            <v-tab-item :value="'tab-2'">
              <v-card flat tile>
                <AssignmentsCalendar :assignments-list="filteredAssignments" />
              </v-card>
            </v-tab-item>
          </v-tabs-items>
        </v-card>
      </v-flex>
    </v-layout>
  </v-container>
</template>
<script>
import Breadcrumbs from "@/components/Breadcrumbs";
import assignmentsApi from "@/api/assignmentsApi";
import AssignmentsCalendar from "@/components/AssignmentsCalendar.vue";
import AssignmentsGrid from "@/components/AssignmentsGrid.vue";
import moment from "moment";

export default {
  name: "AssignmentsSchedule",
  components: {
    AssignmentsCalendar,
    AssignmentsGrid,
    Breadcrumbs,
  },
  data() {
    return {
      tab: null,
      allAssignments: [],
      filteredAssignments: [],
      startDateMenu: false,
      endDateMenu: false,
      startDateNavMenu: false,
      endDateNavMenu: false,
      filterStartDate: moment()
          .startOf("day")
          .format("YYYY-MM-DD"),
      filterEndDate: moment()
          .add(7, "days")
          .startOf("day")
          .format("YYYY-MM-DD"),
    };
  },
  mounted() {
    assignmentsApi.getUserAssignments().then((response) => {
      if (response.data) {
        this.allAssignments = response.data;
      }
    });
  },
  watch: {
    allAssignments: {
      // Handle inital load.
      handler: function(val, oldVal) {
        this.filterAssignments();
      },
    },
    search: {
      handler: function(val, oldVal) {
        this.filterAssignments();
      },
    },
    filterStartDate: {
      handler: function(val, oldVal) {
        this.filterAssignments();
      },
    },
    filterEndDate: {
      handler: function(val, oldVal) {
        this.filterAssignments();
      },
    },
  },
  methods: {
    filterAssignments() {
      let ret = this.allAssignments;
      if (this.filterStartDate) {
        ret = ret.filter((o) => {
          return (
            !o.start ||
            moment(this.filterStartDate).isBefore(
                moment(o.start)
                    .startOf("day")
                    .add(1, "seconds"),
            )
          );
        });
      }
      if (this.filterEndDate) {
        ret = ret.filter((o) => {
          return (
            !o.start ||
            moment(this.filterEndDate).isAfter(
                moment(o.start)
                    .startOf("day")
                    .subtract(1, "seconds"),
            )
          );
        });
      }
      if (this.search) {
        ret = ret.filter((o) => {
          let b = false;
          const keys = Object.keys(o);
          for (let i = 0; i < keys.length; i++) {
            if (
              o[keys[i]] && // Not null or undefined
              typeof o[keys[i]] === "string" && // Is a string
              o[keys[i]].toUpperCase().indexOf(this.search.toUpperCase()) >= 0
            ) {
              b = true;
              break;
            }
          }
          return b;
        });
      }
      this.filteredAssignments = ret;
    },
  },
};
</script>

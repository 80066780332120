<template>
  <v-card class="no-margin" tile>
    <v-card-text class="pa-0 pb-1">
      <v-tabs
        v-model="tab"
        fixed-tabs
        :background-color="'primary darken-1'"
        hide-slider="true"
        :active-class="'primary black--text'"
      >
        <v-tab href="#tab-1">
          Active
          <v-icon left>mdi-bell-ring-outline</v-icon>
          <v-badge v-if="activeActions.length"
                  color="accent"
                  class="justify-start"
                  :content="activeActions.length"
                  inline
                  bordered
                  ></v-badge
                >
        </v-tab>
        <v-tab href="#tab-2">
          Hidden
          <v-icon left>mdi-bell-off-outline</v-icon>
          <v-badge v-if="hiddenActions.length"
                  color="accent"
                  class="justify-start"
                  :content="hiddenActions.length"
                  inline
                  bordered
                  ></v-badge
                >
        </v-tab>
      </v-tabs>
      <v-tabs-items v-model="tab">
        <v-tab-item :value="'tab-1'">
          <ActionItemsList :actions="activeActions" :is-hidden="false" />
        </v-tab-item>
        <v-tab-item :value="'tab-2'">
          <ActionItemsList :actions="hiddenActions" :is-hidden="true" />
        </v-tab-item>
      </v-tabs-items>
    </v-card-text>
  </v-card>
</template>
<script>
import router from "@/router/index.js";
import homeApi from "@/api/homeApi";
import ActionItemsList from "@/components/ActionItemsList.vue";

export default {
  name: "HomeActions",
  props: {actions: Array},
  components: {
    ActionItemsList,
  },
  data() {
    return {
      tab: "",
    };
  },
  methods: {
    actionButtonClick(action) {
      if (action.orderId && action.orderId !== 0) {
        router.push({
          name: action.redirect,
          params: {orderId: action.orderId},
        });
      } else {
        router.push({name: action.redirect});
      }
    },
    toggleHideActionItem(action) {
      action.isHidden = !action.isHidden;
      homeApi.toggleHideActionItem(action.id).then(() => {});
    },
  },
  computed: {
    activeActions() {
      return this.actions.filter((a) => {
        return a.isHidden == false;
      });
    },
    hiddenActions() {
      return this.actions.filter((a) => {
        return a.isHidden == true;
      });
    },
  },
};
</script>
<style scoped>
.no-margin {
  margin: 0 !important;
}
</style>

import {mapGetters} from "vuex";
import {Role} from "@/helpers/role";
export const currentUserMixin = {
  data: {
    employee: false,
  },
  mounted() {
    if (this.currentUser.roles.length > 1) {
      this.employee = false;
    } else {
      this.currentUser.roles.forEach((x) => {
        if (x === Role.Employee) {
          this.employee = true;
        }
      });
    }
  },
  computed: mapGetters({
    isAuthenticated: "isAuthenticated",
    currentUser: "getCurrentUser",
  }),
};

import Vue from "vue";

export default {
  getAllOrdersForPayrollTips() {
    return Vue.axios.get(`${process.env.VUE_APP_API_BASE}/Payroll`);
  },
  putPayrollTipsSubmissions(payrollDate, orders) {
    return Vue.axios.post(
        `${process.env.VUE_APP_API_BASE}/Payroll/${payrollDate}`,
        orders,
    );
  },
  getAllOrdersForPayrollCommissions() {
    return Vue.axios.get(`${process.env.VUE_APP_API_BASE}/Payroll/Commissions`);
  },
  putPayrollCommissionSubmissions(payrollDate, orders) {
    return Vue.axios.post(
        `${process.env.VUE_APP_API_BASE}/Payroll` +
        "/PostPayrollCommissionSubmissions/" +
        payrollDate,
        orders,
    );
  },
  getAllPayrollInquiryData(searchFilters) {
    return Vue.axios.post(
        `${process.env.VUE_APP_API_BASE}/Payroll/Inquiry/`,
        searchFilters,
    );
  },
  getAllPayrollInquiryData(searchFilters) {
    return Vue.axios.post(
        `${process.env.VUE_APP_API_BASE}/Payroll/Inquiry/`,
        searchFilters,
    );
  },
};

<template>
  <v-breadcrumbs :items="crumbs">
    <template v-slot:item="{ item }">
      <v-breadcrumbs-item :to="item.to" :disabled="item.disabled" exact="true">
        <span
          :class="
            crumbs.indexOf(item) == crumbs.length - 1
              ? 'black--text subtitle-1'
              : 'black--text subtitle-1 font-weight-black text-shadow'
          "
          >{{ item.text.toUpperCase() }}</span
        >
      </v-breadcrumbs-item>
    </template>
    <template v-slot:divider>
      <v-icon color="black">mdi-chevron-right</v-icon>
    </template>
  </v-breadcrumbs>
</template>
<script>
export default {
  name: "Breadcrumbs",
  props: {
    customLastCrumb: String,
  },
  computed: {
    crumbs: function() {
      const ret = [];
      const pathArray = this.$route.path.split("/").filter((p) => {
        return p !== "" && p !== null && typeof p !== "undefined";
      });
      ret.push({
        text: "Home",
        to: "/",
        disabled: false,
      });
      for (let i = 0; i < pathArray.length; i++) {
        ret.push({
          text: pathArray[i],
          to: `/${pathArray[i]}`,
          disabled: i === pathArray.length - 1,
        });
      }

      if (this.customLastCrumb) {
        ret[ret.length - 1] = {
          text: this.customLastCrumb,
          to: "",
          disabled: true,
        };
      }

      return ret;
    },
  },
};
</script>
<style scoped>
.text-shadow {
  text-shadow: grey 2px 2px 4px;
}
</style>

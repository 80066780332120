import Vue from "vue";

export default {
  login(credentials) {
    return Vue.axios.post(
        `${process.env.VUE_APP_API_BASE}/Auth/Login`,
        credentials,
    );
  },
  isAzureAuth() {
    return Vue.prototype.$AuthService.isLoggedIn();
  },
  azureLogin() {
    Vue.prototype.$AuthService.login();
  },
  forgotPassword(email) {
    return Vue.axios.post(
        `${process.env.VUE_APP_API_BASE}/Auth/ForgotPassword/${email}`,
    );
  },
  resetPassword(credentials) {
    return Vue.axios.post(
        `${process.env.VUE_APP_API_BASE}/Auth/ResetPassword`,
        credentials,
    );
  },
};

<template>
  <v-container fluid class="pa-0">
    <v-layout flex align-center justify-center>
      <v-flex md12>
        <v-card tile>
          <v-toolbar dense color="primary">
            <Breadcrumbs />
          </v-toolbar>
          <v-divider></v-divider>
          <v-toolbar flat>
            <v-toolbar-items style="width: 100%;" class="hidden-sm-and-down">
              <v-row justify="center">
                <v-col width="20%">
                  <v-menu
                    v-model="startDateMenu"
                    :close-on-content-click="false"
                    transition="scale-transition"
                    offset-y
                  >
                    <template v-slot:activator="{ on }">
                      <v-text-field
                        class="mt-2"
                        v-model="filterStartDate"
                        label="Start Date"
                        prepend-icon="event"
                        readonly
                        v-on="on"
                      ></v-text-field>
                    </template>
                    <v-date-picker
                      v-model="filterStartDate"
                      @input="startDateMenu = false"
                    ></v-date-picker>
                  </v-menu>
                </v-col>
                <v-col width="20%">
                  <v-menu
                    v-model="endDateMenu"
                    :close-on-content-click="false"
                    transition="scale-transition"
                    offset-y
                  >
                    <template v-slot:activator="{ on }">
                      <v-text-field
                        class="mt-2"
                        v-model="filterEndDate"
                        label="End Date"
                        prepend-icon="event"
                        readonly
                        v-on="on"
                      ></v-text-field>
                    </template>
                    <v-date-picker
                      v-model="filterEndDate"
                      @input="endDateMenu = false"
                    ></v-date-picker>
                  </v-menu>
                </v-col>
                <v-col width="20%">
                  <v-select
                    v-model="filterMarket"
                    :items="marketList"
                    item-text="description"
                    item-value="id"
                    label="Markets"
                    multiple
                    chips
                    clearable
                  >
                  </v-select>
                </v-col>
                <v-col width="20%">
                  <v-select
                    v-model="scheduleType"
                    :items="scheduleTypeList"
                    item-text="description"
                    item-value="id"
                    label="Filters"
                    chips
                    clearable
                  >
                  </v-select>
                </v-col>
                <v-col width="20%" class="d-flex justify-center">
                  <v-btn
                    color="accent"
                    :disabled="!filterMarket.length > 0"
                    @click="searchResults"
                    >Search</v-btn
                  >
                </v-col>
              </v-row>
              <v-divider></v-divider>
            </v-toolbar-items>
            <v-menu :close-on-content-click="false">
              <template v-slot:activator="{ on }">
                <span class="hidden-md-and-up">Filters</span>
                <v-app-bar-nav-icon
                  v-on="on"
                  class="hidden-md-and-up"
                ></v-app-bar-nav-icon>
              </template>
              <v-list flat>
                <v-menu
                    v-model="startDateNavMenu"
                    :close-on-content-click="false"
                    transition="scale-transition"
                    offset-y
                    min-width="290px"
                  >
                    <template v-slot:activator="{ on }">
                      <v-text-field
                        class="pa-3"
                        v-model="filterStartDate"
                        label="Start Date"
                        prepend-icon="event"
                        readonly
                        v-on="on"
                      ></v-text-field>
                    </template>
                    <v-date-picker
                      v-model="filterStartDate"
                      @input="startDateNavMenu = false"
                    ></v-date-picker>
                </v-menu>
                <v-menu
                    v-model="endDateNavMenu"
                    :close-on-content-click="false"
                    transition="scale-transition"
                    offset-y
                    min-width="290px"
                  >
                    <template v-slot:activator="{ on }">
                      <v-text-field
                        class="pa-3"
                        v-model="filterEndDate"
                        label="End Date"
                        prepend-icon="event"
                        readonly
                        v-on="on"
                      ></v-text-field>
                    </template>
                    <v-date-picker
                      v-model="filterEndDate"
                      @input="endDateNavMenu = false"
                    ></v-date-picker>
                </v-menu>
                <v-divider></v-divider>
                <v-subheader>Market</v-subheader>
                <!--eslint-disable-next-line max-len-->
                <v-list-item-group v-model="filterMarket" multiple color="primary">
                  <v-list-item
                    v-for="(market, index) in marketList"
                    :key="index"
                    :items="marketList"
                    :value="market.id"
                  >
                    <template v-slot:default="{ active, toggle }">
                      <v-list-item-content>
                        <v-list-item-title
                          v-text="market.description"
                        ></v-list-item-title>
                      </v-list-item-content>
                      <v-list-item-action>
                        <v-checkbox
                          @click="toggle"
                          dense
                          color="primary"
                          :input-value="active"
                        ></v-checkbox>
                      </v-list-item-action>
                    </template>
                  </v-list-item>
                </v-list-item-group>
                <v-divider></v-divider>
                <v-subheader>Filters</v-subheader>
                <!--eslint-disable-next-line max-len-->
                <v-list-item-group v-model="scheduleType" color="primary">
                  <v-list-item
                    v-for="(schedule, index) in scheduleTypeList"
                    :key="index"
                    :items="scheduleTypeList"
                    :value="schedule.id"
                  >
                    <v-list-item-content>
                      <v-list-item-title
                        v-text="schedule.description"
                      ></v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>
                </v-list-item-group>
                <v-divider></v-divider>
                <v-btn
                    color="accent"
                    :disabled="!filterMarket.length > 0"
                    @click="searchResults"
                    min-width="290px"
                    class="ma-3"
                    >Search</v-btn
                  >
              </v-list>
            </v-menu>
          </v-toolbar>
        </v-card>
        <ClockAuditGrid :order-list="allOrders" :isSchedule="false" />
      </v-flex>
    </v-layout>
  </v-container>
</template>

<script>
import {mapGetters} from "vuex";
import orderApi from "@/api/orderApi";
import assignmentsApi from "@/api/assignmentsApi";
import Breadcrumbs from "@/components/Breadcrumbs";
import moment from "moment";
import ClockAuditGrid from "@/components/ClockAuditGrid.vue";

export default {
  name: "TimeClockAudit",
  components: {
    Breadcrumbs,
    ClockAuditGrid,
  },
  data() {
    return {
      startDateMenu: false,
      endDateMenu: false,
      startDateNavMenu: false,
      endDateNavMenu: false,
      filterStartDate: new Date().toISOString().slice(0, 10),
      filterEndDate: new Date().toISOString().slice(0, 10),
      marketList: [],
      filterMarket: [],
      scheduleType: [],
      scheduleTypeList: [
        {
          description: "Show All Schedules",
          id: 1,
          marketDescription: "All Schedules",
          marketId: 1,
        },
        {
          description: "Show Future Schedules",
          id: 2,
          marketDescription: "Future Schedules",
          marketId: 2,
        },
        {
          description: "No Clock In Time",
          id: 3,
          marketDescription: "No Clock In",
          marketId: 3,
        },
      ],
      allOrders: [],
    };
  },

  computed: {
    ...mapGetters({
      orderFilters: "getOrderFilters",
      currentUser: "getCurrentUser",
    }),
  },
  async mounted() {
    await orderApi.getMarketList().then((response) => {
      this.marketList = response.data.marketList;
      this.initialLoad = false;
    });
    this.initFilters();
  },
  methods: {
    initFilters() {
      this.filterMarket =
        this.orderFilters["market"] ? this.orderFilters["market"] :
          !this.isPayrollEmployee ?
          this.currentUser.cityLocations.map((x) => x.id) : "";
    },
    async searchResults() {
      // eslint-disable-next-line max-len
      const startdate = new Date(this.filterStartDate).toISOString().slice(0, 10);
      const enddate = new Date(this.filterEndDate).toISOString().slice(0, 10);
      const data = {
        startDate: startdate,
        endDate: enddate,
        market: this.filterMarket,
      };
      // eslint-disable-next-line max-len
      await assignmentsApi.getScheduledEmployees(data).then((response) => {
        let currentTime = moment().format("MM/DD/YYYY h:mm A");
        currentTime = Math.floor(new Date(currentTime).getTime() / 1000);
        let result;
        if (this.scheduleType === 2) {
          result = response.data.filter((element)=> {
            // eslint-disable-next-line max-len
            Math.floor(new Date(element.scheduleStart).getTime() / 1000) > currentTime;
          });
        } else if (this.scheduleType === 3) {
          result = response.data.filter((element) => {
            return element.clockIn == "";
          });
        } else {
          result = response.data;
        }
        this.allOrders = result;
      });
    },
  },
};
</script>

<template>
  <v-container fluid class="pa-0">
    <v-layout flex align-center justify-center>
      <v-flex md12>
        <v-toolbar dense color="primary">
          <Breadcrumbs />
        </v-toolbar>
        <v-divider></v-divider>
        <OrdersFilterToolbar
          class="ma-1"
          v-if="!isSubmissionsHistory"
          :allOrders="orders"
          :isCommissions="true"
          :orderMarketList="marketList"
          @setFilteredOrders="setFilteredOrders"
        >
        </OrdersFilterToolbar>
        <v-data-table
              class="ma-1"
              v-if="isSubmissionsHistory"
              :headers="submissionsHistoryHeaders"
              :items="payrollSubmissions"
              no-data-text="No Orders Found"
            >
              <template v-slot:top>
                <v-row>
                  <v-switch
                    class="ma-1 ml-7"
                    v-model="isSubmissionsHistory"
                    v-on="on"
                    label="History"
                    color="accent"
                  ></v-switch>
                </v-row>
              </template>
              <template #item.timestamp="{item}">
                {{ getDateFromDateTime(item.timestamp) }}
              </template>
              <template #item.processedDate="{item}">
                {{ getCalendarTime(item.processedDate) }}
              </template>
              <template #item.amount="{item}">
                {{ getNumeral(item.amount) }}
              </template>
        </v-data-table>
        <v-data-table
          class="ma-1"
          :headers="headers"
          :items="filteredOrders"
          :sort-by="sortBy"
          :sort-desc="sortOrder"
          show-expand
          v-if="!isSubmissionsHistory"
        >
        <template v-slot:header.cityOrderId>
          <button @click="filterClick('cityOrderId')">Order</button>
        </template>
        <template v-slot:header.name>
          <button @click="filterClick('name')">Name</button>
        </template>
        <template v-slot:header.market>
          <button @click="filterClick('market')">Market</button>
        </template>
        <template v-slot:header.startDateTime>
          <button @click="filterClick('startDateTime')">Event Date</button>
        </template>
        <template v-slot:header.paidDate>
          <button @click="filterClick('paidDate')">Date Paid</button>
        </template>
        <template v-slot:header.totalFoodSales>
          <button @click="filterClick('totalFoodSales')">
            Total Food Sales</button>
        </template>
        <template v-slot:header.totalFoodSalesDiscounts>
          <button @click="filterClick('totalFoodSalesDiscounts')">
            Total Food Sales Discounts</button>
        </template>
        <template v-slot:header.commissions>
          <button @click="filterClick('commissions')">Total Commissions</button>
        </template>
        <template v-slot:header.commissionCount>
          <button @click="filterClick('commissionCount')">
            Commission Count</button>
        </template>
          <template v-slot:top>
            <v-row>
              <v-switch
                    class="ma-1 ml-7"
                    v-model="isSubmissionsHistory"
                    v-on="on"
                    label="History"
                    color="accent"
                  ></v-switch>
              <v-spacer></v-spacer>
              <v-btn
                color="accent"
                :disabled="!filteredOrders.length"
                @click="exportToExcel"
                :class="['exportexcel',{'accent': valid }]"
                ><i class="fas fa-download"></i></v-btn
              >
              <v-dialog v-model="exportDialog" max-width="330px" persistent>
                <template v-slot:activator="{ on }" persistent>
                  <v-btn
                    class="mr-3"
                    v-on="on"
                    v-show="!isManager"
                    color="accent"
                    :disabled="!filteredOrders.length"
                    >Post To Payroll</v-btn
                  >
                </template>
                <v-card>
                  <v-card-title>Please Enter Paycheck Date</v-card-title>
                  <v-card-text>
                    <v-menu
                      v-model="exportMenu"
                      :close-on-content-click="false"
                      transition="scale-transition"
                      offset-y
                    >
                      <template v-slot:activator="{ on }">
                        <v-text-field
                          class="mt-2"
                          v-model="payrollDate"
                          label="Date"
                          prepend-icon="event"
                          readonly
                          clearable
                          v-on="on"
                          :rules="[rules.required, validDate(payrollDate)]"
                        ></v-text-field>
                      </template>
                      <v-date-picker
                        v-model="payrollDate"
                        @input="exportMenu = false"
                      ></v-date-picker>
                    </v-menu>
                  </v-card-text>
                  <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn
                      class="mr-3"
                      v-on="on"
                      color="accent"
                      @click="exportDialog = false"
                      >Cancel</v-btn
                    >
                    <v-btn
                      class="mr-3"
                      v-on="on"
                      color="accent"
                      :disabled="!isDateValid"
                      @click="submitPayrollData"
                      >Submit</v-btn
                    >
                  </v-card-actions>
                </v-card>
              </v-dialog>
            </v-row>
          </template>
          <template #item.startDateTime="{item}">
            {{ getDateFromDateTime(item.startDateTime) }}
          </template>
          <template #item.paidDate="{item}">
            {{ getDateFromDateTime(item.paidDate) }}
          </template>
          <template #item.commissions="{item}">
            {{ calculateTotalCommissions(item.commissions) }}
          </template>
          <template #item.totalFoodSales="{item}">
            {{ getNumeral(item.totalFoodSales) }}
          </template>
          <template #item.totalFoodSalesDiscounts="{item}">
            {{ getNumeral(item.totalFoodSalesDiscounts) }}
          </template>
          <template #item.commissionCount="{item}">
            {{ item.commissions.length }}
          </template>
          <template v-slot:expanded-item="{ headers, item }">
            <td :colspan="headers.length">
              <v-data-table
                class="commissions"
                :headers="commissionsHeaders"
                hide-default-footer
                :items="item.commissions"
              >
                <template v-slot:top>
                  <v-toolbar flat>
                    <v-toolbar-title>Commissions</v-toolbar-title>
                  </v-toolbar>
                  <v-divider class="primary"></v-divider>
                </template>
                <template #item.name="{item}">
                  {{ `${item.user.firstName} ${item.user.lastName}` }}
                </template>
                <template #item.cityEmployeeId="{item}">
                  {{ `${item.user.cityEmployeeId}` }}
                </template>
                <template #item.amount="{item}">
                  {{ getNumeral(item.amount) }}
                </template>
              </v-data-table>
            </td>
          </template>
        </v-data-table>
      </v-flex>
    </v-layout>
  </v-container>
</template>

<script>
import Breadcrumbs from "@/components/Breadcrumbs";
import payrollApi from "@/api/payrollApi";
import OrdersFilterToolbar from "@/components/OrdersFilterToolbar";
import UserTime from "@/helpers/userTime";
import numeral from "numeral";
import moment from "moment";
import {Role} from "@/helpers/role";
import {mapGetters} from "vuex";
import * as XLSX from "xlsx";
import "@fortawesome/fontawesome-free/css/all.min.css";

export default {
  name: "PayrollCommissions",
  components: {
    Breadcrumbs,
    OrdersFilterToolbar,
  },
  mounted() {
    this.updateCommissionData();
    if (this.$cookie.get("payrollSortBy")) {
      this.sortBy = this.$cookie.get("payrollSortBy");
      this.sortOrder = this.$cookie.get("payrollSortOrder");
      this.sortOrder = JSON.parse(this.sortOrder);
    }
  },
  data() {
    return {
      orders: [],
      marketList: [],
      filteredOrders: [],
      exportMenu: false,
      exportDialog: false,
      payrollDate: "",
      isDateValid: false,
      isManager: false,
      payrollSubmissions: [],
      rules: {
        required: (value) => !!value || "Required.",
      },
      isSubmissionsHistory: false,
      sortOrder: false,
      sortBy: "startDateTime",
    };
  },
  methods: {
    updateCommissionData() {
      payrollApi.getAllOrdersForPayrollCommissions().then((response) => {
        if (response.data) {
          this.orders = response.data.orders;
          this.payrollSubmissions = response.data.payrollSubmissions;
          this.marketList = response.data.marketList;
        }
      });
      this.currentUser.roles.forEach((x) => {
        if (x === Role.CateringManager) {
          this.isManager = true;
        }
      });
    },
    filterClick(clickedHeader) {
      // eslint-disable-next-line max-len
      const sortBy = this.$cookie.get("payrollSortBy");
      if (clickedHeader === sortBy) {
        this.$cookie.set("payrollSortOrder", !this.sortOrder);
      } else {
        this.$cookie.set("payrollSortOrder", false);
      }
      this.$cookie.set("payrollSortBy", clickedHeader);
    },
    setFilteredOrders(orders) {
      this.filteredOrders = orders;
    },
    getNumeral(amount) {
      return numeral(amount).format("$0,0.00");
    },
    getDateFromDateTime(date) {
      return UserTime.getShortDate(date);
    },
    calculateTotalCommissions(commissions) {
      let totalCommissions = 0;
      commissions.forEach((x) => {
        totalCommissions += x.amount;
      });
      return this.getNumeral(totalCommissions);
    },
    validDate(payrollDate) {
      if (
        moment(payrollDate).isSameOrAfter(this.getDateFromDateTime(new Date()))
      ) {
        this.isDateValid = true;
        return true;
      } else {
        this.isDateValid = false;
        return "Date must be in the future";
      }
    },
    submitPayrollData() {
      this.exportDialog = false;
      payrollApi
          .putPayrollCommissionSubmissions(
              this.payrollDate, this.filteredOrders)
          .then((response) => {
            this.orders = [];
            this.orders = response.data.orders;
            this.payrollSubmissions = response.data.payrollSubmissions;
            this.filteredOrders = [];
            this.payrollDate = "";
            this.$root.$emit("successToast", "Payroll updated.");
          }),
      (error) => {
        this.$root.$emit(
            "errorToast",
            "Error encountered.  Please contact support.",
        );
      };
    },
    getCalendarTime(date) {
      return UserTime.getCalendarTimestamp(date);
    },
    exportToExcel() {
      if (this.filteredOrders.length === 0) {
        return;
      }
      const headers = ["Order", "Name", "Market",
        "Event Date", "Date Paid", "Total Food Sales",
        "Total Food Sales Discount", "Total Commionssions",
        "Commission Count",
      ];
      // Map data to match headers
      const dataMapped = this.filteredOrders.map((item) => ({
        "Order": item.cityOrderId,
        "Name": item.name,
        "Market": item.market,
        "Event Date": this.getDateFromDateTime(item.startDateTime),
        "Date Paid": this.getDateFromDateTime(item.paidDate),
        "Total Food Sales": this.getNumeral(item.totalFoodSales),
        // eslint-disable-next-line max-len
        "Total Food Sales Discount": this.getNumeral(item.totalFoodSalesDiscounts),
        "Total Commionssions": this.calculateTotalCommissions(item.commissions),
        "Commission Count": item.commissions.length,
      }));

      // eslint-disable-next-line max-len
      const worksheet = XLSX.utils.json_to_sheet(dataMapped, {header: headers});
      const workbook = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet1");
      // eslint-disable-next-line max-len
      const excelBuffer = XLSX.write(workbook, {bookType: "xlsx", type: "array"});
      // eslint-disable-next-line max-len
      const blob = new Blob([excelBuffer], {type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8"});
      const url = URL.createObjectURL(blob);
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", "PayrollCommissions_data.xlsx");
      document.body.appendChild(link);
      link.click();
      URL.revokeObjectURL(url);
      document.body.removeChild(link);
    },
  },
  computed: {
    headers() {
      return [
        {text: "Order", value: "cityOrderId"},
        {text: "Name", value: "name"},
        {text: "Market", value: "market"},
        {text: "Event Date", value: "startDateTime"},
        {text: "Date Paid", value: "paidDate"},
        {text: "Total Food Sales", value: "totalFoodSales"},
        {text: "Total Food Sales Discounts", value: "totalFoodSalesDiscounts"},
        {text: "Total Commissions", value: "commissions"},
        {text: "Commission Count", value: "commissionCount"},
        {text: "", value: "data-table-expand"},
      ];
    },
    commissionsHeaders() {
      return [
        {text: "Name", value: "name"},
        {text: "Id", value: "cityEmployeeId"},
        {text: "Amount", value: "amount"},
      ];
    },
    submissionsHistoryHeaders() {
      return [
        {text: "Batch Id", value: "id"},
        {text: "Paycheck Date", value: "timestamp"},
        {text: "Processed Date", value: "processedDate"},
        {text: "Type", value: "type"},
        {text: "Batch Total", value: "amount"},
        {text: "Processed By", value: "processedByName"},
      ];
    },
    ...mapGetters({
      isAuthenticated: "isAuthenticated",
      currentUser: "getCurrentUser",
    }),
  },
};
</script>

<style scoped>
.commissions {
  border-style: solid;
  border-color: #cec3ba;
}
.exportexcel {
  right: 25px;
  top: 10px;
}
</style>

<template>
  <v-card class="no-margin" tile>
    <v-card-text class="pa-0 pb-1">
      <v-alert
        v-for="(action, index) in actions"
        :key="index"
        dense
        text
        dismissible
        transition="scale-transition"
        class="mx-3 my-2 elevation-7"
      >
        <template v-slot:close="{ toggle }">
          <v-btn
            class="grey lighten-1"
            small
            @click="
              toggle;
              toggleHideActionItem(action);
            "
          >
            {{ isHidden ? 'RESTORE' : 'IGNORE' }}
          </v-btn>
        </template>
        <v-row align="center">
          <v-icon class="pr-3" color="accent">{{ action.icon }}</v-icon>
          <v-col class="grow pa-0 black--text" v-html="action.message"></v-col>
          <v-col class="shrink pa-0 pr-2">
            <v-btn
              small
              v-show="action.buttonText"
              @click="actionButtonClick(action)"
              color="accent"
              class="mr-3"
              >{{ action.buttonText }}</v-btn
            >
          </v-col>
        </v-row>
      </v-alert>
    </v-card-text>
  </v-card>
</template>
<script>
import router from "@/router/index.js";
import homeApi from "@/api/homeApi";

export default {
  props: {
    actions: Array,
    isHidden: Boolean,

  },
  methods: {
    actionButtonClick(action) {
      if (action.buttonUrl) {
        window.open(action.buttonUrl, "_blank");
      } else if (action.orderId && action.orderId !== 0) {
        router.push({
          name: action.redirect,
          params: {orderId: action.orderId},
        });
      } else {
        router.push({name: action.redirect});
      }
    },
    toggleHideActionItem(action) {
      action.isHidden = !action.isHidden;
      homeApi.toggleHideActionItem(action.id).then(() => {});
    },
  },
};
</script>
<style scoped>
.no-margin {
  margin: 0 !important;
}
</style>

<template>
  <v-container fluid class="pa-0">
    <v-layout flex align-center justify-center>
      <v-flex md12>
        <v-card tile>
          <v-toolbar dense color="primary">
            <Breadcrumbs />
          </v-toolbar>
          <v-divider></v-divider>
          <v-layout column>
            <v-tabs
              v-model="tabs"
              fixed-tabs
              :background-color="'primary darken-1'"
              hide-slider="true"
              :active-class="'primary black--text'"
            >
              <v-tab href="#tab-1">
                Current
              </v-tab>
              <v-tab href="#tab-2"
              v-on:click="getPastAssignments">
                History
              </v-tab>
              <v-tabs-items v-model="tabs">
                <v-tab-item :value="'tab-1'">
                  <v-card flat hide-details class="ma-2">
                    <v-select :items="locations"
                    v-model="selectedLocation"
                    item-text="description"
                    item-value="id"
                    clearable
                    outlined
                    label="Locations"
                    ></v-select>
                  </v-card>
                  <v-spacer></v-spacer>
                  <Assignments :isAdminAssignments="true"
                  :selectedLocation="selectedLocation" />
                </v-tab-item>
                <v-tab-item :value="'tab-2'">
                  <v-card>
                    <v-data-table
                      :items="historicalAssignments"
                      :headers="assignedHeaders"
                      no-data-text="No employees assigned"
                      v-model="historicalAssignments"
                    >
                      <template #item.employee="{item}">
                        {{ `${item.user.firstName} ${item.user.lastName}` }}
                      </template>
                      <template #item.date="{item}">
                        {{ getShortDate(item.start) }}
                      </template>
                      <template #item.start="{item}">
                        {{ getShortTime(item.start) }}
                      </template>
                      <template #item.end="{item}">
                        {{ getShortTime(item.end) }}
                      </template>
                    </v-data-table>
                  </v-card>
                </v-tab-item>
              </v-tabs-items>
            </v-tabs>
          </v-layout>
        </v-card>
      </v-flex>
    </v-layout>
  </v-container>
</template>

<script>
import Breadcrumbs from "@/components/Breadcrumbs";
import Assignments from "@/components/Assignments";
import assignmentsApi from "@/api/assignmentsApi";
import UserTime from "@/helpers/userTime";
import {mapGetters} from "vuex";

export default {
  name: "AdminAssignments",
  components: {
    Breadcrumbs,
    Assignments,
  },
  data() {
    return {
      tabs: null,
      locations: [],
      allJobCodesList: [],
      selectedLocation: 0,
      isAssigning: false,
      allEmployees: [],
      filteredEmployees: [],
      availableEmployees: [],
      selectedEmployees: [],
      assignedEmployees: [],
      datePicker: false,
      modify: false,
      historicalAssignments: [],
      removeEmployee: [],
      loading: false,
    };
  },
  mounted() {
    assignmentsApi.getLocations().then((response) => {
      this.locations = response.data.allLocationsList;
    });
    if (this.currentUser.cityLocations.length === 1) {
      this.currentUser.cityLocations.forEach((x) => {
        this.selectedLocation = x.marketId;
      });
    }
  },
  methods: {
    getShortDate(date) {
      return UserTime.getShortDate(new Date(date));
    },
    getShortTime(date) {
      return UserTime.getShortTime(date);
    },
    buildHistoricalAssignments(assignments) {
      assignments.forEach((x) => {
        x.location = x.cityLocation.description;
        x.role = x.cityJobCode.description;
      });
      this.historicalAssignments = assignments;
    },
    getPastAssignments() {
      console.log("Getting Past Assighnments");
      assignmentsApi.getPastAdminAssignments().then((response) => {
        this.buildHistoricalAssignments(response.data.assignmentsList);
      });
    },
  },
  computed: {
    assignedHeaders() {
      return [
        {text: "Employee", value: "employee"},
        {text: "Role", value: "role"},
        {text: "Date", value: "date"},
        {text: "Start", value: "start"},
        {text: "End", value: "end"},
        {text: "Location", value: "location"},
      ];
    },
    ...mapGetters({
      currentUser: "getCurrentUser",
    }),
  },
};
</script>
<style>
.v-small-dialog__content {
  padding: 0;
}
</style>

<template>
  <v-layout v-resize="onResize" column>
    <v-data-table
      class="elevation-2 minimum-height"
      :headers="isMobile ? mobileHeaders : headers"
      :items="orderList"
      :search="search"
      sort-by="start"
      sort-desc
      :hide-default-headers="isMobile"
    >
      <template v-slot:body="props">
        <tbody v-if="orderList.length > 0 && !isMobile">
        <!--eslint-disable-next-line max-len-->
          <tr v-for="(item, index) in props.items" :key="index" :class="getColor(item)">
            <td class="text-xs-right">{{item.employeeId}}</td>
            <td class="text-xs-right">{{item.name}}</td>
            <td class="text-xs-right one">
              {{ item.poneNumber }}
            </td>
            <td class="text-xs-right two">
              {{ item.dafaultMrketName }}
            </td>
            <td class="text-xs-right">{{ item.orderId }}</td>
            <!--eslint-disable-next-line max-len-->
            <td class="text-xs-right">{{getDate(item.eventDate, item.eventTime)}}</td>
            <td class="text-xs-right">
              {{ item.scheduleStart }}
            </td>
            <td class="text-xs-right">
              {{ item.scheduleEnd }}
            </td>
            <td class="text-xs-right">
              {{getClockIn(item.clockIn)}}
            </td>
            <td class="text-xs-right">
              {{ getClockOut(item.clockOut) }}
            </td>
            <td class="text-xs-right">
              {{ item.hoursWorked }}
            </td>
          </tr>
        </tbody>
        <tbody v-else-if="orderList.length > 0 && isMobile">
          <!--eslint-disable-next-line max-len-->
          <tr v-for="(item, index) in props.items" :key="index" :class="getColor(item)">
            <td>
              <ul class="flex-content">
                <!--eslint-disable-next-line max-len-->
                <!--eslint-disable-next-line max-len-->
                <li class="flex-item">
                  <div class="item-content">
                    Name:
                  </div>
                  <div>
                    {{item.name}}
                  </div>
                </li>
                <li class="flex-item">
                  <div class="item-content">
                    Phone Number:
                  </div>
                  <div>
                    {{item.poneNumber}}
                  </div>
                </li>
                <li class="flex-item">
                  <div class="item-content">
                    Order Id:
                  </div>
                  <div>
                    {{item.orderId}}
                  </div>
                </li>
                <li class="flex-item">
                  <div class="item-content">
                    Start Date:
                  </div>
                  <div>
                    {{item.scheduleStart}}
                  </div>
                </li>
              </ul>
            </td>
          </tr>
        </tbody>
        <tbody v-else>
          <tr class="v-data-table__empty-wrapper">
            <td colspan="10">
              No orders found
            </td>
          </tr>
        </tbody>
      </template>
    </v-data-table>
  </v-layout>
</template>

<script>
import UserTime from "@/helpers/userTime";
import moment from "moment";
import {iconMixin} from "@/mixins/iconMixin.js";

export default {
  mixins: [iconMixin],
  name: "ClockAuditGrid",
  props: {
    orderList: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      isMobile: false,
      dateTimeMaxValue: "9999-12-31T23:59:59.9999999",
    };
  },
  computed: {
    headers() {
      return [
        {text: "Employee Number", value: "employeeId", width: "100px"},
        {text: "Employee Name", value: "name", width: "150px"},
        {text: "Employee Phone", value: "poneNumber", width: "150px"},
        {text: "Markets", value: "dafaultMrketName", width: "100px"},
        {text: "Order No.", value: "orderId", width: "120px"},
        {text: "Event Time", value: "eventDate", width: "120px"},
        {text: "Scheduled Start", value: "scheduleStart", width: "100px"},
        {text: "Scheduled End", value: "scheduleEnd", width: "100px"},
        {text: "Clock In", value: "clockIn", width: "100px"},
        {text: "Clock Out", value: "clockOut", width: "100px"},
        {text: "Hours Worked", value: "hoursWorked", width: "100px"},
      ];
    },
    mobileHeaders() {
      return [
        {text: "Employee Number", value: "employeeId"},
        {text: "Employee Name", value: "name"},
        {text: "Employee Phone", value: "poneNumber"},
        {text: "Markets", value: "dafaultMrketName"},
        {text: "Order No.", value: "orderId"},
        {text: "Event Time", value: "eventDate"},
        {text: "Scheduled Start", value: "scheduleStart"},
        {text: "Scheduled End", value: "scheduleEnd"},
        {text: "Clock In", value: "clockIn"},
        {text: "Clock Out", value: "clockOut"},
        {text: "Hours Worked", value: "hoursWorked"},
      ];
    },
  },
  methods: {
    getColor(item) {
      let currentTime = moment().format("MM/DD/YYYY h:mm A");
      currentTime = Math.floor(new Date(currentTime).getTime() / 1000);
      // eslint-disable-next-line max-len
      if ((currentTime > Math.floor(new Date(item.scheduleStart).getTime() / 1000)) && this.getClockIn(item.clockIn) === "") {
        return "red-bg";
        // eslint-disable-next-line max-len
      } else if ((currentTime > Math.floor(new Date(item.scheduleStart).getTime() / 1000)) && this.getClockIn(item.clockIn) != null) {
        return "green-bg";
      } else {
        return "default-bg";
      }
    },
    getClockIn(clockInDate) {
      if (clockInDate !== "") {
        return moment.utc(clockInDate).local().format("MM/DD/YYYY h:mm A");
      } else {
        return "";
      }
    },
    getClockOut(clockOutDate) {
      if (clockOutDate !== "") {
        return moment.utc(clockOutDate).local().format("MM/DD/YYYY h:mm A");
      } else {
        return "";
      }
    },
    getDate(date, time) {
      let eventDate = date;
      eventDate = eventDate.split(" ")[0];
      // eslint-disable-next-line max-len
      const num2time = (num) => num.toString().replace(/(\d{1,2})(\d{2})$/, "$1:$2");
      let eventTime = num2time(time);
      eventTime = moment(eventTime, ["HH.mm"]).format("hh:mm A");
      return eventDate + " " + eventTime;
    },
    onResize() {
      if (window.innerWidth < 769) {
        this.isMobile = true;
      } else {
        this.isMobile = false;
      }
    },
    getAssignedCount(order) {
      if (order.orderUsers) {
        return order.orderUsers.length;
      }
      return 0;
    },
    getAssignedUsers(order) {
      let ret = "";
      if (order.orderUsers && order.orderUsers.length) {
        order.orderUsers.forEach((ou) => {
          if (ou.user) {
            ret += `${ou.user.firstName} ${ou.user.lastName}\r\n`;
          }
        });
      }
      return ret;
    },
    getStartDateTime(order) {
      if (this.isMobile) {
        return order.startDateTime ?
          `${UserTime.getShortDateTime(order.startDateTime)} ${
            order.startDateTimeZone
          }` :
          "";
      } else {
        return order.startDateTime ?
          `${UserTime.getLongDateTime(order.startDateTime)} ${
            order.startDateTimeZone
          }` :
          "";
      }
    },
    getEndDateTime(order) {
      return order.startDateTime === order.expectedEnd ||
        !order.expectedEnd ||
        order.expectedEnd === this.dateTimeMaxValue ?
        UserTime.NoTimeSet :
        `${UserTime.getLongTime(order.expectedEnd)} ${
          order.startDateTimeZone
        }`;
    },
  },
  watch: {
    orderList: {
      handler: function(val, oldVal) {
        this.orderList.forEach((o) => {
          o.assignedCount = this.getAssignedCount(o);
        });
      },
    },
  },
};
</script>

<style scoped>
@media screen and (max-width: 768px) {
  .mobile table tbody tr {
    max-width: 100%;
    position: relative;
    display: block;
  }
  .mobile table tbody tr td {
    display: flex;
    width: 100%;
    border-bottom: 1px solid #f5f5f5;
    height: auto;
    padding: 10px;
  }
  .mobile table tbody tr td ul li:before {
    content: attr(data-label);
    padding-right: 0.5em;
    text-align: left;
    display: block;
    color: #999;
  }
  .v-datatable__actions__select {
    width: 50%;
    margin: 0px;
    justify-content: flex-start;
  }
  .mobile .theme--light tbody tr:hover:not(.v-datatable__expand-row) {
    background: transparent;
  }
}
.flex-content {
  padding: 0;
  margin: 0;
  list-style: none;
  display: flex;
  flex-wrap: wrap;
  width: 100%;
}
.flex-item {
  padding: 5px;
  width: 50%;
  font-weight: bold;
}
.mdi-chevron-double-right {
  vertical-align: middle;
}
.minimum-height {
  min-height: 400px;
  z-index: 1;
}
.vertical-align-middle {
  vertical-align: middle;
}
.red-bg {
    background: #ffc1cc;
}
.green-bg {
    background: #a5f1a3;
}
.flex-content {
  padding: 0;
  margin: 0;
  list-style: none;
  display: flex;
  flex-wrap: wrap;
  width: 100%;
}
.flex-item {
  padding: 5px;
  width: 50%;
  height: 50px;
  font-weight: bold;
}
.item-content{
  color: #4d4d4d;
}
.theme--light.v-data-table tbody
tr.green-bg:hover:not(.v-data-table__expanded__content)
:not(.v-data-table__empty-wrapper){
  background: #a5f1a3 !important;
}
.theme--light.v-data-table tbody
tr.red-bg:hover:not(.v-data-table__expanded__content)
:not(.v-data-table__empty-wrapper){
  background: #ffc1cc !important;
}
</style>

var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-container",
    { staticClass: "pa-0", attrs: { fluid: "" } },
    [
      _c(
        "v-layout",
        { attrs: { flex: "", "align-center": "", "justify-center": "" } },
        [
          _c(
            "v-flex",
            { attrs: { md12: "" } },
            [
              _c(
                "v-card",
                { attrs: { tile: "" } },
                [
                  _c(
                    "v-toolbar",
                    { attrs: { dense: "", color: "primary" } },
                    [_c("Breadcrumbs")],
                    1
                  ),
                  _c("v-divider"),
                  _c(
                    "v-tabs",
                    {
                      attrs: {
                        "fixed-tabs": "",
                        "background-color": "primary darken-1",
                        "hide-slider": "true",
                        "active-class": "primary black--text"
                      },
                      model: {
                        value: _vm.tab,
                        callback: function($$v) {
                          _vm.tab = $$v
                        },
                        expression: "tab"
                      }
                    },
                    [
                      _c(
                        "v-tab",
                        { attrs: { href: "#tab-1" } },
                        [
                          _vm._v(" Timeclock "),
                          _c("v-icon", { attrs: { left: "" } }, [
                            _vm._v("mdi-format-list-bulleted")
                          ])
                        ],
                        1
                      ),
                      _c(
                        "v-tab",
                        { attrs: { href: "#tab-2" } },
                        [
                          _vm._v(" Log "),
                          _c("v-icon", { attrs: { left: "" } }, [
                            _vm._v("mdi-calendar-month")
                          ])
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "v-tabs-items",
                    {
                      model: {
                        value: _vm.tab,
                        callback: function($$v) {
                          _vm.tab = $$v
                        },
                        expression: "tab"
                      }
                    },
                    [
                      _c(
                        "v-tab-item",
                        { attrs: { value: "tab-1" } },
                        [
                          _c(
                            "v-card",
                            { attrs: { flat: "", tile: "" } },
                            [
                              _c(
                                "v-row",
                                [
                                  _c("v-spacer"),
                                  _c(
                                    "span",
                                    {
                                      staticClass: "font-weight-light pa-3",
                                      class: {
                                        "display-3": this.$vuetify.breakpoint
                                          .mdAndUp,
                                        "display-1": this.$vuetify.breakpoint
                                          .smAndDown
                                      },
                                      attrs: {
                                        align: "center",
                                        justify: "center"
                                      }
                                    },
                                    [
                                      _vm._v(
                                        " " +
                                          _vm._s(_vm.currentDateTimeString) +
                                          " "
                                      )
                                    ]
                                  ),
                                  _c("v-spacer")
                                ],
                                1
                              ),
                              !_vm.todayAssignments || _vm.todayAssignments < 1
                                ? _c(
                                    "v-card",
                                    [
                                      _c(
                                        "v-card-text",
                                        {
                                          attrs: {
                                            align: "middle",
                                            justify: "center"
                                          }
                                        },
                                        [_vm._v(" No events scheduled today. ")]
                                      )
                                    ],
                                    1
                                  )
                                : _vm._e(),
                              _c(
                                "vue-custom-scrollbar",
                                { attrs: { supressScrollX: "" } },
                                [
                                  _c(
                                    "v-layout",
                                    {
                                      staticClass: "shifts",
                                      attrs: { column: "" }
                                    },
                                    _vm._l(_vm.todayAssignments, function(
                                      assignment,
                                      index
                                    ) {
                                      return _c(
                                        "v-card",
                                        {
                                          key: index,
                                          staticClass: "pa-2",
                                          attrs: { tile: "" }
                                        },
                                        [
                                          _c(
                                            "v-row",
                                            {
                                              attrs: {
                                                dense: "",
                                                align: "center",
                                                justify: "center"
                                              }
                                            },
                                            [
                                              _c(
                                                "v-col",
                                                {
                                                  attrs: {
                                                    align: "center",
                                                    justify: "center"
                                                  }
                                                },
                                                [
                                                  _c(
                                                    "v-card",
                                                    {
                                                      staticClass:
                                                        "pt-2 px-4 elevation-3",
                                                      attrs: {
                                                        tile: "",
                                                        width: "360"
                                                      }
                                                    },
                                                    [
                                                      _vm._v(
                                                        " " +
                                                          _vm._s(
                                                            assignment.order
                                                              .cityOrderId
                                                          ) +
                                                          " "
                                                      ),
                                                      _c(
                                                        "v-row",
                                                        { staticClass: "px-2" },
                                                        [
                                                          _c("strong", [
                                                            _vm._v(
                                                              _vm._s(
                                                                assignment.order
                                                                  .name
                                                              )
                                                            )
                                                          ])
                                                        ]
                                                      ),
                                                      _c(
                                                        "v-row",
                                                        { staticClass: "px-2" },
                                                        [
                                                          _c("span", [
                                                            _vm._v(
                                                              _vm._s(
                                                                assignment
                                                                  .cityJobCode
                                                                  .description
                                                              )
                                                            )
                                                          ])
                                                        ]
                                                      ),
                                                      _c("v-divider", {
                                                        staticClass: "mt-1"
                                                      }),
                                                      _c(
                                                        "v-row",
                                                        { staticClass: "px-2" },
                                                        [
                                                          _c(
                                                            "a",
                                                            {
                                                              attrs: {
                                                                target: "_blank"
                                                              },
                                                              on: {
                                                                click: function(
                                                                  $event
                                                                ) {
                                                                  return _vm.cateringChipPrint(
                                                                    assignment.order,
                                                                    assignment
                                                                      .order
                                                                      .cityOrderId,
                                                                    _vm.prepChitProcessId
                                                                  )
                                                                }
                                                              }
                                                            },
                                                            [
                                                              _c("strong", [
                                                                _vm._v(
                                                                  "MM Prep Catering Chit Print"
                                                                )
                                                              ]),
                                                              _c("v-icon", [
                                                                _vm._v(
                                                                  "mdi-open-in-new"
                                                                )
                                                              ])
                                                            ],
                                                            1
                                                          )
                                                        ]
                                                      ),
                                                      _c(
                                                        "v-row",
                                                        { staticClass: "px-2" },
                                                        [
                                                          _c(
                                                            "a",
                                                            {
                                                              attrs: {
                                                                target: "_blank"
                                                              },
                                                              on: {
                                                                click: function(
                                                                  $event
                                                                ) {
                                                                  return _vm.cateringChipPrint(
                                                                    assignment.order,
                                                                    assignment
                                                                      .order
                                                                      .cityOrderId,
                                                                    _vm.assemblyProcesId
                                                                  )
                                                                }
                                                              }
                                                            },
                                                            [
                                                              _c("strong", [
                                                                _vm._v(
                                                                  " MM Assembly Catering Chit Print "
                                                                )
                                                              ]),
                                                              _c("v-icon", [
                                                                _vm._v(
                                                                  "mdi-open-in-new"
                                                                )
                                                              ])
                                                            ],
                                                            1
                                                          )
                                                        ]
                                                      ),
                                                      _c("v-divider", {
                                                        staticClass: "mt-1"
                                                      }),
                                                      _c("v-simple-table", {
                                                        staticClass:
                                                          "text-md-center pt-1",
                                                        attrs: { dense: "" },
                                                        scopedSlots: _vm._u(
                                                          [
                                                            {
                                                              key: "default",
                                                              fn: function() {
                                                                return [
                                                                  _c(
                                                                    "tbody",
                                                                    [
                                                                      _c("tr", [
                                                                        _c(
                                                                          "td",
                                                                          {
                                                                            attrs: {
                                                                              align:
                                                                                "center",
                                                                              justify:
                                                                                "center"
                                                                            }
                                                                          },
                                                                          [
                                                                            _c(
                                                                              "strong",
                                                                              [
                                                                                _vm._v(
                                                                                  "START"
                                                                                )
                                                                              ]
                                                                            )
                                                                          ]
                                                                        ),
                                                                        _c(
                                                                          "td",
                                                                          [
                                                                            _c(
                                                                              "strong",
                                                                              [
                                                                                _vm._v(
                                                                                  " " +
                                                                                    _vm._s(
                                                                                      _vm.getLongDateTime(
                                                                                        assignment.start
                                                                                      )
                                                                                    )
                                                                                )
                                                                              ]
                                                                            )
                                                                          ]
                                                                        )
                                                                      ]),
                                                                      _vm._l(
                                                                        _vm.getClockEvents(
                                                                          assignment.id
                                                                        ),
                                                                        function(
                                                                          clockEvent
                                                                        ) {
                                                                          return [
                                                                            clockEvent.clockIn
                                                                              ? _c(
                                                                                  "tr",
                                                                                  {
                                                                                    key:
                                                                                      clockEvent.id +
                                                                                      "IN"
                                                                                  },
                                                                                  [
                                                                                    _c(
                                                                                      "td",
                                                                                      [
                                                                                        _vm._v(
                                                                                          "IN"
                                                                                        )
                                                                                      ]
                                                                                    ),
                                                                                    _c(
                                                                                      "td",
                                                                                      [
                                                                                        _vm._v(
                                                                                          " " +
                                                                                            _vm._s(
                                                                                              _vm.getUtcLongDateTime(
                                                                                                clockEvent.clockIn
                                                                                              )
                                                                                            ) +
                                                                                            " "
                                                                                        )
                                                                                      ]
                                                                                    )
                                                                                  ]
                                                                                )
                                                                              : _vm._e(),
                                                                            clockEvent.clockOut
                                                                              ? _c(
                                                                                  "tr",
                                                                                  {
                                                                                    key:
                                                                                      clockEvent.id +
                                                                                      "OUT"
                                                                                  },
                                                                                  [
                                                                                    _c(
                                                                                      "td",
                                                                                      [
                                                                                        _vm._v(
                                                                                          "OUT"
                                                                                        )
                                                                                      ]
                                                                                    ),
                                                                                    _c(
                                                                                      "td",
                                                                                      [
                                                                                        _vm._v(
                                                                                          " " +
                                                                                            _vm._s(
                                                                                              clockEvent.clockOut
                                                                                                ? _vm.getUtcLongDateTime(
                                                                                                    clockEvent.clockOut
                                                                                                  )
                                                                                                : ""
                                                                                            ) +
                                                                                            " "
                                                                                        )
                                                                                      ]
                                                                                    )
                                                                                  ]
                                                                                )
                                                                              : _vm._e()
                                                                          ]
                                                                        }
                                                                      )
                                                                    ],
                                                                    2
                                                                  )
                                                                ]
                                                              },
                                                              proxy: true
                                                            }
                                                          ],
                                                          null,
                                                          true
                                                        )
                                                      })
                                                    ],
                                                    1
                                                  )
                                                ],
                                                1
                                              ),
                                              _c(
                                                "v-col",
                                                {
                                                  attrs: {
                                                    align: "center",
                                                    justify: "center"
                                                  }
                                                },
                                                [
                                                  _c(
                                                    "v-btn",
                                                    {
                                                      staticClass: "ma-1",
                                                      attrs: {
                                                        "x-large": "",
                                                        color: "accent",
                                                        disabled: _vm.isButtonDisabled(
                                                          assignment,
                                                          true
                                                        )
                                                      },
                                                      on: {
                                                        click: function(
                                                          $event
                                                        ) {
                                                          return _vm.submitClockEvent(
                                                            assignment
                                                          )
                                                        }
                                                      }
                                                    },
                                                    [_vm._v("Clock In")]
                                                  ),
                                                  _c(
                                                    "p",
                                                    {
                                                      directives: [
                                                        {
                                                          name: "show",
                                                          rawName: "v-show",
                                                          value: _vm.isButtonDisabled(
                                                            assignment,
                                                            false
                                                          ),
                                                          expression:
                                                            "isButtonDisabled(assignment, false)"
                                                        }
                                                      ]
                                                    },
                                                    [
                                                      _vm._v(
                                                        " " +
                                                          _vm._s(
                                                            _vm.getDisabledReason(
                                                              assignment,
                                                              false
                                                            )
                                                          ) +
                                                          " "
                                                      )
                                                    ]
                                                  ),
                                                  _c(
                                                    "v-btn",
                                                    {
                                                      staticClass: "ma-1",
                                                      attrs: {
                                                        "x-large": "",
                                                        color: "accent",
                                                        disabled: _vm.isButtonDisabled(
                                                          assignment,
                                                          false
                                                        )
                                                      },
                                                      on: {
                                                        click: function(
                                                          $event
                                                        ) {
                                                          return _vm.submitClockEvent(
                                                            assignment
                                                          )
                                                        }
                                                      }
                                                    },
                                                    [_vm._v("Clock Out")]
                                                  )
                                                ],
                                                1
                                              ),
                                              _c(
                                                "v-btn",
                                                {
                                                  staticClass: "white--text",
                                                  attrs: {
                                                    color: "accent",
                                                    title: "Image or PDF",
                                                    "x-large": ""
                                                  },
                                                  on: {
                                                    click: function($event) {
                                                      return _vm.openModal(
                                                        assignment.order
                                                          .cityOrderId
                                                      )
                                                    }
                                                  }
                                                },
                                                [_vm._v(" Add Image ")]
                                              )
                                            ],
                                            1
                                          )
                                        ],
                                        1
                                      )
                                    }),
                                    1
                                  )
                                ],
                                1
                              )
                            ],
                            1
                          )
                        ],
                        1
                      ),
                      _c(
                        "v-tab-item",
                        { attrs: { value: "tab-2" } },
                        [
                          _c(
                            "v-card",
                            { attrs: { flat: "" } },
                            [
                              _c(
                                "v-card-text",
                                { staticClass: "pa-0" },
                                [
                                  _c("ClockEventsGrid", {
                                    attrs: {
                                      clockEventsList: _vm.pastClockEvents
                                    }
                                  })
                                ],
                                1
                              )
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _c("AddImage", {
        attrs: { visible: _vm.visible, orderId: _vm.cityOrderId },
        on: {
          close: function($event) {
            _vm.visible = false
          }
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
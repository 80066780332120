import Vue from "vue";

export default {
  getAssignments(cityOrderId) {
    return Vue.axios.get(
        `${process.env.VUE_APP_API_BASE}/Assignments/${cityOrderId}`,
    );
  },
  getAdminAssignments() {
    return Vue.axios.get(
        `${process.env.VUE_APP_API_BASE}/Assignments/GetAdminAssignments`,
    );
  },
  putAssignments(cityOrderId, assignments) {
    return Vue.axios.put(
        `${process.env.VUE_APP_API_BASE}/Assignments/${cityOrderId}`,
        assignments,
    );
  },
  getUserAssignments() {
    return Vue.axios.get(
        `${process.env.VUE_APP_API_BASE}/Assignments/GetUserAssignments`,
    );
  },
  getUserAssignmentsByUserId(UserId, date) {
    date = date.split("T");
    date = `${date[0]}T00:00:00`;
    return Vue.axios.get(
        // eslint-disable-next-line max-len
        `${process.env.VUE_APP_API_BASE}/Assignments/GetUserAssignmentsByUserId?userId=${UserId}&date=${date}`,
    );
  },
  putAdminAssignments(assignments) {
    return Vue.axios.put(
        // eslint-disable-next-line max-len
        `${process.env.VUE_APP_API_BASE}/Assignments/UpdateAdminAssignments`,
        assignments,
    );
  },
  removeAdminAssignment(orderUserId) {
    return Vue.axios.post(
        // eslint-disable-next-line max-len
        `${process.env.VUE_APP_API_BASE}/Assignments/DeleteAdminAssignment/${orderUserId}`,
    );
  },
  getPastAdminAssignments() {
    return Vue.axios.get(
        `${process.env.VUE_APP_API_BASE}/Assignments/GetPastAdminAssignments`,
    );
  },
  getLocations() {
    return Vue.axios.get(
        `${process.env.VUE_APP_API_BASE}/Assignments/GetLocations`,
    );
  },
  getScheduledEmployees(data) {
    return Vue.axios.post(
        // eslint-disable-next-line max-len
        `${process.env.VUE_APP_API_BASE}/Assignments/GetScheduledEmployees`, data);
  },
  uploadFiles(data) {
    const fileData = [...data.file];
    const fData = new FormData();
    for ( let i = 0; i < fileData.length; i++) {
      const file = fileData[i];
      fData.append("file", file);
    }
    fData.append("OrderId", data.OrderId);
    fData.append("Title", data.Title);
    fData.append("Description", data.Description);
    return Vue.axios.post(
        // eslint-disable-next-line max-len
        `${process.env.VUE_APP_API_BASE}/Assignments/UploadFilesToBlob`, fData, {headers: {"Content-Type": "multipart/form-data"}});
  },
  getFiles(orderId) {
    return Vue.axios.get(
        // eslint-disable-next-line max-len
        `${process.env.VUE_APP_API_BASE}/Assignments/DownloadFilesToBlob?orderId=${orderId}`,
    );
  },
  deleteFiles(orderId, imgName) {
    console.log(orderId, ":::", imgName);
    return Vue.axios.post(
        // eslint-disable-next-line max-len
        `${process.env.VUE_APP_API_BASE}/Assignments/DeleteFilesFromBlob?orderId=${orderId}&FileName=${imgName}`,
    );
  },
};

<template>
  <v-layout column>
    <v-data-table
      class="elevation-2 minimum-height"
      :headers="headers"
      hide-default-header
      :items="clockEventsList"
      group-by="groupHeaderKey"
      :sort-by="clockIn"
      dense
      ref="expandableTable"
    >
      <template v-slot:no-data>
        No punches to view.
      </template>
      <template v-slot:group.header="{ group, toggle, isOpen }">
        <v-btn @click="toggle()" icon>
          <v-icon
            >mdi-{{
              isOpen
                ? "arrow-up-drop-circle-outline"
                : "arrow-down-drop-circle-outline"
            }}</v-icon
          >
        </v-btn>
        <span
          ><strong>{{ getGroupHeader(group) }}</strong></span
        >
      </template>
      <template v-slot:group.summary="{ items }">
        <span class="pl-5">{{ getGroupSummary(items) }}</span>
      </template>
      <template v-slot:item.clockEvent="{ item }">
        <div>
          <span class="block-50">IN:</span>
          <span>{{ getimeString(item.clockIn) }}</span>
        </div>
        <div>
          <span class="block-50">OUT:</span>
          <span>{{ getimeString(item.clockOut) }}</span>
        </div>
      </template>
    </v-data-table>
  </v-layout>
</template>

<script>
import UserTime from "@/helpers/userTime";

export default {
  name: "ClockEventsGrid",
  props: {
    clockEventsList: Array,
  },
  methods: {
    getimeString(timestamp) {
      const t = UserTime.getUtcLongTime(timestamp);
      return t;
    },
    getGroupSummary(items) {
      // Calculate time worked.
      if (items.length) {
        let total = 0;
        items.forEach((item) => {
          if (item.clockOut) {
            if (item.clockOut[item.clockOut.length - 1] === "Z") {
              item.clockOut = item.clockOut.slice(0, item.clockOut.length - 2);
            }

            total += new Date(item.clockOut) - new Date(item.clockIn);
          }
        });
        return this.parseMS(total);
      }
    },
    parseMS(ms) {
      let seconds = ms / 1000;
      const hours = parseInt(seconds / 3600);
      seconds = seconds % 3600;
      const minutes = parseInt(seconds / 60);
      seconds = parseInt(seconds % 60);
      return `${hours} hours, ${minutes} minutes, ${seconds} seconds`;
    },
    getGroupHeader(group) {
      const order = this.clockEventsList.filter((ce) => {
        return ce.groupHeaderKey === group;
      })[0];
      return `${UserTime.getLongDateTime(order.startDateTime)} -- ${
        order.groupHeaderText
      }`;
    },
    collapseAll() {
      for (const name of Object.keys(this.$refs.expandableTable.openCache)) {
        this.$refs.expandableTable.openCache[name] = false;
      }
    },
  },
  mounted() {
    this.collapseAll();
  },
  computed: {
    headers() {
      return [{text: "", value: "clockEvent", sortable: false}];
    },
  },
};
</script>
<style scoped>
.minimum-height {
  min-height: 400px;
}
.block-50 {
  display: inline-block;
  width: 50px;
}
</style>

<template>
  <v-container fluid class="pa-0">
    <v-layout flex align-center justify-center>
      <v-flex md12>
        <v-card tile>
          <v-toolbar dense dark color="primary">
            <Breadcrumbs :customLastCrumb="'User Profile'" />
          </v-toolbar>
          <v-divider></v-divider>
        </v-card>
      </v-flex>
    </v-layout>
    <v-card>
      <UserDetails :user-data="userData" visible=true>
      </UserDetails>
    </v-card>
  </v-container>
</template>
<script>
import Breadcrumbs from "@/components/Breadcrumbs";
import UserDetails from "@/components/UserDetails";
import userApi from "@/api/userApi";
export default {
  name: "ViewProfile",
  components: {
    Breadcrumbs,
    UserDetails,
  },
  data() {
    return {
      userData: {},
    };
  },
  mounted() {
    const email = JSON.parse(localStorage.getItem("current-user")).userName;
    this.getUserProfileDetails(email);
  },
  methods: {
    async getUserProfileDetails(email) {
      await userApi.getProfileDetails(email).then((response) => {
        if (response.data) {
          this.userData = response.data;
        }
      });
    },
  },
  computed: {},
};
</script>

<style scoped>
ul {
  list-style-type: square;
}

.profile-parent-div li {
  margin: 2px 0;
}

.profile-parent-div {
  background-color: #ffffff;
  height: 80vh;
}

.profile-parent-div div {
  font-size: 16px;
  color: #000000DE
}
</style>

var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-card",
    { staticClass: "no-margin", attrs: { tile: "" } },
    [
      _c(
        "v-card-text",
        { staticClass: "pa-0 pb-1" },
        _vm._l(_vm.actions, function(action, index) {
          return _c(
            "v-alert",
            {
              key: index,
              staticClass: "mx-3 my-2 elevation-7",
              attrs: {
                dense: "",
                text: "",
                dismissible: "",
                transition: "scale-transition"
              },
              scopedSlots: _vm._u(
                [
                  {
                    key: "close",
                    fn: function(ref) {
                      var toggle = ref.toggle
                      return [
                        _c(
                          "v-btn",
                          {
                            staticClass: "grey lighten-1",
                            attrs: { small: "" },
                            on: {
                              click: function($event) {
                                toggle
                                _vm.toggleHideActionItem(action)
                              }
                            }
                          },
                          [
                            _vm._v(
                              " " +
                                _vm._s(_vm.isHidden ? "RESTORE" : "IGNORE") +
                                " "
                            )
                          ]
                        )
                      ]
                    }
                  }
                ],
                null,
                true
              )
            },
            [
              _c(
                "v-row",
                { attrs: { align: "center" } },
                [
                  _c(
                    "v-icon",
                    { staticClass: "pr-3", attrs: { color: "accent" } },
                    [_vm._v(_vm._s(action.icon))]
                  ),
                  _c("v-col", {
                    staticClass: "grow pa-0 black--text",
                    domProps: { innerHTML: _vm._s(action.message) }
                  }),
                  _c(
                    "v-col",
                    { staticClass: "shrink pa-0 pr-2" },
                    [
                      _c(
                        "v-btn",
                        {
                          directives: [
                            {
                              name: "show",
                              rawName: "v-show",
                              value: action.buttonText,
                              expression: "action.buttonText"
                            }
                          ],
                          staticClass: "mr-3",
                          attrs: { small: "", color: "accent" },
                          on: {
                            click: function($event) {
                              return _vm.actionButtonClick(action)
                            }
                          }
                        },
                        [_vm._v(_vm._s(action.buttonText))]
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        }),
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
<template>
  <v-layout v-resize="onResize" column>
    <v-data-table
      class="elevation-2 minimum-height"
      :headers="isMobile ? mobileHeaders : headers"
      :items="localAssignments"
      :search="search"
      :sort-by="sortBy"
      :sort-desc="sortOrder"
      :hide-default-header="isMobile"
      :class="{ mobile: isMobile }"
      :items-per-page="parseInt(itemsPerPage)"
      @update:items-per-page="handlePageChange($event)"
    >
    <template v-slot:header.name>
      <button @click="filterClick('name')">Name</button>
    </template>
    <template v-slot:header.date>
      <button @click="filterClick('date')">Date</button>
    </template>
    <template v-slot:header.start>
      <button @click="filterClick('start')">Start</button>
    </template>
    <template v-slot:header.end>
      <button @click="filterClick('end')">End</button>
    </template>
    <template v-slot:header.orderClass>
      <button @click="filterClick('orderClass')">Type</button>
    </template>
      <template v-slot:body="props">
        <tbody v-if="!localAssignments || localAssignments.length < 1">
          <tr class="v-data-table__empty-wrapper">
            <td colspan="6">
              {{ isSchedule ? "No shifts scheduled." : "No orders found." }}
            </td>
          </tr>
        </tbody>
        <tbody v-else-if="!isMobile">
          <tr v-for="(item, index) in props.items" :key="index">
            <td class="text-xs-right">
              {{ getEventName(item) }}
            </td>
            <td class="text-xs-right">
              {{ getShortDate(item.start) }}
            </td>
            <td class="text-xs-right">
              <span class="no-wrap">{{
                `${getShortTime(item.start)} ${item.order.startDateTimeZone ||
                  item.cityLocation.timezone}`
              }}</span>
            </td>
            <td class="text-xs-right">
              <span class="no-wrap">{{
                `${getShortTime(item.end)}  ${item.order.startDateTimeZone ||
                  item.cityLocation.timezone}`
              }}</span>
            </td>
            <td class="text-xs-right">
              {{ item.order.orderClass || "Admin" }}
            </td>
            <td class="text-xs-right">{{ item.order.status || "admin" }}</td>
            <td class="text-xs-right">
              {{ item.order.market || item.cityLocation.description }}
            </td>
            <td>
              <v-icon class="accent--text" @click="selectRow(item)"
                v-show="item.cityOrderId > 0">
                mdi-chevron-right mdi-36px
              </v-icon>
            </td>
          </tr>
        </tbody>
        <tbody v-else-if="isMobile">
          <tr v-for="(item, index) in props.items" :key="index">
            <td class="pa-1">
              <ul class="flex-content">
                <li class="flex-item pa-0" data-label="Name">
                  {{ getEventName(item) }}
                </li>
                <li class="flex-item pa-0" data-label="Job">
                  {{ item.cityJobCode.description }}
                </li>
                <li class="flex-item pa-0" data-label="Start">
                  {{
                    `${getShortTime(item.start)}  ${item.order
                      .startDateTimeZone || item.cityLocation.timezone}`
                  }}
                </li>
                <li class="flex-item pa-0" data-label="End">
                  {{
                    `${getShortTime(item.end)}  ${item.order
                      .startDateTimeZone || item.cityLocation.timezone}`
                  }}
                </li>
                <!-- Use this to keep the button in place. -->
                <li class="flex-item pa-0"></li>
                <li class="flex-item pa-0 text-end">
                  <v-icon class="accent--text" @click="selectRow(item.order)"
                  v-show="item.cityOrderId > 0">
                    mdi-chevron-right mdi-36px
                  </v-icon>
                </li>
              </ul>
            </td>
          </tr>
        </tbody>
      </template>
    </v-data-table>
  </v-layout>
</template>

<script>
import UserTime from "@/helpers/userTime";
import router from "@/router/index.js";

export default {
  name: "AssignmentsGrid",
  props: {
    assignmentsList: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      isMobile: false,
      localAssignments: this.assignmentsList,
      sortOrder: false,
      sortBy: "date",
      itemsPerPage: 10,
    };
  },
  watch: {
    assignmentsList: {
      // Handle inital load.
      handler: function(val, oldVal) {
        this.localAssignments = val;
      },
    },
  },
  mounted() {
    if (this.$cookie.get("sortBy")) {
      this.sortBy = this.$cookie.get("sortBy");
      this.sortOrder = this.$cookie.get("sortOrder");
      this.sortOrder = JSON.parse(this.sortOrder);
    }
    if (this.$cookie.get("perPageItems")) {
      this.itemsPerPage = this.$cookie.get("perPageItems");
    }
  },
  computed: {
    headers() {
      return [
        {text: "Name", value: "name"},
        {text: "Date", value: "date"},
        {text: "Start", value: "start"},
        {text: "End", value: "end"},
        {text: "Type", value: "orderClass"},
        {text: "", value: "actions", sortable: false},
      ];
    },
    mobileHeaders() {
      return [
        {text: "Name", value: "name"},
        {text: "Start", value: "startDateTime"},
      ];
    },
  },
  methods: {
    onResize() {
      if (window.innerWidth < 769) {
        this.isMobile = true;
      } else {
        this.isMobile = false;
      }
    },
    handlePageChange(event) {
      this.$cookie.set("perPageItems", event);
    },
    filterClick(clickedHeader) {
      // eslint-disable-next-line max-len
      const sortBy = this.$cookie.get("sortBy");
      if (clickedHeader === sortBy) {
        this.$cookie.set("sortOrder", !this.sortOrder);
      } else {
        this.$cookie.set("sortOrder", false);
      }
      this.$cookie.set("sortBy", clickedHeader);
    },
    getShortDate(timestamp) {
      return UserTime.getShortDate(timestamp);
    },
    getShortTime(timestamp) {
      return UserTime.getShortTime(timestamp);
    },
    getEventName(assn) {
      if (assn.order.cityOrderId === 0) {
        // Admin assignment
        return `ADMIN - ${assn.cityLocation.description}`;
      } else {
        return assn.order.name;
      }
    },
    selectRow(order) {
      router.push({
        name: "ScheduleDetails",
        params: {orderId: order.cityOrderId},
      });
    },
  },
};
</script>
<style scoped>
.no-wrap {
  white-space: nowrap;
}
@media screen and (max-width: 768px) {
  .mobile table tbody tr {
    max-width: 100%;
    position: relative;
    display: block;
  }
  .mobile table tbody tr td {
    display: flex;
    width: 100%;
    border-bottom: 1px solid #f5f5f5;
    height: auto;
    padding: 10px;
  }
  .mobile table tbody tr td ul li:before {
    content: attr(data-label);
    padding-right: 0.5em;
    text-align: left;
    display: block;
    color: #999;
  }
  .v-datatable__actions__select {
    width: 50%;
    margin: 0px;
    justify-content: flex-start;
  }
  .mobile .theme--light tbody tr:hover:not(.v-datatable__expand-row) {
    background: transparent;
  }
}
.flex-content {
  padding: 0;
  margin: 0;
  list-style: none;
  display: flex;
  flex-wrap: wrap;
  width: 100%;
}
.flex-item {
  padding: 5px;
  width: 50%;
  font-weight: bold;
}
.mdi-chevron-double-right {
  vertical-align: middle;
}
.minimum-height {
  min-height: 400px;
}
.vertical-align-middle {
  vertical-align: middle;
}
</style>

var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-layout",
    { attrs: { column: "" } },
    [
      _c("v-data-table", {
        attrs: {
          headers: _vm.headers,
          items: _vm.allUsersList,
          search: _vm.search
        },
        scopedSlots: _vm._u([
          {
            key: "top",
            fn: function() {
              return [
                _c(
                  "v-toolbar",
                  { attrs: { flat: "" } },
                  [
                    _c("v-spacer"),
                    _c(
                      "v-toolbar-items",
                      { staticClass: "hidden-sm-and-down" },
                      [
                        _c("v-select", {
                          staticClass: "pa-3",
                          attrs: {
                            items: _vm.userRoleList,
                            label: "User Role",
                            multiple: "",
                            chips: ""
                          },
                          model: {
                            value: _vm.filterRole,
                            callback: function($$v) {
                              _vm.filterRole = $$v
                            },
                            expression: "filterRole"
                          }
                        }),
                        _c("v-spacer"),
                        _c("v-text-field", {
                          staticClass: "pa-3 pt-5",
                          attrs: {
                            "append-icon": "mdi-magnify",
                            label: "Search",
                            "single-line": "",
                            "hide-details": ""
                          },
                          model: {
                            value: _vm.search,
                            callback: function($$v) {
                              _vm.search = $$v
                            },
                            expression: "search"
                          }
                        })
                      ],
                      1
                    ),
                    _c(
                      "v-menu",
                      {
                        attrs: { "close-on-content-click": false },
                        scopedSlots: _vm._u([
                          {
                            key: "activator",
                            fn: function(ref) {
                              var on = ref.on
                              return [
                                _c(
                                  "v-app-bar-nav-icon",
                                  _vm._g(
                                    { staticClass: "hidden-md-and-up" },
                                    on
                                  )
                                )
                              ]
                            }
                          }
                        ])
                      },
                      [
                        _c(
                          "v-list",
                          { attrs: { flat: "" } },
                          [
                            _c("v-text-field", {
                              staticClass: "pa-3",
                              attrs: {
                                "append-icon": "mdi-magnify",
                                label: "Search",
                                "single-line": "",
                                "hide-details": ""
                              },
                              model: {
                                value: _vm.search,
                                callback: function($$v) {
                                  _vm.search = $$v
                                },
                                expression: "search"
                              }
                            }),
                            _c("v-subheader", [_vm._v("User Role")]),
                            _c(
                              "v-list-item-group",
                              {
                                attrs: { multiple: "", color: "primary" },
                                model: {
                                  value: _vm.filterRole,
                                  callback: function($$v) {
                                    _vm.filterRole = $$v
                                  },
                                  expression: "filterRole"
                                }
                              },
                              _vm._l(_vm.userRoleList, function(role, index) {
                                return _c("v-list-item", {
                                  key: index,
                                  attrs: { value: role },
                                  scopedSlots: _vm._u(
                                    [
                                      {
                                        key: "default",
                                        fn: function(ref) {
                                          var active = ref.active
                                          var toggle = ref.toggle
                                          return [
                                            _c(
                                              "v-list-item-content",
                                              [
                                                _c("v-list-item-title", {
                                                  domProps: {
                                                    textContent: _vm._s(role)
                                                  }
                                                })
                                              ],
                                              1
                                            ),
                                            _c(
                                              "v-list-item-action",
                                              [
                                                _c("v-checkbox", {
                                                  attrs: {
                                                    dense: "",
                                                    color: "primary",
                                                    "input-value": active
                                                  },
                                                  on: { click: toggle }
                                                })
                                              ],
                                              1
                                            )
                                          ]
                                        }
                                      }
                                    ],
                                    null,
                                    true
                                  )
                                })
                              }),
                              1
                            )
                          ],
                          1
                        )
                      ],
                      1
                    )
                  ],
                  1
                )
              ]
            },
            proxy: true
          },
          {
            key: "item.name",
            fn: function(ref) {
              var item = ref.item
              return [
                _vm._v(" " + _vm._s(item.firstName + " " + item.lastName) + " ")
              ]
            }
          },
          {
            key: "item.locations",
            fn: function(ref) {
              var item = ref.item
              return [
                _c(
                  "v-tooltip",
                  {
                    attrs: { bottom: "" },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "activator",
                          fn: function(ref) {
                            var on = ref.on
                            return [
                              _c(
                                "span",
                                _vm._g(
                                  {
                                    staticClass:
                                      "font-weight-black accent--text"
                                  },
                                  on
                                ),
                                [_vm._v(_vm._s(_vm.getLocationsCount(item)))]
                              )
                            ]
                          }
                        }
                      ],
                      null,
                      true
                    )
                  },
                  [
                    _c("div", [
                      _c("pre", [_vm._v(_vm._s(_vm.getLocations(item)))])
                    ])
                  ]
                )
              ]
            }
          },
          {
            key: "item.jobCodes",
            fn: function(ref) {
              var item = ref.item
              return [
                _c(
                  "v-tooltip",
                  {
                    attrs: { bottom: "" },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "activator",
                          fn: function(ref) {
                            var on = ref.on
                            return [
                              _c(
                                "span",
                                _vm._g(
                                  {
                                    staticClass:
                                      "font-weight-black accent--text"
                                  },
                                  on
                                ),
                                [_vm._v(_vm._s(_vm.getJobCodesCount(item)))]
                              )
                            ]
                          }
                        }
                      ],
                      null,
                      true
                    )
                  },
                  [
                    _c("div", [
                      _c("pre", [_vm._v(_vm._s(_vm.getJobCodes(item)))])
                    ])
                  ]
                )
              ]
            }
          },
          {
            key: "item.roles",
            fn: function(ref) {
              var item = ref.item
              return [
                _c(
                  "v-tooltip",
                  {
                    attrs: { bottom: "" },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "activator",
                          fn: function(ref) {
                            var on = ref.on
                            return [
                              _c(
                                "v-icon",
                                _vm._g(
                                  {
                                    directives: [
                                      {
                                        name: "show",
                                        rawName: "v-show",
                                        value: _vm.showAdminRole(item),
                                        expression: "showAdminRole(item)"
                                      }
                                    ]
                                  },
                                  on
                                ),
                                [_vm._v("mdi-account-cowboy-hat")]
                              )
                            ]
                          }
                        }
                      ],
                      null,
                      true
                    )
                  },
                  [_c("span", [_vm._v("Administrator")])]
                ),
                _c(
                  "v-tooltip",
                  {
                    attrs: { bottom: "" },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "activator",
                          fn: function(ref) {
                            var on = ref.on
                            return [
                              _c(
                                "v-icon",
                                _vm._g(
                                  {
                                    directives: [
                                      {
                                        name: "show",
                                        rawName: "v-show",
                                        value: _vm.showManagerRole(item),
                                        expression: "showManagerRole(item)"
                                      }
                                    ]
                                  },
                                  on
                                ),
                                [_vm._v("mdi-account-outline")]
                              )
                            ]
                          }
                        }
                      ],
                      null,
                      true
                    )
                  },
                  [_c("span", [_vm._v("Catering Manager")])]
                ),
                _c(
                  "v-tooltip",
                  {
                    attrs: { bottom: "" },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "activator",
                          fn: function(ref) {
                            var on = ref.on
                            return [
                              _c(
                                "v-icon",
                                _vm._g(
                                  {
                                    directives: [
                                      {
                                        name: "show",
                                        rawName: "v-show",
                                        value: _vm.showPayrollRole(item),
                                        expression: "showPayrollRole(item)"
                                      }
                                    ]
                                  },
                                  on
                                ),
                                [_vm._v("mdi-account-tie-outline")]
                              )
                            ]
                          }
                        }
                      ],
                      null,
                      true
                    )
                  },
                  [_c("span", [_vm._v("Payroll")])]
                ),
                _c(
                  "v-tooltip",
                  {
                    attrs: { bottom: "" },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "activator",
                          fn: function(ref) {
                            var on = ref.on
                            return [
                              _c(
                                "v-icon",
                                _vm._g(
                                  {
                                    directives: [
                                      {
                                        name: "show",
                                        rawName: "v-show",
                                        value: _vm.showSalesRole(item),
                                        expression: "showSalesRole(item)"
                                      }
                                    ]
                                  },
                                  on
                                ),
                                [_vm._v("mdi-account-cash-outline")]
                              )
                            ]
                          }
                        }
                      ],
                      null,
                      true
                    )
                  },
                  [_c("span", [_vm._v("Sales Rep")])]
                ),
                _c(
                  "v-tooltip",
                  {
                    attrs: { bottom: "" },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "activator",
                          fn: function(ref) {
                            var on = ref.on
                            return [
                              _c(
                                "v-icon",
                                _vm._g(
                                  {
                                    directives: [
                                      {
                                        name: "show",
                                        rawName: "v-show",
                                        value: _vm.showEmployeeRole(item),
                                        expression: "showEmployeeRole(item)"
                                      }
                                    ]
                                  },
                                  on
                                ),
                                [_vm._v("mdi-account-outline")]
                              )
                            ]
                          }
                        }
                      ],
                      null,
                      true
                    )
                  },
                  [_c("span", [_vm._v("Employee")])]
                )
              ]
            }
          }
        ])
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
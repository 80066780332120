<template>
  <v-container fluid fill-height>
    <v-layout flex align-center justify-center>
      <v-flex v-if="showEmployeeLogin" xs12 sm10 md6 lg4 xl3>
        <v-card tile>
          <v-toolbar dense color="primary">
            <v-toolbar-title>Login</v-toolbar-title>
          </v-toolbar>
          <v-divider></v-divider>
          <v-card-text class="pt-4">
            <div>
              <v-form v-model="valid" ref="form">
                <v-text-field
                  label="Email"
                  type="email"
                  required
                  v-model="credentials.email"
                  :rules="emailRules"
                  @keyup.enter.native="login"
                />

                <v-text-field
                  label="Password"
                  v-model="credentials.password"
                  min="8"
                  :append-icon="e1 ? 'visibility' : 'visibility_off'"
                  @click:append="() => (e1 = !e1)"
                  :type="e1 ? 'password' : 'text'"
                  :rules="passwordRules"
                  required
                  @keyup.enter.native="login"
                />
              </v-form>
            </div>
          </v-card-text>
          <v-card-actions>
            <v-btn
              :class="{
                accent: valid,
              }"
              :disabled="!valid"
              @click="login"
            >
              Employee Login
            </v-btn>
            <v-spacer></v-spacer>
            <router-link to="/forgotpassword">Forgot Password</router-link>
          </v-card-actions>
        </v-card>
      </v-flex>
      <v-flex v-else xs12 sm10 md6 lg4 xl3>
        <v-card tile>
          <v-card-title>
            Welcome to City Catering Que
          </v-card-title>
          <v-card-text>
            Choose your sign-in method:
            <div align="middle" justify="center">
              <v-btn
                tile
                outlined
                class="ma-1 px-1"
                @click="showEmployeeLogin = !showEmployeeLogin"
              >
                <v-icon class="pr-1" color="black">mdi-account-circle</v-icon>
                Sign in as Employee
              </v-btn>
            </div>
            <div align="middle" justify="center">
              <v-btn tile outlined class="ma-1 px-1" @click="azureLogin"
                ><v-icon class="pr-1" color="accent">mdi-grill</v-icon>
                Sign in with City BBQ Account
              </v-btn>
            </div>
          </v-card-text>
        </v-card>
      </v-flex>
    </v-layout>
  </v-container>
</template>

<script>
import authApi from "@/api/authApi";

export default {
  name: "Login",
  mounted() {
    if (this.$AuthService.isLoggedIn()) {
      this.$AuthService.getToken();
    }
  },
  data() {
    return {
      credentials: {},
      showEmployeeLogin: false,
      cityEmail: "@citybbq.com",
      valid: false,
      e1: true,
      passwordRules: [(v) => !!v || "Password is required"],
      emailRules: [
        (v) => !!v || "E-mail is required",
        (v) =>
        // eslint-disable-next-line max-len
          /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(v) ||
          "E-mail must be valid",
      ],
    };
  },
  methods: {
    login() {
      if (this.$refs.form.validate()) {
        if (this.credentials.email.includes(this.cityEmail)) {
          this.azureLogin();
        } else {
          this.$store.dispatch("authRequest", this.credentials)
              .then((result) => {
                this.$store
                    .dispatch("userRequest", result.data.id)
                    .then((response) => {
                      if (this.$route.path !== "/") {
                        this.$router.push("/");
                      }
                    });
              });
        }
      }
    },
    azureLogin() {
      authApi.azureLogin();
    },
  },
};
</script>

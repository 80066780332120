<template>
  <v-layout column>
    <v-data-table
      :headers="headers"
      :items="allUsersList"
      :search="search"
    >
      <template v-slot:top>
        <v-toolbar flat>
          <v-spacer></v-spacer>
          <v-toolbar-items class="hidden-sm-and-down">
            <v-select
              v-model="filterRole"
              :items="userRoleList"
              label="User Role"
              multiple
              chips
              class="pa-3"
            ></v-select>
            <v-spacer></v-spacer>
            <v-text-field
              v-model="search"
              append-icon="mdi-magnify"
              label="Search"
              single-line
              hide-details
              class="pa-3 pt-5"
            ></v-text-field>
          </v-toolbar-items>
          <v-menu :close-on-content-click="false">
            <template v-slot:activator="{ on }">
              <v-app-bar-nav-icon
                v-on="on"
                class="hidden-md-and-up"
              ></v-app-bar-nav-icon>
            </template>
            <v-list flat>
              <v-text-field
                v-model="search"
                append-icon="mdi-magnify"
                label="Search"
                single-line
                hide-details
                class="pa-3"
              ></v-text-field>
              <v-subheader>User Role</v-subheader>
              <v-list-item-group v-model="filterRole" multiple color="primary">
                <v-list-item
                  v-for="(role, index) in userRoleList"
                  :key="index"
                  :value="role"
                >
                  <template v-slot:default="{ active, toggle }">
                    <v-list-item-content>
                      <v-list-item-title v-text="role"></v-list-item-title>
                    </v-list-item-content>
                    <v-list-item-action>
                      <v-checkbox
                        @click="toggle"
                        dense
                        color="primary"
                        :input-value="active"
                      ></v-checkbox>
                    </v-list-item-action>
                  </template>
                </v-list-item>
              </v-list-item-group>
            </v-list>
          </v-menu>
        </v-toolbar>
      </template>
      <template v-slot:item.name="{ item }">
        {{ `${item.firstName} ${item.lastName}` }}
      </template>
      <template v-slot:item.locations="{ item }">
        <v-tooltip bottom>
          <template v-slot:activator="{ on }">
            <span v-on="on" class="font-weight-black accent--text">{{
              getLocationsCount(item)
            }}</span>
          </template>
          <div>
            <pre>{{ getLocations(item) }}</pre>
          </div>
        </v-tooltip>
      </template>
      <template v-slot:item.jobCodes="{ item }">
        <v-tooltip bottom>
          <template v-slot:activator="{ on }">
            <span v-on="on" class="font-weight-black accent--text">{{
              getJobCodesCount(item)
            }}</span>
          </template>
          <div>
            <pre>{{ getJobCodes(item) }}</pre>
          </div>
        </v-tooltip>
      </template>
      <template v-slot:item.roles="{ item }">
        <v-tooltip bottom>
          <template v-slot:activator="{ on }">
            <v-icon v-on="on" v-show="showAdminRole(item)"
              >mdi-account-cowboy-hat</v-icon
            >
          </template>
          <span>Administrator</span>
        </v-tooltip>
        <v-tooltip bottom>
          <template v-slot:activator="{ on }">
            <v-icon v-on="on" v-show="showManagerRole(item)"
              >mdi-account-outline</v-icon
            >
          </template>
          <span>Catering Manager</span>
        </v-tooltip>
        <v-tooltip bottom>
          <template v-slot:activator="{ on }">
            <v-icon v-on="on" v-show="showPayrollRole(item)"
              >mdi-account-tie-outline</v-icon
            >
          </template>
          <span>Payroll</span>
        </v-tooltip>
        <v-tooltip bottom>
          <template v-slot:activator="{ on }">
            <v-icon v-on="on" v-show="showSalesRole(item)"
              >mdi-account-cash-outline</v-icon
            >
          </template>
          <span>Sales Rep</span>
        </v-tooltip>
        <v-tooltip bottom>
          <template v-slot:activator="{ on }">
            <v-icon v-on="on" v-show="showEmployeeRole(item)"
              >mdi-account-outline</v-icon
            >
          </template>
          <span>Employee</span>
        </v-tooltip>
      </template>
    </v-data-table>
  </v-layout>
</template>

<script>
import {Role} from "@/helpers/role";

export default {
  name: "UsersGrid",
  props: {
    allUsersList: Array,
    currentUsersList: Array,
  },
  data() {
    return {
      search: "",
      filterNeedInvites: false,
      userRoleList: Object.values(Role),
      filterRole: [],
    };
  },
  methods: {
    showAdminRole(item) {
      return item.roles && item.roles.includes(Role.Administrator);
    },
    showManagerRole(item) {
      return item.roles && item.roles.includes(Role.CateringManager);
    },
    showPayrollRole(item) {
      return item.roles && item.roles.includes(Role.Payroll);
    },
    showSalesRole(item) {
      return item.roles && item.roles.includes(Role.SalesRep);
    },
    showEmployeeRole(item) {
      return item.roles && item.roles.includes(Role.Employee);
    },
    getAssignedCount(order) {
      if (order.orderUsers) {
        return order.orderUsers.length;
      }
      return 0;
    },
    getAssignedUsers(order) {
      let ret = "";
      if (order.orderUsers && order.orderUsers.length) {
        order.orderUsers.forEach((ou) => {
          if (ou.user) {
            ret += `${ou.user.firstName} ${ou.user.lastName}\r\n`;
          }
        });
      }
      return ret;
    },
    getLocations(user) {
      let ret = "";
      if (user.cityLocations && user.cityLocations.length) {
        user.cityLocations.forEach((l) => {
          if (l) {
            ret += `${l.id} ${l.description}\r\n`;
          }
        });
      }
      return ret;
    },
    getLocationsCount(user) {
      if (user.cityLocations) {
        return user.cityLocations.length;
      }
      return 0;
    },
    getJobCodes(user) {
      let ret = "";
      if (user.cityJobCodes && user.cityJobCodes.length) {
        user.cityJobCodes.forEach((c) => {
          if (c) {
            ret += `${c.id} ${c.description}\r\n`;
          }
        });
      }
      return ret;
    },
    getJobCodesCount(user) {
      if (user.cityJobCodes) {
        return user.cityJobCodes.length;
      }
      return 0;
    },
  },
  computed: {
    headers() {
      return [
        {text: "Id", value: "cityEmployeeId"},
        {text: "Name", value: "name"},
        {text: "Email", value: "email"},
        {
          text: "Roles",
          value: "roles",
          filter: (value) => {
            if (!this.filterRole || this.filterRole.length < 1) {
              return true;
            }
            let ret = false;
            this.filterRole.forEach((role) => {
              if (value && value.includes(role)) {
                ret = true;
              }
            });
            return ret;
          },
        },
        {text: "Locations", value: "locations"},
        {text: "Job Codes", value: "jobCodes"},
        {text: "Status", value: "status"},
      ];
    },
  },
};
</script>

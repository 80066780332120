var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-snackbar",
    {
      attrs: { color: _vm.color, timeout: _vm.timeout, top: true },
      model: {
        value: _vm.snackbar,
        callback: function($$v) {
          _vm.snackbar = $$v
        },
        expression: "snackbar"
      }
    },
    [
      _vm._v(" " + _vm._s(_vm.text) + " "),
      _vm.showCloseButton
        ? _c(
            "v-btn",
            {
              on: {
                click: function($event) {
                  _vm.snackbar = false
                }
              }
            },
            [_vm._v(" Close ")]
          )
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
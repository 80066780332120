<template>
  <v-container fluid class="pa-0">
    <v-layout flex align-center justify-center>
      <v-flex md12>
        <v-card tile>
          <v-toolbar dense color="primary">
            <Breadcrumbs />
          </v-toolbar>
          <v-divider></v-divider>
          <v-tabs
            v-model="tab"
            fixed-tabs
            :background-color="'primary darken-1'"
            hide-slider="true"
            :active-class="'primary black--text'"
          >
            <v-tab href="#tab-1">
              Timeclock
              <v-icon left>mdi-format-list-bulleted</v-icon>
            </v-tab>
            <v-tab href="#tab-2">
              Log
              <v-icon left>mdi-calendar-month</v-icon>
            </v-tab>
          </v-tabs>
          <v-tabs-items v-model="tab">
            <v-tab-item :value="'tab-1'">
              <v-card flat tile>
                <v-row>
                  <v-spacer></v-spacer>
                  <span
                    class="font-weight-light pa-3"
                    :class="{
                      'display-3': this.$vuetify.breakpoint.mdAndUp,
                      'display-1': this.$vuetify.breakpoint.smAndDown,
                    }"
                    align="center"
                    justify="center"
                  >
                    {{ currentDateTimeString }}
                  </span>
                  <v-spacer></v-spacer>
                </v-row>
                <v-card v-if="!todayAssignments || todayAssignments < 1">
                  <v-card-text align="middle" justify="center">
                    No events scheduled today.
                  </v-card-text>
                </v-card>
                <vue-custom-scrollbar supressScrollX>
                  <v-layout column class="shifts">
                    <v-card
                      tile
                      class="pa-2"
                      v-for="(assignment, index) in todayAssignments"
                      :key="index"
                    >
                      <v-row dense align="center" justify="center">
                        <v-col align="center" justify="center">
                          <v-card
                            tile
                            class="pt-2 px-4 elevation-3"
                            width="360"
                          >
                            {{ assignment.order.cityOrderId }}
                            <v-row class="px-2">
                              <strong>{{ assignment.order.name }}</strong>
                            </v-row>
                            <v-row class="px-2">
                              <span>{{
                                assignment.cityJobCode.description
                              }}</span>
                            </v-row>
                            <v-divider class="mt-1"></v-divider>
                            <v-row class="px-2">
                              <a
                                @click="cateringChipPrint(
                                  assignment.order,
                                  assignment.order.cityOrderId,
                                  prepChitProcessId)"
                                  target="_blank"
                                ><strong>MM Prep Catering Chit Print</strong>
                                <v-icon>mdi-open-in-new</v-icon></a
                              >
                            </v-row>
                            <v-row class="px-2">
                              <a
                                @click="cateringChipPrint(
                                  assignment.order,
                                  assignment.order.cityOrderId,
                                  assemblyProcesId)"
                                target="_blank"
                                ><strong> MM Assembly Catering Chit Print
                                </strong>
                                <v-icon>mdi-open-in-new</v-icon></a
                              >
                            </v-row>
                            <v-divider class="mt-1"></v-divider>
                            <v-simple-table dense class="text-md-center pt-1">
                              <template v-slot:default>
                                <tbody>
                                  <tr>
                                    <td align="center" justify="center">
                                      <strong>START</strong>
                                    </td>
                                    <td>
                                      <strong>
                                        {{
                                          getLongDateTime(assignment.start)
                                        }}</strong
                                      >
                                    </td>
                                  </tr>
                                  <template
                                    v-for="clockEvent in getClockEvents(
                                      assignment.id
                                    )"
                                  >
                                    <tr
                                      v-if="clockEvent.clockIn"
                                      :key="clockEvent.id + 'IN'"
                                    >
                                      <td>IN</td>
                                      <td>
                                        {{
                                          getUtcLongDateTime(clockEvent.clockIn)
                                        }}
                                      </td>
                                    </tr>
                                    <tr
                                      v-if="clockEvent.clockOut"
                                      :key="clockEvent.id + 'OUT'"
                                    >
                                      <td>OUT</td>
                                      <td>
                                        {{
                                          clockEvent.clockOut
                                            ? getUtcLongDateTime(
                                                clockEvent.clockOut
                                              )
                                            : ""
                                        }}
                                      </td>
                                    </tr>
                                  </template>
                                </tbody>
                              </template>
                            </v-simple-table>
                          </v-card>
                        </v-col>
                        <v-col align="center" justify="center">
                          <v-btn
                            x-large
                            color="accent"
                            class="ma-1"
                            @click="submitClockEvent(assignment)"
                            :disabled="isButtonDisabled(assignment, true)"
                            >Clock In</v-btn
                          >
                          <p v-show="isButtonDisabled(assignment, false)">
                            {{ getDisabledReason(assignment, false) }}
                          </p>
                          <v-btn
                            x-large
                            color="accent"
                            class="ma-1"
                            @click="submitClockEvent(assignment)"
                            :disabled="isButtonDisabled(assignment, false)"
                            >Clock Out</v-btn
                          >
                        </v-col>
                        <v-btn
                          color="accent"
                          class="white--text"
                          title="Image or PDF"
                          x-large
                          @click="openModal(assignment.order.cityOrderId)"
                        >
                          Add Image
                        </v-btn>
                      </v-row>
                    </v-card>
                  </v-layout>
                </vue-custom-scrollbar>
              </v-card>
            </v-tab-item>
            <v-tab-item :value="'tab-2'">
              <v-card flat>
                <v-card-text class="pa-0"
                  ><ClockEventsGrid :clockEventsList="pastClockEvents" />
                </v-card-text>
              </v-card>
            </v-tab-item>
          </v-tabs-items>
        </v-card>
      </v-flex>
    </v-layout>
    <AddImage
      :visible="visible"
      @close="visible = false"
      :orderId="cityOrderId"
    />
  </v-container>
</template>

<script>
import Breadcrumbs from "@/components/Breadcrumbs";
import UserTime from "@/helpers/userTime";
import clockEventsApi from "@/api/clockEventsApi";
import ClockEventsGrid from "@/components/ClockEventsGrid";
import AddImage from "@/components/AddImage";
import moment from "moment";
import vueCustomScrollbar from "vue-custom-scrollbar";
import Vue from "vue";

export default {
  name: "Timeclock",
  components: {
    ClockEventsGrid,
    Breadcrumbs,
    vueCustomScrollbar,
    AddImage,
  },
  data() {
    return {
      currentDateTimeString: "",
      clockedInAssignmentsList: [],
      pastClockEvents: [],
      todayAssignments: [],
      visible: false,
      isClockedIn: false,
      cityOrderId: null,
      prepChitProcessId: "cf68d857-f5c0-4918-8446-fc8e4fdd04ca",
      assemblyProcesId: "4a96a289-e020-4a78-b213-d7b085120429",
    };
  },
  computed: {
    checkModalVis() {
      return this.visible;
    },
  },
  mounted() {
    setInterval(this.updateTime, 1000);
    clockEventsApi
        .getClockEventsVm(this.getUtcLongDateTime(new Date()))
        .then((response) => {
          this.pastClockEvents = response.data.pastClockEvents;
          this.todayAssignments = response.data.todayAssignments;
          // eslint-disable-next-line max-len
          this.clockedInAssignmentsList = response.data.clockedInAssignmentsList;
        });
  },
  methods: {
    openModal(orderID) {
      this.visible = true;
      this.cityOrderId = orderID;
    },
    updateTime() {
      this.currentDateTimeString = UserTime.now();
    },
    closeModal() {
      this.title = "";
      this.selectTitle = "";
      this.files = [];
      this.allOrderId = "";
      document.querySelector("input[type=file]").value = "";
      this.description = "";
      this.dialog = false;
    },
    cateringChipPrint(order, cityOrderId, processId) {
      const store = order.market.split("/")[1];
      Vue.swal({
        icon: "warning",
        // eslint-disable-next-line max-len
        title: `Are you sure you want to print the chit for ${store} ?`,
        text: "",
        type: "success",
        showCancelButton: true,
        confirmButtonColor: "#AF1E2D",
        confirmButtonText: "Print",
        focusCancel: true,
        closeOnConfirm: false,
      }).then((result) => {
        if (result.isConfirmed) {
          // eslint-disable-next-line max-len
          clockEventsApi.chitRePrint(cityOrderId, processId).then((response) => {
            if (response.status == 200) {
              this.$root.$emit("successToast", "Printing job queued.");
            } else {
              // eslint-disable-next-line max-len
              this.$root.$emit("erroToast", "Erro encountered while Printing the chit.");
            }
          }),
          (error) => {
            this.$root.$emit(
                "errorToast",
                "Error encountered.  Please contact support.",
            );
          };
        }
      });
    },
    submitClockEvent(assignment) {
      clockEventsApi
          .postClockEvent({orderUserId: assignment.id})
          .then((response) => {
            this.$root.$emit("successToast", "Clock punch successful.");
            let index = -1;
            for (let i = 0; i < this.pastClockEvents.length; i++) {
              if (this.pastClockEvents[i].id == response.data.id) {
                index = i;
                break;
              }
            }
            if (index < 0) {
              this.pastClockEvents.push(response.data);
              this.clockedInAssignmentsList.push(response.data.orderUser);
            } else {
              Object.assign(this.pastClockEvents[index], response.data);
              // eslint-disable-next-line max-len
              this.clockedInAssignmentsList = this.clockedInAssignmentsList.filter(
                  // Remove from clocked-in list.
                  (ou) => {
                    return ou.id !== assignment.id;
                  },
              );
            }
          }),
      (error) => {
        this.$root.$emit(
            "errorToast",
            "Error encountered.  Please contact support.",
        );
      };
    },
    getDisabledReason(assignment, isClockIn) {
      if (moment(assignment.start).diff(moment(), "minutes") > 10) {
        // eslint-disable-next-line max-len
        return `Assignment start (${moment(assignment.start)}) is more than 10 minutes from now (${moment()}).`;
      } else if (moment().isAfter(moment(assignment.end))) {
        // eslint-disable-next-line max-len
        return `Assignment end (${moment(assignment.end)}) is past current time (${moment()}).`;
      } else if (this.clockedInAssignmentsList.length) {
        // eslint-disable-next-line max-len
        return `Already clocked in for assignment {${this.clockedInAssignmentsList[0].id}}.`;
      }
    },
    isButtonDisabled(assignment, isClockIn) {
      if (
        this.clockedInAssignmentsList.filter((ou) => {
          return assignment.id === ou.id;
        }).length > 0
      ) {
        // Clock out enabled when clocked-in.
        this.isClockedIn = false;
        return isClockIn;
      } else if (
        isClockIn &&
        moment(assignment.start).diff(moment(), "minutes") <= 10 &&
        moment().isBefore(moment(assignment.end)) &&
        !this.clockedInAssignmentsList.length
      ) {
        // Enabled if 'Clock In' button,
        // AND now is within ten minutes of start
        // AND now not after assignment end
        // AND not clocked into another event.
        this.isClockedIn = true;
        return false;
      }
      this.isClockedIn = true;
      return true;
    },
    getUtcLongDateTime(timestamp) {
      return UserTime.getUtcLongDateTime(timestamp);
    },
    getLongDateTime(timestamp) {
      return UserTime.getLongDateTime(timestamp);
    },
    getClockEvents(orderUserId) {
      const ret = this.pastClockEvents.filter((ce) => {
        return ce.orderUser.id === orderUserId;
      });

      ret.sort(function(a, b) {
        return new Date(a.clockIn) - new Date(b.clockIn);
      });

      return ret;
    },
  },
};
</script>
<style>
.shifts {
  max-height: calc(67vh);
}
@media screen and (max-width: 768px) {
  .shifts {
    max-height: calc(73vh);
  }
}
@media screen and (max-width: 415px) {
  .shifts {
    max-height: calc(65vh);
  }
}
@media screen and (max-height: 750px) {
  .shifts {
    max-height: calc(62vh);
  }
}
.ps__thumb-y {
  background-color: #000;
}
.row {
  justify-content: space-between !important;
}
.outer {
  width: 100%;
  display: flex;
  justify-content: center;
  margin-bottom: 30px;
}
</style>

import "@mdi/font/css/materialdesignicons.css";

import Vue from "vue";

import Vuetify from "vuetify/lib";

Vue.use(Vuetify);

const vuetify = new Vuetify({
  icons: {
    iconfont: "md",
  },
  theme: {
    dark: false,
    themes: {
      light: {
        primary: {
          base: "#CEc3BA",
          darken1: "#b9b0a8",
          darken2: "#aca59e",
        },
        secondary: {
          base: "#000",
          ligten5: "#fff",
        },
        tertiary: "#A0684E",
        anchor: "#282828",
        accent: "#AF1E2D",
        warning: "#ea760e",
        error: "#f44336",
        sucess: "#6d8646",
        info: "#291e2a",
      },
    },
  },
});

export default vuetify;

<template>
  <v-container fluid class="pa-0">
    <v-layout flex align-center justify-center>
      <v-flex md12>
        <v-card tile>
          <v-toolbar dense color="primary">
            <Breadcrumbs />
          </v-toolbar>
          <v-toolbar flat>
            <v-toolbar-items style="width: 100%;" class="hidden-sm-and-down">
              <v-row>
                <v-col>
                  <v-text-field
                    class="mt-2"
                    v-model="search"
                    append-icon="mdi-magnify"
                    label="Search"
                    single-line
                    hide-details
                    clearable
                  ></v-text-field>
                </v-col>
                <v-col>
                  <v-menu
                    v-model="startDateMenu"
                    :close-on-content-click="false"
                    transition="scale-transition"
                    offset-y
                  >
                    <template v-slot:activator="{ on }">
                      <v-text-field
                        v-model="filterStartDate"
                        label="Order Start"
                        prepend-icon="event"
                        readonly
                        clearable
                        v-on="on"
                      ></v-text-field>
                    </template>
                    <v-date-picker
                      v-model="filterStartDate"
                      @input="startDateMenu = false"
                    ></v-date-picker>
                  </v-menu>
                </v-col>
                <v-col>
                  <v-menu
                    v-model="endDateMenu"
                    :close-on-content-click="false"
                    transition="scale-transition"
                    offset-y
                  >
                    <template v-slot:activator="{ on }">
                      <v-text-field
                        v-model="filterEndDate"
                        label="Order End"
                        prepend-icon="event"
                        readonly
                        clearable
                        v-on="on"
                      ></v-text-field>
                    </template>
                    <v-date-picker
                      v-model="filterEndDate"
                      @input="endDateMenu = false"
                    ></v-date-picker>
                  </v-menu>
                </v-col>
                <v-col>
                  <v-menu
                    v-model="payStartDateMenu"
                    :close-on-content-click="false"
                    transition="scale-transition"
                    offset-y
                  >
                    <template v-slot:activator="{ on }">
                      <v-text-field
                        v-model="filterPayStartDate"
                        label="Paycheck Start"
                        prepend-icon="event"
                        readonly
                        clearable
                        v-on="on"
                      ></v-text-field>
                    </template>
                    <v-date-picker
                      v-model="filterPayStartDate"
                      @input="payStartDateMenu = false"
                    ></v-date-picker>
                  </v-menu>
                </v-col>
                <v-col>
                  <v-menu
                    v-model="payEndDateMenu"
                    :close-on-content-click="false"
                    transition="scale-transition"
                    offset-y
                  >
                    <template v-slot:activator="{ on }">
                      <v-text-field
                        v-model="filterPayEndDate"
                        label="Paycheck End"
                        prepend-icon="event"
                        readonly
                        clearable
                        v-on="on"
                      ></v-text-field>
                    </template>
                    <v-date-picker
                      v-model="filterPayEndDate"
                      @input="payEndDateMenu = false"
                    ></v-date-picker>
                  </v-menu>
                </v-col>
              </v-row>
            </v-toolbar-items>
            <v-menu :close-on-content-click="false">
              <template v-slot:activator="{ on }">
                <span class="hidden-md-and-up">Filters</span>
                <v-app-bar-nav-icon
                  v-on="on"
                  class="hidden-md-and-up"
                ></v-app-bar-nav-icon>
              </template>
              <v-list flat>
                <v-text-field
                  v-model="search"
                  append-icon="mdi-magnify"
                  label="Search"
                  single-line
                  hide-details
                  class="pa-3"
                ></v-text-field>
                <v-menu
                  v-model="startDateNavMenu"
                  :close-on-content-click="false"
                  :nudge-right="40"
                  transition="scale-transition"
                  offset-y
                  min-width="290px"
                >
                  <template v-slot:activator="{ on }">
                    <v-text-field
                      v-model="filterStartDate"
                      label="Order Start"
                      prepend-icon="event"
                      readonly
                      clearable
                      v-on="on"
                      class="pa-3"
                    ></v-text-field>
                  </template>
                  <v-date-picker
                    v-model="filterStartDate"
                    @input="startDateNavMenu = false"
                  ></v-date-picker>
                </v-menu>
                <v-menu
                  v-model="endDateNavMenu"
                  :close-on-content-click="false"
                  :nudge-right="40"
                  transition="scale-transition"
                  offset-y
                  min-width="290px"
                >
                  <template v-slot:activator="{ on }">
                    <v-text-field
                      v-model="filterEndDate"
                      label="Order End"
                      prepend-icon="event"
                      readonly
                      clearable
                      v-on="on"
                      class="pa-3"
                    ></v-text-field>
                  </template>
                  <v-date-picker
                    v-model="filterEndDate"
                    @input="endDateNavMenu = false"
                  ></v-date-picker>
                </v-menu>
                <v-menu
                  v-model="payStartDateNavMenu"
                  :close-on-content-click="false"
                  :nudge-right="40"
                  transition="scale-transition"
                  offset-y
                  min-width="290px"
                >
                  <template v-slot:activator="{ on }">
                    <v-text-field
                      v-model="filterStartDate"
                      label="Check Start"
                      prepend-icon="event"
                      readonly
                      clearable
                      v-on="on"
                      class="pa-3"
                    ></v-text-field>
                  </template>
                  <v-date-picker
                    v-model="filterStartDate"
                    @input="payStartDateNavMenu = false"
                  ></v-date-picker>
                </v-menu>
                <v-menu
                  v-model="payEndDateNavMenu"
                  :close-on-content-click="false"
                  :nudge-right="40"
                  transition="scale-transition"
                  offset-y
                  min-width="290px"
                >
                  <template v-slot:activator="{ on }">
                    <v-text-field
                      v-model="filterPayEndDate"
                      label="Check End"
                      prepend-icon="event"
                      readonly
                      clearable
                      v-on="on"
                      class="pa-3"
                    ></v-text-field>
                  </template>
                  <v-date-picker
                    v-model="filterPayEndDate"
                    @input="payEndDateNavMenu = false"
                  ></v-date-picker>
                </v-menu>
              </v-list>
            </v-menu>
          </v-toolbar>
          <v-divider></v-divider>
          <v-layout column>
            <v-data-table
              :headers="headers"
              :items="filteredCommissions"
              class="minimum-height"
            >
              <template v-slot:item.orderStart="{ item }">
                {{ getStartDateTime(item) }}
              </template>
              <template v-slot:item.payrollSubmissionTimestamp="{ item }">
                {{ getShortDate(item.payrollSubmissionTimestamp) }}
              </template>
              <template v-slot:item.amount="{ item }">
                <v-tooltip bottom
                  v-if="item.isEstimate">
                  <template v-slot:activator="{on}">
                <v-icon
                v-on="on">mdi-approximately-equal-box</v-icon>
                  </template>
                  <span>Only an Estimate.
                    Amount may change. Pending Approval</span>
                </v-tooltip>
                {{ getNumeral(item.amount) }}
              </template>
            </v-data-table>
          </v-layout>
        </v-card>
      </v-flex>
    </v-layout>
  </v-container>
</template>

<script>
import Breadcrumbs from "@/components/Breadcrumbs";
import commissionsApi from "@/api/commissionsApi";
import UserTime from "@/helpers/userTime.js";
import numeral from "numeral";
import moment from "moment";

export default {
  name: "Commissions",
  components: {
    Breadcrumbs,
  },
  data() {
    return {
      allCommissions: [],
      filteredCommissions: [],
      startDateMenu: false,
      endDateMenu: false,
      payStartDateMenu: false,
      payEndDateMenu: false,
      startDateNavMenu: false,
      endDateNavMenu: false,
      payStartDateNavMenu: false,
      payEndDateNavMenu: false,
      search: "",
      filterStartDate: moment()
          .startOf("day")
          .format("YYYY-MM-DD"),
      filterEndDate: moment()
          .add(7, "days")
          .startOf("day")
          .format("YYYY-MM-DD"),
      filterPayStartDate: moment()
          .startOf("day")
          .format("YYYY-MM-DD"),
      filterPayEndDate: moment()
          .add(7, "days")
          .startOf("day")
          .format("YYYY-MM-DD"),
    };
  },
  mounted() {
    commissionsApi.getCommissions().then((response) => {
      this.allCommissions = response.data;
    });
  },
  computed: {
    headers() {
      return [
        {text: "Date", value: "orderStart"},
        {
          text: "Order Name",
          value: "orderName",
        },
        {text: "Amount", value: "amount"},
        {
          text: "Paycheck Date",
          value: "payrollSubmissionTimestamp",
        },
      ];
    },
  },
  watch: {
    allCommissions: {
      // Handle inital load.
      handler: function(val, oldVal) {
        this.filterCommissions();
      },
    },
    search: {
      handler: function(val, oldVal) {
        this.filterCommissions();
      },
    },
    filterStartDate: {
      handler: function(val, oldVal) {
        this.filterCommissions();
      },
    },
    filterEndDate: {
      handler: function(val, oldVal) {
        this.filterCommissions();
      },
    },
    filterPayStartDate: {
      handler: function(val, oldVal) {
        this.filterCommissions();
      },
    },
    filterPayEndDate: {
      handler: function(val, oldVal) {
        this.filterCommissions();
      },
    },
  },
  methods: {
    getStartDateTime(order) {
      return order.orderStart ?
        `${UserTime.getLongDateTime(order.orderStart)} ${
          order.orderStartTimeZone
        }` :
        "";
    },
    getShortDate(timestamp) {
      return UserTime.getShortDate(timestamp);
    },
    getUtcLongDateTime(timestamp) {
      return UserTime.getUtcLongDateTime(timestamp);
    },
    getNumeral(amount) {
      return numeral(amount).format("$0,0.00");
    },
    filterCommissions() {
      let ret = this.allCommissions;
      if (this.filterStartDate) {
        ret = ret.filter((o) => {
          return (
            !o.orderStart ||
            moment(this.filterStartDate).isBefore(
                moment(o.orderStart)
                    .startOf("day")
                    .add(1, "seconds"),
            )
          );
        });
      }
      if (this.filterEndDate) {
        ret = ret.filter((o) => {
          return (
            !o.orderStart ||
            moment(this.filterEndDate).isAfter(
                moment(o.orderStart)
                    .startOf("day")
                    .subtract(1, "seconds"),
            )
          );
        });
      }
      if (this.filterPayStartDate) {
        ret = ret.filter((o) => {
          return (
            !o.payrollSubmissionTimestamp ||
            moment(this.filterPayStartDate).isBefore(
                moment(o.payrollSubmissionTimestamp)
                    .startOf("day")
                    .add(1, "seconds"),
            )
          );
        });
      }
      if (this.filterPayEndDate) {
        ret = ret.filter((o) => {
          return (
            !o.payrollSubmissionTimestamp ||
            moment(this.filterPayEndDate).isAfter(
                moment(o.payrollSubmissionTimestamp)
                    .startOf("day")
                    .subtract(1, "seconds"),
            )
          );
        });
      }
      if (this.search) {
        ret = ret.filter((o) => {
          let b = false;
          const keys = Object.keys(o);
          for (let i = 0; i < keys.length; i++) {
            if (
              o[keys[i]] && // Not null or undefined
              typeof o[keys[i]] === "string" && // Is a string
              o[keys[i]].toUpperCase().indexOf(this.search.toUpperCase()) >= 0
            ) {
              b = true;
              break;
            }
          }
          return b;
        });
      }
      this.filteredCommissions = ret;
    },
  },
};
</script>
<style scoped>
.minimum-height {
  min-height: 400px;
}
</style>

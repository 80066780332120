var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-layout",
    {
      directives: [
        {
          name: "resize",
          rawName: "v-resize",
          value: _vm.onResize,
          expression: "onResize"
        }
      ],
      attrs: { column: "" }
    },
    [
      _c("v-data-table", {
        staticClass: "elevation-2 minimum-height",
        attrs: {
          headers: _vm.isMobile ? _vm.mobileHeaders : _vm.headers,
          items: _vm.orderList,
          search: _vm.search,
          "sort-by": "start",
          "sort-desc": "",
          "hide-default-headers": _vm.isMobile
        },
        scopedSlots: _vm._u([
          {
            key: "body",
            fn: function(props) {
              return [
                _vm.orderList.length > 0 && !_vm.isMobile
                  ? _c(
                      "tbody",
                      _vm._l(props.items, function(item, index) {
                        return _c(
                          "tr",
                          { key: index, class: _vm.getColor(item) },
                          [
                            _c("td", { staticClass: "text-xs-right" }, [
                              _vm._v(_vm._s(item.employeeId))
                            ]),
                            _c("td", { staticClass: "text-xs-right" }, [
                              _vm._v(_vm._s(item.name))
                            ]),
                            _c("td", { staticClass: "text-xs-right one" }, [
                              _vm._v(" " + _vm._s(item.poneNumber) + " ")
                            ]),
                            _c("td", { staticClass: "text-xs-right two" }, [
                              _vm._v(" " + _vm._s(item.dafaultMrketName) + " ")
                            ]),
                            _c("td", { staticClass: "text-xs-right" }, [
                              _vm._v(_vm._s(item.orderId))
                            ]),
                            _c("td", { staticClass: "text-xs-right" }, [
                              _vm._v(
                                _vm._s(
                                  _vm.getDate(item.eventDate, item.eventTime)
                                )
                              )
                            ]),
                            _c("td", { staticClass: "text-xs-right" }, [
                              _vm._v(" " + _vm._s(item.scheduleStart) + " ")
                            ]),
                            _c("td", { staticClass: "text-xs-right" }, [
                              _vm._v(" " + _vm._s(item.scheduleEnd) + " ")
                            ]),
                            _c("td", { staticClass: "text-xs-right" }, [
                              _vm._v(
                                " " + _vm._s(_vm.getClockIn(item.clockIn)) + " "
                              )
                            ]),
                            _c("td", { staticClass: "text-xs-right" }, [
                              _vm._v(
                                " " +
                                  _vm._s(_vm.getClockOut(item.clockOut)) +
                                  " "
                              )
                            ]),
                            _c("td", { staticClass: "text-xs-right" }, [
                              _vm._v(" " + _vm._s(item.hoursWorked) + " ")
                            ])
                          ]
                        )
                      }),
                      0
                    )
                  : _vm.orderList.length > 0 && _vm.isMobile
                  ? _c(
                      "tbody",
                      _vm._l(props.items, function(item, index) {
                        return _c(
                          "tr",
                          { key: index, class: _vm.getColor(item) },
                          [
                            _c("td", [
                              _c("ul", { staticClass: "flex-content" }, [
                                _c("li", { staticClass: "flex-item" }, [
                                  _c("div", { staticClass: "item-content" }, [
                                    _vm._v(" Name: ")
                                  ]),
                                  _c("div", [
                                    _vm._v(" " + _vm._s(item.name) + " ")
                                  ])
                                ]),
                                _c("li", { staticClass: "flex-item" }, [
                                  _c("div", { staticClass: "item-content" }, [
                                    _vm._v(" Phone Number: ")
                                  ]),
                                  _c("div", [
                                    _vm._v(" " + _vm._s(item.poneNumber) + " ")
                                  ])
                                ]),
                                _c("li", { staticClass: "flex-item" }, [
                                  _c("div", { staticClass: "item-content" }, [
                                    _vm._v(" Order Id: ")
                                  ]),
                                  _c("div", [
                                    _vm._v(" " + _vm._s(item.orderId) + " ")
                                  ])
                                ]),
                                _c("li", { staticClass: "flex-item" }, [
                                  _c("div", { staticClass: "item-content" }, [
                                    _vm._v(" Start Date: ")
                                  ]),
                                  _c("div", [
                                    _vm._v(
                                      " " + _vm._s(item.scheduleStart) + " "
                                    )
                                  ])
                                ])
                              ])
                            ])
                          ]
                        )
                      }),
                      0
                    )
                  : _c("tbody", [
                      _c("tr", { staticClass: "v-data-table__empty-wrapper" }, [
                        _c("td", { attrs: { colspan: "10" } }, [
                          _vm._v(" No orders found ")
                        ])
                      ])
                    ])
              ]
            }
          }
        ])
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
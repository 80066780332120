<template>
  <v-container fluid class="pa-0">
    <div v-show="show" class="profile-parent-div">
      <v-card-text>
        <v-row>
          <v-col cols="4">
            <v-list-item-title
            style="font-weight: bold">
            First Name:</v-list-item-title>
            <v-list-item-content>
              {{ userData.firstName }}
            </v-list-item-content>
          </v-col>
          <v-col cols="4">
            <v-list-item-title
            style="font-weight: bold">
            Last Name:</v-list-item-title>
            <v-list-item-content>
              {{ userData.lastName }}
            </v-list-item-content>
          </v-col>
          <v-col cols="4">
            <v-list-item-title
            style="font-weight: bold">
            CCQ Email:</v-list-item-title>
            <v-list-item-content>
              {{ userData.cityEmailAddress }}
            </v-list-item-content>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="4">
            <v-list-item-title style="font-weight: bold">
              Eligible For Commission:
              <span id="condition" @mouseover="ShowHoverMessage"
               @mouseout="showText = false"
               v-show="eligibleForCommission == 'No'">?</span>
              {{ eligibleForCommission }}
            </v-list-item-title>
            <v-list-item-content>
              {{ EligibleCommision() }}
              <div class="hover-text" v-if="showText">
                <pre>{{ hoverText }}</pre>
              </div>
            </v-list-item-content>
          </v-col>
          <v-col cols="4">
            <v-list-item-title style="font-weight: bold">
              Approved Driver:
            </v-list-item-title>
            <v-list-item-content>
              {{ approvedDriverText }}
            </v-list-item-content>
          </v-col>
          <v-col cols="4">
            <v-list-item-title style="font-weight: bold">
              Monkey Media Email:</v-list-item-title>
            <v-list-item-content>
              {{ existInMonkey }}
            </v-list-item-content>
          </v-col>
        </v-row>

        <v-row>
          <v-col cols="4">
            <v-list-item-content>
              <v-list-item-title style="font-weight: bold;
                display: flex; align-items: center;">
                <span>SMS Notifications:</span>
                <v-checkbox
                  v-model="smsNotificationEnabled"
                  @change="updateNotificationStatus"
                  dense
                  color="primary"
                  hide-details
                  class="ml-2"
                ></v-checkbox>
              </v-list-item-title>
            </v-list-item-content>
          </v-col>
        </v-row>

        <v-row>
          <!-- Security Groups -->
          <v-col cols="4">
            <v-list-item-title style="font-weight: bold">
              Security Groups:
              <v-icon @click="viewModal('roles')"
              class="editIcon">mdi-pencil</v-icon>
            </v-list-item-title>
            <v-list-item-content>
              <ul>
                <li v-for="(role, index) in roles" :key="index">{{ role }}</li>
              </ul>
            </v-list-item-content>
          </v-col>

          <!-- Locations Assigned -->
          <v-col cols="4">
            <v-list-item-title style="font-weight: bold">
              Locations Assigned:</v-list-item-title>
            <v-list-item-content>
              <ul>
                <li v-for="(location, index) in locations"
                :key="index">{{ location.description }}</li>
              </ul>
            </v-list-item-content>
          </v-col>

          <!-- Jobs Assigned -->
          <v-col cols="4">
            <v-list-item-title style="font-weight: bold">
              Jobs Assigned:</v-list-item-title>
            <v-list-item-content>
              <ul>
                <li v-for="(job, index) in jobcodes"
                :key="index">{{ job.description }}</li>
              </ul>
            </v-list-item-content>
          </v-col>
        </v-row>
      </v-card-text>
    </div>
    <v-dialog :value="openModal" persistent>
      <v-container class="group-form" v-show="editablefield === 'roles'">
        <v-row>
          <v-col cols="12">
            <h3>Assign Roles to User</h3>
          </v-col>
          <v-col cols="12">
            <v-select
              v-model="selectedRoles"
              :items="rolesList"
              item-text="name"
              item-value="id"
              label="Select Roles"
              multiple
            ></v-select>
          </v-col>
          <v-btn small @click="submitRoles" color="accent" class="ma-3">
            Assign Roles to user
          </v-btn>
          <v-btn small @click="closeModal" color="secondary" class="ma-3">
            Close
          </v-btn>
        </v-row>
      </v-container>
    </v-dialog>
    <v-alert
      v-model="notificationUpdated"
      type="success"
      dismissible
      class="mt-3"
    >
      SMS Notification updated successfully!
    </v-alert>
  </v-container>
</template>
<script>
import userApi from "@/api/userApi";
export default {
  name: "ViewProfile",
  props: {
    userData: {
      type: Object,
      required: true,
    },
    visible: {
      type: Boolean,
      required: false,
    },
  },
  components: {},
  data() {
    return {
      userRoles: [],
      eligibleForCommission: "No",
      commissionRate: 0,
      hoverText: "",
      showText: false,
      editMode: false,
      editablefield: "",
      smsNotificationEnabled: false,
      notificationUpdated: false,
      selectedRoles: [],
    };
  },
  mounted() {
    this.setInitialRoles();
  },
  watch: {
    userData: {
      handler() {
        this.setInitialRoles();
      },
      deep: true,
    },
  },
  methods: {
    EligibleCommision() {
      const reqRoles = ["SalesRep", "CallCenter"];
      if (this.userData.roles) {
        const userRoles = this.userData.roles;
        const inMokey = this.existInMonkey;
        this.smsNotificationEnabled = this.userData.smsNotification;
        // eslint-disable-next-line max-len
        if (inMokey == "Yes" && userRoles.some((r) => reqRoles.includes(r))) {
          this.eligibleForCommission = "Yes";
        } else {
          this.eligibleForCommission = "No";
        }
        if (this.eligibleForCommission == "Yes") {
          if (userRoles.includes("SalesRep")) {
            return `Commission Rate: ${3.5}%`;
          } else {
            return `Commission Rate: ${1.5}%`;
          }
        }
      }
    },
    setInitialRoles() {
      if (this.userData.roles && this.userData.roles.length > 0) {
        // eslint-disable-next-line max-len
        const roleIds = this.rolesList.filter((role) => {
          this.userData.roles.includes(role.name);
        }).map((role) => {
          role.id;
        });
        // eslint-disable-next-line max-len
        this.selectedRoles = roleIds;
      } else {
        this.selectedRoles = [];
      }
    },
    async updateNotificationStatus(enabled) {
      try {
        // eslint-disable-next-line max-len
        userApi.upsertNotificationFlag(this.userData.cityEmailAddress, enabled).then((response) => {
          if (response.status == 200) {
            this.notificationUpdated = true;
            // Automatically hide the alert after a few seconds (optional)
            setTimeout(() => {
              this.notificationUpdated = false;
            }, 1000);
            this.pullRoles();
          }
        }),
        (error) => {
          console.log(`Failed to assign roles: ${error}`);
        };
      } catch (error) {
        console.error("Error updating notification status: ", error);
      }
    },
    ShowHoverMessage() {
      this.showText = true;
      let msg = "";
      if (this.userData.monkeyDetails.length == 0) {
        msg += "Monkey Media and CCQ email must match. \n";
      }
      if (this.eligibleForCommission == "No") {
        // eslint-disable-next-line max-len
        msg += "Must have one of the security groups, either “SalesRep”, or “CallCenter.";
      }
      this.hoverText = msg;
    },
    viewModal(field) {
      this.editMode = true;
      this.editablefield = field;
    },
    async submitRoles() {
      if (!this.selectedRoles || this.selectedRoles.length === 0) {
        console.log("Please select at least one role.");
        return;
      }
      const azureAD = true;
      const roles = this.selectedRoles;
      // eslint-disable-next-line max-len
      userApi.postUserRoles(roles, this.userData.cityEmailAddress, azureAD).then((response) => {
        this.closeModal();
        this.pullRoles();
      }),
      (error) => {
        console.log(`Failed to assign roles: ${error}`);
      };
    },
    closeModal() {
      this.editMode = false;
      this.$emit("close");
    },
    async pullRoles() {
      const email = this.userData.cityEmailAddress;
      await userApi.getUserRoles(email).then((response) => {
        this.userData.roles = response.data;
        // eslint-disable-next-line arrow-parens
        const roleIds = this.rolesList.filter(role => this.userData.roles.
            includes(role.name))
        // eslint-disable-next-line arrow-parens
            .map(role => role.id);
        console.log("role::", roleIds);
        this.selectedRoles = roleIds;
      }),
      (error) => {
        console.log(`Failed to assign roles: ${error}`);
      };
    },
  },
  computed: {
    show: {
      get() {
        return this.visible;
      },
    },
    openModal: {
      get() {
        return this.editMode;
      },
      set(value) {
        if (!value) {
          this.$emit("close");
        }
      },
    },
    // selectedRoles: {
    //   get() {
    //     if (this.userData.roles) {
    //       return this.userData.roles.map((role) => {
    //         role.id || [];
    //       });
    //     }
    //     return [];
    //   },
    //   set(value) {
    //     if (this.rolesList) {
    //       // eslint-disable-next-line max-len
    //       this.userData.roles = this.rolesList.filter((role) => {
    //         value.includes(role.id);
    //       });
    //     }
    //   },
    // },
    locations() {
      return this.userData.cityLocations;
    },
    jobcodes() {
      return this.userData.cityJobCodes;
    },
    roles() {
      return this.userData.roles;
    },
    rolesList() {
      return this.userData.rolesList;
    },
    existInMonkey() {
      // eslint-disable-next-line max-len
      if (Object.keys(this.userData).length > 0 && this.userData.monkeyDetails.length > 0) {
        return "Yes";
      } else {
        return "No";
      }
    },
    approvedDriverText() {
      if ( this.userData.approvedDriver) {
        return "Yes";
      } else {
        return "No";
      }
    },
  },
};
</script>
<style scoped>
  ul {
    list-style-type: square;
  }
  .profile-parent-div li {
    margin: 2px 0;
  }
  .profile-parent-div {
    background-color: #ffffff;
    border: 1px solid#cec3ba !important;
    height: auto;
  }
  .profile-parent-div div {
    font-size: 16px;
    color: #000000DE
  }
  .v-tooltip__content {
    background: black !important;
  }
  #condition {
    color: blue;
    animation: blink 1s linear infinite;
    font-size: larger;
  }
  @keyframes blinker {
    50% {
      opacity: 0;
    }
  }
  .hover-text {
    position: absolute;
    background: black;
    color: white !important;
    padding: 5px;
    border-radius: 5px;
    z-index: 100;
  }
  .view-link {
    color: blue;
  }
  .group-form {
    background: white;
  }
  .v-list-item {
    min-height: 32px !important;
  }
  .editIcon {
    color: blue !important;
    font-size: 20px;
  }
  .v-application .primary--text {
    color: black !important;
  }
  .v-input--selection-controls {
    margin-top: 0px !important;
  }
</style>

var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-container",
    { attrs: { fluid: "", "fill-height": "" } },
    [
      _c(
        "v-layout",
        { attrs: { flex: "", "align-center": "", "justify-center": "" } },
        [
          _vm.showEmployeeLogin
            ? _c(
                "v-flex",
                { attrs: { xs12: "", sm10: "", md6: "", lg4: "", xl3: "" } },
                [
                  _c(
                    "v-card",
                    { attrs: { tile: "" } },
                    [
                      _c(
                        "v-toolbar",
                        { attrs: { dense: "", color: "primary" } },
                        [_c("v-toolbar-title", [_vm._v("Login")])],
                        1
                      ),
                      _c("v-divider"),
                      _c("v-card-text", { staticClass: "pt-4" }, [
                        _c(
                          "div",
                          [
                            _c(
                              "v-form",
                              {
                                ref: "form",
                                model: {
                                  value: _vm.valid,
                                  callback: function($$v) {
                                    _vm.valid = $$v
                                  },
                                  expression: "valid"
                                }
                              },
                              [
                                _c("v-text-field", {
                                  attrs: {
                                    label: "Email",
                                    type: "email",
                                    required: "",
                                    rules: _vm.emailRules
                                  },
                                  nativeOn: {
                                    keyup: function($event) {
                                      if (
                                        !$event.type.indexOf("key") &&
                                        _vm._k(
                                          $event.keyCode,
                                          "enter",
                                          13,
                                          $event.key,
                                          "Enter"
                                        )
                                      ) {
                                        return null
                                      }
                                      return _vm.login($event)
                                    }
                                  },
                                  model: {
                                    value: _vm.credentials.email,
                                    callback: function($$v) {
                                      _vm.$set(_vm.credentials, "email", $$v)
                                    },
                                    expression: "credentials.email"
                                  }
                                }),
                                _c("v-text-field", {
                                  attrs: {
                                    label: "Password",
                                    min: "8",
                                    "append-icon": _vm.e1
                                      ? "visibility"
                                      : "visibility_off",
                                    type: _vm.e1 ? "password" : "text",
                                    rules: _vm.passwordRules,
                                    required: ""
                                  },
                                  on: {
                                    "click:append": function() {
                                      return (_vm.e1 = !_vm.e1)
                                    }
                                  },
                                  nativeOn: {
                                    keyup: function($event) {
                                      if (
                                        !$event.type.indexOf("key") &&
                                        _vm._k(
                                          $event.keyCode,
                                          "enter",
                                          13,
                                          $event.key,
                                          "Enter"
                                        )
                                      ) {
                                        return null
                                      }
                                      return _vm.login($event)
                                    }
                                  },
                                  model: {
                                    value: _vm.credentials.password,
                                    callback: function($$v) {
                                      _vm.$set(_vm.credentials, "password", $$v)
                                    },
                                    expression: "credentials.password"
                                  }
                                })
                              ],
                              1
                            )
                          ],
                          1
                        )
                      ]),
                      _c(
                        "v-card-actions",
                        [
                          _c(
                            "v-btn",
                            {
                              class: {
                                accent: _vm.valid
                              },
                              attrs: { disabled: !_vm.valid },
                              on: { click: _vm.login }
                            },
                            [_vm._v(" Employee Login ")]
                          ),
                          _c("v-spacer"),
                          _c(
                            "router-link",
                            { attrs: { to: "/forgotpassword" } },
                            [_vm._v("Forgot Password")]
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            : _c(
                "v-flex",
                { attrs: { xs12: "", sm10: "", md6: "", lg4: "", xl3: "" } },
                [
                  _c(
                    "v-card",
                    { attrs: { tile: "" } },
                    [
                      _c("v-card-title", [
                        _vm._v(" Welcome to City Catering Que ")
                      ]),
                      _c("v-card-text", [
                        _vm._v(" Choose your sign-in method: "),
                        _c(
                          "div",
                          { attrs: { align: "middle", justify: "center" } },
                          [
                            _c(
                              "v-btn",
                              {
                                staticClass: "ma-1 px-1",
                                attrs: { tile: "", outlined: "" },
                                on: {
                                  click: function($event) {
                                    _vm.showEmployeeLogin = !_vm.showEmployeeLogin
                                  }
                                }
                              },
                              [
                                _c(
                                  "v-icon",
                                  {
                                    staticClass: "pr-1",
                                    attrs: { color: "black" }
                                  },
                                  [_vm._v("mdi-account-circle")]
                                ),
                                _vm._v(" Sign in as Employee ")
                              ],
                              1
                            )
                          ],
                          1
                        ),
                        _c(
                          "div",
                          { attrs: { align: "middle", justify: "center" } },
                          [
                            _c(
                              "v-btn",
                              {
                                staticClass: "ma-1 px-1",
                                attrs: { tile: "", outlined: "" },
                                on: { click: _vm.azureLogin }
                              },
                              [
                                _c(
                                  "v-icon",
                                  {
                                    staticClass: "pr-1",
                                    attrs: { color: "accent" }
                                  },
                                  [_vm._v("mdi-grill")]
                                ),
                                _vm._v(" Sign in with City BBQ Account ")
                              ],
                              1
                            )
                          ],
                          1
                        )
                      ])
                    ],
                    1
                  )
                ],
                1
              )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
<template>
  <v-layout v-resize="onResize" column>
    <v-data-table
      class="elevation-2 minimum-height test123"
      :headers="isMobile ? mobileHeaders : headers"
      :items="orderList"
      :search="search"
      :sort-by="sortBy"
      :sort-desc="sortOrder"
      :hide-default-header="isMobile"
      :class="{ mobile: isMobile }"
      :items-per-page="parseInt(itemsPerPage)"
      @update:items-per-page="handlePageChange($event)"
    >
    <template v-slot:header.name>
      <button @click="filterClick('name')">Name</button>
    </template>
    <template v-slot:header.startDateTime>
      <button @click="filterClick('startDateTime')">Start</button>
    </template>
    <template v-slot:header.expectedEnd>
      <button @click="filterClick('expectedEnd')">Expected End</button>
    </template>
    <template v-slot:header.assignedCount>
      <button @click="filterClick('assignedCount')">Assigned</button>
    </template>
    <template v-slot:header.orderClass>
      <button @click="filterClick('orderClass')">Type</button>
    </template>
    <template v-slot:header.status>
      <button @click="filterClick('status')">Status</button>
    </template>
    <template v-slot:header.market>
      <button @click="filterClick('market')">Market</button>
    </template>
    <template v-slot:header.isDocument>
      <button @click="filterClick('isDocument')">Content Atttached</button>
    </template>
      <template v-slot:body="props">
        <tbody v-if="!orderList || orderList.length < 1">
          <tr class="v-data-table__empty-wrapper">
            <td colspan="6">
              {{ isSchedule ? "No shifts scheduled." : "No orders found." }}
            </td>
          </tr>
        </tbody>
        <tbody v-else-if="!isMobile">
          <tr v-for="(item, index) in props.items" :key="index">
            <td class="text-xs-right">
              <CancelIcon
              v-show="item.isDeleted"
              class="vertical-align-middle"
              />
              <EzCaterIcon
                v-show="item.ezCaterOrderId"
                class="vertical-align-middle"
              />
              <QuoteIcon
                v-show="item.type === quote"
                class="vertical-align-middle"
              />
              <WeddingIcon
                v-if="item.format && item.format === wedding"
                class="vertical-align-middle"
              />
              {{ item.name }}
            </td>
            <td class="text-xs-right">
              {{ getStartDateTime(item) }}
            </td>
            <td class="text-xs-right">
              {{ getEndDateTime(item) }}
            </td>
            <td class="text-xs-right" :hidden="isSchedule">
              <v-tooltip bottom>
                <template v-slot:activator="{ on }">
                  <span
                    v-on="on"
                    :hidden="item.assignedCount > 0"
                    class="font-weight-black accent--text"
                    >{{ item.assignedCount }}</span
                  >
                  <span v-on="on" :hidden="item.assignedCount < 1">{{
                    item.assignedCount
                  }}</span>
                </template>
                <div>
                  <pre>{{ getAssignedUsers(item) }}</pre>
                </div>
              </v-tooltip>
            </td>
            <td class="text-xs-right">{{ item.orderClass }}</td>
            <td class="text-xs-right">{{ item.status }}</td>
            <td class="text-xs-right">
              {{ item.market }}
            </td>
            <td v-if="currentPath == 'ScheduleEmployees'" class="text-xs-right">
              <v-img
                  class="mx-2 assignImage"
                  @click="getQuickAddPopup(item)"
                  src="@/assets/Calendar.jpg"
                  max-height="30"
                  max-width="30"
                  contain
                >
              </v-img>
            </td>
            <td class="text-xs-right">
              <v-checkbox
                :input-value="item.isDocument"
                readonly="true"
              ></v-checkbox>
            </td>
            <td>
              <v-icon class="accent--text" @click="selectRow(item)">
                mdi-chevron-right mdi-36px
              </v-icon>
            </td>
          </tr>
        </tbody>
        <tbody v-else-if="isMobile">
          <tr v-for="(item, index) in props.items" :key="index">
            <td class="pa-1">
              <ul class="flex-content">
                <li class="flex-item pa-0" data-label="Name">
                  <span
                    v-show="item.ezCaterOrderId"
                    class="font-weight-black accent--text"
                    >EZ
                  </span>
                  {{ item.name }}
                </li>
                <li class="flex-item pa-0" data-label="Market">
                  {{ item.market }}
                </li>
                <li class="flex-item pa-0" data-label="Start">
                  {{ getStartDateTime(item) }}
                </li>
                <li class="flex-item pa-0" data-label="Assigned">
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on }">
                      <span v-on="on" class="font-weight-black accent--text">{{
                        item.assignedCount
                      }}</span>
                    </template>
                    <div>
                      <pre>{{ getAssignedUsers(item) }}</pre>
                    </div>
                  </v-tooltip>
                </li>
                <!-- Use this to keep the button in place. -->
                <li class="flex-item pa-0" :hidden="!isSchedule"></li>
                <li class="flex-item pa-0">
                  <v-img
                  class="mx-2 assignImage"
                  @click="getQuickAddPopup(item)"
                  src="@/assets/Calendar.jpg"
                  max-height="30"
                  max-width="30"
                  contain
                ></v-img>
                </li>
                <li class="flex-item pa-0 text-end">
                  <v-icon class="accent--text" @click="selectRow(item)">
                    mdi-chevron-right mdi-36px
                  </v-icon>
                </li>
              </ul>
            </td>
          </tr>
        </tbody>
      </template>
    </v-data-table>
    <div>
      <v-dialog :value="show" persistent>
        <v-col class="assignPopup" cols="12">
        <Assignments :isExported="isExported"
        :order="order" :isAdminAssignments="false" :calledFrom="'Grid'"
        @updatePage="updatePage" @close="close" />
      </v-col>
      </v-dialog>
    </div>
  </v-layout>
</template>

<script>
import UserTime from "@/helpers/userTime";
import router from "@/router/index.js";
import {iconMixin} from "@/mixins/iconMixin.js";
import Assignments from "@/components/Assignments.vue";
import {mapGetters} from "vuex";

export default {
  mixins: [iconMixin],
  name: "OrdersGrid",
  props: {
    orderList: {
      type: Array,
      required: true,
    },
    isSchedule: {
      type: Boolean,
      required: true,
    },
    visible: {
      type: Boolean,
      required: false,
    },
  },
  components: {
    Assignments,
  },
  data() {
    return {
      isMobile: false,
      dateTimeMaxValue: "9999-12-31T23:59:59.9999999",
      selectedOrder: {},
      isExported: false,
      sortOrder: true,
      sortBy: "start",
      itemsPerPage: 15,
      currentPath: "",
    };
  },
  mounted() {
    if (this.$cookie.get("sortBy")) {
      this.sortBy = this.$cookie.get("sortBy");
      this.sortOrder = this.$cookie.get("sortOrder");
      this.sortOrder = JSON.parse(this.sortOrder);
    }
    if (this.$cookie.get("perPageItems")) {
      this.itemsPerPage = this.$cookie.get("perPageItems");
    }
    this.currentPath = this.$route.name.replace(" ", "");
  },
  computed: {
    ...mapGetters({
      orderFilters: "getOrderFilters",
    }),
    headers() {
      let headersList = [
        {text: "Name", value: "name"},
        {text: "Start", value: "startDateTime"},
        {text: "Expected End", value: "expectedEnd"},
        {
          text: "Assigned",
          value: "assignedCount",
          align: this.isSchedule ? " d-none" : " ",
        },
        {text: "Type", value: "orderClass"},
        {text: "Status", value: "status"},
        {text: "Market", value: "market"},
        {text: "Assign", value: "addEmployees"},
        {text: "Content Atttached", value: "isDocument"},
        {text: "", value: "actions", sortable: false},
      ];
      if (this.currentPath != "ScheduleEmployees") {
        headersList = headersList.filter((obj) => {
          return obj.value != "addEmployees";
        });
      }
      return headersList;
    },
    mobileHeaders() {
      return [
        {text: "Name", value: "name"},
        {text: "Market", value: "market"},
        {text: "Start", value: "startDateTime"},
        {
          text: "Assigned",
          value: "assignedCount",
          align: this.isSchedule ? " d-none" : " ",
        },
        {text: "Assign", value: "addEmployees"},
      ];
    },
    show: {
      get() {
        return this.visible;
      },
      set(value) {
        if (!value) {
          this.$emit("close");
        }
      },
    },
    order: {
      get() {
        return this.selectedOrder;
      },
    },
    perPage() {
      return this.itemsPerPage;
    },
  },
  methods: {
    onResize() {
      if (window.innerWidth < 769) {
        this.isMobile = true;
      } else {
        this.isMobile = false;
      }
    },
    handlePageChange(event) {
      this.$cookie.set("perPageItems", event);
    },
    filterClick(clickedHeader) {
      // eslint-disable-next-line max-len
      const sortBy = this.$cookie.get("sortBy");
      if (clickedHeader === sortBy) {
        this.$cookie.set("sortOrder", !this.sortOrder);
      } else {
        this.$cookie.set("sortOrder", false);
      }
      this.$cookie.set("sortBy", clickedHeader);
    },
    updatePage() {
      this.$emit("getOrders", this.orderFilters);
    },
    getAssignedCount(order) {
      if (order.orderUsers) {
        return order.orderUsers.length;
      }
      return 0;
    },
    getAssignedUsers(order) {
      let ret = "";
      if (order.orderUsers && order.orderUsers.length) {
        order.orderUsers.forEach((ou) => {
          if (ou.user) {
            ret += `${ou.user.firstName} ${ou.user.lastName}\r\n`;
          }
        });
      }
      return ret;
    },
    getStartDateTime(order) {
      if (this.isMobile) {
        return order.startDateTime ?
          `${UserTime.getShortDateTime(order.startDateTime)} ${
            order.startDateTimeZone
          }` :
          "";
      } else {
        return order.startDateTime ?
          `${UserTime.getLongDateTime(order.startDateTime)} ${
            order.startDateTimeZone
          }` :
          "";
      }
    },
    getEndDateTime(order) {
      return order.startDateTime === order.expectedEnd ||
        !order.expectedEnd ||
        order.expectedEnd === this.dateTimeMaxValue ?
        UserTime.NoTimeSet :
        `${UserTime.getLongTime(order.expectedEnd)} ${
          order.startDateTimeZone
        }`;
    },
    selectRow(order) {
      router.push({
        name: this.isSchedule ? "ScheduleDetails" : "OrderDetails",
        params: {orderId: order.cityOrderId},
      });
    },
    getQuickAddPopup(order) {
      this.visible = true;
      this.selectedOrder = order;
    },
    close() {
      this.visible = false;
    },
  },
  watch: {
    orderList: {
      handler: function(val, oldVal) {
        this.orderList.forEach((o) => {
          o.assignedCount = this.getAssignedCount(o);
        });
      },
    },
  },
};
</script>
<style scoped>
@media screen and (max-width: 768px) {
  .mobile table tbody tr {
    max-width: 100%;
    position: relative;
    display: block;
  }
  .mobile table tbody tr td {
    display: flex;
    width: 100%;
    border-bottom: 1px solid #f5f5f5;
    height: auto;
    padding: 10px;
  }
  .mobile table tbody tr td ul li:before {
    content: attr(data-label);
    padding-right: 0.5em;
    text-align: left;
    display: block;
    color: #999;
  }
  .v-datatable__actions__select {
    width: 50%;
    margin: 0px;
    justify-content: flex-start;
  }
  .mobile .theme--light tbody tr:hover:not(.v-datatable__expand-row) {
    background: transparent;
  }
}
.flex-content {
  padding: 0;
  margin: 0;
  list-style: none;
  display: flex;
  flex-wrap: wrap;
  width: 100%;
}
.flex-item {
  padding: 5px;
  width: 50%;
  font-weight: bold;
}
.mdi-chevron-double-right {
  vertical-align: middle;
}
.minimum-height {
  min-height: 400px;
}
.vertical-align-middle {
  vertical-align: middle;
}
.assignPopup {
  background-color: #f5f5f5;
}
.assignImage {
  cursor: pointer;
}
</style>

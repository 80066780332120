var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "svg",
    {
      attrs: {
        version: "1.1",
        id: "Layer_1",
        xmlns: "http://www.w3.org/2000/svg",
        "xmlns:xlink": "http://www.w3.org/1999/xlink",
        x: "0px",
        y: "0px",
        width: "20px",
        height: "20px",
        viewBox: "0 0 20 20",
        "enable-background": "new 0 0 20 20",
        "xml:space": "preserve"
      }
    },
    [
      _c("image", {
        attrs: {
          id: "image0",
          width: "20",
          height: "20",
          x: "0",
          y: "0",
          "xlink:href":
            "\ndata:image/png;base64,\niVBORw0KGgoAAAANSUhEUgAAABAAAAAQCAMAAAAoLQ9TAAAABGdBTUEAALGPC/xhBQAAACBjSFJN\nAAB6JgAAgIQAAPoAAACA6AAAdTAAAOpgAAA6mAAAF3CculE8AAABKVBMVEUJtncGtXUFtHUAs3IA\nsnEAsnAAs3EBsG0Wtncju4EQtHMAsW4EtHMUtXYVtXcTtXUItHMFsW5izKC36NS76NWr5M04voYf\nuHum5My26NW26NTI7d9bzKAAsG1HxJG+6tg+vYUTsG1rzaK16NQctngrvYMuun9+1LDT8eY4v4gA\nsW8FtXUAr2yN3L7P8OOA1rJ/2LaR3L7e9u4ArGVAv4nE7N1PxZUAsGyO3L/J7t910Kd106100apl\nzKEZtni96thozKECrWhLxZPG7d1Nwo4ZtHRayJlgy58ftHS76del4sk1u4ExvoYduX0GsW9ozqPE\n7Nyz59JRxpU2vIS86tm66de06NS56dZr0aoCsG0Vtnchu4ARtHQBsW4Es3MStXUVtXYMtHX///9H\nF9lRAAAAAWJLR0RiK7kdPAAAAAd0SU1FB+QFFhIaK/+gAIIAAACjSURBVBjTY2BgRAEMDEzMKICJ\ngRFVgBEswMLKxszMBgRQAXYOTi5uHl4OPn4BsICgkLCIqJi4hKSUtIwsUIBbTl5BUUlZRVVNXUNT\ni5FBW0dXT9/A0EjT2MTUzJwZJGBhaWVtY2unZu/gyAoyg9vJ2cXVzd3D08vbx5cFZKifv31AYFBw\nSGhYeESkNsjayKjomNi4+AQOjkQcDkN1Oobn0L0PABx/FZSRbHh5AAAAJXRFWHRkYXRlOmNyZWF0\nZQAyMDIwLTA1LTIyVDE4OjI2OjQzKzAzOjAwVOMe+AAAACV0RVh0ZGF0ZTptb2RpZnkAMjAyMC0w\nNS0yMlQxODoyNjo0MyswMzowMCW+pkQAAAAASUVORK5CYII="
        }
      })
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
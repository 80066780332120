import { render, staticRenderFns } from "./CancelIcon.vue?vue&type=template&id=1612eac3&"
var script = {}


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/home/vsts/work/1/s/frontend/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('1612eac3')) {
      api.createRecord('1612eac3', component.options)
    } else {
      api.reload('1612eac3', component.options)
    }
    module.hot.accept("./CancelIcon.vue?vue&type=template&id=1612eac3&", function () {
      api.rerender('1612eac3', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/components/CancelIcon.vue"
export default component.exports
var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-card",
    { staticClass: "no-margin", attrs: { tile: "" } },
    [
      _c(
        "v-card-text",
        { staticClass: "pa-0 pb-1" },
        [
          _c(
            "v-tabs",
            {
              attrs: {
                "fixed-tabs": "",
                "background-color": "primary darken-1",
                "hide-slider": "true",
                "active-class": "primary black--text"
              },
              model: {
                value: _vm.tab,
                callback: function($$v) {
                  _vm.tab = $$v
                },
                expression: "tab"
              }
            },
            [
              _c(
                "v-tab",
                { attrs: { href: "#tab-1" } },
                [
                  _vm._v(" Active "),
                  _c("v-icon", { attrs: { left: "" } }, [
                    _vm._v("mdi-bell-ring-outline")
                  ]),
                  _vm.activeActions.length
                    ? _c("v-badge", {
                        staticClass: "justify-start",
                        attrs: {
                          color: "accent",
                          content: _vm.activeActions.length,
                          inline: "",
                          bordered: ""
                        }
                      })
                    : _vm._e()
                ],
                1
              ),
              _c(
                "v-tab",
                { attrs: { href: "#tab-2" } },
                [
                  _vm._v(" Hidden "),
                  _c("v-icon", { attrs: { left: "" } }, [
                    _vm._v("mdi-bell-off-outline")
                  ]),
                  _vm.hiddenActions.length
                    ? _c("v-badge", {
                        staticClass: "justify-start",
                        attrs: {
                          color: "accent",
                          content: _vm.hiddenActions.length,
                          inline: "",
                          bordered: ""
                        }
                      })
                    : _vm._e()
                ],
                1
              )
            ],
            1
          ),
          _c(
            "v-tabs-items",
            {
              model: {
                value: _vm.tab,
                callback: function($$v) {
                  _vm.tab = $$v
                },
                expression: "tab"
              }
            },
            [
              _c(
                "v-tab-item",
                { attrs: { value: "tab-1" } },
                [
                  _c("ActionItemsList", {
                    attrs: { actions: _vm.activeActions, "is-hidden": false }
                  })
                ],
                1
              ),
              _c(
                "v-tab-item",
                { attrs: { value: "tab-2" } },
                [
                  _c("ActionItemsList", {
                    attrs: { actions: _vm.hiddenActions, "is-hidden": true }
                  })
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
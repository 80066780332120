import moment from "moment";

export default {
  LanguageCode: "en-US",
  Timezone: new Intl.DateTimeFormat("en-US").resolvedOptions().timeZone,
  NoTimeSet: "Not Set",
  Placeholder: "0001-01-01T00:00:00",
  CalendarMaxDate: "2030-12-31",
  CalendarMinDate: "2000-01-01",
  MaxDateTime: moment("9999/12/31", "YYYY/MM/DD"),
  MinDateTime: moment("0001/01/01", "YYYY/MM/DD"),

  now() {
    return moment().format("l LTS");
  },
  // UTC
  getUtcLongDateTime(date) {
    if (this.isBadDate(date)) return "";
    return moment.utc(date).local().format("LLL");
  },
  getUtcLongTime(date) {
    if (this.isBadDate(date)) return "";
    return moment.utc(date).local().format("LTS");
  },
  getUtcShortTime(date) {
    if (this.isBadDate(date)) return "";
    return moment.utc(date).local().format("LT");
  },
  // DateTime
  getLongDateTime(date) {
    if (this.isBadDate(date)) return "";
    return moment(date).format("LLL");
  },
  getShortDateTime(date) {
    if (this.isBadDate(date)) return "";
    return moment(date).format("l LT");
  },
  getXLongDateTime(date) {
    if (this.isBadDate(date)) return "";
    return moment(date).format("LLLL");
  },
  getCalendarTimestamp(date) {
    if (this.isBadDate(date)) return "";
    return moment(date).format("YYYY-MM-DD HH:mm");
  },
  // Date
  getLongDate(date) {
    if (this.isBadDate(date)) return "";
    return moment(date).format("LL");
  },
  getShortDate(date) {
    if (this.isBadDate(date)) return "";
    return moment(date).format("l");
  },
  getXLongDate(date) {
    if (this.isBadDate(date)) return "";
    return moment(date).format("dddd, MMMM Do YYYY");
  },
  getIso8601Date(date) {
    if (this.isBadDate(date)) return "";
    return moment(date).format("YYYY-MM-DD");
  },
  // Time
  getLongTime(date) {
    if (this.isBadDate(date)) return "";
    return moment(date).format("LTS");
  },
  getShortTime(date) {
    if (this.isBadDate(date)) return "";
    return moment(date).format("LT");
  },
  get24HourTime(date) {
    if (this.isBadDate(date)) return "";
    return moment(date).format("HH:mm");
  },
  formatTimeToDateTime(date, time) {
    const dateAndTime = moment(new Date(date + "T" + time));
    return dateAndTime;
  },
  getDateTimeFromDateAndTime(date, time) {
    return moment(`${date} ${time}`);
  },
  isBadDate(date) {
    return (
      !date ||
      moment(this.MaxDateTime).isSame(date, "year") ||
      moment(this.MinDateTime).isSame(date, "year")
    );
  },
};

var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-app",
    [
      _c(
        "v-navigation-drawer",
        {
          attrs: { "enable-resize-watcher": "", app: "" },
          model: {
            value: _vm.drawer,
            callback: function($$v) {
              _vm.drawer = $$v
            },
            expression: "drawer"
          }
        },
        [
          _c(
            "v-list",
            { staticClass: "py-0", attrs: { dense: "", nav: "" } },
            [
              _c(
                "v-list-item",
                {
                  staticClass: "px-0 hidden-sm-and-up",
                  attrs: { "two-line": "" }
                },
                [
                  _c(
                    "v-list-item-content",
                    [
                      _c("v-list-item-title", {
                        directives: [
                          {
                            name: "show",
                            rawName: "v-show",
                            value: _vm.isAuthenticated,
                            expression: "isAuthenticated"
                          }
                        ],
                        domProps: {
                          textContent: _vm._s(
                            _vm.currentUser.firstName &&
                              _vm.currentUser.lastName
                              ? _vm.currentUser.firstName +
                                  " " +
                                  _vm.currentUser.lastName
                              : ""
                          )
                        }
                      }),
                      _c("v-list-item-subtitle", {
                        directives: [
                          {
                            name: "show",
                            rawName: "v-show",
                            value: _vm.isAuthenticated,
                            expression: "isAuthenticated"
                          }
                        ],
                        domProps: { textContent: _vm._s(_vm.currentUser.email) }
                      })
                    ],
                    1
                  )
                ],
                1
              ),
              _c("v-divider"),
              _vm._l(_vm.navItems, function(item, index) {
                return _c(
                  "v-list-group",
                  {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: _vm.showNavItem(item),
                        expression: "showNavItem(item)"
                      }
                    ],
                    key: index,
                    attrs: {
                      appendIcon: item.subList ? "arrow_drop_down" : ""
                    },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "activator",
                          fn: function() {
                            return [
                              _c(
                                "v-list-item",
                                {
                                  attrs: {
                                    to: item.path == "#" ? "" : item.path,
                                    link: "",
                                    id: "navList"
                                  }
                                },
                                [
                                  _c(
                                    "v-list-item-icon",
                                    [_c("v-icon", [_vm._v(_vm._s(item.icon))])],
                                    1
                                  ),
                                  _c(
                                    "v-list-item-content",
                                    [
                                      _c("v-list-item-title", [
                                        _vm._v(_vm._s(item.title))
                                      ])
                                    ],
                                    1
                                  )
                                ],
                                1
                              )
                            ]
                          },
                          proxy: true
                        }
                      ],
                      null,
                      true
                    )
                  },
                  _vm._l(item.subList, function(subItem) {
                    return _c(
                      "v-list-item",
                      {
                        directives: [
                          {
                            name: "show",
                            rawName: "v-show",
                            value: _vm.showNavItem(subItem),
                            expression: "showNavItem(subItem)"
                          }
                        ],
                        key: subItem.title,
                        staticClass: "pl-5",
                        attrs: {
                          to: subItem.path == "#" ? "" : subItem.path,
                          link: ""
                        }
                      },
                      [
                        _c(
                          "v-list-item-icon",
                          [_c("v-icon", [_vm._v(_vm._s(subItem.icon))])],
                          1
                        ),
                        _c(
                          "v-list-item-content",
                          [
                            _c("v-list-item-title", [
                              _vm._v(_vm._s(subItem.title))
                            ])
                          ],
                          1
                        )
                      ],
                      1
                    )
                  }),
                  1
                )
              }),
              _c("v-divider"),
              _c(
                "v-list-item",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: !_vm.isAuthenticated,
                      expression: "!isAuthenticated"
                    }
                  ],
                  attrs: { link: "", to: "/login" }
                },
                [
                  _c(
                    "v-list-item-icon",
                    [_c("v-icon", [_vm._v("mdi-login")])],
                    1
                  ),
                  _c(
                    "v-list-item-content",
                    [_c("v-list-item-title", [_vm._v("Login")])],
                    1
                  )
                ],
                1
              ),
              _c(
                "v-list-item",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.isAuthenticated,
                      expression: "isAuthenticated"
                    }
                  ],
                  attrs: { link: "" },
                  on: {
                    click: function($event) {
                      return _vm.logout()
                    }
                  }
                },
                [
                  _c(
                    "v-list-item-icon",
                    [_c("v-icon", [_vm._v("mdi-logout")])],
                    1
                  ),
                  _c(
                    "v-list-item-content",
                    [_c("v-list-item-title", [_vm._v("Logout")])],
                    1
                  )
                ],
                1
              )
            ],
            2
          )
        ],
        1
      ),
      _c(
        "v-app-bar",
        { staticClass: "secondary", attrs: { app: "" } },
        [
          _c("v-app-bar-nav-icon", {
            staticStyle: { color: "#fff" },
            on: {
              click: function($event) {
                $event.stopPropagation()
                _vm.drawer = !_vm.drawer
              }
            }
          }),
          _c("v-img", {
            staticClass: "mx-2",
            attrs: {
              src: require("@/assets/logo-citybbq-white.png"),
              "max-height": "40",
              "max-width": "180",
              contain: ""
            }
          }),
          _c("v-spacer"),
          _c(
            "v-list-item",
            [
              _c(
                "v-list-item-content",
                {
                  staticClass: "hidden-xs-only",
                  staticStyle: { "text-align": "end" },
                  attrs: { "two-line": "" }
                },
                [
                  _c("v-list-item-title", {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: _vm.isAuthenticated,
                        expression: "isAuthenticated"
                      }
                    ],
                    staticStyle: { color: "#fff" },
                    domProps: {
                      textContent: _vm._s(
                        _vm.currentUser.firstName && _vm.currentUser.lastName
                          ? _vm.currentUser.firstName +
                              " " +
                              _vm.currentUser.lastName
                          : ""
                      )
                    }
                  }),
                  _c("v-list-item-subtitle", {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: _vm.isAuthenticated,
                        expression: "isAuthenticated"
                      }
                    ],
                    staticStyle: { color: "#fff" },
                    domProps: {
                      textContent: _vm._s(
                        _vm.currentUser.email ? _vm.currentUser.email : ""
                      )
                    }
                  })
                ],
                1
              ),
              _c(
                "v-list-item-icon",
                { staticClass: "hidden-xs-only" },
                [
                  _c(
                    "v-menu",
                    {
                      attrs: { left: "", bottom: "" },
                      scopedSlots: _vm._u([
                        {
                          key: "activator",
                          fn: function(ref) {
                            var on = ref.on
                            return [
                              _c("v-hover", {
                                scopedSlots: _vm._u(
                                  [
                                    {
                                      key: "default",
                                      fn: function(ref) {
                                        var hover = ref.hover
                                        return [
                                          _c(
                                            "v-btn",
                                            _vm._g(
                                              {
                                                directives: [
                                                  {
                                                    name: "show",
                                                    rawName: "v-show",
                                                    value: _vm.isAuthenticated,
                                                    expression:
                                                      "isAuthenticated"
                                                  }
                                                ],
                                                attrs: {
                                                  icon: "",
                                                  elevation: hover ? 8 : 4
                                                }
                                              },
                                              on
                                            ),
                                            [
                                              _c(
                                                "v-icon",
                                                {
                                                  staticStyle: {
                                                    color: "#fff"
                                                  },
                                                  attrs: {
                                                    link: "",
                                                    size: "60"
                                                  }
                                                },
                                                [
                                                  _vm._v(
                                                    "mdi-account-circle-outline"
                                                  )
                                                ]
                                              )
                                            ],
                                            1
                                          )
                                        ]
                                      }
                                    }
                                  ],
                                  null,
                                  true
                                )
                              })
                            ]
                          }
                        }
                      ])
                    },
                    [
                      _c(
                        "v-list",
                        [
                          _c(
                            "v-list-item",
                            [
                              _c("v-list-item-content", [
                                _c("span", [
                                  _vm._v(_vm._s(_vm.currentUser.email))
                                ])
                              ])
                            ],
                            1
                          ),
                          _c("v-divider"),
                          _c(
                            "v-list-item",
                            {
                              directives: [
                                {
                                  name: "show",
                                  rawName: "v-show",
                                  value: _vm.isAuthenticated,
                                  expression: "isAuthenticated"
                                }
                              ],
                              attrs: { link: "", to: "/viewProfile" }
                            },
                            [
                              _c(
                                "v-list-item-icon",
                                [_c("v-icon", [_vm._v("mdi-account")])],
                                1
                              ),
                              _c(
                                "v-list-item-content",
                                [
                                  _c("v-list-item-title", [
                                    _vm._v("View User Profile")
                                  ])
                                ],
                                1
                              )
                            ],
                            1
                          ),
                          _c("v-divider"),
                          _c(
                            "v-list-item",
                            {
                              attrs: { link: "" },
                              on: {
                                click: function($event) {
                                  return _vm.logout()
                                }
                              }
                            },
                            [
                              _c(
                                "v-list-item-icon",
                                [_c("v-icon", [_vm._v("mdi-logout")])],
                                1
                              ),
                              _c(
                                "v-list-item-content",
                                [_c("v-list-item-title", [_vm._v("Logout")])],
                                1
                              )
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _c("v-content", [_c("Spinner"), _c("router-view")], 1),
      _c("Snackbar", { ref: "snackbar" }),
      _c(
        "v-footer",
        { attrs: { color: "secondary", padless: "" } },
        [
          _c(
            "v-row",
            { attrs: { justify: "center", "no-gutters": "" } },
            [
              _c(
                "v-col",
                {
                  staticClass: "py-4 text-center white--text",
                  attrs: { cols: "12" }
                },
                [
                  _vm._v(" ©" + _vm._s(new Date().getFullYear()) + " — "),
                  _c("strong", [_vm._v("City Barbeque")])
                ]
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-layout",
    {
      directives: [
        {
          name: "resize",
          rawName: "v-resize",
          value: _vm.onResize,
          expression: "onResize"
        }
      ],
      attrs: { column: "" }
    },
    [
      _c("v-data-table", {
        staticClass: "elevation-2 minimum-height test123",
        class: { mobile: _vm.isMobile },
        attrs: {
          headers: _vm.isMobile ? _vm.mobileHeaders : _vm.headers,
          items: _vm.orderList,
          search: _vm.search,
          "sort-by": _vm.sortBy,
          "sort-desc": _vm.sortOrder,
          "hide-default-header": _vm.isMobile,
          "items-per-page": parseInt(_vm.itemsPerPage)
        },
        on: {
          "update:items-per-page": function($event) {
            return _vm.handlePageChange($event)
          }
        },
        scopedSlots: _vm._u([
          {
            key: "header.name",
            fn: function() {
              return [
                _c(
                  "button",
                  {
                    on: {
                      click: function($event) {
                        return _vm.filterClick("name")
                      }
                    }
                  },
                  [_vm._v("Name")]
                )
              ]
            },
            proxy: true
          },
          {
            key: "header.startDateTime",
            fn: function() {
              return [
                _c(
                  "button",
                  {
                    on: {
                      click: function($event) {
                        return _vm.filterClick("startDateTime")
                      }
                    }
                  },
                  [_vm._v("Start")]
                )
              ]
            },
            proxy: true
          },
          {
            key: "header.expectedEnd",
            fn: function() {
              return [
                _c(
                  "button",
                  {
                    on: {
                      click: function($event) {
                        return _vm.filterClick("expectedEnd")
                      }
                    }
                  },
                  [_vm._v("Expected End")]
                )
              ]
            },
            proxy: true
          },
          {
            key: "header.assignedCount",
            fn: function() {
              return [
                _c(
                  "button",
                  {
                    on: {
                      click: function($event) {
                        return _vm.filterClick("assignedCount")
                      }
                    }
                  },
                  [_vm._v("Assigned")]
                )
              ]
            },
            proxy: true
          },
          {
            key: "header.orderClass",
            fn: function() {
              return [
                _c(
                  "button",
                  {
                    on: {
                      click: function($event) {
                        return _vm.filterClick("orderClass")
                      }
                    }
                  },
                  [_vm._v("Type")]
                )
              ]
            },
            proxy: true
          },
          {
            key: "header.status",
            fn: function() {
              return [
                _c(
                  "button",
                  {
                    on: {
                      click: function($event) {
                        return _vm.filterClick("status")
                      }
                    }
                  },
                  [_vm._v("Status")]
                )
              ]
            },
            proxy: true
          },
          {
            key: "header.market",
            fn: function() {
              return [
                _c(
                  "button",
                  {
                    on: {
                      click: function($event) {
                        return _vm.filterClick("market")
                      }
                    }
                  },
                  [_vm._v("Market")]
                )
              ]
            },
            proxy: true
          },
          {
            key: "header.isDocument",
            fn: function() {
              return [
                _c(
                  "button",
                  {
                    on: {
                      click: function($event) {
                        return _vm.filterClick("isDocument")
                      }
                    }
                  },
                  [_vm._v("Content Atttached")]
                )
              ]
            },
            proxy: true
          },
          {
            key: "body",
            fn: function(props) {
              return [
                !_vm.orderList || _vm.orderList.length < 1
                  ? _c("tbody", [
                      _c("tr", { staticClass: "v-data-table__empty-wrapper" }, [
                        _c("td", { attrs: { colspan: "6" } }, [
                          _vm._v(
                            " " +
                              _vm._s(
                                _vm.isSchedule
                                  ? "No shifts scheduled."
                                  : "No orders found."
                              ) +
                              " "
                          )
                        ])
                      ])
                    ])
                  : !_vm.isMobile
                  ? _c(
                      "tbody",
                      _vm._l(props.items, function(item, index) {
                        return _c("tr", { key: index }, [
                          _c(
                            "td",
                            { staticClass: "text-xs-right" },
                            [
                              _c("CancelIcon", {
                                directives: [
                                  {
                                    name: "show",
                                    rawName: "v-show",
                                    value: item.isDeleted,
                                    expression: "item.isDeleted"
                                  }
                                ],
                                staticClass: "vertical-align-middle"
                              }),
                              _c("EzCaterIcon", {
                                directives: [
                                  {
                                    name: "show",
                                    rawName: "v-show",
                                    value: item.ezCaterOrderId,
                                    expression: "item.ezCaterOrderId"
                                  }
                                ],
                                staticClass: "vertical-align-middle"
                              }),
                              _c("QuoteIcon", {
                                directives: [
                                  {
                                    name: "show",
                                    rawName: "v-show",
                                    value: item.type === _vm.quote,
                                    expression: "item.type === quote"
                                  }
                                ],
                                staticClass: "vertical-align-middle"
                              }),
                              item.format && item.format === _vm.wedding
                                ? _c("WeddingIcon", {
                                    staticClass: "vertical-align-middle"
                                  })
                                : _vm._e(),
                              _vm._v(" " + _vm._s(item.name) + " ")
                            ],
                            1
                          ),
                          _c("td", { staticClass: "text-xs-right" }, [
                            _vm._v(
                              " " + _vm._s(_vm.getStartDateTime(item)) + " "
                            )
                          ]),
                          _c("td", { staticClass: "text-xs-right" }, [
                            _vm._v(" " + _vm._s(_vm.getEndDateTime(item)) + " ")
                          ]),
                          _c(
                            "td",
                            {
                              staticClass: "text-xs-right",
                              attrs: { hidden: _vm.isSchedule }
                            },
                            [
                              _c(
                                "v-tooltip",
                                {
                                  attrs: { bottom: "" },
                                  scopedSlots: _vm._u(
                                    [
                                      {
                                        key: "activator",
                                        fn: function(ref) {
                                          var on = ref.on
                                          return [
                                            _c(
                                              "span",
                                              _vm._g(
                                                {
                                                  staticClass:
                                                    "font-weight-black accent--text",
                                                  attrs: {
                                                    hidden:
                                                      item.assignedCount > 0
                                                  }
                                                },
                                                on
                                              ),
                                              [
                                                _vm._v(
                                                  _vm._s(item.assignedCount)
                                                )
                                              ]
                                            ),
                                            _c(
                                              "span",
                                              _vm._g(
                                                {
                                                  attrs: {
                                                    hidden:
                                                      item.assignedCount < 1
                                                  }
                                                },
                                                on
                                              ),
                                              [
                                                _vm._v(
                                                  _vm._s(item.assignedCount)
                                                )
                                              ]
                                            )
                                          ]
                                        }
                                      }
                                    ],
                                    null,
                                    true
                                  )
                                },
                                [
                                  _c("div", [
                                    _c("pre", [
                                      _vm._v(_vm._s(_vm.getAssignedUsers(item)))
                                    ])
                                  ])
                                ]
                              )
                            ],
                            1
                          ),
                          _c("td", { staticClass: "text-xs-right" }, [
                            _vm._v(_vm._s(item.orderClass))
                          ]),
                          _c("td", { staticClass: "text-xs-right" }, [
                            _vm._v(_vm._s(item.status))
                          ]),
                          _c("td", { staticClass: "text-xs-right" }, [
                            _vm._v(" " + _vm._s(item.market) + " ")
                          ]),
                          _vm.currentPath == "ScheduleEmployees"
                            ? _c(
                                "td",
                                { staticClass: "text-xs-right" },
                                [
                                  _c("v-img", {
                                    staticClass: "mx-2 assignImage",
                                    attrs: {
                                      src: require("@/assets/Calendar.jpg"),
                                      "max-height": "30",
                                      "max-width": "30",
                                      contain: ""
                                    },
                                    on: {
                                      click: function($event) {
                                        return _vm.getQuickAddPopup(item)
                                      }
                                    }
                                  })
                                ],
                                1
                              )
                            : _vm._e(),
                          _c(
                            "td",
                            { staticClass: "text-xs-right" },
                            [
                              _c("v-checkbox", {
                                attrs: {
                                  "input-value": item.isDocument,
                                  readonly: "true"
                                }
                              })
                            ],
                            1
                          ),
                          _c(
                            "td",
                            [
                              _c(
                                "v-icon",
                                {
                                  staticClass: "accent--text",
                                  on: {
                                    click: function($event) {
                                      return _vm.selectRow(item)
                                    }
                                  }
                                },
                                [_vm._v(" mdi-chevron-right mdi-36px ")]
                              )
                            ],
                            1
                          )
                        ])
                      }),
                      0
                    )
                  : _vm.isMobile
                  ? _c(
                      "tbody",
                      _vm._l(props.items, function(item, index) {
                        return _c("tr", { key: index }, [
                          _c("td", { staticClass: "pa-1" }, [
                            _c("ul", { staticClass: "flex-content" }, [
                              _c(
                                "li",
                                {
                                  staticClass: "flex-item pa-0",
                                  attrs: { "data-label": "Name" }
                                },
                                [
                                  _c(
                                    "span",
                                    {
                                      directives: [
                                        {
                                          name: "show",
                                          rawName: "v-show",
                                          value: item.ezCaterOrderId,
                                          expression: "item.ezCaterOrderId"
                                        }
                                      ],
                                      staticClass:
                                        "font-weight-black accent--text"
                                    },
                                    [_vm._v("EZ ")]
                                  ),
                                  _vm._v(" " + _vm._s(item.name) + " ")
                                ]
                              ),
                              _c(
                                "li",
                                {
                                  staticClass: "flex-item pa-0",
                                  attrs: { "data-label": "Market" }
                                },
                                [_vm._v(" " + _vm._s(item.market) + " ")]
                              ),
                              _c(
                                "li",
                                {
                                  staticClass: "flex-item pa-0",
                                  attrs: { "data-label": "Start" }
                                },
                                [
                                  _vm._v(
                                    " " +
                                      _vm._s(_vm.getStartDateTime(item)) +
                                      " "
                                  )
                                ]
                              ),
                              _c(
                                "li",
                                {
                                  staticClass: "flex-item pa-0",
                                  attrs: { "data-label": "Assigned" }
                                },
                                [
                                  _c(
                                    "v-tooltip",
                                    {
                                      attrs: { bottom: "" },
                                      scopedSlots: _vm._u(
                                        [
                                          {
                                            key: "activator",
                                            fn: function(ref) {
                                              var on = ref.on
                                              return [
                                                _c(
                                                  "span",
                                                  _vm._g(
                                                    {
                                                      staticClass:
                                                        "font-weight-black accent--text"
                                                    },
                                                    on
                                                  ),
                                                  [
                                                    _vm._v(
                                                      _vm._s(item.assignedCount)
                                                    )
                                                  ]
                                                )
                                              ]
                                            }
                                          }
                                        ],
                                        null,
                                        true
                                      )
                                    },
                                    [
                                      _c("div", [
                                        _c("pre", [
                                          _vm._v(
                                            _vm._s(_vm.getAssignedUsers(item))
                                          )
                                        ])
                                      ])
                                    ]
                                  )
                                ],
                                1
                              ),
                              _c("li", {
                                staticClass: "flex-item pa-0",
                                attrs: { hidden: !_vm.isSchedule }
                              }),
                              _c(
                                "li",
                                { staticClass: "flex-item pa-0" },
                                [
                                  _c("v-img", {
                                    staticClass: "mx-2 assignImage",
                                    attrs: {
                                      src: require("@/assets/Calendar.jpg"),
                                      "max-height": "30",
                                      "max-width": "30",
                                      contain: ""
                                    },
                                    on: {
                                      click: function($event) {
                                        return _vm.getQuickAddPopup(item)
                                      }
                                    }
                                  })
                                ],
                                1
                              ),
                              _c(
                                "li",
                                { staticClass: "flex-item pa-0 text-end" },
                                [
                                  _c(
                                    "v-icon",
                                    {
                                      staticClass: "accent--text",
                                      on: {
                                        click: function($event) {
                                          return _vm.selectRow(item)
                                        }
                                      }
                                    },
                                    [_vm._v(" mdi-chevron-right mdi-36px ")]
                                  )
                                ],
                                1
                              )
                            ])
                          ])
                        ])
                      }),
                      0
                    )
                  : _vm._e()
              ]
            }
          }
        ])
      }),
      _c(
        "div",
        [
          _c(
            "v-dialog",
            { attrs: { value: _vm.show, persistent: "" } },
            [
              _c(
                "v-col",
                { staticClass: "assignPopup", attrs: { cols: "12" } },
                [
                  _c("Assignments", {
                    attrs: {
                      isExported: _vm.isExported,
                      order: _vm.order,
                      isAdminAssignments: false,
                      calledFrom: "Grid"
                    },
                    on: { updatePage: _vm.updatePage, close: _vm.close }
                  })
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
import Vue from "vue";
import Vuex from "vuex";
import authApi from "@/api/authApi";
import userApi from "@/api/userApi";

Vue.use(Vuex);

export const store = new Vuex.Store({
  state: {
    currentUser: JSON.parse(localStorage.getItem("current-user")) || {},
    userStatus: "",
    authToken: localStorage.getItem("auth-token") || "",
    authStatus: "",
    orderFilters: JSON.parse(sessionStorage.getItem("order-filters")) || {},
    workingOrderId: localStorage.getItem("workingOrderId") || "",
    loading: false,
  },
  mutations: {
    USER_REQUEST(state) {
      state.userStatus = "Requesting user.";
    },
    USER_SUCCESS(state, user) {
      state.userStatus = "User retrieved successfully.";
      state.currentUser = user;
    },
    USER_ERROR(state) {
      state.authStatus = "User lookup error!";
    },
    AUTH_REQUEST(state) {
      state.authStatus = "Requesting authentication.";
    },
    AZURE_AUTH_REQUEST(state) {
      state.authStatus = "Azure authentication.";
    },
    AUTH_SUCCESS(state, token) {
      state.authStatus = "Authentication succeeded.";
      state.authToken = token;
    },
    AUTH_ERROR(state) {
      state.authStatus = "Auth error!";
    },
    AUTH_LOGOUT(state) {
      state.authToken = "";
      state.currentUser = {};
    },
    ORDER_FILTER_UPDATE(state, filters) {
      state.orderFilters = filters;
    },
    SET_WORKING_ORDER_ID(state, orderId) {
      state.workingOrderId = orderId;
    },
    START_LOADING(state, isLoading) {
      state.loading = isLoading;
    },
    FINISH_LOADING(state, isLoading) {
      state.loading = isLoading;
    },
  },
  actions: {
    userRequestEmail({commit}, email) {
      return new Promise((resolve, reject) => {
        commit("USER_REQUEST");
        userApi.getByEmail(email).then((result) => {
          localStorage.setItem("current-user", JSON.stringify(result.data));
          commit("USER_SUCCESS", result.data);
          resolve(result);
        }),
        (error) => {
          commit("USER_ERROR", error);
          localStorage.removeItem("current-user");
          reject(error);
        };
      });
    },
    userRequest({commit}, userId) {
      return new Promise((resolve, reject) => {
        commit("USER_REQUEST");
        userApi.getById(userId).then((result) => {
          localStorage.setItem("current-user", JSON.stringify(result.data));
          commit("USER_SUCCESS", result.data);
          resolve(result);
        }),
        (error) => {
          commit("USER_ERROR", error);
          localStorage.removeItem("current-user");
          reject(error);
        };
      });
    },
    authAzureLoggedIn({commit}, token) {
      return new Promise((resolve, reject) => {
        commit("AZURE_AUTH_REQUEST");
        if (token && token.length > 0) {
          localStorage.setItem("auth-token", token);
          commit("AUTH_SUCCESS", token);
          resolve("Success");
        } else {
          commit("AUTH_ERROR");
          reject(new Error("Azure token missing."));
        }
      });
    },
    authRequest({commit}, credentials) {
      return new Promise((resolve, reject) => {
        commit("AUTH_REQUEST");
        authApi.login(credentials).then((result) => {
          localStorage.setItem("auth-token", result.data.auth_token);
          commit("AUTH_SUCCESS", result.data.auth_token);
          resolve(result);
        }),
        (error) => {
          commit("AUTH_ERROR", error);
          localStorage.removeItem("auth-token");
          reject(error);
        };
      });
    },
    authLogout({commit}) {
      commit("AUTH_LOGOUT");
      localStorage.removeItem("auth-token");
      localStorage.removeItem("current-user");
    },
    setWorkingOrderId({commit}, orderId) {
      commit("SET_WORKING_ORDER_ID", orderId);
      localStorage.setItem("workingOrderId", orderId);
    },
    orderFilterUpdate({commit}, filters) {
      commit("ORDER_FILTER_UPDATE", filters);
      sessionStorage.setItem("order-filters", JSON.stringify(filters));
    },
  },
  getters: {
    getCurrentUser: (state) => {
      return state.currentUser;
    },
    isAuthenticated: (state) => {
      return !!state.authToken && !!state.currentUser;
    },
    getAuthStatus: (state) => {
      return state.authStatus;
    },
    getAuthToken: (state) => {
      return state.authToken;
    },
    getWorkingOrderId: (state) => {
      return state.workingOrderId;
    },
    getOrderFilters: (state) => {
      return state.orderFilters;
    },
    getLoading: (state) => {
      return state.loading;
    },
  },
});

export default store;

var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("v-overlay", { attrs: { value: _vm.show } }),
      _vm.show
        ? _c("v-progress-circular", {
            staticClass: "centered",
            attrs: { indeterminate: _vm.show, color: "green" }
          })
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
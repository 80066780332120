import Vue from "vue";
import axios from "axios";
import VueAxios from "vue-axios";
import App from "./App.vue";
import vuetify from "./plugins/vuetify";
import router from "./router";
import {store} from "./store/store";
import VCurrencyField from "v-currency-field";
import {VTextField} from "vuetify/lib";
import AuthService from "./msal";
import moment from "moment";
import VueSweetalert2 from "vue-sweetalert2";
import "sweetalert2/dist/sweetalert2.min.css";
import DatetimePicker from "vuetify-datetime-picker";
import VueCookie from "vue-cookie";

Vue.config.productionTip = false;
// AuthService for MSAL - Azure AD.
Vue.prototype.$AuthService = new AuthService();
// Currency field init.
Vue.component("v-text-field", VTextField);
Vue.use(VCurrencyField, {
  locale: "en",
  decimalLength: 2,
  autoDecimalMode: true,
  min: null,
  max: null,
  defaultValue: 0,
});

Vue.use(VueAxios, axios);
Vue.use(VueSweetalert2);
Vue.use(DatetimePicker);
Vue.use(VueCookie);

// Add bearer token to all requests.
Vue.axios.interceptors.request.use(
    (config) => {
      const authToken = store.getters["getAuthToken"];
      if (authToken) {
        config.headers.Authorization = `Bearer ${authToken}`;
      }
      return config;
    },
    (err) => {
      return Promise.reject(err);
    },
);

Vue.axios.interceptors.response.use(
    function(response) {
      return response;
    },
    function(error) {
      if (error.response) {
        if (error.response.status === 401) {
          if (Vue.prototype.$AuthService.isLoggedIn()) {
            // If AD user, then try to get a new access token.
            Vue.prototype.$AuthService.getToken();
          } else {
            Vue.swal({
              title: "Session Expired",
              text:
              "Your session has expired. " +
              "Would you like to be redirected to the login page?",
              type: "warning",
              showCancelButton: true,
              confirmButtonColor: "accent",
              confirmButtonText: "Yes",
              closeOnConfirm: false,
            }).then((result) => {
              if (result.value) {
                store.dispatch("authLogout").then(() => {
                  Vue.prototype.$AuthService.logout();
                  router.push("/login");
                });
              }
            });
          }
        } else if (
          error.response.status !== 401 &&
        error.response.status >= 400 &&
        error.response.status < 500
        ) {
          let devDetails = "";
          if (
            process.env.NODE_ENV.toUpperCase() === "PRODUCTION" &&
          error.response.data.developerDetails
          ) {
            Object.keys(error.response.data.developerDetails).forEach((key) => {
              devDetails += `<details>
              <summary><strong>${key}</strong></summary>
              ${JSON.stringify(error.response.data.developerDetails[key])}
              </details>`;
            });
          }
          let errorDetail = "";
          if (error.response.data.detail) {
            errorDetail = error.response.data.detail;
          } else if (error.response.data.errors) {
            Object.keys(error.response.data.errors).forEach((key) => {
              errorDetail += `${error.response.data.errors[key]} <br />`;
            });
          } else if (
            error.response.data &&
          typeof error.response.data === "string"
          ) {
            errorDetail = error.response.data;
          } else {
            errorDetail = "Unknown Error";
          }
          const step = {
            confirmButtonText: "Ok",
            html: `<div>
              <div>${errorDetail}</div>
              <div style="text-align: left;">${devDetails}</div>`,
          };
          if (Vue.swal.getQueueStep()) {
            Vue.swal.insertQueueStep(step);
          } else {
            Vue.swal.queue([step]);
          }
        } else if (
          process.env.NODE_ENV.toUpperCase() !== "PRODUCTION" &&
        error.response.status >= 500 &&
        error.response.status < 600
        ) {
        // Non-production server errors
          const step = {
            icon: "error",
            confirmButtonText: "Ok",
            title: "Server Error",
            html: `<div>
              <a href="${error.response.data.type}" target="_blank">
                <strong>
                  ${error.response.data.status} - ${error.response.data.title}
                </strong>
              </a>
            </div>
            <div style="overflow: scroll;">
                <pre>${error.response.data.detail}</pre>
              </div>
            </div>`,
          };
          if (Vue.swal.getQueueStep()) {
            Vue.swal.insertQueueStep(step);
          } else {
            Vue.swal.queue([step]);
          }
        } else if (process.env.NODE_ENV.toUpperCase() !== "PRODUCTION") {
        // Unknown error.
          const step = {
            icon: "error",
            confirmButtonText: "Ok",
            title: "Unknown Error",
            text: `${error.response.data}`,
          };
          if (Vue.swal.getQueueStep()) {
            Vue.swal.insertQueueStep(step);
          } else {
            Vue.swal.queue([step]);
          }
        }
      }
      return Promise.reject(error);
    },
);

Vue.axios.interceptors.request.use(
    (config) => {
      store.commit("START_LOADING", true);
      return config;
    }, (error) => {
      store.commit("FINISH_LOADING", false);
      return Promise.reject(error);
    });

Vue.axios.interceptors.response.use((response) => {
  store.commit("FINISH_LOADING", false);
  return response;
}, (error) => {
  store.commit("FINISH_LOADING", false);
  return Promise.reject(error);
});

// Fix vuetify calendar's annoying warning.
// Should get fixed by them at some point.
const ignoreWarnMessage =
  // eslint-disable-next-line max-len
  "The .native modifier for v-on is only valid on components but it was used on <div>.";
Vue.config.warnHandler = function(msg, vm, trace) {
  if (msg === ignoreWarnMessage) {
    msg = null;
    vm = null;
    trace = null;
  }
};

new Vue({
  vuetify,
  router,
  store,
  moment,
  render: (h) => h(App),
}).$mount("#app");

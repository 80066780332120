var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-container",
    { staticClass: "pa-0", attrs: { fluid: "" } },
    [
      _c(
        "v-layout",
        { attrs: { flex: "", "align-center": "", "justify-center": "" } },
        [
          _c(
            "v-flex",
            { attrs: { md12: "" } },
            [
              _c(
                "v-card",
                { attrs: { tile: "" } },
                [
                  _c(
                    "v-toolbar",
                    { attrs: { dense: "", color: "primary" } },
                    [_c("Breadcrumbs")],
                    1
                  ),
                  _c("v-divider"),
                  _c(
                    "v-layout",
                    { attrs: { column: "" } },
                    [
                      _c(
                        "v-tabs",
                        {
                          attrs: {
                            "fixed-tabs": "",
                            "background-color": "primary darken-1",
                            "hide-slider": "true",
                            "active-class": "primary black--text"
                          },
                          model: {
                            value: _vm.tabs,
                            callback: function($$v) {
                              _vm.tabs = $$v
                            },
                            expression: "tabs"
                          }
                        },
                        [
                          _c("v-tab", { attrs: { href: "#tab-1" } }, [
                            _vm._v(" Current ")
                          ]),
                          _c(
                            "v-tab",
                            {
                              attrs: { href: "#tab-2" },
                              on: { click: _vm.getPastAssignments }
                            },
                            [_vm._v(" History ")]
                          ),
                          _c(
                            "v-tabs-items",
                            {
                              model: {
                                value: _vm.tabs,
                                callback: function($$v) {
                                  _vm.tabs = $$v
                                },
                                expression: "tabs"
                              }
                            },
                            [
                              _c(
                                "v-tab-item",
                                { attrs: { value: "tab-1" } },
                                [
                                  _c(
                                    "v-card",
                                    {
                                      staticClass: "ma-2",
                                      attrs: { flat: "", "hide-details": "" }
                                    },
                                    [
                                      _c("v-select", {
                                        attrs: {
                                          items: _vm.locations,
                                          "item-text": "description",
                                          "item-value": "id",
                                          clearable: "",
                                          outlined: "",
                                          label: "Locations"
                                        },
                                        model: {
                                          value: _vm.selectedLocation,
                                          callback: function($$v) {
                                            _vm.selectedLocation = $$v
                                          },
                                          expression: "selectedLocation"
                                        }
                                      })
                                    ],
                                    1
                                  ),
                                  _c("v-spacer"),
                                  _c("Assignments", {
                                    attrs: {
                                      isAdminAssignments: true,
                                      selectedLocation: _vm.selectedLocation
                                    }
                                  })
                                ],
                                1
                              ),
                              _c(
                                "v-tab-item",
                                { attrs: { value: "tab-2" } },
                                [
                                  _c(
                                    "v-card",
                                    [
                                      _c("v-data-table", {
                                        attrs: {
                                          items: _vm.historicalAssignments,
                                          headers: _vm.assignedHeaders,
                                          "no-data-text":
                                            "No employees assigned"
                                        },
                                        scopedSlots: _vm._u([
                                          {
                                            key: "item.employee",
                                            fn: function(ref) {
                                              var item = ref.item
                                              return [
                                                _vm._v(
                                                  " " +
                                                    _vm._s(
                                                      item.user.firstName +
                                                        " " +
                                                        item.user.lastName
                                                    ) +
                                                    " "
                                                )
                                              ]
                                            }
                                          },
                                          {
                                            key: "item.date",
                                            fn: function(ref) {
                                              var item = ref.item
                                              return [
                                                _vm._v(
                                                  " " +
                                                    _vm._s(
                                                      _vm.getShortDate(
                                                        item.start
                                                      )
                                                    ) +
                                                    " "
                                                )
                                              ]
                                            }
                                          },
                                          {
                                            key: "item.start",
                                            fn: function(ref) {
                                              var item = ref.item
                                              return [
                                                _vm._v(
                                                  " " +
                                                    _vm._s(
                                                      _vm.getShortTime(
                                                        item.start
                                                      )
                                                    ) +
                                                    " "
                                                )
                                              ]
                                            }
                                          },
                                          {
                                            key: "item.end",
                                            fn: function(ref) {
                                              var item = ref.item
                                              return [
                                                _vm._v(
                                                  " " +
                                                    _vm._s(
                                                      _vm.getShortTime(item.end)
                                                    ) +
                                                    " "
                                                )
                                              ]
                                            }
                                          }
                                        ]),
                                        model: {
                                          value: _vm.historicalAssignments,
                                          callback: function($$v) {
                                            _vm.historicalAssignments = $$v
                                          },
                                          expression: "historicalAssignments"
                                        }
                                      })
                                    ],
                                    1
                                  )
                                ],
                                1
                              )
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-row",
    [
      _c(
        "v-expansion-panels",
        { staticClass: "ma-5" },
        [
          _c(
            "v-expansion-panel",
            [
              _c(
                "v-expansion-panel-header",
                { staticClass: "primary" },
                [
                  _c("div", { staticClass: "headline" }, [
                    _vm._v("Images and Content")
                  ]),
                  _c(
                    "v-dialog",
                    {
                      attrs: { persistent: "" },
                      scopedSlots: _vm._u([
                        {
                          key: "activator",
                          fn: function(ref) {
                            var on = ref.on
                            var attrs = ref.attrs
                            return [
                              _c(
                                "v-btn",
                                _vm._g(
                                  _vm._b(
                                    {
                                      staticClass: "add-btn",
                                      attrs: {
                                        color: "accent",
                                        dark: "",
                                        title: "Image or PDF"
                                      }
                                    },
                                    "v-btn",
                                    attrs,
                                    false
                                  ),
                                  on
                                ),
                                [_vm._v(" Add Image ")]
                              )
                            ]
                          }
                        }
                      ]),
                      model: {
                        value: _vm.dialog,
                        callback: function($$v) {
                          _vm.dialog = $$v
                        },
                        expression: "dialog"
                      }
                    },
                    [
                      _c(
                        "v-card",
                        [
                          _c(
                            "v-card-text",
                            [
                              _c(
                                "v-container",
                                [
                                  _c(
                                    "v-row",
                                    [
                                      _c("v-col", { attrs: { cols: "12" } }, [
                                        _c("input", {
                                          attrs: {
                                            multiple: "",
                                            label: "Upload File",
                                            counter: "",
                                            accept: "image/*,.pdf,.heic,.heif",
                                            rules: _vm.rules,
                                            placeholder: "Input File",
                                            type: "file"
                                          },
                                          on: { change: _vm.assignValue }
                                        })
                                      ]),
                                      _c(
                                        "v-col",
                                        { attrs: { cols: "12" } },
                                        [
                                          _c("v-select", {
                                            attrs: {
                                              label: "Title",
                                              items: _vm.items,
                                              required: ""
                                            },
                                            on: {
                                              change: function($event) {
                                                ;[
                                                  _vm.changeToText(),
                                                  _vm.checkValue()
                                                ]
                                              }
                                            },
                                            model: {
                                              value: _vm.selectTitle,
                                              callback: function($$v) {
                                                _vm.selectTitle = $$v
                                              },
                                              expression: "selectTitle"
                                            }
                                          }),
                                          _vm.showText
                                            ? _c("v-text-field", {
                                                attrs: {
                                                  label: "Title",
                                                  outlined: ""
                                                },
                                                model: {
                                                  value: _vm.title,
                                                  callback: function($$v) {
                                                    _vm.title = $$v
                                                  },
                                                  expression: "title"
                                                }
                                              })
                                            : _vm._e()
                                        ],
                                        1
                                      ),
                                      _c(
                                        "v-col",
                                        { attrs: { cols: "12" } },
                                        [
                                          _c("v-text-field", {
                                            attrs: {
                                              label: "Description",
                                              outlined: "",
                                              required: ""
                                            },
                                            on: { change: _vm.checkValue },
                                            model: {
                                              value: _vm.description,
                                              callback: function($$v) {
                                                _vm.description = $$v
                                              },
                                              expression: "description"
                                            }
                                          })
                                        ],
                                        1
                                      ),
                                      _c(
                                        "v-col",
                                        { attrs: { cols: "12" } },
                                        [
                                          _c("v-text-field", {
                                            attrs: {
                                              label: "Order",
                                              outlined: "",
                                              disabled: "",
                                              value: _vm.order
                                            }
                                          })
                                        ],
                                        1
                                      )
                                    ],
                                    1
                                  )
                                ],
                                1
                              )
                            ],
                            1
                          ),
                          _c(
                            "v-card-actions",
                            [
                              _c("v-spacer"),
                              _c(
                                "v-btn",
                                {
                                  attrs: { color: "blue darken-1", text: "" },
                                  on: { click: _vm.closeModal }
                                },
                                [_vm._v(" Cancel ")]
                              ),
                              _c(
                                "v-btn",
                                {
                                  attrs: {
                                    color: "blue darken-1",
                                    text: "",
                                    disabled: _vm.checkEnable
                                  },
                                  on: { click: _vm.saveUploadedFiles }
                                },
                                [_vm._v(" Save ")]
                              )
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "v-expansion-panel-content",
                [
                  _c(
                    "v-layout",
                    {
                      directives: [
                        {
                          name: "resize",
                          rawName: "v-resize",
                          value: _vm.onResize,
                          expression: "onResize"
                        }
                      ],
                      attrs: { column: "" }
                    },
                    [
                      _c(
                        "v-dialog",
                        {
                          attrs: { persistent: "" },
                          scopedSlots: _vm._u([
                            {
                              key: "activator",
                              fn: function(ref) {
                                var on = ref.on
                                var attrs = ref.attrs
                                return [
                                  _c("v-data-table", {
                                    staticClass: "elevation-2 minimum-height",
                                    class: { mobile: _vm.isMobile },
                                    attrs: {
                                      headers: _vm.isMobile
                                        ? _vm.mobileHeaders
                                        : _vm.headers,
                                      "hide-default-header": _vm.isMobile,
                                      items: _vm.dataArr
                                    },
                                    scopedSlots: _vm._u(
                                      [
                                        {
                                          key: "body",
                                          fn: function(props) {
                                            return [
                                              !_vm.dataArr ||
                                              _vm.dataArr.length < 1
                                                ? _c("tbody", [
                                                    _c(
                                                      "tr",
                                                      {
                                                        staticClass:
                                                          "v-data-table__empty-wrapper"
                                                      },
                                                      [
                                                        _c(
                                                          "td",
                                                          {
                                                            attrs: {
                                                              colspan: "6"
                                                            }
                                                          },
                                                          [
                                                            _vm._v(
                                                              " No Files Uploaded "
                                                            )
                                                          ]
                                                        )
                                                      ]
                                                    )
                                                  ])
                                                : !_vm.isMobile
                                                ? _c(
                                                    "tbody",
                                                    _vm._l(
                                                      props.items,
                                                      function(item, index) {
                                                        return _c(
                                                          "tr",
                                                          { key: index },
                                                          [
                                                            _c(
                                                              "td",
                                                              {
                                                                staticClass:
                                                                  "text-xs-right",
                                                                attrs: {
                                                                  title:
                                                                    item.name
                                                                }
                                                              },
                                                              [
                                                                _vm._v(
                                                                  " " +
                                                                    _vm._s(
                                                                      _vm.getDocumentName(
                                                                        item.name
                                                                      )
                                                                    ) +
                                                                    " "
                                                                )
                                                              ]
                                                            ),
                                                            _c(
                                                              "td",
                                                              {
                                                                staticClass:
                                                                  "text-xs-right"
                                                              },
                                                              [
                                                                _vm._v(
                                                                  _vm._s(
                                                                    item.uploadedDate
                                                                  )
                                                                )
                                                              ]
                                                            ),
                                                            _c(
                                                              "td",
                                                              {
                                                                staticClass:
                                                                  "text-xs-right"
                                                              },
                                                              [
                                                                _vm._v(
                                                                  _vm._s(
                                                                    item.uploadedTime
                                                                  )
                                                                )
                                                              ]
                                                            ),
                                                            _c(
                                                              "td",
                                                              {
                                                                staticClass:
                                                                  "text-xs-right"
                                                              },
                                                              [
                                                                _vm._v(
                                                                  _vm._s(
                                                                    _vm.order
                                                                  )
                                                                )
                                                              ]
                                                            ),
                                                            _c(
                                                              "td",
                                                              {
                                                                staticClass:
                                                                  "text-xs-right"
                                                              },
                                                              [
                                                                _vm._v(
                                                                  _vm._s(
                                                                    item.title
                                                                  )
                                                                )
                                                              ]
                                                            ),
                                                            _c(
                                                              "td",
                                                              {
                                                                staticClass:
                                                                  "text-xs-right"
                                                              },
                                                              [
                                                                _c(
                                                                  "a",
                                                                  _vm._g(
                                                                    _vm._b(
                                                                      {
                                                                        staticClass:
                                                                          "view-link",
                                                                        attrs: {
                                                                          title:
                                                                            "View Content, Title & Description"
                                                                        },
                                                                        on: {
                                                                          click: function(
                                                                            $event
                                                                          ) {
                                                                            return _vm.viewDetails(
                                                                              index
                                                                            )
                                                                          }
                                                                        }
                                                                      },
                                                                      "a",
                                                                      attrs,
                                                                      false
                                                                    ),
                                                                    on
                                                                  ),
                                                                  [
                                                                    _vm._v(
                                                                      " View Details "
                                                                    )
                                                                  ]
                                                                )
                                                              ]
                                                            ),
                                                            _c(
                                                              "td",
                                                              {
                                                                staticClass:
                                                                  "text-center"
                                                              },
                                                              [
                                                                !item.imageSrc.includes(
                                                                  "iframe"
                                                                )
                                                                  ? _c(
                                                                      "span",
                                                                      [
                                                                        _c(
                                                                          "v-icon",
                                                                          {
                                                                            attrs: {
                                                                              color:
                                                                                "accent",
                                                                              large:
                                                                                "",
                                                                              title:
                                                                                "Image"
                                                                            }
                                                                          },
                                                                          [
                                                                            _vm._v(
                                                                              " mdi-image "
                                                                            )
                                                                          ]
                                                                        )
                                                                      ],
                                                                      1
                                                                    )
                                                                  : _c(
                                                                      "span",
                                                                      [
                                                                        _c(
                                                                          "v-icon",
                                                                          {
                                                                            attrs: {
                                                                              color:
                                                                                "accent",
                                                                              large:
                                                                                "",
                                                                              title:
                                                                                "PDF File"
                                                                            }
                                                                          },
                                                                          [
                                                                            _vm._v(
                                                                              " mdi-file-pdf-box "
                                                                            )
                                                                          ]
                                                                        )
                                                                      ],
                                                                      1
                                                                    )
                                                              ]
                                                            ),
                                                            _c(
                                                              "td",
                                                              {
                                                                staticClass:
                                                                  "text-xs-right"
                                                              },
                                                              [
                                                                _c(
                                                                  "v-icon",
                                                                  {
                                                                    attrs: {
                                                                      color:
                                                                        "accent"
                                                                    },
                                                                    on: {
                                                                      click: function(
                                                                        $event
                                                                      ) {
                                                                        return _vm.deleteItem(
                                                                          item
                                                                        )
                                                                      }
                                                                    }
                                                                  },
                                                                  [
                                                                    _vm._v(
                                                                      " mdi-delete "
                                                                    )
                                                                  ]
                                                                )
                                                              ],
                                                              1
                                                            )
                                                          ]
                                                        )
                                                      }
                                                    ),
                                                    0
                                                  )
                                                : _c(
                                                    "tbody",
                                                    _vm._l(
                                                      props.items,
                                                      function(item, index) {
                                                        return _c(
                                                          "tr",
                                                          { key: index },
                                                          [
                                                            _c(
                                                              "td",
                                                              {
                                                                staticClass:
                                                                  "pa-1"
                                                              },
                                                              [
                                                                _c(
                                                                  "ul",
                                                                  {
                                                                    staticClass:
                                                                      "flex-content"
                                                                  },
                                                                  [
                                                                    _c(
                                                                      "li",
                                                                      {
                                                                        staticClass:
                                                                          "flex-item pa-0",
                                                                        attrs: {
                                                                          "data-label":
                                                                            "Name"
                                                                        }
                                                                      },
                                                                      [
                                                                        _vm._v(
                                                                          " " +
                                                                            _vm._s(
                                                                              _vm.getDocumentName(
                                                                                item.name
                                                                              )
                                                                            ) +
                                                                            " "
                                                                        )
                                                                      ]
                                                                    ),
                                                                    _c(
                                                                      "li",
                                                                      {
                                                                        staticClass:
                                                                          "flex-item text-right",
                                                                        attrs: {
                                                                          "data-label":
                                                                            "Details"
                                                                        }
                                                                      },
                                                                      [
                                                                        _c(
                                                                          "a",
                                                                          _vm._g(
                                                                            _vm._b(
                                                                              {
                                                                                staticClass:
                                                                                  "view-link",
                                                                                attrs: {
                                                                                  title:
                                                                                    "View Content, Title & Description"
                                                                                },
                                                                                on: {
                                                                                  click: function(
                                                                                    $event
                                                                                  ) {
                                                                                    return _vm.viewDetails(
                                                                                      index
                                                                                    )
                                                                                  }
                                                                                }
                                                                              },
                                                                              "a",
                                                                              attrs,
                                                                              false
                                                                            ),
                                                                            on
                                                                          ),
                                                                          [
                                                                            _vm._v(
                                                                              " View Details "
                                                                            )
                                                                          ]
                                                                        )
                                                                      ]
                                                                    ),
                                                                    _c(
                                                                      "li",
                                                                      {
                                                                        staticClass:
                                                                          "flex-item pa-0",
                                                                        attrs: {
                                                                          "data-label":
                                                                            "Order Id"
                                                                        }
                                                                      },
                                                                      [
                                                                        _vm._v(
                                                                          " " +
                                                                            _vm._s(
                                                                              _vm.order
                                                                            ) +
                                                                            " "
                                                                        )
                                                                      ]
                                                                    ),
                                                                    _c(
                                                                      "li",
                                                                      {
                                                                        staticClass:
                                                                          "flex-item text-right",
                                                                        attrs: {
                                                                          "data-label":
                                                                            "Actions"
                                                                        }
                                                                      },
                                                                      [
                                                                        _c(
                                                                          "v-icon",
                                                                          {
                                                                            attrs: {
                                                                              color:
                                                                                "accent"
                                                                            },
                                                                            on: {
                                                                              click: function(
                                                                                $event
                                                                              ) {
                                                                                return _vm.deleteItem(
                                                                                  item
                                                                                )
                                                                              }
                                                                            }
                                                                          },
                                                                          [
                                                                            _vm._v(
                                                                              " mdi-delete "
                                                                            )
                                                                          ]
                                                                        )
                                                                      ],
                                                                      1
                                                                    )
                                                                  ]
                                                                )
                                                              ]
                                                            )
                                                          ]
                                                        )
                                                      }
                                                    ),
                                                    0
                                                  )
                                            ]
                                          }
                                        }
                                      ],
                                      null,
                                      true
                                    )
                                  })
                                ]
                              }
                            }
                          ]),
                          model: {
                            value: _vm.descriptionDialog,
                            callback: function($$v) {
                              _vm.descriptionDialog = $$v
                            },
                            expression: "descriptionDialog"
                          }
                        },
                        [
                          _c(
                            "v-card",
                            [
                              _c(
                                "v-card-text",
                                [
                                  _c("v-spacer"),
                                  _c(
                                    "v-btn",
                                    {
                                      staticClass: "float",
                                      attrs: {
                                        icon: "",
                                        "x-large": "",
                                        light: ""
                                      },
                                      on: { click: _vm.closeDescModal }
                                    },
                                    [_c("v-icon", [_vm._v(" mdi-close ")])],
                                    1
                                  ),
                                  _c(
                                    "v-container",
                                    [
                                      _c(
                                        "v-row",
                                        [
                                          _c(
                                            "v-col",
                                            {
                                              staticClass: "pad-top",
                                              attrs: { cols: "12" }
                                            },
                                            [
                                              _c("span", {
                                                staticClass: "border-dark",
                                                attrs: { width: "100%" },
                                                domProps: {
                                                  innerHTML: _vm._s(_vm.descImg)
                                                }
                                              })
                                            ]
                                          ),
                                          _c(
                                            "v-col",
                                            { attrs: { cols: "12" } },
                                            [
                                              _vm._v(
                                                " Image Name: " +
                                                  _vm._s(_vm.descName) +
                                                  " "
                                              )
                                            ]
                                          ),
                                          _c(
                                            "v-col",
                                            { attrs: { cols: "12" } },
                                            [
                                              _vm._v(
                                                " Title: " +
                                                  _vm._s(_vm.descTitle) +
                                                  " "
                                              )
                                            ]
                                          ),
                                          _c(
                                            "v-col",
                                            { attrs: { cols: "12" } },
                                            [
                                              _vm._v(
                                                " Description: " +
                                                  _vm._s(_vm.descDetails) +
                                                  " "
                                              )
                                            ]
                                          )
                                        ],
                                        1
                                      )
                                    ],
                                    1
                                  )
                                ],
                                1
                              ),
                              _c(
                                "v-card-actions",
                                [
                                  _c("v-spacer"),
                                  _c(
                                    "v-btn",
                                    {
                                      attrs: {
                                        color: "blue darken-1",
                                        text: ""
                                      },
                                      on: { click: _vm.closeDescModal }
                                    },
                                    [_vm._v(" Close ")]
                                  )
                                ],
                                1
                              )
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
import EzCaterIcon from "@/components/EzCaterIcon.vue";
import WeddingIcon from "@/components/WeddingIcon.vue";
import QuoteIcon from "@/components/QuoteIcon.vue";
import CancelIcon from "@/components/CancelIcon.vue";

export const iconMixin = {
  data() {
    return {
      wedding: "Wedding",
      quote: "quote",
    };
  },
  components: {
    EzCaterIcon,
    WeddingIcon,
    QuoteIcon,
    CancelIcon,
  },
};

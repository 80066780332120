import Vue from "vue";

export default {
  getTipsByOrderId(orderId) {
    return Vue.axios.get(
        `${process.env.VUE_APP_API_BASE}/Tips/GetTipsByOrderId/${orderId}`,
    );
  },
  putTips(orderId, tips) {
    return Vue.axios.put(
        `${process.env.VUE_APP_API_BASE}/Tips/${orderId}`,
        tips,
    );
  },
  getTips() {
    return Vue.axios.get(`${process.env.VUE_APP_API_BASE}/Tips/`);
  },
};

<template>
  <v-toolbar flat>
    <v-toolbar-items style="width: 100%;" class="hidden-sm-and-down">
      <v-row>
        <v-col width="20%" v-if="!isSchedule">
          <v-switch
            label="Tip Discrepancy"
            class="mt-4 mr-2"
            v-show="isPayroll"
            v-model="tipSwitch"
          >
          </v-switch>
          <v-select
            v-model="filterStatus"
            :items="orderStatusList"
            label="Order Status"
            multiple
            v-if="!isPayroll && !isCommissions && !isSchedule"
            chips
            clearable
          ></v-select>
        </v-col>
        <v-col width="20%" v-if="!isPayroll && !isCommissions">
          <v-select
            v-model="filterFormat"
            :items="orderFormatList"
            label="Order Format"
            multiple
            chips
            clearable
          ></v-select>
        </v-col>
        <v-col width="20%" v-if="!isSchedule">
          <v-select
            v-model="filterMarket"
            :items="orderMarketList"
            item-text="description"
            item-value="id"
            label="Markets"
            multiple
            chips
            clearable
          >
          </v-select>
        </v-col>
        <v-col width="20%">
          <v-text-field
            class="mt-2"
            v-model="search"
            append-icon="mdi-magnify"
            label="Search"
            single-line
            hide-details
            clearable
          ></v-text-field>
        </v-col>
        <v-col width="20%">
          <v-menu
            v-model="startDateMenu"
            :close-on-content-click="false"
            transition="scale-transition"
            offset-y
          >
            <template v-slot:activator="{ on }">
              <v-text-field
                class="mt-2"
                v-model="filterStartDate"
                label="Start"
                prepend-icon="event"
                readonly
                v-on="on"
              ></v-text-field>
            </template>
            <v-date-picker
              v-model="filterStartDate"
              @input="startDateMenu = false"
            ></v-date-picker>
          </v-menu>
        </v-col>
        <v-col width="20%">
          <v-menu
            v-model="endDateMenu"
            :close-on-content-click="false"
            transition="scale-transition"
            offset-y
          >
            <template v-slot:activator="{ on }">
              <v-text-field
                class="mt-2"
                v-model="filterEndDate"
                label="End"
                prepend-icon="event"
                readonly
                v-on="on"
              ></v-text-field>
            </template>
            <v-date-picker
              v-model="filterEndDate"
              @input="endDateMenu = false"
            ></v-date-picker>
          </v-menu>
        </v-col>
        <v-col width="20%" v-if="isSchedule">
          <v-autocomplete
            v-model="filterRevenueSource"
            :items="allEmployeesList"
            item-text="fullName"
            item-value="cityEmployeeId"
            label="Revenue Source"
            multiple
            chips
            clearable
          ></v-autocomplete>
        </v-col>
        <v-col width="20%" v-if="isSchedule">
          <v-autocomplete
            v-model="filterEnteredBy"
            :items="allEmployeesList"
            item-text="fullName"
            item-value="cityEmployeeId"
            label="Entered By"
            multiple
            chips
            clearable
          ></v-autocomplete>
        </v-col>
        <v-col width="20%" v-if="!isPayroll && !isCommissions">
          <v-select
            v-model="isContentAtt"
            clearable
            label="Attachment"
            :items="options"
            variant="underlined"
            class="mt-2"
          ></v-select>
        </v-col>
        <!--eslint-disable-next-line max-len-->
        <v-col width="20%" class="mt-2 mx-auto" v-if="!isPayroll && !isCommissions">
          <v-btn
            small
            @click="getFilteredOrders"
            color="accent"
            class="pa-3 justify-center"
            >Submit</v-btn
          >
        </v-col>
      </v-row>
    </v-toolbar-items>
    <v-menu :close-on-content-click="false">
      <template v-slot:activator="{ on }">
        <span class="hidden-md-and-up">Filters</span>
        <v-app-bar-nav-icon
          v-on="on"
          class="hidden-md-and-up"
        ></v-app-bar-nav-icon>
      </template>
      <v-list flat>
        <v-text-field
          v-model="search"
          append-icon="mdi-magnify"
          label="Search"
          single-line
          hide-details
          class="pa-3"
        ></v-text-field>
        <v-menu
          v-model="startDateNavMenu"
          :close-on-content-click="false"
          :nudge-right="40"
          transition="scale-transition"
          offset-y
          min-width="290px"
        >
          <template v-slot:activator="{ on }">
            <v-text-field
              v-model="filterStartDate"
              label="Start"
              prepend-icon="event"
              readonly
              clearable
              v-on="on"
              class="pa-3"
            ></v-text-field>
          </template>
          <v-date-picker
            v-model="filterStartDate"
            @input="startDateNavMenu = false"
          ></v-date-picker>
        </v-menu>
        <v-menu
          v-model="endDateNavMenu"
          :close-on-content-click="false"
          :nudge-right="40"
          transition="scale-transition"
          offset-y
          min-width="290px"
        >
          <template v-slot:activator="{ on }">
            <v-text-field
              v-model="filterEndDate"
              label="End"
              prepend-icon="event"
              readonly
              clearable
              v-on="on"
              class="pa-3"
            ></v-text-field>
          </template>
          <v-date-picker
            v-model="filterEndDate"
            @input="endDateNavMenu = false"
          ></v-date-picker>
        </v-menu>
        <v-subheader>Order Status</v-subheader>
        <v-list-item-group v-model="filterStatus" multiple color="primary">
          <v-list-item
            v-for="(status, index) in orderStatusList"
            :key="index"
            :value="status"
          >
            <template v-slot:default="{ active, toggle }">
              <v-list-item-content>
                <v-list-item-title v-text="status"></v-list-item-title>
              </v-list-item-content>
              <v-list-item-action>
                <v-checkbox
                  @click="toggle"
                  dense
                  color="primary"
                  :input-value="active"
                ></v-checkbox>
              </v-list-item-action>
            </template>
          </v-list-item>
        </v-list-item-group>
        <v-divider></v-divider>
        <v-subheader>Market</v-subheader>
        <v-list-item-group v-model="filterMarket" multiple color="primary">
          <v-list-item
            v-for="(market, index) in orderMarketList"
            :key="index"
            :items="orderMarketList"
            :value="market.id"
          >
            <template v-slot:default="{ active, toggle }">
              <v-list-item-content>
                <v-list-item-title
                  v-text="market.description"
                ></v-list-item-title>
              </v-list-item-content>
              <v-list-item-action>
                <v-checkbox
                  @click="toggle"
                  dense
                  color="primary"
                  :input-value="active"
                ></v-checkbox>
              </v-list-item-action>
            </template>
          </v-list-item>
        </v-list-item-group>
        <v-divider></v-divider>
          <v-select
            v-model="isContentAtt"
            clearable
            label="Attachment"
            :items="options"
            variant="underlined"
          ></v-select>
        <v-divider></v-divider>
        <v-btn
          v-if="!isPayroll && !isCommissions"
          width="90%"
          small
          @click="getFilteredOrders"
          color="accent"
          class="ma-3"
          >Submit</v-btn
        >
      </v-list>
    </v-menu>
  </v-toolbar>
</template>
<script>
import {mapGetters} from "vuex";
import moment from "moment";
import store from "@/store/store";
import {Role} from "@/helpers/role";

export default {
  name: "OrdersFilterToolbar",
  data() {
    return {
      startDateMenu: false,
      endDateMenu: false,
      startDateNavMenu: false,
      endDateNavMenu: false,
      isContentAtt: "All",
      options: ["With Attachment", "Without Attachment", "All"],
      search: "",
      filterStartDate: "",
      filterEndDate: "",
      filterMarket: [],
      filterStatus: [],
      filterFormat: [],
      filterRevenueSource: [],
      filterEnteredBy: [],
      filteredOrders: [],
      tipSwitch: false,
      isPayrollEmployee: false,
    };
  },
  props: {
    allOrders: Array,
    orderStatusList: Array,
    orderMarketList: Array,
    allEmployeesList: Array,
    orderFormatList: Array,
    isPayroll: Boolean,
    isCommissions: Boolean,
    isSchedule: Boolean,
  },
  mounted() {
    this.currentUser.roles.forEach((x) => {
      if (x === Role.Payroll) {
        this.isPayrollEmployee = true;
      }
    });
    this.initFilters();
  },
  watch: {
    allOrders: {
      // Handle inital load.
      handler: function(val, oldVal) {
        this.filterOrders();
      },
    },
    search: {
      handler: function(val, oldVal) {
        this.filterOrders();
      },
    },
    filterStartDate: {
      handler: function(val, oldVal) {
        this.filterOrders();
      },
    },
    filterEndDate: {
      handler: function(val, oldVal) {
        this.filterOrders();
      },
    },
    filterMarket: {
      handler: function(val, oldVal) {
        this.filterOrders();
      },
    },
    filterStatus: {
      handler: function(val, oldVal) {
        this.filterOrders();
      },
    },
    filterFormat: {
      handler: function(val, oldVal) {
        this.filterOrders();
      },
    },
    filterEnteredBy: {
      handler: function(val, oldVal) {
        this.filterOrders();
      },
    },
    filterRevenueSource: {
      handler: function(val, oldVal) {
        this.filterOrders();
      },
    },
    tipSwitch() {
      this.$emit("tipDiscrepancy", {tipSwitch: this.tipSwitch});
    },
  },
  methods: {
    initFilters() {
      this.search = this.orderFilters["search"] ?
        this.orderFilters["search"] : "";
      this.filterStartDate =
        this.setStartDate(this.orderFilters["startDate"]);
      this.filterEndDate =
        this.setEndDate(this.orderFilters["endDate"]);
      this.filterMarket =
        this.orderFilters["market"] ? this.orderFilters["market"] :
          !this.isPayrollEmployee ?
          this.currentUser.cityLocations.map((x) => x.id) : "";
      this.filterStatus =
        this.orderFilters["status"] ? this.orderFilters["status"] : "";
      this.filterFormat =
        this.orderFilters["format"] ? this.orderFilters["format"] : "";
      // this.isContentAtt = this.orderFilters["document"];
    },
    getFilteredOrders() {
      this.orderFilters.document = this.isContentAtt;
      this.$emit("getOrders", this.orderFilters);
    },
    setStartDate(date) {
      return date ? date : this.defaultStartDate();
    },
    setEndDate(date) {
      return date ? date:
        this.defaultEndDate();
    },
    defaultStartDate() {
      return this.isPayrollEmployee ?
        moment().subtract(365, "days").startOf("day").format("YYYY-MM-DD") :
        moment().startOf("day").format("YYYY-MM-DD");
    },
    defaultEndDate() {
      return this.isPayrollEmployee ?
        moment().startOf("day").format("YYYY-MM-DD") :
        moment().add(7, "days").startOf("day").format("YYYY-MM-DD");
    },
    filterOrders() {
      store.dispatch(
          "orderFilterUpdate", {
            search: this.search || "",
            startDate: this.filterStartDate || "",
            endDate: this.filterEndDate || "",
            market: this.filterMarket || [],
            status: this.filterStatus || [],
            format: this.filterFormat || [],
            document: this.orderFilters.document,
          },
      );

      let ret = this.allOrders;
      if (this.filterStartDate) {
        ret = ret.filter((o) => {
          return (
            !o.startDateTime ||
            moment(this.filterStartDate).isBefore(
                moment(o.startDateTime)
                    .startOf("day")
                    .add(1, "seconds"),
            )
          );
        });
      }
      if (this.filterEndDate) {
        ret = ret.filter((o) => {
          return (
            !o.startDateTime ||
            moment(this.filterEndDate).isAfter(
                moment(o.startDateTime)
                    .startOf("day")
                    .subtract(1, "seconds"),
            )
          );
        });
      }
      if (this.filterMarket && this.filterMarket.length) {
        ret = ret.filter((o) => {
          return (
            !o.accountingUnit || this.filterMarket.includes(o.accountingUnit)
          );
        });
      }
      if (this.filterStatus && this.filterStatus.length) {
        ret = ret.filter((o) => {
          return !o.status || this.filterStatus.includes(o.status);
        });
      }
      if (this.filterFormat && this.filterFormat.length) {
        ret = ret.filter((o) => {
          return this.filterFormat.includes(o.format);
        });
      }
      if (this.filterRevenueSource && this.filterRevenueSource.length) {
        ret = ret.filter((o) => {
          return (
            o.revenueSourceEmployeeId == null ||
            typeof o.revenueSourceEmployeeId === "undefined" ||
            this.filterRevenueSource.includes(o.revenueSourceEmployeeId)
          );
        });
      }
      if (this.filterEnteredBy && this.filterEnteredBy.length) {
        ret = ret.filter((o) => {
          return (
            o.enteredByEmployeeId == null ||
            typeof o.enteredByEmployeeId === "undefined" ||
            this.filterEnteredBy.includes(o.enteredByEmployeeId)
          );
        });
      }
      if (this.search) {
        ret = ret.filter((o) => {
          let b = false;
          const keys = Object.keys(o);
          for (let i = 0; i < keys.length; i++) {
            if (
              o[keys[i]] && // Not null or undefined
              typeof o[keys[i]] === "string" && // Is a string
              o[keys[i]].toUpperCase().indexOf(this.search.toUpperCase()) >= 0
            ) {
              b = true;
              break;
            }
          }
          return b;
        });
      }
      this.$emit("setFilteredOrders", ret);
    },
  },
  computed: {
    ...mapGetters({
      orderFilters: "getOrderFilters",
      currentUser: "getCurrentUser",
    }),
  },
};
</script>

<template>
  <v-dialog :value="show" persistent>
    <v-card>
      <v-card-text>
        <v-container>
          <v-row>
            <v-col cols="12">
              <input
                multiple
                label="Upload File"
                counter
                accept="image/*,.pdf,.heic,.heif"
                :rules="rules"
                v-on:change="assignValue"
                placeholder="Input File"
                type="file"
              />
            </v-col>
            <v-col cols="12">
              <v-select
                v-model="selectTitle"
                label="Title"
                :items="items"
                v-on:change="[changeToText(), checkValue()]"
                required
              ></v-select>
              <v-text-field
                v-if="showText"
                label="Title"
                v-model="title"
                outlined
              ></v-text-field>
            </v-col>
            <v-col cols="12">
              <v-text-field
                label="Description"
                v-model="description"
                v-on:change="checkValue"
                outlined
                required
              ></v-text-field>
            </v-col>
            <v-col cols="12">
              <v-text-field
                :value="orderId"
                label="Order"
                outlined
                disabled
              ></v-text-field>
            </v-col>
          </v-row>
        </v-container>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="blue darken-1" text @click="[$emit('close'), clearAll()]">
          Cancel
        </v-btn>
        <v-btn
          color="blue darken-1"
          text
          :disabled="checkEnable"
          @click="saveUploadedFiles"
        >
          Save
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import assignmentsApi from "@/api/assignmentsApi";
import Vue from "vue";

export default {
  name: "Timeclock",
  props: {
    visible: Boolean,
    orderId: Number,
  },
  computed: {
    checkEnable() {
      return this.isEnable;
    },
    show: {
      get() {
        return this.visible;
      },
      set(value) {
        if (!value) {
          this.$emit("close");
        }
      },
    },
  },
  data() {
    return {
      selectOrderId: [],
      files: [],
      isEnable: true,
      title: "",
      description: "",
      selectTitle: "",
      showText: false,
      rules: [
        (value) => {
          let totalSize = 0;
          value.forEach((element) => {
            totalSize += element.size;
          });
          return totalSize < 20000000 || "File Size should be less than 20MB";
        },
      ],
      // eslint-disable-next-line max-len
      items: [
        "Event Set Up & Recap",
        "FS Food Temp Log",
        "FS Event/Wedding Pictures",
        "Invoice Signature",
        "Other",
      ],
    };
  },
  methods: {
    assignValue(e) {
      this.files = e.target.files;
      this.checkValue();
    },
    clearAll() {
      this.title = "";
      this.selectTitle = "";
      this.files = [];
      document.querySelector("input[type=file]").value = "";
      this.description = "";
    },
    changeToText() {
      if (this.selectTitle === "Other") {
        this.showText = true;
      } else {
        this.showText = false;
      }
    },
    saveUploadedFiles() {
      let title;
      if (this.selectTitle === "Other") {
        title = this.title;
      } else {
        title = this.selectTitle;
      }
      const OrderId = this.orderId;
      const data = {
        file: this.files,
        OrderId,
        Title: title,
        Description: this.description,
      };
      // eslint-disable-next-line max-len
      if (
        data.file.length > 0 &&
        data.Title.length > 0 &&
        data.Description.length > 0
      ) {
        Vue.swal({
          icon: "success",
          title: "Upload in Progress!",
          // eslint-disable-next-line max-len
          text: "Please allow up to two minutes to complete processing!",
          type: "success",
          showCancelButton: false,
          confirmButtonColor: "accent",
          confirmButtonText: "Ok",
          closeOnConfirm: false,
        });
        assignmentsApi.uploadFiles(data).then((response) => {
          if (response.status === 200) {
            console.log("Response Status::", response);
          }
        });
        this.$emit("close");
        this.title = "";
        this.selectTitle = "";
        this.files = [];
        this.orderId = "";
        document.querySelector("input[type=file]").value = "";
        this.description = "";
      } else {
        Vue.swal({
          // eslint-disable-next-line max-len
          title: "No Files Uploaded!",
          text: "Please uplaod Files to save this to the Order",
          type: "error",
          showCancelButton: false,
          confirmButtonColor: "accent",
          confirmButtonText: "Ok",
          closeOnConfirm: false,
        });
        this.title = "";
        this.selectTitle = "";
        this.files = [];
        this.orderId = "";
        document.querySelector("input[type=file]").value = "";
        this.description = "";
      }
    },
    checkValue() {
      // eslint-disable-next-line max-len
      if (
        this.selectTitle.length > 0 &&
        this.files.length > 0 &&
        this.description.length > 0
      ) {
        this.isEnable = false;
      }
    },
  },
};
</script>

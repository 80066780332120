<template>
  <v-container fluid class="pa-0">
    <v-container fluid fill-height class="pa-0 pb-1">
      <v-layout flex align-center justify-center>
        <v-flex>
          <v-expansion-panels accordion :value="0">
            <v-expansion-panel>
              <v-expansion-panel-header
                color="primary"
                class="title black--text font-weight-bold"
              >Action Items
                <v-badge v-if="actions.length"
                  color="accent"
                  class="justify-start"
                  :content="actions.length"
                  inline
                  bordered
                  ></v-badge
                >
              </v-expansion-panel-header>
              <v-expansion-panel-content>
                <HomeActions :actions="actions" />
              </v-expansion-panel-content>
            </v-expansion-panel>
          </v-expansion-panels>
        </v-flex>
      </v-layout>
    </v-container>
    <!-- <v-container fluid fill-height class="pa-0 pb-1">
      <v-layout flex align-center justify-center>
        <v-flex>
          <v-expansion-panels accordion :value="0">
            <v-expansion-panel>
              <v-expansion-panel-header
                color="primary"
                class="title black--text font-weight-bold"
                >Upcoming Events</v-expansion-panel-header
              >
              <v-expansion-panel-content>
                <v-tabs
                  v-model="tab"
                  fixed-tabs
                  :background-color="'primary darken-1'"
                  hide-slider="true"
                  :active-class="'primary black--text'"
                >
                  <v-tab href="#tab-1">
                    List
                    <v-icon left>mdi-format-list-bulleted</v-icon>
                  </v-tab>
                  <v-tab href="#tab-2">
                    Calendar
                    <v-icon left>mdi-calendar-month</v-icon>
                  </v-tab>
                </v-tabs>
                <v-tabs-items v-model="tab">
                  <v-tab-item :value="'tab-1'">
                    <v-card tile flat>
                      <OrdersGrid :order-list="orders" :isSchedule="true" />
                    </v-card>
                  </v-tab-item>
                  <v-tab-item :value="'tab-2'">
                    <v-card tile flat>
                      <OrdersCalendar
                        :order-list="orders"
                        :isSchedule="true"
                        default-type="4day"
                      />
                    </v-card>
                  </v-tab-item>
                </v-tabs-items>
              </v-expansion-panel-content>
            </v-expansion-panel>
          </v-expansion-panels>
        </v-flex>
      </v-layout>
    </v-container> -->
  </v-container>
</template>
<script>
import homeApi from "@/api/homeApi";
import HomeActions from "@/components/HomeActions.vue";
// import OrdersGrid from "@/components/OrdersGrid.vue";
// import OrdersCalendar from "@/components/OrdersCalendar.vue";

export default {
  name: "Home",
  components: {
    // OrdersGrid,
    // OrdersCalendar,
    HomeActions,
  },
  data() {
    return {
      tab: "",
      orders: [],
      actions: [],
    };
  },
  created() {
    homeApi.getHomeVm().then((response) => {
      // this.orders = response.data.orders;
      this.actions = response.data.actions;
    });
  },
};
</script>
<style scoped>
.v-expansion-panel-content >>> .v-expansion-panel-content__wrap {
  padding: 0 !important;
}
</style>

var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-row",
    { staticClass: "fill-height elevation-2" },
    [
      _c(
        "v-col",
        { staticClass: "pt-0" },
        [
          _c(
            "v-sheet",
            { attrs: { height: "64" } },
            [
              _c(
                "v-toolbar",
                { attrs: { flat: "", color: "white" } },
                [
                  _c(
                    "v-btn",
                    {
                      staticClass: "mr-4",
                      attrs: { outlined: "", color: "grey darken-4" },
                      on: { click: _vm.setToday }
                    },
                    [_vm._v("Today ")]
                  ),
                  _c(
                    "v-btn",
                    {
                      attrs: {
                        fab: "",
                        text: "",
                        small: "",
                        color: "grey darken-2"
                      },
                      on: { click: _vm.prev }
                    },
                    [
                      _c("v-icon", { attrs: { small: "" } }, [
                        _vm._v("mdi-chevron-left")
                      ])
                    ],
                    1
                  ),
                  _c(
                    "v-btn",
                    {
                      attrs: {
                        fab: "",
                        text: "",
                        small: "",
                        color: "grey darken-2"
                      },
                      on: { click: _vm.next }
                    },
                    [
                      _c("v-icon", { attrs: { small: "" } }, [
                        _vm._v("mdi-chevron-right")
                      ])
                    ],
                    1
                  ),
                  _c("v-toolbar-title", [_vm._v(_vm._s(_vm.title))]),
                  _c("v-spacer"),
                  _c(
                    "v-menu",
                    {
                      attrs: { bottom: "", right: "" },
                      scopedSlots: _vm._u([
                        {
                          key: "activator",
                          fn: function(ref) {
                            var on = ref.on
                            return [
                              _c(
                                "v-btn",
                                _vm._g(
                                  {
                                    attrs: {
                                      outlined: "",
                                      color: "grey darken-2"
                                    }
                                  },
                                  on
                                ),
                                [
                                  _c("span", [
                                    _vm._v(_vm._s(_vm.typeToLabel[_vm.type]))
                                  ]),
                                  _c("v-icon", { attrs: { right: "" } }, [
                                    _vm._v("mdi-menu-down")
                                  ])
                                ],
                                1
                              )
                            ]
                          }
                        }
                      ])
                    },
                    [
                      _c(
                        "v-list",
                        [
                          _c(
                            "v-list-item",
                            {
                              on: {
                                click: function($event) {
                                  _vm.type = "day"
                                }
                              }
                            },
                            [_c("v-list-item-title", [_vm._v("Day")])],
                            1
                          ),
                          _c(
                            "v-list-item",
                            {
                              on: {
                                click: function($event) {
                                  _vm.type = "week"
                                }
                              }
                            },
                            [_c("v-list-item-title", [_vm._v("Week")])],
                            1
                          ),
                          _c(
                            "v-list-item",
                            {
                              on: {
                                click: function($event) {
                                  _vm.type = "month"
                                }
                              }
                            },
                            [_c("v-list-item-title", [_vm._v("Month")])],
                            1
                          ),
                          _c(
                            "v-list-item",
                            {
                              on: {
                                click: function($event) {
                                  _vm.type = "4day"
                                }
                              }
                            },
                            [_c("v-list-item-title", [_vm._v("4 days")])],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          ),
          _c(
            "v-sheet",
            { attrs: { height: "600" } },
            [
              _c("v-calendar", {
                ref: "calendar",
                attrs: {
                  color: "accent",
                  events: _vm.orderList,
                  "event-color": _vm.getEventColor,
                  type: _vm.type,
                  "event-start": "calStartDateTime",
                  "event-end": "calExpectedEnd"
                },
                on: {
                  "click:event": _vm.showEvent,
                  "click:more": _vm.viewDay,
                  "click:date": _vm.viewDay,
                  change: _vm.changeView
                },
                model: {
                  value: _vm.focus,
                  callback: function($$v) {
                    _vm.focus = $$v
                  },
                  expression: "focus"
                }
              }),
              _c(
                "v-menu",
                {
                  attrs: {
                    "close-on-content-click": false,
                    activator: _vm.selectedElement,
                    "offset-x": ""
                  },
                  model: {
                    value: _vm.selectedOpen,
                    callback: function($$v) {
                      _vm.selectedOpen = $$v
                    },
                    expression: "selectedOpen"
                  }
                },
                [
                  _c(
                    "v-card",
                    {
                      attrs: {
                        color: "grey lighten-4",
                        "min-width": "250px",
                        flat: "",
                        tile: ""
                      }
                    },
                    [
                      _c(
                        "v-card-text",
                        [
                          _c("div", [
                            _c("strong", [
                              _vm._v(_vm._s(_vm.selectedEvent.name))
                            ])
                          ]),
                          _c("v-divider"),
                          _c("div", [
                            _vm._v("(" + _vm._s(_vm.selectedEvent.status) + ")")
                          ]),
                          _c("div", [
                            _vm._v(
                              " " +
                                _vm._s(
                                  this.getDateTimeWithTimezone(
                                    _vm.getLongDateTime(
                                      _vm.selectedEvent.startDateTime
                                    ),
                                    _vm.selectedEvent.startDateTimeZone
                                  )
                                ) +
                                " "
                            )
                          ]),
                          _c("div", [
                            _vm._v(
                              " Employees assigned: " +
                                _vm._s(
                                  _vm.getAssignedCount(_vm.selectedEvent)
                                ) +
                                " "
                            )
                          ]),
                          _c("div", [_vm._v(_vm._s(_vm.selectedEvent.notes))])
                        ],
                        1
                      ),
                      _c(
                        "v-card-actions",
                        [
                          _c(
                            "v-btn",
                            {
                              attrs: { text: "", color: "accent" },
                              on: {
                                click: function($event) {
                                  return _vm.goToOrder(_vm.selectedEvent)
                                }
                              }
                            },
                            [_vm._v("Go")]
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
import Vue from "vue";

export default {
  getClockEventsVm(currentDate) {
    return Vue.axios.get(`${process.env.VUE_APP_API_BASE}/ClockEvents/` +
      currentDate);
  },
  postClockEvent(clockEvent) {
    return Vue.axios.post(
        `${process.env.VUE_APP_API_BASE}/ClockEvents/`,
        clockEvent,
    );
  },
  chitRePrint(orderId, processId) {
    return Vue.axios.post(`${process.env.VUE_APP_API_BASE}/ClockEvents/` +
        `ChitRePrint?OrderId=${orderId}&ProcessId=${processId}`);
  },

};

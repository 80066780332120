var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-container",
    { staticClass: "pa-0", attrs: { fluid: "" } },
    [
      _c(
        "div",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.show,
              expression: "show"
            }
          ],
          staticClass: "profile-parent-div"
        },
        [
          _c(
            "v-card-text",
            [
              _c(
                "v-row",
                [
                  _c(
                    "v-col",
                    { attrs: { cols: "4" } },
                    [
                      _c(
                        "v-list-item-title",
                        { staticStyle: { "font-weight": "bold" } },
                        [_vm._v(" First Name:")]
                      ),
                      _c("v-list-item-content", [
                        _vm._v(" " + _vm._s(_vm.userData.firstName) + " ")
                      ])
                    ],
                    1
                  ),
                  _c(
                    "v-col",
                    { attrs: { cols: "4" } },
                    [
                      _c(
                        "v-list-item-title",
                        { staticStyle: { "font-weight": "bold" } },
                        [_vm._v(" Last Name:")]
                      ),
                      _c("v-list-item-content", [
                        _vm._v(" " + _vm._s(_vm.userData.lastName) + " ")
                      ])
                    ],
                    1
                  ),
                  _c(
                    "v-col",
                    { attrs: { cols: "4" } },
                    [
                      _c(
                        "v-list-item-title",
                        { staticStyle: { "font-weight": "bold" } },
                        [_vm._v(" CCQ Email:")]
                      ),
                      _c("v-list-item-content", [
                        _vm._v(
                          " " + _vm._s(_vm.userData.cityEmailAddress) + " "
                        )
                      ])
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "v-row",
                [
                  _c(
                    "v-col",
                    { attrs: { cols: "4" } },
                    [
                      _c(
                        "v-list-item-title",
                        { staticStyle: { "font-weight": "bold" } },
                        [
                          _vm._v(" Eligible For Commission: "),
                          _c(
                            "span",
                            {
                              directives: [
                                {
                                  name: "show",
                                  rawName: "v-show",
                                  value: _vm.eligibleForCommission == "No",
                                  expression: "eligibleForCommission == 'No'"
                                }
                              ],
                              attrs: { id: "condition" },
                              on: {
                                mouseover: _vm.ShowHoverMessage,
                                mouseout: function($event) {
                                  _vm.showText = false
                                }
                              }
                            },
                            [_vm._v("?")]
                          ),
                          _vm._v(" " + _vm._s(_vm.eligibleForCommission) + " ")
                        ]
                      ),
                      _c("v-list-item-content", [
                        _vm._v(" " + _vm._s(_vm.EligibleCommision()) + " "),
                        _vm.showText
                          ? _c("div", { staticClass: "hover-text" }, [
                              _c("pre", [_vm._v(_vm._s(_vm.hoverText))])
                            ])
                          : _vm._e()
                      ])
                    ],
                    1
                  ),
                  _c(
                    "v-col",
                    { attrs: { cols: "4" } },
                    [
                      _c(
                        "v-list-item-title",
                        { staticStyle: { "font-weight": "bold" } },
                        [_vm._v(" Approved Driver: ")]
                      ),
                      _c("v-list-item-content", [
                        _vm._v(" " + _vm._s(_vm.approvedDriverText) + " ")
                      ])
                    ],
                    1
                  ),
                  _c(
                    "v-col",
                    { attrs: { cols: "4" } },
                    [
                      _c(
                        "v-list-item-title",
                        { staticStyle: { "font-weight": "bold" } },
                        [_vm._v(" Monkey Media Email:")]
                      ),
                      _c("v-list-item-content", [
                        _vm._v(" " + _vm._s(_vm.existInMonkey) + " ")
                      ])
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "v-row",
                [
                  _c(
                    "v-col",
                    { attrs: { cols: "4" } },
                    [
                      _c(
                        "v-list-item-content",
                        [
                          _c(
                            "v-list-item-title",
                            {
                              staticStyle: {
                                "font-weight": "bold",
                                display: "flex",
                                "align-items": "center"
                              }
                            },
                            [
                              _c("span", [_vm._v("SMS Notifications:")]),
                              _c("v-checkbox", {
                                staticClass: "ml-2",
                                attrs: {
                                  dense: "",
                                  color: "primary",
                                  "hide-details": ""
                                },
                                on: { change: _vm.updateNotificationStatus },
                                model: {
                                  value: _vm.smsNotificationEnabled,
                                  callback: function($$v) {
                                    _vm.smsNotificationEnabled = $$v
                                  },
                                  expression: "smsNotificationEnabled"
                                }
                              })
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "v-row",
                [
                  _c(
                    "v-col",
                    { attrs: { cols: "4" } },
                    [
                      _c(
                        "v-list-item-title",
                        { staticStyle: { "font-weight": "bold" } },
                        [
                          _vm._v(" Security Groups: "),
                          _c(
                            "v-icon",
                            {
                              staticClass: "editIcon",
                              on: {
                                click: function($event) {
                                  return _vm.viewModal("roles")
                                }
                              }
                            },
                            [_vm._v("mdi-pencil")]
                          )
                        ],
                        1
                      ),
                      _c("v-list-item-content", [
                        _c(
                          "ul",
                          _vm._l(_vm.roles, function(role, index) {
                            return _c("li", { key: index }, [
                              _vm._v(_vm._s(role))
                            ])
                          }),
                          0
                        )
                      ])
                    ],
                    1
                  ),
                  _c(
                    "v-col",
                    { attrs: { cols: "4" } },
                    [
                      _c(
                        "v-list-item-title",
                        { staticStyle: { "font-weight": "bold" } },
                        [_vm._v(" Locations Assigned:")]
                      ),
                      _c("v-list-item-content", [
                        _c(
                          "ul",
                          _vm._l(_vm.locations, function(location, index) {
                            return _c("li", { key: index }, [
                              _vm._v(_vm._s(location.description))
                            ])
                          }),
                          0
                        )
                      ])
                    ],
                    1
                  ),
                  _c(
                    "v-col",
                    { attrs: { cols: "4" } },
                    [
                      _c(
                        "v-list-item-title",
                        { staticStyle: { "font-weight": "bold" } },
                        [_vm._v(" Jobs Assigned:")]
                      ),
                      _c("v-list-item-content", [
                        _c(
                          "ul",
                          _vm._l(_vm.jobcodes, function(job, index) {
                            return _c("li", { key: index }, [
                              _vm._v(_vm._s(job.description))
                            ])
                          }),
                          0
                        )
                      ])
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _c(
        "v-dialog",
        { attrs: { value: _vm.openModal, persistent: "" } },
        [
          _c(
            "v-container",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.editablefield === "roles",
                  expression: "editablefield === 'roles'"
                }
              ],
              staticClass: "group-form"
            },
            [
              _c(
                "v-row",
                [
                  _c("v-col", { attrs: { cols: "12" } }, [
                    _c("h3", [_vm._v("Assign Roles to User")])
                  ]),
                  _c(
                    "v-col",
                    { attrs: { cols: "12" } },
                    [
                      _c("v-select", {
                        attrs: {
                          items: _vm.rolesList,
                          "item-text": "name",
                          "item-value": "id",
                          label: "Select Roles",
                          multiple: ""
                        },
                        model: {
                          value: _vm.selectedRoles,
                          callback: function($$v) {
                            _vm.selectedRoles = $$v
                          },
                          expression: "selectedRoles"
                        }
                      })
                    ],
                    1
                  ),
                  _c(
                    "v-btn",
                    {
                      staticClass: "ma-3",
                      attrs: { small: "", color: "accent" },
                      on: { click: _vm.submitRoles }
                    },
                    [_vm._v(" Assign Roles to user ")]
                  ),
                  _c(
                    "v-btn",
                    {
                      staticClass: "ma-3",
                      attrs: { small: "", color: "secondary" },
                      on: { click: _vm.closeModal }
                    },
                    [_vm._v(" Close ")]
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _c(
        "v-alert",
        {
          staticClass: "mt-3",
          attrs: { type: "success", dismissible: "" },
          model: {
            value: _vm.notificationUpdated,
            callback: function($$v) {
              _vm.notificationUpdated = $$v
            },
            expression: "notificationUpdated"
          }
        },
        [_vm._v(" SMS Notification updated successfully! ")]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
import Vue from "vue";

export default {
  getCommissionsByOrderId(orderId) {
    return Vue.axios.get(
        process.env.VUE_APP_API_BASE +
        "/Commissions/GetCommissionsByOrderId/" +
        orderId,
    );
  },
  putCommissions(orderId, commissions) {
    return Vue.axios.put(
        `${process.env.VUE_APP_API_BASE}/Commissions/${orderId}`,
        commissions,
    );
  },
  getCommissions() {
    return Vue.axios.get(`${process.env.VUE_APP_API_BASE}/Commissions/`);
  },
};

<template>
  <v-list>
    <v-list-item v-for="(item, index) in detailItems" :key="index">
      <v-list-item-content class="py-0">
        <v-list-item-title
          class="font-weight-bold mt-1"
          v-text="item.label"
        ></v-list-item-title>
        <v-list-item-content class="pt-0 pb-0 body-2"
          v-if="!item.isLink"
        ><div>
          <CancelIcon
            v-show="item.voided"
            class="vertical-align-middle"
          />
          <EzCaterIcon
            v-show="item.ezOrder"
            class="vertical-align-middle"
          />
          <QuoteIcon
            v-show="item.quote === quote"
            class="vertical-align-middle"
          />
          <WeddingIcon
            v-show="item.format && item.format === wedding"
            class="vertical-align-middle"
          />
            {{item.data}}</div>
        </v-list-item-content>
        <v-list-item-content class="pt-0 pb-0 body-2"
          v-if="item.isLink"
          ><a :href="item.link" target="_blank"
            >{{item.data}}
            <v-icon>mdi-open-in-new</v-icon></a
          ></v-list-item-content
        >
      </v-list-item-content>
    </v-list-item>
  </v-list>
</template>
<script>
import {iconMixin} from "@/mixins/iconMixin.js";
export default {
  mixins: [iconMixin],
  name: "OrderDetailsList",
  props: {
    detailItems: Array,
  },
};
</script>
<style scoped>
.wrap-text {
  -webkit-line-clamp: unset !important;
  overflow-wrap: break-word !important;
  text-overflow: unset !important;
  white-space: unset !important;
}
</style>

var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("v-breadcrumbs", {
    attrs: { items: _vm.crumbs },
    scopedSlots: _vm._u([
      {
        key: "item",
        fn: function(ref) {
          var item = ref.item
          return [
            _c(
              "v-breadcrumbs-item",
              {
                attrs: { to: item.to, disabled: item.disabled, exact: "true" }
              },
              [
                _c(
                  "span",
                  {
                    class:
                      _vm.crumbs.indexOf(item) == _vm.crumbs.length - 1
                        ? "black--text subtitle-1"
                        : "black--text subtitle-1 font-weight-black text-shadow"
                  },
                  [_vm._v(_vm._s(item.text.toUpperCase()))]
                )
              ]
            )
          ]
        }
      },
      {
        key: "divider",
        fn: function() {
          return [
            _c("v-icon", { attrs: { color: "black" } }, [
              _vm._v("mdi-chevron-right")
            ])
          ]
        },
        proxy: true
      }
    ])
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-container",
    { staticClass: "pa-0", attrs: { fluid: "" } },
    [
      _c(
        "v-container",
        { staticClass: "pa-0 pb-1", attrs: { fluid: "", "fill-height": "" } },
        [
          _c(
            "v-layout",
            { attrs: { flex: "", "align-center": "", "justify-center": "" } },
            [
              _c(
                "v-flex",
                [
                  _c(
                    "v-expansion-panels",
                    { attrs: { accordion: "", value: 0 } },
                    [
                      _c(
                        "v-expansion-panel",
                        [
                          _c(
                            "v-expansion-panel-header",
                            {
                              staticClass: "title black--text font-weight-bold",
                              attrs: { color: "primary" }
                            },
                            [
                              _vm._v("Action Items "),
                              _vm.actions.length
                                ? _c("v-badge", {
                                    staticClass: "justify-start",
                                    attrs: {
                                      color: "accent",
                                      content: _vm.actions.length,
                                      inline: "",
                                      bordered: ""
                                    }
                                  })
                                : _vm._e()
                            ],
                            1
                          ),
                          _c(
                            "v-expansion-panel-content",
                            [
                              _c("HomeActions", {
                                attrs: { actions: _vm.actions }
                              })
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
<template>
<div>
<v-overlay :value="show" />
  <v-progress-circular class="centered"
      :indeterminate="show"
      color="green"
      v-if="show"
  ></v-progress-circular>
</div>
</template>
<script>
import {mapGetters} from "vuex";

export default {
  name: "Spinner",
  data() {
    return {
      show: false,
    };
  },
  computed: mapGetters({
    getLoading: "getLoading",
  }),
  watch: {
    getLoading: function(val, oldVal) {
      if (val) {
        this.show = true;
      } else {
        this.show = false;
      }
    },
  },
};
</script>
<style scoped>
.centered {
    z-index: 1000;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
</style>

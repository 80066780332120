import Vue from "vue";

export default {
  getHomeVm() {
    return Vue.axios.get(`${process.env.VUE_APP_API_BASE}/Home/`);
  },
  toggleHideActionItem(actionItemId) {
    return Vue.axios.post(
        // eslint-disable-next-line max-len
        `${process.env.VUE_APP_API_BASE}/Home/ToggleHideActionItem/${actionItemId}`,
    );
  },
};

<template>
  <v-container fluid class="pa-0">
    <v-layout flex align-center justify-center>
      <v-flex md12>
        <v-card tile>
          <v-toolbar dense dark color="primary">
            <Breadcrumbs :customLastCrumb="order.name" />
          </v-toolbar>
          <v-divider></v-divider>
          <OrderDetailsTriColumn :order="order" />
        </v-card>
      </v-flex>
    </v-layout>
    <v-row>
      <v-col cols="12">
        <AddContent :order="order.cityOrderId" v-if="order.cityOrderId"/>
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
import Breadcrumbs from "@/components/Breadcrumbs";
import OrderDetailsTriColumn from "@/components/OrderDetailsTriColumn";
import AddContent from "@/components/AddContent.vue";
import orderApi from "@/api/orderApi";
import {mapGetters} from "vuex";

export default {
  name: "ScheduleDetails",
  components: {
    Breadcrumbs,
    OrderDetailsTriColumn,
    AddContent,
  },
  data() {
    return {
      order: {},
    };
  },
  mounted() {
    // eslint-disable-next-line max-len
    orderApi.getScheduleOrderById(this.workingOrderId).then((response) => {
      if (response.data) {
        this.order = response.data;
      }
    });
  },
  computed: mapGetters({
    workingOrderId: "getWorkingOrderId",
  }),
};
</script>

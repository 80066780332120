<template>
  <v-container fluid fill-height>
    <v-layout flex align-center justify-center>
      <v-flex xs12 sm10 md6 lg4 xl3>
        <v-card tile>
          <v-toolbar dense color="primary">
          <v-toolbar-title>Reset Forgotten Password</v-toolbar-title>
        </v-toolbar>
        <v-divider></v-divider>
          <v-card-text class="pt-4">
            <div>
              <v-row>
                <v-col cols="12" sm="12" md="12">
                  Please enter the user name or email address for your account.
                  You will be emailed a link to reset your password. Please
                  check your email account’s Spam or Junk folder to ensure the
                  message was not filtered.
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12" sm="12" md="12">
                  <v-form v-model="valid" ref="form">
                    <v-text-field
                      label="Email"
                      type="email"
                      required
                      v-model="email"
                      :rules="emailRules"
                    />
                  </v-form>
                </v-col>
              </v-row>
            </div>
          </v-card-text>
          <v-card-actions>
            <v-btn
              color="accent"
              :class="{
                accent: valid,
              }"
              :disabled="!valid"
              @click="submit"
              >Send Reset Link</v-btn
            >
            <v-spacer></v-spacer>
            <v-btn to="/login" color="accent">Back</v-btn>
          </v-card-actions>
        </v-card>
      </v-flex>
    </v-layout>
  </v-container>
</template>
<script>
import authApi from "@/api/authApi";

export default {
  name: "ForgotPassword",
  data() {
    return {
      valid: false,
      email: "",
      emailRules: [
        (v) => !!v || "E-mail is required",
        (v) =>
        // eslint-disable-next-line max-len
          /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(v) ||
          "E-mail must be valid",
      ],
    };
  },
  methods: {
    submit() {
      if (this.$refs.form.validate()) {
        authApi.forgotPassword(this.email).then((response) => {
          this.$router.push("/login");
        });
      }
    },
  },
  computed: {
    show: {
      get() {
        return this.value;
      },
      set(value) {
        this.close();
      },
    },
  },
};
</script>

var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-container",
    { staticClass: "pa-0", attrs: { fluid: "" } },
    [
      _c(
        "v-layout",
        { attrs: { flex: "", "align-center": "", "justify-center": "" } },
        [
          _c(
            "v-flex",
            { attrs: { md12: "" } },
            [
              _c(
                "v-toolbar",
                { attrs: { dense: "", color: "primary" } },
                [_c("Breadcrumbs")],
                1
              ),
              _c("v-divider"),
              !_vm.isSubmissionsHistory
                ? _c("OrdersFilterToolbar", {
                    staticClass: "ma-1",
                    attrs: {
                      allOrders: _vm.orders,
                      isCommissions: true,
                      orderMarketList: _vm.marketList
                    },
                    on: { setFilteredOrders: _vm.setFilteredOrders }
                  })
                : _vm._e(),
              _vm.isSubmissionsHistory
                ? _c("v-data-table", {
                    staticClass: "ma-1",
                    attrs: {
                      headers: _vm.submissionsHistoryHeaders,
                      items: _vm.payrollSubmissions,
                      "no-data-text": "No Orders Found"
                    },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "top",
                          fn: function() {
                            return [
                              _c(
                                "v-row",
                                [
                                  _c(
                                    "v-switch",
                                    _vm._g(
                                      {
                                        staticClass: "ma-1 ml-7",
                                        attrs: {
                                          label: "History",
                                          color: "accent"
                                        },
                                        model: {
                                          value: _vm.isSubmissionsHistory,
                                          callback: function($$v) {
                                            _vm.isSubmissionsHistory = $$v
                                          },
                                          expression: "isSubmissionsHistory"
                                        }
                                      },
                                      _vm.on
                                    )
                                  )
                                ],
                                1
                              )
                            ]
                          },
                          proxy: true
                        },
                        {
                          key: "item.timestamp",
                          fn: function(ref) {
                            var item = ref.item
                            return [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    _vm.getDateFromDateTime(item.timestamp)
                                  ) +
                                  " "
                              )
                            ]
                          }
                        },
                        {
                          key: "item.processedDate",
                          fn: function(ref) {
                            var item = ref.item
                            return [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    _vm.getCalendarTime(item.processedDate)
                                  ) +
                                  " "
                              )
                            ]
                          }
                        },
                        {
                          key: "item.amount",
                          fn: function(ref) {
                            var item = ref.item
                            return [
                              _vm._v(
                                " " + _vm._s(_vm.getNumeral(item.amount)) + " "
                              )
                            ]
                          }
                        }
                      ],
                      null,
                      false,
                      11555141
                    )
                  })
                : _vm._e(),
              !_vm.isSubmissionsHistory
                ? _c("v-data-table", {
                    staticClass: "ma-1",
                    attrs: {
                      headers: _vm.headers,
                      items: _vm.filteredOrders,
                      "sort-by": _vm.sortBy,
                      "sort-desc": _vm.sortOrder,
                      "show-expand": ""
                    },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "header.cityOrderId",
                          fn: function() {
                            return [
                              _c(
                                "button",
                                {
                                  on: {
                                    click: function($event) {
                                      return _vm.filterClick("cityOrderId")
                                    }
                                  }
                                },
                                [_vm._v("Order")]
                              )
                            ]
                          },
                          proxy: true
                        },
                        {
                          key: "header.name",
                          fn: function() {
                            return [
                              _c(
                                "button",
                                {
                                  on: {
                                    click: function($event) {
                                      return _vm.filterClick("name")
                                    }
                                  }
                                },
                                [_vm._v("Name")]
                              )
                            ]
                          },
                          proxy: true
                        },
                        {
                          key: "header.market",
                          fn: function() {
                            return [
                              _c(
                                "button",
                                {
                                  on: {
                                    click: function($event) {
                                      return _vm.filterClick("market")
                                    }
                                  }
                                },
                                [_vm._v("Market")]
                              )
                            ]
                          },
                          proxy: true
                        },
                        {
                          key: "header.startDateTime",
                          fn: function() {
                            return [
                              _c(
                                "button",
                                {
                                  on: {
                                    click: function($event) {
                                      return _vm.filterClick("startDateTime")
                                    }
                                  }
                                },
                                [_vm._v("Event Date")]
                              )
                            ]
                          },
                          proxy: true
                        },
                        {
                          key: "header.paidDate",
                          fn: function() {
                            return [
                              _c(
                                "button",
                                {
                                  on: {
                                    click: function($event) {
                                      return _vm.filterClick("paidDate")
                                    }
                                  }
                                },
                                [_vm._v("Date Paid")]
                              )
                            ]
                          },
                          proxy: true
                        },
                        {
                          key: "header.totalFoodSales",
                          fn: function() {
                            return [
                              _c(
                                "button",
                                {
                                  on: {
                                    click: function($event) {
                                      return _vm.filterClick("totalFoodSales")
                                    }
                                  }
                                },
                                [_vm._v(" Total Food Sales")]
                              )
                            ]
                          },
                          proxy: true
                        },
                        {
                          key: "header.totalFoodSalesDiscounts",
                          fn: function() {
                            return [
                              _c(
                                "button",
                                {
                                  on: {
                                    click: function($event) {
                                      return _vm.filterClick(
                                        "totalFoodSalesDiscounts"
                                      )
                                    }
                                  }
                                },
                                [_vm._v(" Total Food Sales Discounts")]
                              )
                            ]
                          },
                          proxy: true
                        },
                        {
                          key: "header.commissions",
                          fn: function() {
                            return [
                              _c(
                                "button",
                                {
                                  on: {
                                    click: function($event) {
                                      return _vm.filterClick("commissions")
                                    }
                                  }
                                },
                                [_vm._v("Total Commissions")]
                              )
                            ]
                          },
                          proxy: true
                        },
                        {
                          key: "header.commissionCount",
                          fn: function() {
                            return [
                              _c(
                                "button",
                                {
                                  on: {
                                    click: function($event) {
                                      return _vm.filterClick("commissionCount")
                                    }
                                  }
                                },
                                [_vm._v(" Commission Count")]
                              )
                            ]
                          },
                          proxy: true
                        },
                        {
                          key: "top",
                          fn: function() {
                            return [
                              _c(
                                "v-row",
                                [
                                  _c(
                                    "v-switch",
                                    _vm._g(
                                      {
                                        staticClass: "ma-1 ml-7",
                                        attrs: {
                                          label: "History",
                                          color: "accent"
                                        },
                                        model: {
                                          value: _vm.isSubmissionsHistory,
                                          callback: function($$v) {
                                            _vm.isSubmissionsHistory = $$v
                                          },
                                          expression: "isSubmissionsHistory"
                                        }
                                      },
                                      _vm.on
                                    )
                                  ),
                                  _c("v-spacer"),
                                  _c(
                                    "v-btn",
                                    {
                                      class: [
                                        "exportexcel",
                                        { accent: _vm.valid }
                                      ],
                                      attrs: {
                                        color: "accent",
                                        disabled: !_vm.filteredOrders.length
                                      },
                                      on: { click: _vm.exportToExcel }
                                    },
                                    [
                                      _c("i", {
                                        staticClass: "fas fa-download"
                                      })
                                    ]
                                  ),
                                  _c(
                                    "v-dialog",
                                    {
                                      attrs: {
                                        "max-width": "330px",
                                        persistent: ""
                                      },
                                      scopedSlots: _vm._u(
                                        [
                                          {
                                            key: "activator",
                                            fn: function(ref) {
                                              var on = ref.on
                                              return [
                                                _c(
                                                  "v-btn",
                                                  _vm._g(
                                                    {
                                                      directives: [
                                                        {
                                                          name: "show",
                                                          rawName: "v-show",
                                                          value: !_vm.isManager,
                                                          expression:
                                                            "!isManager"
                                                        }
                                                      ],
                                                      staticClass: "mr-3",
                                                      attrs: {
                                                        color: "accent",
                                                        disabled: !_vm
                                                          .filteredOrders.length
                                                      }
                                                    },
                                                    on
                                                  ),
                                                  [_vm._v("Post To Payroll")]
                                                )
                                              ]
                                            }
                                          }
                                        ],
                                        null,
                                        false,
                                        2001648287
                                      ),
                                      model: {
                                        value: _vm.exportDialog,
                                        callback: function($$v) {
                                          _vm.exportDialog = $$v
                                        },
                                        expression: "exportDialog"
                                      }
                                    },
                                    [
                                      _c(
                                        "v-card",
                                        [
                                          _c("v-card-title", [
                                            _vm._v("Please Enter Paycheck Date")
                                          ]),
                                          _c(
                                            "v-card-text",
                                            [
                                              _c(
                                                "v-menu",
                                                {
                                                  attrs: {
                                                    "close-on-content-click": false,
                                                    transition:
                                                      "scale-transition",
                                                    "offset-y": ""
                                                  },
                                                  scopedSlots: _vm._u(
                                                    [
                                                      {
                                                        key: "activator",
                                                        fn: function(ref) {
                                                          var on = ref.on
                                                          return [
                                                            _c(
                                                              "v-text-field",
                                                              _vm._g(
                                                                {
                                                                  staticClass:
                                                                    "mt-2",
                                                                  attrs: {
                                                                    label:
                                                                      "Date",
                                                                    "prepend-icon":
                                                                      "event",
                                                                    readonly:
                                                                      "",
                                                                    clearable:
                                                                      "",
                                                                    rules: [
                                                                      _vm.rules
                                                                        .required,
                                                                      _vm.validDate(
                                                                        _vm.payrollDate
                                                                      )
                                                                    ]
                                                                  },
                                                                  model: {
                                                                    value:
                                                                      _vm.payrollDate,
                                                                    callback: function(
                                                                      $$v
                                                                    ) {
                                                                      _vm.payrollDate = $$v
                                                                    },
                                                                    expression:
                                                                      "payrollDate"
                                                                  }
                                                                },
                                                                on
                                                              )
                                                            )
                                                          ]
                                                        }
                                                      }
                                                    ],
                                                    null,
                                                    false,
                                                    313717954
                                                  ),
                                                  model: {
                                                    value: _vm.exportMenu,
                                                    callback: function($$v) {
                                                      _vm.exportMenu = $$v
                                                    },
                                                    expression: "exportMenu"
                                                  }
                                                },
                                                [
                                                  _c("v-date-picker", {
                                                    on: {
                                                      input: function($event) {
                                                        _vm.exportMenu = false
                                                      }
                                                    },
                                                    model: {
                                                      value: _vm.payrollDate,
                                                      callback: function($$v) {
                                                        _vm.payrollDate = $$v
                                                      },
                                                      expression: "payrollDate"
                                                    }
                                                  })
                                                ],
                                                1
                                              )
                                            ],
                                            1
                                          ),
                                          _c(
                                            "v-card-actions",
                                            [
                                              _c("v-spacer"),
                                              _c(
                                                "v-btn",
                                                _vm._g(
                                                  {
                                                    staticClass: "mr-3",
                                                    attrs: { color: "accent" },
                                                    on: {
                                                      click: function($event) {
                                                        _vm.exportDialog = false
                                                      }
                                                    }
                                                  },
                                                  _vm.on
                                                ),
                                                [_vm._v("Cancel")]
                                              ),
                                              _c(
                                                "v-btn",
                                                _vm._g(
                                                  {
                                                    staticClass: "mr-3",
                                                    attrs: {
                                                      color: "accent",
                                                      disabled: !_vm.isDateValid
                                                    },
                                                    on: {
                                                      click:
                                                        _vm.submitPayrollData
                                                    }
                                                  },
                                                  _vm.on
                                                ),
                                                [_vm._v("Submit")]
                                              )
                                            ],
                                            1
                                          )
                                        ],
                                        1
                                      )
                                    ],
                                    1
                                  )
                                ],
                                1
                              )
                            ]
                          },
                          proxy: true
                        },
                        {
                          key: "item.startDateTime",
                          fn: function(ref) {
                            var item = ref.item
                            return [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    _vm.getDateFromDateTime(item.startDateTime)
                                  ) +
                                  " "
                              )
                            ]
                          }
                        },
                        {
                          key: "item.paidDate",
                          fn: function(ref) {
                            var item = ref.item
                            return [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    _vm.getDateFromDateTime(item.paidDate)
                                  ) +
                                  " "
                              )
                            ]
                          }
                        },
                        {
                          key: "item.commissions",
                          fn: function(ref) {
                            var item = ref.item
                            return [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    _vm.calculateTotalCommissions(
                                      item.commissions
                                    )
                                  ) +
                                  " "
                              )
                            ]
                          }
                        },
                        {
                          key: "item.totalFoodSales",
                          fn: function(ref) {
                            var item = ref.item
                            return [
                              _vm._v(
                                " " +
                                  _vm._s(_vm.getNumeral(item.totalFoodSales)) +
                                  " "
                              )
                            ]
                          }
                        },
                        {
                          key: "item.totalFoodSalesDiscounts",
                          fn: function(ref) {
                            var item = ref.item
                            return [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    _vm.getNumeral(item.totalFoodSalesDiscounts)
                                  ) +
                                  " "
                              )
                            ]
                          }
                        },
                        {
                          key: "item.commissionCount",
                          fn: function(ref) {
                            var item = ref.item
                            return [
                              _vm._v(
                                " " + _vm._s(item.commissions.length) + " "
                              )
                            ]
                          }
                        },
                        {
                          key: "expanded-item",
                          fn: function(ref) {
                            var headers = ref.headers
                            var item = ref.item
                            return [
                              _c(
                                "td",
                                { attrs: { colspan: headers.length } },
                                [
                                  _c("v-data-table", {
                                    staticClass: "commissions",
                                    attrs: {
                                      headers: _vm.commissionsHeaders,
                                      "hide-default-footer": "",
                                      items: item.commissions
                                    },
                                    scopedSlots: _vm._u(
                                      [
                                        {
                                          key: "top",
                                          fn: function() {
                                            return [
                                              _c(
                                                "v-toolbar",
                                                { attrs: { flat: "" } },
                                                [
                                                  _c("v-toolbar-title", [
                                                    _vm._v("Commissions")
                                                  ])
                                                ],
                                                1
                                              ),
                                              _c("v-divider", {
                                                staticClass: "primary"
                                              })
                                            ]
                                          },
                                          proxy: true
                                        },
                                        {
                                          key: "item.name",
                                          fn: function(ref) {
                                            var item = ref.item
                                            return [
                                              _vm._v(
                                                " " +
                                                  _vm._s(
                                                    item.user.firstName +
                                                      " " +
                                                      item.user.lastName
                                                  ) +
                                                  " "
                                              )
                                            ]
                                          }
                                        },
                                        {
                                          key: "item.cityEmployeeId",
                                          fn: function(ref) {
                                            var item = ref.item
                                            return [
                                              _vm._v(
                                                " " +
                                                  _vm._s(
                                                    "" +
                                                      item.user.cityEmployeeId
                                                  ) +
                                                  " "
                                              )
                                            ]
                                          }
                                        },
                                        {
                                          key: "item.amount",
                                          fn: function(ref) {
                                            var item = ref.item
                                            return [
                                              _vm._v(
                                                " " +
                                                  _vm._s(
                                                    _vm.getNumeral(item.amount)
                                                  ) +
                                                  " "
                                              )
                                            ]
                                          }
                                        }
                                      ],
                                      null,
                                      true
                                    )
                                  })
                                ],
                                1
                              )
                            ]
                          }
                        }
                      ],
                      null,
                      false,
                      2994081602
                    )
                  })
                : _vm._e()
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
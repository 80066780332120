<template>
  <v-row>
    <v-expansion-panels class="ma-5">
      <v-expansion-panel>
        <v-expansion-panel-header class="primary">
          <div class="headline">Images and Content</div>
          <v-dialog
  v-model="dialog"
  persistent
>
  <template v-slot:activator="{ on, attrs }">
    <v-btn
      color="accent"
      dark
      v-bind="attrs"
      v-on="on"
      title="Image or PDF"
      class="add-btn"
    >
      Add Image
    </v-btn>
  </template>
  <v-card>
    <v-card-text>
      <v-container>
        <v-row>
          <v-col
            cols="12"
          >
            <input
              multiple
              label="Upload File"
              counter
              accept="image/*,.pdf,.heic,.heif"
              :rules="rules"
              v-on:change="assignValue"
              placeholder="Input File"
              type="file"
            />
              <!-- <template v-slot:selection="{text}">
                <v-chip label small>{{text}}</v-chip>
              </template>
            </input> -->
          </v-col>
          <v-col cols="12">
            <v-select
              v-model="selectTitle"
              label="Title"
              :items="items"
              v-on:change="[changeToText(), checkValue()]"
              required
              ></v-select>
            <v-text-field
              v-if="showText"
              label="Title"
              v-model="title"
              outlined
            ></v-text-field>
          </v-col>
          <v-col cols="12">
            <v-text-field
              label="Description"
              v-model="description"
              v-on:change="checkValue"
              outlined
              required
            ></v-text-field>
          </v-col>
          <v-col
            cols="12"
          >
            <v-text-field
              label="Order"
              outlined
              disabled
              :value="order"
            ></v-text-field>
          </v-col>
        </v-row>
      </v-container>
    </v-card-text>
    <v-card-actions>
      <v-spacer></v-spacer>
      <v-btn
        color="blue darken-1"
        text
        @click="closeModal"
      >
        Cancel
      </v-btn>
      <v-btn
        color="blue darken-1"
        text
        :disabled=checkEnable
        @click="saveUploadedFiles"
      >
        Save
      </v-btn>
    </v-card-actions>
  </v-card>
</v-dialog>
        </v-expansion-panel-header>
        <v-expansion-panel-content>
<v-layout v-resize="onResize" column>
  <v-dialog
    v-model="descriptionDialog"
    persistent
  >
  <template v-slot:activator="{ on, attrs }">
  <v-data-table
    class="elevation-2 minimum-height"
    :headers="isMobile ? mobileHeaders : headers"
    :hide-default-header="isMobile"
    :items="dataArr"
    :class="{ mobile: isMobile }"
  >
    <template v-slot:body="props">
      <tbody v-if="!dataArr || dataArr.length < 1">
        <tr class="v-data-table__empty-wrapper">
          <td colspan="6">
            No Files Uploaded
          </td>
        </tr>
      </tbody>
      <tbody v-else-if="!isMobile">
        <tr v-for="(item, index) in props.items" :key="index">
          <td class="text-xs-right" :title="item.name">
            {{getDocumentName(item.name)}}
          </td>
          <td class="text-xs-right">{{item.uploadedDate}}</td>
          <td class="text-xs-right">{{item.uploadedTime}}</td>
          <td class="text-xs-right">{{order}}</td>
          <td class="text-xs-right">{{item.title}}</td>
          <td class="text-xs-right">
            <a
              v-bind="attrs"
              v-on="on"
              title="View Content, Title & Description"
              @click="viewDetails(index)"
              class="view-link"
            >
              View Details
            </a>
          </td>
          <td class="text-center">
            <!-- eslint-disable-next-line max-len -->
            <span v-if="!item.imageSrc.includes('iframe')">
              <v-icon
                color="accent"
                large
                title="Image"
              >
                mdi-image
              </v-icon>
            </span>
            <span v-else>
              <v-icon
                color="accent"
                large
                title="PDF File"
              >
                mdi-file-pdf-box
              </v-icon>
            </span>
          </td>
          <td class="text-xs-right">
            <v-icon
              color="accent"
              @click="deleteItem(item)"
            >
              mdi-delete
            </v-icon>
          </td>
        </tr>
      </tbody>
      <tbody v-else>
        <tr v-for="(item, index) in props.items" :key="index">
          <td class="pa-1">
            <ul class="flex-content">
              <li class="flex-item pa-0" data-label="Name">
                {{getDocumentName(item.name)}}
              </li>
              <li class="flex-item text-right" data-label="Details">
                <a
                  v-bind="attrs"
                  v-on="on"
                  title="View Content, Title & Description"
                  @click="viewDetails(index)"
                  class="view-link"
                >
                  View Details
                </a>
              </li>
              <li class="flex-item pa-0" data-label="Order Id">
                {{ order }}
              </li>
              <li class="flex-item text-right" data-label="Actions">
                <v-icon
                  color="accent"
                  @click="deleteItem(item)"
                >
                  mdi-delete
                </v-icon>
              </li>
            </ul>
          </td>
        </tr>
      </tbody>
    </template>
  </v-data-table>
    </template>
    <v-card>
      <v-card-text>
        <v-spacer></v-spacer>
        <v-btn
          icon
          x-large
          light
          class="float"
          @click="closeDescModal"
        >
          <v-icon>
            mdi-close
          </v-icon>
        </v-btn>
        <v-container>
          <v-row>
            <v-col
              cols="12"
              class="pad-top"
            >
            <span v-html="descImg" class="border-dark" width="100%">
            </span>
            </v-col>
            <v-col cols="12">
              Image Name: {{descName}}
            </v-col>
            <v-col cols="12">
              Title: {{descTitle}}
            </v-col>
            <v-col cols="12">
              Description: {{descDetails}}
            </v-col>
          </v-row>
        </v-container>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn
          color="blue darken-1"
          text
          @click="closeDescModal"
        >
          Close
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</v-layout>
        </v-expansion-panel-content>
      </v-expansion-panel>
    </v-expansion-panels>
  </v-row>
</template>
<script>
import assignmentsApi from "@/api/assignmentsApi";
import moment from "moment";
import {mapGetters} from "vuex";
import {Role} from "@/helpers/role";
import Vue from "vue";

export default {
  name: "Add Content",
  data() {
    return {
      isMobile: false,
      files: [],
      dataArr: [],
      dialog: false,
      descriptionDialog: false,
      isEnable: true,
      descImg: "",
      descName: "",
      descTitle: "",
      descDetails: "",
      title: "",
      description: "",
      selectTitle: "",
      showText: false,
      isManager: false,
      isPayroll: false,
      isAdmin: false,
      isEmployee: false,
      isSalesRep: false,
      rules: [
        (value) => {
          let totalSize = 0;
          value.forEach((element) => {
            totalSize += element.size;
          });
          return totalSize < 20000000 || "File Size should be less than 20MB";
        },
      ],
      // eslint-disable-next-line max-len
      items: ["Event Set Up & Recap", "FS Food Temp Log", "FS Event/Wedding Pictures", "Invoice Signature", "Other"],
    };
  },
  computed: {
    headers() {
      return [
        {text: "Name", value: "name", width: "150px"},
        {text: "Date Uploaded", value: "", width: "130px"},
        {text: "Time", value: "", width: "100px"},
        {text: "Order Id", value: "", width: "100px"},
        {text: "Title", value: "title", width: "150px"},
        {text: "Description", value: "", width: "130px"},
        {text: "Image/PDF", value: "imageSrc", width: "120px"},
        {text: "Actions", value: "actions", width: "100px"},
      ];
    },
    mobileHeaders() {
      return [
        {text: "Name", value: "name", width: "50px"},
        {text: "Date Uploaded", value: "uploadedDate"},
        {text: "Order Id", value: ""},
        {text: "Image/PDF", value: "imageSrc"},
        {text: "Actions", value: "actions"},
      ];
    },
    checkEnable() {
      return this.isEnable;
    },
    ...mapGetters({
      isAuthenticated: "isAuthenticated",
      currentUser: "getCurrentUser",
    }),
  },
  props: {
    order: Object,
  },
  mounted() {
    // setTimeout(() => {
    this.downloadFiles();
    // }, 1000);
    this.currentUser.roles.forEach((x) => {
      if (x === Role.CateringManager) {
        this.isManager = true;
      } else if (x === Role.Payroll) {
        this.isPayroll = true;
      } else if (x === Role.Administrator) {
        this.isAdmin = true;
      } else if (x === Role.SalesRep) {
        this.isSalesRep = true;
      } else if (x === Role.Employee) {
        this.isEmployee = true;
      }
    });
  },
  methods: {
    assignValue(e) {
      this.files = e.target.files;
      this.checkValue();
    },
    checkValue() {
      // eslint-disable-next-line max-len
      if (this.selectTitle.length > 0 && this.files.length > 0 && this.description.length > 0) {
        this.isEnable = false;
      }
    },
    getDocumentName(documentName) {
      if (documentName.includes("@")) {
        const name = documentName.split("@")[0];
        const mime = documentName.split(".")[1];
        const docName = `${name}.${mime}`;
        return docName;
      } else {
        return documentName;
      }
    },
    deleteItem(item) {
      Vue.swal({
        icon: "warning",
        // eslint-disable-next-line max-len
        title: `Are you sure you want to delete the File ${this.getDocumentName(item.name)}?`,
        text: "",
        type: "success",
        showCancelButton: true,
        confirmButtonColor: "#AF1E2D",
        confirmButtonText: "Delete",
        focusCancel: true,
        closeOnConfirm: false,
      }).then((result) => {
        if (result.isConfirmed) {
          // eslint-disable-next-line max-len
          assignmentsApi.deleteFiles(this.order, item.name).then((response) => {
            this.downloadFiles();
          });
        }
      });
    },
    viewDetails(index) {
      this.descImg = this.dataArr[index].imageSrc;
      this.descName = this.getDocumentName(this.dataArr[index].name);
      this.descTitle = this.dataArr[index].title;
      this.descDetails = this.dataArr[index].description;
    },
    onResize() {
      if (window.innerWidth < 769) {
        this.isMobile = true;
      } else {
        this.isMobile = false;
      }
    },
    changeToText() {
      if (this.selectTitle === "Other") {
        this.showText = true;
      } else {
        this.showText = false;
      }
    },
    closeDescModal() {
      this.descriptionDialog = false;
    },
    closeModal() {
      this.title = "";
      this.selectTitle = "";
      this.files = [];
      document.querySelector("input[type=file]").value = "";
      this.description = "";
      this.dialog = false;
    },
    saveUploadedFiles() {
      let title;
      if (this.selectTitle === "Other") {
        title = this.title;
      } else {
        title = this.selectTitle;
      }
      // eslint-disable-next-line max-len
      const OrderId = document.querySelector(".v-dialog__content.v-dialog__content--active div:nth-child(4) input").value;
      const data = {
        file: this.files,
        OrderId,
        Title: title,
        Description: this.description,
      };
      // eslint-disable-next-line max-len
      if (data.file.length > 0 && data.Title.length > 0 && data.Description.length > 0) {
        Vue.swal({
          icon: "success",
          title: "Upload in Progress!",
          // eslint-disable-next-line max-len
          text: "Please allow up to two minutes to complete processing!",
          type: "success",
          showCancelButton: false,
          confirmButtonColor: "accent",
          confirmButtonText: "Ok",
          closeOnConfirm: false,
        });
        assignmentsApi.uploadFiles(data).then((response) => {
          if (response.status === 200) {
            // setTimeout(() => {
            this.downloadFiles();
            // }, 1500);
          }
        });
        this.dialog = false;
        this.title = "";
        this.selectTitle = "";
        this.files = [];
        document.querySelector("input[type=file]").value = "";
        this.description = "";
      } else {
        Vue.swal({
          // eslint-disable-next-line max-len
          title: "No Files Uploaded!",
          text: "Please enter all details to upload the data",
          type: "error",
          showCancelButton: false,
          confirmButtonColor: "accent",
          confirmButtonText: "Ok",
          closeOnConfirm: false,
        });
        this.title = "";
        this.selectTitle = "";
        this.files = [];
        document.querySelector("input[type=file]").value = "";
        this.description = "";
      }
    },
    downloadFiles() {
      this.dataArr = [];
      // eslint-disable-next-line max-len
      // let cityOrderId = document.querySelector(" div > ul > li:nth-child(5) > div > span").textContent;
      // let cityOrderId = this.order;
      // eslint-disable-next-line max-len
      const orderId = this.order;
      assignmentsApi.getFiles(orderId).then((response) => {
        const data = response.data;
        if (data.length > 0) {
          data.forEach((x) =>{
            const imgName = x.imageName;
            const mimeType = x.imageName.split(".")[1];
            let imgString = "";
            if (mimeType === "pdf") {
              // eslint-disable-next-line max-len
              imgString = `<iframe title="PDF File" src="data:application/pdf;base64, ${x.imageString}" width="100%" height="100%"></iframe>`;
            } else if (x.imageString) {
              // eslint-disable-next-line max-len
              imgString = `<img src="data:image/png;base64, ${x.imageString}" class="pa-1" width="100%" height="100%" style="border: 1px solid black"/>`;
            }
            // eslint-disable-next-line max-len
            const date = moment(x.uploadDate).local().format("MM/DD/YYYY");
            const time = moment.utc(x.uploadTime).format("h:mm A");
            const title = x.title;
            const description = x.description;
            if (imgString) {
              // eslint-disable-next-line max-len
              const dataObj = {
                "name": imgName,
                "imageSrc": imgString,
                "uploadedDate": date,
                "uploadedTime": time,
                "title": title,
                "description": description,
              };
              this.dataArr.push(dataObj);
            }
          });
        } else {
          this.dataArr = [];
        }
      });
    },
  },
};
</script>
<style scoped>
.flex-content {
  padding: 0;
  margin: 0;
  list-style: none;
  display: flex;
  flex-wrap: wrap;
  width: 100%;
}
.flex-item {
  padding: 5px;
  width: 50%;
  font-weight: bold;
  word-wrap: break-word;
}
.text-xs-right {
  word-wrap: break-word;
}

.v-data-table tbody > tr > td:nth-child(1) {
  max-width: 120px !important;
  overflow: hidden !important;
  white-space: nowrap !important;
  text-overflow: ellipsis !important;
}
.v-data-table tbody > tr > td > ul > li:nth-child(1) {
  max-width: auto !important;
  overflow: hidden !important;
  white-space: nowrap !important;
  text-overflow: ellipsis !important;
}

.view-link {
  cursor: pointer;
  color: blue;
  text-decoration: underline;
}
.headline {
  width: 70% !important;
}
a:hover {
  color: blue !important;
}
.pad-top{
  padding-top: 35px;
}
.float{
  float: right;
}
.v-dialog .v-card__text {
  padding-right: 10px !important;
}
</style>

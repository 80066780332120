<template>
  <v-row>
    <v-col class="hidden-sm-and-up" xs="12">
      <TriColList :detail-items="mobileDetailItems" />
    </v-col>

    <v-col class="hidden-xs-only hidden-lg-and-up" sm="6">
      <TriColList :detail-items="firstHalfDetailItems" />
    </v-col>
    <v-col class="hidden-xs-only hidden-lg-and-up" sm="6">
      <TriColList :detail-items="secondHalfDetailItems" />
    </v-col>

    <v-col class="hidden-md-and-down" lg="4">
      <TriColList :detail-items="firstThirdDetailItems" />
    </v-col>
    <v-col class="hidden-md-and-down" lg="4">
      <TriColList :detail-items="secondThirdDetailItems" />
    </v-col>
    <v-col class="hidden-md-and-down" lg="4">
      <TriColList :detail-items="finalThirdDetailItems" />
    </v-col>
  </v-row>
</template>
<script>
import {mapGetters} from "vuex";
import {Role} from "@/helpers/role";
import UserTime from "@/helpers/userTime.js";
import TriColList from "@/components/TriColList.vue";
import numeral from "numeral";

export default {
  name: "OrderDetailsTriColumn",
  props: {
    order: Array,
  },
  components: {
    TriColList,
  },
  methods: {
    getNumeral(amount) {
      return numeral(amount).format("$0,0.00");
    },
    getDateTimeWithTimezone(datetime, timezone) {
      return datetime ? `${datetime} ${timezone}` : "";
    },
    getFormattedPhone(phone) {
      if (!phone || phone == " ") return "";
      const phoneArray = phone.match(/(\d{3})(\d{3})(\d{4})/);
      return "(" + phoneArray[1] + ") " + phoneArray[2] + "-" + phoneArray[3];
    },
    getMediaMonkeyProductionSheetLink(cityOrderId) {
      return (
        process.env.VUE_APP_ORDER_DETAILS_MONKEY_MEDIA_PRODUCTION_SHEET_LINK +
        cityOrderId + "/?shownav=0"
      );
    },
    getMediaMonkeyInvoiceLink(cityOrderId) {
      return (
        process.env.VUE_APP_ORDER_DETAILS_MONKEY_MEDIA_INVOICE_LINK +
        cityOrderId
      );
    },
  },
  computed: {
    ...mapGetters({
      currentUser: "getCurrentUser",
    }),
    isEmployee() {
      return (
        this.currentUser.roles.length == 1 &&
        this.currentUser.roles[0] === Role.Employee
      );
    },
    mobileDetailItems() {
      const copy = Array.from(this.detailItems);
      copy.push({
        label: "Instructions",
        data: this.order.eventInstructions,
        visibleForEmployee: true,
      },
      {
        label: "Additional Notes",
        data: this.order.notes,
        visibleForEmployee: true,
      });
      return copy;
    },
    firstHalfDetailItems() {
      const copy = Array.from(this.detailItems);
      const halfLength = Math.ceil(this.detailItems.length / 2);
      const ret = copy.slice(0, halfLength);
      ret.push({
        label: "Instructions",
        data: this.order.eventInstructions,
        visibleForEmployee: true,
      });
      return ret;
    },
    secondHalfDetailItems() {
      const copy = Array.from(this.detailItems);
      const halfLength = Math.ceil(this.detailItems.length / 2);
      const ret = copy.slice(halfLength, copy.length);
      ret.push({
        label: "Additional Notes",
        data: this.order.notes,
        visibleForEmployee: true,
      });
      return ret;
    },
    firstThirdDetailItems() {
      const copy = Array.from(this.detailItems);
      const thirdLength = Math.ceil(this.detailItems.length / 3);
      return copy.slice(0, thirdLength);
    },
    secondThirdDetailItems() {
      const copy = Array.from(this.detailItems);
      const thirdLength = Math.ceil(this.detailItems.length / 3);
      const twoThirdLength = Math.ceil((2 * this.detailItems.length) / 3);
      const ret = copy.slice(thirdLength, twoThirdLength);
      ret.push({
        label: "Additional Notes",
        data: this.order.notes,
        visibleForEmployee: true,
      });
      return ret;
    },
    finalThirdDetailItems() {
      const copy = Array.from(this.detailItems);
      const twoThirdLength = Math.ceil((2 * this.detailItems.length) / 3);
      const ret = copy.slice(twoThirdLength, copy.length);
      ret.push({
        label: "Instructions",
        data: this.order.eventInstructions,
        visibleForEmployee: true,
      });
      return ret;
    },
    detailItems() {
      const fullDetails = [
        {
          label: "MM Id",
          data: this.order.cityOrderId,
          visibleForEmployee: true,
        },
        {
          label: "EZ Id",
          data: this.order.ezCaterOrderId,
          visibleForEmployee: true,
        },
        {
          label: "Name",
          data: this.order.name,
          visibleForEmployee: true,
          voided: this.order.isDeleted,
          format: this.order.format,
          ezOrder: this.order.ezCaterOrderId,
          quote: this.order.type,
        },
        {
          label: "Format",
          data: this.order.format,
          visibleForEmployee: true,
        },
        {
          label: "Accounting Unit",
          data: this.order.accountingUnit,
          visibleForEmployee: false,
        },
        {
          label: "Type",
          data: this.order.orderClass,
          visibleForEmployee: false,
        },
        {
          label: "Status",
          data: this.order.status,
          visibleForEmployee: false,
        },
        {
          label: "Revenue Source",
          data: this.order.revenueSourceName,
          visibleForEmployee: false,
        },
        {
          label: "Entered By",
          data: this.order.enteredByName,
          visibleForEmployee: false,
        },
        {
          label: "Market",
          data: `${this.order.accountingUnit} - ${this.order.market}`,
          visibleForEmployee: true,
        },
        {
          label: "Client",
          data: this.order.clientName,
          visibleForEmployee: true,
        },
        {
          label: "Address",
          data: this.order.deliveryAddress,
          visibleForEmployee: true,
        },
        {
          label: "Event Total",
          data: this.getNumeral(this.order.eventCost),
          visibleForEmployee: false,
        },
        {
          label: "Guest Count",
          data: this.order.guests,
        },
        {
          label: "Date",
          data: this.getDateTimeWithTimezone(
              UserTime.getLongDateTime(this.order.startDateTime),
              this.order.startDateTimeZone,
          ),
          visibleForEmployee: true,
        },
        {
          label: "Eat Time",
          data: this.getDateTimeWithTimezone(
              UserTime.getShortTime(this.order.startDateTime),
              this.order.startDateTimeZone,
          ),
          visibleForEmployee: true,
        },
        {
          label: "Expected End",
          data: this.getDateTimeWithTimezone(
              UserTime.getShortTime(this.order.expectedEnd),
              this.order.startDateTimeZone,
          ),
          visibleForEmployee: false,
        },
        {
          label: "Region",
          data: this.order.regionName,
          visibleForEmployee: false,
        },
        {
          label: "Store",
          data: this.order.store,
          visibleForEmployee: true,
        },
        {
          label: "Billing Name",
          data: this.order.billingName,
          visibleForEmployee: false,
        },
        {
          label: "Confirmed By",
          data: this.order.confirmedBy,
          visibleForEmployee: false,
        },
        {
          label: "Confirmed At",
          data: this.getDateTimeWithTimezone(
              UserTime.getLongDateTime(this.order.confirmedDateTime),
              this.order.startDateTimeZone,
          ),
          visibleForEmployee: false,
        },
        {
          label: "Gratuity",
          data: this.getNumeral(this.order.gratuity),
          visibleForEmployee: false,
        },
        {
          label: "Auto-Gratuity",
          data: this.getNumeral(this.order.autoGratuity),
          visibleForEmployee: false,
        },
        {
          label: "Additional Gratuity",
          data: this.getNumeral(this.order.additionalGratuity),
          visibleForEmployee: false,
        },
        {
          label: "Delivery Fee",
          data: this.getNumeral(this.order.deliveryFee),
          visibleForEmployee: false,
        },
        {
          label: "Delivery Fee Adjustment",
          data: this.getNumeral(this.order.deliveryFeeAdjustment),
          visibleForEmployee: false,
        },
        {
          label: "Delivery Fee Default",
          data: this.getNumeral(this.order.deliveryFeeDefault),
          visibleForEmployee: false,
        },
        {
          label: "Delivery Type",
          data: this.order.deliveryType,
          visibleForEmployee: true,
        },
        {
          label: "Accepter Company",
          data: this.order.orderAccepterCompany,
          visibleForEmployee: true,
        },
        {
          label: "Accepter Name",
          data: this.order.orderAccepterName,
          visibleForEmployee: true,
        },
        {
          label: "Accepter Phone",
          data: this.getFormattedPhone(this.order.orderAccepterPhone),
          visibleForEmployee: true,
        },
        {
          label: "Paid in Full",
          data: this.order.isPaid ? "Yes" : "No",
          visibleForEmployee: false,
        },
        {
          label: "Paid Date",
          data: this.getDateTimeWithTimezone(
              UserTime.getLongDateTime(this.order.paidDate),
              this.order.startDateTimeZone,
          ),
          visibleForEmployee: false,
        },
        {
          label: "Commission Sales Amount",
          data: this.getNumeral(this.order.totalFoodSales),
          visibleForEmployee: false,
        },
        {
          label: "Non-Food Sales",
          data: this.getNumeral(this.order.nonFoodSales),
          visibleForEmployee: false,
        },
        {
          label: "MM Invoice",
          data: `Invoice - ${this.order.cityOrderId}`,
          link: this.getMediaMonkeyInvoiceLink(this.order.cityOrderId),
          visibleForEmployee: true,
          isLink: true,
        },
        {
          label: "MM Production Sheet",
          data: `Sheet - ${this.order.cityOrderId}`,
          link: this.getMediaMonkeyProductionSheetLink(this.order.cityOrderId),
          visibleForEmployee: true,
          isLink: true,
        },
      ];
      if (this.isEmployee) {
        return fullDetails.filter((item) => {
          return item.visibleForEmployee === true;
        });
      } else {
        return fullDetails;
      }
    },
  },
};
</script>
